import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Label, Row, Input,
} from 'reactstrap';

function Items({
  hideNavButtons, toggleTabProgress
  ,
}) {
  return (
    <Form
      id="general-information"
      className="px-5"
      onSubmit={(e) => toggleTabProgress(3, e)}
    >
      <Row className="d-flex align-items-center form-header">
        <Col className="d-flex align-items-center">
          {hideNavButtons && <span className="step-number-round mr-2">01</span>}
          Items
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="4">
          <Label className="label-header" for="entityTitle">
            Description of items
          </Label>
        </Col>
        <Col lg="8">
          <textarea id="1" className="form-control input-text" rows="2" />
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="4">
          <Label className="label-header" for="entityDate">
            Quantity / Weight (Pcs. / kg)
          </Label>
        </Col>
        <Col md="8">
          <Input
            type="text"
            className="form-control input-text"
          />
        </Col>
      </Row>
      <Row className="mt-60px">
        <Col lg="4">
          <Label className="label-header" for="legalAddress">
            Value (EUR)
          </Label>
        </Col>
        <Col lg="8">
          <Input
            type="number"
            id="percent"
            className="inner form-control"
            min={0}
          />
        </Col>
      </Row>
      {!hideNavButtons && (
        <Row className="mt-60px">
          <Col>
            <div className="pr-0 d-flex">
              <div className="ml-auto d-flex align-items-center">

                <button
                  className="d-flex justify-content-center align-items-center btn btn-outline-primary btn-block waves-effect waves-light mr-3"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '143px',
                    fontSize: '14px',
                  }}

                >
                  Save for later
                </button>

                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary btn-block waves-effect waves-light mt-0"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '100px',
                    fontSize: '14px',
                  }}
                >
                  Next
                  <i
                    className="bx bx-right-arrow-alt ml-1"
                    style={{
                      fontSize: '20px',
                    }}
                  />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  );
}
Items.defaultProps = {
  hideNavButtons: false,
};

Items.propTypes = {
  hideNavButtons: PropTypes.bool,
  toggleTabProgress: PropTypes.func.isRequired,
};

export default Items;
