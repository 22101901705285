import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.scss';

const iconClasses = {
  close: 'bx bx-x',
  trash: 'bx bx-trash-alt',
  eye: 'bx bx-show',
};

function Icon({ name, size }) {
  if (name && iconClasses?.[name]) {
    return (
      <i className={classnames(
        styles.icon,
        iconClasses?.[name],
        styles[size],
      )}
      />
    );
  }
  return null;
}
Icon.defaultProps = {
  size: 'md',
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    'close',
    'trash',
    'eye',
  ]).isRequired,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
  ]),
};

export default Icon;
