import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useLanguage } from 'contexts/LanguageContext';

import { Icon, Button } from 'components/ui';

function NotificationItem({
  content,
  type,
  date,
  isRead,
  isPermissionRequired,
  onAcceptPermission,
  onMarkAsRead,
  onDelete,
}) {
  const { translate } = useLanguage();

  return (
    <li className="_notifications__list-item">
      <div className={classnames(
        '_notifications__list-item-dot',
        type,
      )}
      />
      <div className="_notifications__list-item-content">
        {content}
        <div className="_notifications__list-item-content-date">
          {date}
        </div>
      </div>
      <div className="_notifications__list-item-actions">
        {!isRead && (
          <Button
            color="link"
            onClick={onMarkAsRead}
          >
            {translate('notifications.actions.markAsRead')}
          </Button>
        )}

        {isPermissionRequired && (
          <Button
            color="link"
            onClick={onAcceptPermission}
          >
            {translate('notifications.actions.accept')}
          </Button>
        )}
        <Button onClick={onDelete}>
          <Icon name="close" size="lg" />
        </Button>
      </div>
    </li>
  );
}

NotificationItem.propTypes = {
  content: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  isPermissionRequired: PropTypes.bool.isRequired,
  onAcceptPermission: PropTypes.func.isRequired,
  onMarkAsRead: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NotificationItem;
