import React from 'react';

// TODO: delete component after new design
import CreateList from './CreateList';

function ScreeningCreate() {
  return (
    <CreateList />
  );
}

export default ScreeningCreate;
