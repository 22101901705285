import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, resetStates } from '../store/reducers/auth';

function useQuery() {
  return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
}

function ResetPassword() {
  const { token, email } = useQuery();

  const { error, status, loading } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const [validations, setValidations] = useState([false, false, false]);
  const [redirect, setRedirect] = useState(false);
  const [checkMatch, setCheckMatch] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const {
      password: { value: password },
      confirmpassword: { value: confirmpassword },
    } = e.target.elements;
    if (password === confirmpassword) {
      dispatch(resetPassword(password, token));
      setTimeout(() => {
        setRedirect(true);
      }, 1500);
    }
  }

  const getStatusIcon = (v) => (v ? (
    <i className="bx bxs-check-square align-middle mr-2 success-icon" />
  ) : (
    <i className="bx bxs-x-square align-middle mr-2 error-icon" />
  ));

  const validatePassword = (e) => {
    const password = e.target.value;
    let passwordMatch = '';
    if (e.target.id === 'password') {
      passwordMatch = document.querySelector('#confirmpassword').value;
      setValidations([
        password.length > 7,
        password.search(/[A-Z]/) > -1,
        password.search(/[0-9]/) > -1,
      ]);
    } else if (e.target.id === 'confirmpassword') {
      passwordMatch = document.querySelector('#password').value;
    }
    setCheckMatch((password === passwordMatch)
    && password.trim().length > 0
    && passwordMatch.trim().length > 0);
  };

  useEffect(() => {
    if (status === 'error') {
      setTimeout(() => {
        history.push('/login');
      }, 3500);
    }
    if (status === 'ok') {
      setTimeout(() => {
        history.push('/login');
      }, 3500);
    }
  }, [status]);

  useEffect(() => {
    dispatch(resetStates());
    localStorage.clear();
    localStorage.setItem('email', email);
  }, [token, email]);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col xs="12">
                    <div className="text-primary p-4">
                      <p>
                        Please fill your information to reset your password.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-4">
                <div className="p-2">
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    {error && (
                      <Alert color="danger">Invalid email or password.</Alert>
                    )}
                    {/* <div className="form-group">
                      <Label htmlFor="email">Email</Label>
                      <Input required id="email"
                      name="email" type="email" placeholder="Enter email" />
                    </div> */}
                    <div className="form-group">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        disabled
                        style={{
                          opacity: '0.7',
                        }}
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="form-group">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        required
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={validatePassword}
                      />
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          {getStatusIcon(validations[0])}
                          {' '}
                          must be at least 8
                          characters
                        </li>
                        <li className="list-group-item">
                          {getStatusIcon(validations[1])}
                          {' '}
                          must contain a capital
                          letter
                        </li>
                        <li className="list-group-item">
                          {getStatusIcon(validations[2])}
                          {' '}
                          must contain a number
                        </li>
                        <li className="list-group-item">
                          {getStatusIcon(checkMatch)}
                          {' '}
                          must password match
                        </li>
                      </ul>
                    </div>
                    <div className="form-group">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        required
                        id="confirmpassword"
                        name="confirmpassword"
                        type="password"
                        placeholder="Confirm your password"
                        onChange={validatePassword}
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        disabled={
                          loading
                          || status === 'ok'
                          || status === 'error'
                          || validations.reduce((acc, cur) => acc + cur) < 3
                          || !checkMatch
                        }
                        className={classnames({
                          btn: true,
                          'btn-block': true,
                          'waves-effect': true,
                          'waves-light': true,
                          'btn-primary':
                            status === undefined
                            || status === null
                            || status.length === 0,
                          'btn-success': status === 'ok',
                          'btn-danger': status === 'error',
                        })}
                        type="submit"
                      >
                        {redirect && 'You have been redirected to login...'}
                        {!redirect && status === 'ok'
                          && 'Your password has been changed successfully.'}
                        {!redirect && status === 'error'
                          && 'You have already changed your password.'}
                        {!redirect && status !== 'error' && status !== 'ok' && 'Reset'}
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-muted">
                        <i className="mdi mdi-lock mr-1" />
                        Sign in
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
