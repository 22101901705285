import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

const initialState = {
  results: [],
  error: false,
  loading: false,
  status: '',
};

const historySlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    historyStart(state) {
      state.error = false;
      state.loading = true;
      state.status = '';
    },
    historySuccess(state, action) {
      state.results = action.payload;
      state.error = false;
      state.loading = false;
      state.status = 'ok';
    },
    historyFail(state) {
      state.error = true;
      state.loading = false;
    },
    deleteHistorySuccess(state, action) {
      state.results = state.results.filter((r) => !(r.search_id === action.payload));
      state.error = false;
      state.loading = false;
      state.status = 'ok';
    },
    deleteSelectedHistorySuccess(state, action) {
      state.results = state.results.filter((h) => !(action.payload.selectedRows
        .some(({ id }) => id === h.search_id)));
      state.error = false;
      state.loading = false;
      state.status = 'ok';
    },
    deleteHistoryFail(state) {
      state.error = false;
      state.loading = false;
      state.status = 'ok';
    },
  },
});

export const {
  historyStart,
  historySuccess,
  historyFail,
  deleteHistorySuccess,
  deleteSelectedHistorySuccess,
  deleteHistoryFail,
} = historySlice.actions;

export default historySlice.reducer;

export const getSearchHistory = (id) => async (dispatch) => {
  dispatch(historyStart());
  try {
    const { data: { searches } } = await api.getSearchHistory(id);
    dispatch(historySuccess(searches));
  } catch (error) {
    dispatch(historyFail());
  }
};

export const deleteSelectedHistory = (userId, id) => async (dispatch) => {
  dispatch(historyStart());
  try {
    await api.deleteSearchHistory(userId, id);
    dispatch(deleteSelectedHistorySuccess({ selectedRows: id }));
  } catch (error) {
    console.log('error: ', error);
    dispatch(deleteHistoryFail());
  }
};

export const deleteSearchHistory = (userId, id) => async (dispatch) => {
  try {
    await api.deleteSearchHistory(userId, [{ id }]);
    dispatch(deleteHistorySuccess(id));
  } catch (error) {
    console.log('error: ', error);
    dispatch(deleteHistoryFail());
  }
};
