import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Link,
} from 'react-router-dom';
import { Card, Button, Spinner } from 'reactstrap';

import List from '../components/List';
import Map from '../assets/images/lists/bgbig.png';
import '../assets/css/lists.css';
import { loadLists, loadListItem } from '../store/reducers/lists';
import ListDetailLayout from './ListDetailLayout';
import { changeRoute, resetRoute } from '../store/reducers/route';

function ListLayout() {
  const { data: lists, initialized } = useSelector((state) => state.lists);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!initialized) {
      dispatch(loadLists());
    } else {
      lists.map((list) => {
        dispatch(loadListItem(list.id));
        return '';
      });
    }
    dispatch(changeRoute('Lists '));
    return () => {
      dispatch(resetRoute());
    };
    // eslint-disable-next-line
  }, [dispatch, initialized]);

  if (!initialized) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="mr-2" color="secondary" />
      </div>
    );
  }

  const getNoDataCard = () => (
    <div className="noData">
      <Card className="listCard">
        <h3>There is no list you have created</h3>
        <br />
        <p>If you want create a new list, just click.</p>
        <div>
          <Link to="lists/create">
            <Button
              color="primary"
              className="addListBtn btn waves-effect waves-light"
            >
              Add new list
            </Button>
          </Link>
        </div>
        <br />
        <br />
        <div id="imgContainer">
          <img src={Map} alt="no data" />
        </div>
      </Card>
    </div>
  );

  return (
    <Switch>
      <Route exact path={path}>

        {lists.length === 0 ? (
          getNoDataCard()
        ) : (
          <List listData={lists} />
        )}
      </Route>
      <Route path={`${path}/:id`} component={ListDetailLayout} />
    </Switch>
  );
}

export default ListLayout;
