import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  Row,
  Col,
  Input,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  Button,
  CardBody,
  Card,
  Spinner,
} from 'reactstrap';

import DataTable from 'react-data-table-component';
import ReactSelect from './ReactSelect';

import {
  historyColumns,
  customTableStyles,
  searchFilters,
} from './constants';

import CancelConfirmModal from '../../components/CancelConfirmModal';

// import pdfIcon from '../../assets/images/pdf_24px_outlined.svg';

import '../../assets/css/search-history.css';
import { useAPI } from '../../services/api';
import { filterSearchHistory, formattedFromTo } from './utils';
import { getSearchHistory, deleteSearchHistory, deleteSelectedHistory } from '../../store/reducers/search-history';
import { changeRoute, resetRoute } from '../../store/reducers/route';
// import { makeAdvancedSearch } from '../../store/reducers/search';

const getCurrentDate = (range) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  if (range) return `${yyyy}/${mm}/${dd}`;
  return `${yyyy}-${mm}-${dd}`;
};

function SearchHistory() {
  const api = useAPI();

  const dispatch = useDispatch();
  const historyResults = useSelector((state) => state.history.results);
  const hLoading = useSelector((state) => state.history.loading);
  // const historyStatus = useSelector((state) => state.history.status);

  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.search);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);

  const [query, setQuery] = useState('');
  const [inQuery, setInQuery] = useState('');
  const [clearSelected, setClearSelected] = useState(false);

  const [confirmToggle, setConfirmToggle] = useState(false);
  const [confirmAllToggle, setConfirmAllToggle] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  const [requestBtn, setRequestBtn] = useState(false);

  const [
    searchHistory,
    setSearchHistory,
  ] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    [],
  );
  const [
    selectedFilters,
    setSelectedFilters,
  ] = useState([]);

  // eslint-disable-next-line
  const [usersData, setUsersData] = useState([]);

  const [dateDropdown, setDateDropdown] = useState(
    false,
  );

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [filters, setFilters] = useState([]);
  // eslint-disable-next-line
  const [filteredHistory, setFilteredHistory] = useState([]);
  // eslint-disable-next-line
  const [maxScore, setMaxScore] = useState(100);
  // eslint-disable-next-line
  const [minScore, setMinScore] = useState(65);
  // eslint-disable-next-line
  const [minMaxScore, setMinMaxScore] = useState([65,100]);

  const getUsers = async () => {
    const { data: { users } } = await api.fetchUsers();

    const fixedUsers = users.map((usr) => ({
      value: usr.id,
      label: usr.name,
      user: true,
    }));
    setUsersData(fixedUsers);
  };

  const onDeleteHistory = (id) => {
    dispatch(deleteSearchHistory(user.id, id));
  };

  const getHistory = () => {
    if (user?.id !== undefined) {
      const fixedSearchHistory = historyResults.map((sHistory) => {
        const searchOpt = sHistory.search_options;
        let fixedOptions = [
          ...Object.keys(searchOpt),
        ].map((opt) => {
          if (searchOpt[opt] === true) {
            if (opt === 'aka') {
              return 'Include AKA';
            }
            if (opt === 'phonetics') {
              return 'Include Phonetics';
            }
          }
          return null;
        });

        fixedOptions = fixedOptions.filter(
          (opt2) => opt2 !== null,
        );

        // const fixedSensivity = (s) => {
        //   switch (s) {
        //     case 0:
        //       return 'Exact Match';
        //     case 1:
        //       return 'Moderate';
        //     case 2:
        //       return 'Loose';
        //     default:
        //       return '';
        //   }
        // };

        const fixedDate = (date) => {
          const givenDate = new Date(date);
          const hh = givenDate.getHours();
          const hours = hh < 10 ? `0${hh}` : hh;
          const m = givenDate.getMinutes();
          const minute = m < 10 ? `0${m}` : m;
          const dateTimeFormat = new Intl.DateTimeFormat('en', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          });
          const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
            .formatToParts(givenDate);

          return `${day} ${month} ${year} at ${hours}:${minute}`;
        };
        return {
          id: sHistory.search_id,
          pureDate: sHistory.search_date,
          searchAgain: (
            <Link
              to={`/search?q=${sHistory.search_name}&s=${sHistory.search_score}&phonetics=${sHistory.search_options.phonetics}&aka=${sHistory.search_options.aka}`}
            >
              <Button
                type="button"
                color="link"
                className="btn btn-link restore"
                onClick={() => {}}
              >
                <i
                  className="mdi mdi-restore"
                  style={{
                    marginTop: '-15px',
                    marginLeft: '-13px',
                    position: 'absolute',
                  }}
                />
              </Button>
            </Link>),
          date: fixedDate(sHistory.search_date),
          search_input: sHistory.search_name.length !== 0 ? sHistory.search_name
            : sHistory.search_identity_number,
          search_score: sHistory.search_score,
          search_options: fixedOptions.join(', '),
          count: sHistory.search_results_length,
          user: sHistory.user.name,
          actions: (
            <div className="history-actions">
              <Button
                data-id={sHistory.search_id}
                type="button"
                color="link"
                className="btn btn-link"
                style={{
                  width: '24px',
                  placeItems: 'center',
                  marginRight: '30px',
                }}
                onClick={(e) => {
                  setSelectedId(parseInt(e.currentTarget.getAttribute('data-id'), 10));
                  setSelectedName(sHistory.search_name);
                  setConfirmToggle(true);
                  // onDeleteHistory(parseInt(e.currentTarget.getAttribute('data-id'), 10));
                }}
              >
                <i
                  className="mdi mdi-delete-outline delete"
                  style={{
                    marginLeft: '-12px',
                    marginTop: '-20px',
                  }}
                />
              </Button>
            </div>
          ),
        };
      });
      setSearchHistory(fixedSearchHistory);
      return fixedSearchHistory;
    }
    return '';
  };

  useEffect(() => {
    if (searchHistory.length === 0) {
      getHistory();
    }
    if (usersData.length === 0) getUsers();
    const temp = [];

    temp.push({
      value: 'minMaxScore',
      label: `${minMaxScore[0]}-${minMaxScore[1]}`,
    });
    temp.push(...selectedUsers);
    temp.push(...selectedFilters);
    if (fromDate !== '' && toDate !== '') {
      temp.push({
        value: 'fromto',
        label: `From ${formattedFromTo(fromDate)} to ${formattedFromTo(
          toDate,
        )}`,
      });
    }
    setFilters(temp);
    let f = getHistory();
    try {
      f = filterSearchHistory(query.trim(), temp, f, fromDate, toDate, minMaxScore);
    } catch (error) {
      console.log(error);
    }
    setFilteredHistory(f);
    // eslint-disable-next-line
  }, [
    historyResults,
    loading,
    query,
    setQuery,
    selectedFilters,
    selectedUsers,
    setSelectedFilters,
    setSelectedUsers,
    toDate,
    setToDate,
    minMaxScore,
    setMinMaxScore,
  ]);

  useEffect(() => {
    getHistory();
  }, [clearSelected, dispatch]);

  const onFilterRemove = (e) => {
    if (
      e.target.id === 'phonetics'
      || e.target.id === 'aka'
    ) {
      setSelectedFilters(
        selectedFilters.filter(
          (f) => f.value !== e.target.id,
        ),
      );
    }
    if (e.target.getAttribute('data-id') === 'true') {
      setSelectedUsers(selectedUsers.filter((f) => f.value.toString() !== e.target.id));
    }
    if (e.target.id === 'fromto') {
      setFromDate('');
      setToDate('');
    }
    if (e.target.id === query) {
      setQuery('');
    }
  };

  const checkIncludes = (q) => {
    let tmp = [];
    const tQuery = q.trim();
    if (tQuery.length > 0) {
      tmp = searchHistory.filter(
        (h) => h.date.includes(tQuery)
          || h.search_input.toLowerCase().includes(tQuery)
          || h.search_options.toLowerCase().includes(tQuery)
          || h.search_sensivity.toLowerCase().includes(tQuery)
          || h.user.toLowerCase().includes(tQuery),
      );
    }
    if (query.trim().length > 0) {
      tmp = searchHistory.filter(
        (h) => (h.date.includes(tQuery)
            || h.search_input.toLowerCase().includes(tQuery)
            || h.search_options.toLowerCase().includes(tQuery)
            || h.search_sensivity.toLowerCase().includes(tQuery)
            || h.user.toLowerCase().includes(tQuery))
          && (h.date.includes(query)
            || h.search_input.toLowerCase().includes(query)
            || h.search_options.toLowerCase().includes(query)
            || h.search_sensivity.toLowerCase().includes(query)
            || h.user.toLowerCase().includes(query)),
      );
    }
    setFilteredHistory(tmp);
  };

  const onSortRows = (rows, field, direction) => {
    const ab = rows.sort((a, b) => {
      if (field === 'date') {
        if (new Date(a.pureDate) < new Date(b.pureDate)) {
          return -1;
        }
        if (new Date(a) > new Date(b)) {
          return 1;
        }
      } else {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
      }

      return 0;
    });
    if (direction === 'asc') return ab.slice(0);
    return ab.slice(0).reverse();
  };

  const onDeleteSelected = () => {
    dispatch(deleteSelectedHistory(user.id, selectedRows));
    setTimeout(() => {
      setClearSelected(true);
    }, 1000);
    setTimeout(() => {
      setClearSelected(false);
    }, 1100);
  };

  const getAllReports = (reportId, type) => {
    const reportInterval = setInterval(async () => {
      const { data: { reports: allRep } } = await api.getGeneratedReports(user?.id);
      const report = allRep.filter((r) => r.id === reportId && r.type_id === 2)[0];
      // eslint-disable-next-line
      if (report?.is_available) {
        toastr.options = {
          timeOut: 0,
          extendedTimeOut: 0,
          closeButton: true,
          onclick() { window.open(report.url, '_blank'); },
        };
        toastr.clear();
        toastr.success('Search history report is ready to download. Please click here to download the report', `DOWNLOAD ${type.toUpperCase()} REPORT`);
        clearInterval(reportInterval);
      }
    }, 2000);
  };
  // eslint-disable-next-line no-unused-vars
  const handleGenerate = async (type = 'pdf') => {
    // request pdf
    try {
      const { data: { report_id: reportId } } = await api.requestHistoryFile(user.id, type);
      toastr.info('Search history report is being generated. Please wait...', `PREPARING TO DOWNLOAD ${type.toUpperCase()}`);
      getAllReports(reportId, type);
    } catch (error) {
      console.log(error);
    }
  };

  const onScoreChange = (e) => {
    e.persist();
    if (e.target.id === 'minScore' || e.target.name === 'minScore') {
      if (parseInt(e.target.value, 10) > maxScore) {
        setMinScore(maxScore - 1);
        setMinMaxScore((prevState) => [(maxScore - 1), prevState[1]]);
      } else if (parseInt(e.target.value, 10) < 65) {
        setMinScore(65);
        setMinMaxScore((prevState) => [65, prevState[1]]);
      } else {
        setMinScore(parseInt(e.target.value, 10));
        setMinMaxScore((prevState) => [(parseInt(e.target.value, 10)), prevState[1]]);
      }
    }
    if (e.target.id === 'maxScore' || e.target.name === 'maxScore') {
      if (parseInt(e.target.value, 10) <= minScore) {
        setMaxScore(minScore + 1);
        setMinMaxScore((prevState) => [prevState[0], (minScore + 1)]);
      } else if (parseInt(e.target.value, 10) > 100) {
        setMaxScore(100);
        setMinMaxScore((prevState) => [prevState[0], 100]);
      } else {
        setMaxScore(parseInt(e.target.value, 10));
        setMinMaxScore((prevState) => [prevState[0], (parseInt(e.target.value, 10))]);
      }
    }
  };
  const handleScoreBlur = useCallback((e) => {
    e.persist();
    if (e.target.id === 'minScore' || e.target.name === 'minScore') {
      if (parseInt(e.target.value, 10) < 65) {
        setMinScore(65);
      } else if (parseInt(e.target.value, 10) > 100) {
        setMinScore(maxScore - 1);
      }
    }
    if (e.target.id === 'maxScore' || e.target.name === 'maxScore') {
      if (parseInt(e.target.value, 10) < minScore) {
        setMaxScore(minScore + 1);
      } else if (parseInt(e.target.value, 10) > 100) {
        setMaxScore(100);
      }
    }
  }, [minScore, maxScore]);

  useEffect(() => {
    dispatch(getSearchHistory(user.id));
    dispatch(changeRoute('Search-History'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  return (
    <>
      <CancelConfirmModal
        isOpen={confirmToggle}
        onConfirm={() => {
          onDeleteHistory(selectedId);
          setClearSelected(true);
          setTimeout(() => {
            setClearSelected(false);
          }, 500);
          setConfirmToggle(false);
        }}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn
        selectedName
      >
        Are you sure want to delete
        {' '}
        `
        <b>{selectedName}</b>
        `
        {' '}
        ? If you say yes, you cannot undo this step.
      </CancelConfirmModal>

      <CancelConfirmModal
        isOpen={confirmAllToggle}
        onConfirm={() => {
          onDeleteSelected();
          setClearSelected(true);
          setTimeout(() => {
            setClearSelected(false);
          }, 500);
          setConfirmAllToggle(false);
        }}
        onCancel={() => {
          setConfirmAllToggle(false);
        }}
        // loading={loading}
        // setIsOpen={setConfirmAllToggle}
        deleteUserBtn
        selectedCount={selectedRows.length}
      >
        Are you sure want to delete
        {' '}
        <b>
          {selectedRows.length}
          {' '}
          {selectedRows.length === 1 ? 'client' : 'clients'}
        </b>
        ? If you say yes, you cannot undo this step.
      </CancelConfirmModal>
      <div className="p-4">
        <Row>
          <Col className="pr-0">
            <div className="search-box mr-2">
              <div className="position-absolute search-height">
                <Input
                  autoFocus
                  type="search-history"
                  className="form-control"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  style={{
                    borderRadius: '5px 0px 0px 5px',
                    height: '100%',
                  }}
                />
                <i className="bx bx-search search-icon" />
              </div>
            </div>
          </Col>
          <Col md="2" className="p-0 slt">
            <ReactSelect
              header="Users"
              selectData={usersData}
              setSelected={setSelectedUsers}
              getSelected={selectedUsers}
            />
          </Col>
          <Col md="2" className="p-0 slt">
            <ReactSelect
              header="Search Filters"
              selectData={searchFilters}
              setSelected={setSelectedFilters}
              getSelected={selectedFilters}
            />
          </Col>
          <Col md="2" className="p-0">
            <ButtonDropdown
              className="w-100 button-dropdown height-dropdown date-dropdown"
              isOpen={dateDropdown}
              toggle={() => setDateDropdown(!dateDropdown)}
            >
              <DropdownToggle caret color="white" className="btn d-flex align-items-center date-range">
                Date Range
                <i
                  className="bx bxs-down-arrow ml-auto"
                  style={{
                    fontSize: '10px',
                    color: dateDropdown ? 'rgb(139, 139, 139)' : '#CED4DA',
                    transition: '0.1s',
                  }}
                />
              </DropdownToggle>
              <DropdownMenu className="date-range-dropdown-menu">
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mr-2">
                        <div>
                          <label className="font-weight-bold" htmlFor="s1">
                            From
                          </label>
                          <div className="form-group row ml-0 mt-3">
                            <div>
                              <input
                                className="form-control"
                                type="date"
                                defaultValue={getCurrentDate()}
                                id="from-date"
                                min="1900-01-01"
                                max={getCurrentDate()}
                                value={fromDate}
                                onChange={(e) => {
                                  setFromDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="mr-2">
                        <div>
                          <label className="font-weight-bold" htmlFor="s1">
                            To
                          </label>
                          <div className="form-group row ml-0 mt-3">
                            <div>
                              <input
                                disabled={fromDate.length === 0}
                                className="form-control"
                                type="date"
                                defaultValue={getCurrentDate()}
                                id="to-date"
                                min={fromDate}
                                max={getCurrentDate()}
                                value={toDate}
                                onChange={(e) => {
                                  setToDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
          <Col className="pl-0">
            <Button
              color="primary"
              className="font-16 btn-block height38px noRadiusLeft"
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row>
          <div className="col col-lg-4 mt-3">
            <div className="row">
              <div className="form-group col-lg-9">
                <label htmlFor="minScore">Minimum Score</label>
                <input
                  id="minScore"
                  name="minScore"
                  type="range"
                  min="65"
                  max="100"
                  value={minScore}
                  className="form-control-range"
                  onChange={(e) => onScoreChange(e)}
                />
              </div>
              <div className="form-group col-lg-3">
                <input
                  name="minScore"
                  type="number"
                  min="65"
                  max="100"
                  className="form-control mt-3"
                  value={minScore}
                  onChange={(e) => onScoreChange(e)}
                  onBlur={(e) => handleScoreBlur(e)}
                  style={{ width: '5em' }}
                />
              </div>
            </div>
          </div>
          <div className="col col-lg-4 ml-3 mt-3">
            <div className="row">
              <div className="form-group col-lg-9">
                <label htmlFor="maxScore">Maximum Score</label>
                <input
                  id="maxScore"
                  name="maxScore"
                  type="range"
                  min="65"
                  max="100"
                  value={maxScore}
                  className="form-control-range"
                  onChange={onScoreChange}
                />
              </div>
              <div className="form-group col-lg-3">
                <input
                  name="maxScore"
                  type="number"
                  min="65"
                  max="100"
                  className="form-control mt-3"
                  value={maxScore}
                  onChange={onScoreChange}
                  onBlur={handleScoreBlur}
                  style={{ width: '5em' }}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row
          className="ml-1 mt-3 d-flex"
          style={{
            gap: '10px',
          }}
        >
          {query.trim().length > 0 && (
            <span className="badge badge-soft-primary font-size-12 py-2 pl-3 pr-2 d-flex align-items-center font-weight-normal">
              {query}
              <button
                id={query}
                type="button"
                className="close ml-3 justify-self-end"
                data-dismiss="alert"
                aria-label="Close"
                onClick={onFilterRemove}
              >
                <span id={query} data-id={query} aria-hidden="true">
                  ×
                </span>
              </button>
            </span>
          )}
          {filters.map((filt) => (
            <span key={filt.label} className="badge badge-soft-primary font-size-12 py-2 pl-3 pr-2 d-flex align-items-center font-weight-normal search-history-label">
              {typeof filt === 'string' ? filt : filt.label}
              <button
                id={typeof filt === 'string' ? 'search' : filt.value}
                data-id={filt.user}
                type="button"
                className="close ml-3 justify-self-end"
                data-dismiss="alert"
                aria-label="Close"
                onClick={onFilterRemove}
              >
                <span
                  id={typeof filt === 'string' ? filt : filt.value}
                  data-id={filt.user}
                  aria-hidden="true"
                  style={{
                    color: '#5A8DBF',
                  }}
                >
                  ×
                </span>
              </button>
            </span>
          ))}
          {filters.length > 0 && (
            <Button
              color="link"
              className="btn btn-link waves-effect btn-clearAll"
              onClick={() => {
                setFromDate('');
                setToDate('');
                setSelectedUsers([]);
                setSelectedFilters([]);
                setFilters([]);
              }}
            >
              CLEAR ALL
            </Button>
          )}
        </Row>
        <Card className="mt-5">
          <CardBody className="p-5">
            <Row className="d-flex mb-4">
              <Col md="3" className="p-0">
                <div className="search-box mr-2">
                  <div className="position-relative search-height">
                    <Input
                      autoFocus
                      type="search-history-intable"
                      className="form-control"
                      placeholder="Search..."
                      value={inQuery}
                      onChange={(e) => {
                        setInQuery(e.target.value);
                        checkIncludes(e.target.value);
                      }}
                      style={{
                        borderRadius: '5px',
                      }}
                    />
                    <i className="bx bx-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md="9" className="d-flex justify-content-end pr-0 mr-0">
                {selectedRows.length > 0 && (
                <button type="button" className="py-0 mr-3 noBorder bg-white" onClick={() => setConfirmAllToggle(true)}>
                  <i className="mdi mdi-delete-outline font-size-24 delete" />
                </button>
                )}
                {/* <Button color="secondary" className="font-16 d-flex dReport">
                  <img src={pdfIcon} alt="Download PDF" />
                  Download Report
                </Button> */}
                <Dropdown
                  isOpen={requestBtn}
                  toggle={() => setRequestBtn(!requestBtn)}
                >
                  <DropdownToggle
                    disabled={historyResults.length === 0}
                    className="btn d-flex mr-2 align-items-center btn-secondary"
                    caret
                    style={{
                      paddingTop: '3px',
                      paddingBottom: '2px',
                    }}
                  >
                    <i
                      className="mdi mdi-download-outline"
                      style={{
                        fontSize: '18px',
                        marginRight: '11px',
                        marginTop: '3px',
                      }}
                    />
                    Generate Report
                    <i className="mdi mdi-chevron-down ml-auto" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem
                      className="dropdown-item-report"
                    >
                      <i
                        className="mdi mdi-microsoft-excel mr-2"
                      />
                      <span>Excel</span>
                    </DropdownItem> */}
                    <DropdownItem
                      className="dropdown-item-report"
                      tag={Link}
                      to={`/exports/previews/history/${user?.id}`}
                      target="_blank"
                    >
                      <i
                        className="mdi mdi-file-pdf-box-outline mr-2"
                      />
                      <span>Pdf</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

              </Col>
            </Row>
            <Row>
              <DataTable
                noDataComponent={<p>There are no search records to display</p>}
                clearSelectedRows={clearSelected}
                progressPending={hLoading}
                progressComponent={<Spinner className="mr-2" color="secondary" />}
                defaultSortField="date"
                defaultSortAsc={false}
                sortFunction={onSortRows}
                selectableRows
                selectableRowsVisibleOnly
                onSelectedRowsChange={(e) => {
                  setSelectedRows(e.selectedRows.map((s) => ({ id: s.id })));
                }}
                columns={historyColumns}
                customStyles={customTableStyles}
                data={
                  inQuery.trim().length > 0
                  || (filteredHistory.length > 0
                  || selectedFilters.length > 0
                  || selectedUsers.length > 0
                  || (fromDate !== '' && toDate !== '')
                  || query.trim().length > 0)
                    ? filteredHistory.reverse()
                    : searchHistory.reverse()
                }
                pagination
                highlightOnHover
                noHeader
              />
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SearchHistory;
