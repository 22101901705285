import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

const nameTextStyle = {
  fontSize: '16px',
};
const emailTextStyle = {
  fontSize: '13px',
};

function UserItemHeader({
  id,
  name,
  email,
  pending = false,
  isAdmin,
  onClick,
  setDeleteInvModal,
  setInv,
}) {
  const saving = useSelector((state) => state.users.saving);
  return (
    <>
      <Col md="8">
        <span className="d-block text-dark" style={nameTextStyle}>{name}</span>
        <span className="d-block text-muted" style={emailTextStyle}>{email}</span>
      </Col>
      <Col md="4" className="">
        {
          isAdmin
            ? (
              <span className="d-block text-dark text-center" style={nameTextStyle}>
                Admin
              </span>
            )
            : (
              <div className="d-flex align-items-start ">
                <Button
                  data-id={id}
                  block
                  color={pending ? 'info' : 'secondary'}
                  disabled={saving || pending}
                  onClick={onClick}
                >
                  {pending ? 'Pending' : 'Permissions'}
                </Button>
                {pending && (
                <Button
                  type="button"
                  color="link"
                  className="btn btn-link"
                  data-id={id}
                  style={{
                    width: '24px',
                    height: '38px',
                    display: 'flex',
                    placeItems: 'center',
                    marginLeft: '15px',
                    marginRight: '15px',
                    marginTop: '-6px',
                  }}
                  onClick={() => {
                    setInv({ name, id });
                    setDeleteInvModal(true);
                  }}
                >
                  <i
                    className="mdi mdi-delete-outline delete"
                    style={{
                      marginLeft: '-12px',
                    }}
                  />
                </Button>
                )}
              </div>
            )
        }
      </Col>
    </>
  );
}

UserItemHeader.defaultProps = {
  pending: false,
  name: '',
};

UserItemHeader.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  setInv: PropTypes.func.isRequired,
  setDeleteInvModal: PropTypes.func.isRequired,
};

export default UserItemHeader;
