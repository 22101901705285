import React from 'react';
import PropTypes from 'prop-types';

import { useLanguage } from 'contexts/LanguageContext';

import { Card } from 'components/ui';

function LastSearchedQueries({ queries }) {
  const { translate } = useLanguage();

  return (
    <Card>
      <h3 className="card-title mb-3 fw-500">
        {translate('dashboard.main.lastSearchedQueries.header')}
      </h3>
      <div className="_dashboard__last-searched-queries">
        {queries.map((query) => (
          <span key={query} className="d-flex align-items-start">
            <i className="bx bxs-circle" />
            <span>{query}</span>
          </span>
        ))}
      </div>
    </Card>
  );
}

LastSearchedQueries.defaultProps = {
  queries: [],
};

LastSearchedQueries.propTypes = {
  queries: PropTypes.arrayOf(PropTypes.string),
};

export default LastSearchedQueries;
