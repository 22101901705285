export const getNestedValue = (path = [], obj = {}, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev?.[curr], obj);
};

export const removeEmpty = (obj) => Object.keys(obj)
  .filter((key) => {
    if (Array.isArray(obj[key])) {
      return obj[key].length > 0;
    }
    if (typeof obj[key] === 'boolean') {
      return true;
    }

    return Boolean(obj[key]);
  })
  .reduce((acc, curr) => {
    acc[curr] = obj[curr];
    return acc;
  }, {});

export default getNestedValue;
