import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import notificationMiddleware from 'middlewares/notificationMiddleware';
import { ProvideLanguage } from 'contexts/LanguageContext';
import { ProvideAPI } from 'services/api';
import rootReducer from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([notificationMiddleware]),
});
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./store', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./store').default;
    store.replaceReducer(newRootReducer);
  });
}

ReactDOM.render(
  (
    // <React.StrictMode>
    <Provider store={store}>
      <ProvideAPI>
        <Router>
          <QueryClientProvider client={queryClient}>
            <ProvideLanguage>
              <App />
            </ProvideLanguage>
          </QueryClientProvider>
        </Router>
      </ProvideAPI>
    </Provider>
    // </React.StrictMode>
  ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
