import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container, Row, CardBody, Card, Col, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import MapsVector from './MapsVector';

import '../../assets/css/sanction-map.css';

import { customTableStyles, corruptionMapColumns, riskData } from './constants';

import { changeRoute, resetRoute } from '../../store/reducers/route';
import countries from '../../constants/countries';

import Autocomplete from '../../components/AutoComplete';

const coloredData = {};
riskData.map((dcnt) => countries.map((cnt) => {
  if (cnt.alpha3.toUpperCase() === dcnt.alpha3) {
    Object.assign(coloredData, {
      [cnt.alpha2.toUpperCase()]: parseInt(dcnt.rank, 10),
    });
  }
  return cnt;
}));

function CorruptionMap() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const mapData = useMemo(() => riskData.filter((r) => {
    if (query.trim().length > 0) {
      return r.countryName.toLowerCase()
        .includes(query.trim().toLowerCase())
        || r.region.toLowerCase()
          .includes(query.trim().toLowerCase())
          || r.risk.toLowerCase()
            .includes(query.trim().toLowerCase());
    }
    return true;
  }).map(({
    countryName, region, score, rank, risk,
  }) => ({
    name: countryName,
    region,
    score: parseFloat(score),
    rank: parseInt(rank, 10),
    risk,
  })),
  [query]);

  useEffect(() => {
    dispatch(changeRoute('Corruption-Map'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);
  const allCountries = countries.map(({ name }) => name);
  return (
    <>
      <Container fluid>
        <MapsVector
          coloredData={coloredData}
        />
        <Card className="mb-0">
          <CardBody>
            <Row>
              <Col>
                <Autocomplete
                  setInput={setQuery}
                  placeholder="Search..."
                  suggestions={allCountries}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <DataTable
              noDataComponent="There are no country to display"
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              defaultSortField="score"
              defaultSortAsc={false}
              pagination
              paginationPerPage={35}
              selectableRowsVisibleOnly
              selectableRows={false}
              columns={corruptionMapColumns}
              customStyles={customTableStyles}
              data={mapData}
              highlightOnHover
              noHeader
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CorruptionMap;
