import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Select,
} from 'antd';

import { fetchMedia, fetchMediaClear } from 'store/media';
import { useLanguage } from 'contexts/LanguageContext';

import {
  Card,
  Loading,
  Button,
  Input,
} from 'components/ui';
import MediaListItem from './MediaListItem';

const { Option } = Select;

const mediaSources = {
  1: 'NewsAPI',
  2: 'MediaStack',
  3: 'NAPIAI',
  4: 'NewsCatcher',
};

export default function MediaList() {
  const { translate } = useLanguage();

  const dispatch = useDispatch();

  const { pending, data, query: mediaQuery } = useSelector((state) => state.media);

  const [query, setQuery] = useState('');
  const [keywords, setKeywords] = useState('');
  const [sourceId, setSourceId] = useState(1);

  const getAdverseMedia = useCallback(() => {
    if (query.trim().length > 0) {
      dispatch(fetchMedia({ sourceId, query, keywords }));
    }
  }, [dispatch, sourceId, query, keywords]);

  useEffect(() => () => {
    dispatch(fetchMediaClear());
  }, [dispatch]);

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onKeywordsChange = (e) => {
    setKeywords(e.target.value);
  };

  const onSourceChange = (value) => {
    setSourceId(value);
  };

  const onPressEnter = () => {
    getAdverseMedia();
  };

  return (
    <Card className="_dashboard__media-list">
      <h4>{translate('dashboard.media.header')}</h4>
      <Row gutter={[12, 12]} className="_dashboard__media-list-inputs">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Input.Text
            placeholder={translate('dashboard.media.form.search.placeholder')}
            value={query}
            onChange={onQueryChange}
            onPressEnter={onPressEnter}
          />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Input.Text
            placeholder={translate('dashboard.media.form.keywords.placeholder')}
            value={keywords}
            onChange={onKeywordsChange}
            onPressEnter={onPressEnter}
          />
        </Col>
        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
          <Select
            defaultValue={Object.keys(mediaSources)[0]}
            style={{ width: '100%' }}
            onChange={onSourceChange}
          >
            <Option disabled>
              {translate('dashboard.media.form.source.placeholder')}
            </Option>
            {Object.keys(mediaSources).map((key) => (
              <Option
                key={mediaSources?.[key]}
                value={key}
              >
                {mediaSources?.[key]}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={5} lg={5} xl={5}>
          <Button
            color="primary"
            className="w-100 py-2"
            onClick={getAdverseMedia}
          >
            {translate('dashboard.media.form.submit')}
          </Button>
        </Col>
      </Row>
      <div className="_dashboard__media-list-content">
        {pending && <Loading />}
        {!pending && mediaQuery && data.length === 0 && <p>{translate('dashboard.media.news', 0)}</p>}
        {!pending
          && mediaQuery
          && data.length > 0
          && data.map((item) => <MediaListItem key={item?.url} data={item} />)}
      </div>
    </Card>
  );
}
