import { getNestedValue } from 'helpers';

export const locales = {};
const localeFiles = require.context('./', true, /[A-Za-a0-9-_,\s]+\.js$/i);
localeFiles.keys().filter((fileName) => !['index'].includes(fileName)).forEach((fileName) => {
  const matched = fileName.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
    const locale = matched[1];
    if (locale !== 'index') {
      locales[locale] = localeFiles(fileName).default ?? localeFiles(fileName);
    }
  }
});

export default locales;

export const translate = (
  key,
  args = [],
  argObject = {},
) => {
  const string = getNestedValue(key, locales?.en);

  if (key && string) {
    let newString = string;
    // use arguments for changing string values
    if (Array.isArray(args)) {
      args.forEach((arg, index) => {
        const regex = new RegExp(`{{%s${index}}}`, 'g');
        newString = newString.replace(regex, arg);
      });
    }
    // use count value for pluralization
    if (Object.keys(argObject).length > 0 || typeof args === 'number') {
      const count = argObject?.count ?? args;

      if (typeof count === 'number') {
        const stringArray = newString.split('|');
        const regex = new RegExp('{{%s0}}', 'g');
        switch (count) {
          case 0:
            newString = stringArray.at(0);
            break;
          case 1:
            newString = stringArray.at(1);
            break;
          default:
            newString = stringArray.at(2).replace(regex, count);
            break;
        }
      }
    }
    return newString;
  }
  return '';
};
