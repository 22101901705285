import React from 'react';
import PropTypes from 'prop-types';

import { useDate } from 'hooks';
import { isValidImageUrl } from 'helpers';

import { Card } from 'components/ui';

function MediaListItem({ data }) {
  const { elapsedTime } = useDate();

  return (
    <Card className="_dashboard__media-list-item">
      <div className="_dashboard__media-list-item-content">
        <span>
          {data?.source?.name}
        </span>
        <a
          className="_dashboard__media-list-item-title"
          href={data?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data?.title}
        </a>
        <span className="_dashboard__media-list-item-description">
          {data?.description}
        </span>
        <p className="_dashboard__media-list-item-time">
          {elapsedTime(data?.date)}
        </p>
      </div>
      {isValidImageUrl(data?.image) && (
      <div className="_dashboard__media-list-item-image">
        { typeof data?.image === 'string' && data?.image.length > 0 && (
          <img
            src={data?.image}
            width="100"
            height="100"
            alt={data?.title}
            title={data?.title}
          />
        )}
      </div>
      )}

    </Card>
  );
}

MediaListItem.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    source: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default MediaListItem;
