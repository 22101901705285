import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'reactstrap';

function Welcome({ toggleTabProgress }) {
  return (
    <Row style={{
      paddingBottom: '127px',
    }}
    >
      <Col>
        <Row className="d-flex align-items-center form-header px-5">
          <Col>
            Welcome to Sanction Trace Onboarding
          </Col>
        </Row>
        <Row className="px-50 mt-55">
          <Col>
            <p className="welcome-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Quisque convallis ante vel lectus vestibulum,
              id aliquet ex aliquam. Orci varius natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus.Praesent fermentum lorem vitae semper vestibulum.

              Phasellus ultricies hendrerit tempus. Vestibulum eleifend nisl ex,
              quis consectetur diam viverra nec.Sed non pellentesque ex.
              Vestibulum hendrerit purus cursus massa pharetra euismod.
              Phasellus rutrum massa nec molestie mattis.
              Nulla et odio sit amet urna sodales feugiat. Donec vitae viverra enim.
              Quisque eu libero elementum, euismod arcu et, bibendum est.
            </p>
          </Col>
        </Row>
        <Row className="px-50 mt-97">
          <Col>
            <button
              className="d-flex justify-content-center align-items-center btn btn-primary btn-block waves-effect waves-light mt-0"
              type="submit"
              style={{
                height: '49px',
                fontSize: '14px',
              }}
              onClick={() => {
                toggleTabProgress(1);
              }}
            >
              Start filling the onboarding form
              <i
                className="bx bx-right-arrow-alt ml-1"
                style={{
                  fontSize: '20px',
                }}
              />
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Welcome.propTypes = {
  toggleTabProgress: PropTypes.func.isRequired,
};

export default Welcome;
