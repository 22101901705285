import { useRef } from 'react';
import { debounce } from 'helpers';

function useDebounce(func, delay) {
  const debounceFn = useRef(
    debounce(func, delay),
  ).current;
  return debounceFn;
}

export default useDebounce;
