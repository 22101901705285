import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrganizationChart from '@dabeng/react-orgchart';
import {
  Spinner,
} from 'reactstrap';
import { useAPI } from '../../services/api';

import OrgChartNode from './OrgChartNode';

const OrgChart = () => {
  const api = useAPI();

  const { id } = useParams();
  const [data, setData] = useState([]);
  const createDataTree = (dataset) => {
    const hashTable = Object.create(null);
    dataset.forEach((aData) => {
      hashTable[aData.id] = { ...aData, children: [] };
    });
    const dataTree = [];
    dataset.forEach((aData) => {
      if (aData.parentId) {
        hashTable[aData.parentId].children
          .push({ ...hashTable[aData.id] });
      } else {
        dataTree.push({ ...hashTable[aData.id] });
      }
    });
    return dataTree;
  };

  const createOrgChart = (shareHolders = []) => {
    const dataTree = createDataTree(shareHolders);
    console.log(dataTree);
    setData(dataTree);
  };

  const getShareHolders = async () => {
    try {
      const {
        data: {
          form: {
            data: {
              entityTitle,
              shareHolders: sHolders,
            },
          },
        },
      } = await api.getForm(id);
      const dataWithTitle = [{
        id: 'main',
        parentId: null,
        name: entityTitle,
        type: 'entity',
        percent: '100',
      }, ...sHolders.map((s) => {
        if (s.parentId === null) return ({ ...s, parentId: 'main' });
        return s;
      })];
      console.log(sHolders);
      createOrgChart(dataWithTitle);
      // createOrgChart(sHolders);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShareHolders();
  }, []);

  if (!data[0]?.children) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="mr-2" color="secondary" />
      </div>
    );
  }
  return (
    data.map((d) => (
      <OrganizationChart
        key={d?.id}
        collapsible={false}
        datasource={d}
        NodeTemplate={OrgChartNode}
      />
    ))

  );
};

export default OrgChart;
