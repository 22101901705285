export const tabs = [
  { id: 'sanctions', title: 'Sanctions' },
  // { id: 'pep', title: 'PEP' }, // uncomment this line after pep fixed
  // { id: 'adverseMedia', title: 'Adverse Media' },
];

export const sanctionColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    // minWidth: '150px',
    // width: '300px',
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Source',
    selector: 'source',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Nationality',
    selector: 'nationality',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Score',
    selector: 'score',
    sortable: true,
    width: '90px',
  },
];

export const pepColumns = [
  {
    name: 'Name',
    selector: 'name',
    width: '210px',
    sortable: true,
  },
  {
    name: 'Office',
    selector: 'office',
    width: '210px',
    sortable: true,
  },
  {
    name: 'Gender',
    selector: 'gender',
    width: '80px',
    sortable: true,
  },
  {
    name: 'Birth place',
    selector: 'birthplace',
    width: '210px',
    sortable: true,
  },
  {
    name: 'Birth date',
    selector: 'birthdate',
    width: '120px',
    sortable: true,
  },
  {
    name: 'Nationality',
    selector: 'nationality',
    width: '100px',
    sortable: true,
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
      cursor: 'pointer',
    },
  },
};
