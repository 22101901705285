import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Container, Modal, ModalBody,
} from 'reactstrap';

import { changeRoute, resetRoute } from '../../store/reducers/route';
import { makeSearch, makeAdvancedSearch, resetSearchState } from '../../store/reducers/search';
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import ShareLink from './ShareLink';
import '../../assets/scss/custom/search.scss';
import '../../assets/css/search.css';

const choiceInputs = ['checkbox', 'radio'];

function Search() {
  const results = useSelector((state) => (state.search.results));
  const pepResults = useSelector((state) => (state.search.pepResults));
  const adverseMediaResults = useSelector((state) => (state.search.adverseMediaResults));
  const { status } = useSelector((state) => state.search);
  const loading = useSelector((state) => state.search.loading);
  const error = useSelector((state) => state.search.error);
  const dispatch = useDispatch();
  const location = useLocation();
  const q = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [toggleModal, setToggleModal] = useState(false);
  const [searchParams, setSearchParams] = useState({
    query: q.get('q') ?? '',
    minNameScore: q.get('s') ? parseInt(q.get('s'), 10) : 65,
    idNumber: '',
    birthPlace: '',
    type: '',
    country: [],
    birthDate: { start: '', end: '' },
    aka: q.get('aka') ? q.get('aka') === 'true' : true,
    phonetics: q.get('phonetics') ? q.get('phonetics') === 'true' : true,
    noLogging: false,
  });

  const search = useCallback(({
    query, fuzziness, isAKA, isPEP, isPhonetics, disableLogging,
  }) => {
    dispatch(makeSearch(query, fuzziness, isPEP, isPhonetics, isAKA, !disableLogging));
  }, [dispatch]);

  const advancedSearch = useCallback((params) => {
    dispatch(makeAdvancedSearch(params));
  }, [dispatch]);

  // reset search results
  useEffect(() => () => {
    dispatch(resetSearchState());
  }, [dispatch]);

  const handleSearchParamChange = useCallback((e) => {
    const {
      type, name, value, checked,
    } = e.currentTarget;

    if (choiceInputs.includes(type)) {
      setSearchParams((p) => ({ ...p, [name]: checked }));
    } else {
      setSearchParams((p) => ({ ...p, [name]: value }));
    }
  }, []);

  useEffect(() => {
    dispatch(changeRoute('Search '));
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  return (
    <>
      <Modal size="lg" isOpen={toggleModal} toggle={() => setToggleModal(false)} centered>
        <div style={{ display: 'flex', padding: '21px 35px' }}>
          <button
            type="button"
            onClick={() => setToggleModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginLeft: 'auto', fontSize: '26px', color: 'rgba(37, 33, 59, 0.54)' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody style={{
          overflowY: 'auto',
        }}
        >
          <ShareLink searchParams={searchParams} setToggle={setToggleModal} />
        </ModalBody>
      </Modal>
      <Container fluid className="search">
        <SearchBar
          onSearch={search}
          onAdvancedSearch={advancedSearch}
          onSearchParamChange={handleSearchParamChange}
          params={searchParams}
        />
        <SearchBody
          searchParams={searchParams}
          results={results}
          pepResults={pepResults}
          adverseMediaResults={adverseMediaResults}
          status={status}
          error={error}
          loading={loading}
          setToggleModal={setToggleModal}
        />
      </Container>
    </>
  );
}

Search.propTypes = {};

export default Search;

function SearchBody({
  error = false, loading = false, results = null, status = '',
  setToggleModal, pepResults, adverseMediaResults, searchParams,
}) {
  if (loading) {
    return (
      <div className="w-100">
        <div
          className="text-center d-grid justify-content-center"
          style={{
            height: '100%',
          }}
        >
          <h4
            className="loading-text justify-self-center"
          >
            Loading...
          </h4>
          <div
            className="spinner-border text-secondary mt-3 ml-3 justify-content-center"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-100">
        <div className="text-center">
          <h2 className="search-loading">
            An error occured during the search. Please try again.
          </h2>
        </div>
      </div>
    );
  }

  if (!results) {
    return (
      <div className="row p-5">
        <div className="col-6 text-left">
          <h2>How Sanction Trace Works</h2>
          <p className="mt-3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
            est laborum.
          </p>
        </div>
        <div className="col-6 text-center">
          <img src="https://via.placeholder.com/420x300" alt="placeholder" />
        </div>
      </div>
    );
  }

  return (
    <SearchResults
      searchParams={searchParams}
      pepResults={pepResults}
      adverseMediaResults={adverseMediaResults}
      results={results}
      status={status}
      setShareModal={setToggleModal}
    />
  );
}

SearchBody.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  pepResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  adverseMediaResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  setToggleModal: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    query: PropTypes.string,
    minNameScore: PropTypes.number,
    idNumber: PropTypes.string,
    birthPlace: PropTypes.string,
    type: PropTypes.string,
    birthDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    country: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    aka: PropTypes.bool,
    phonetics: PropTypes.bool,
    noLogging: PropTypes.bool,
  }).isRequired,
};
