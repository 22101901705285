import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import logoOnboarding from '../assets/images/logo-onboarding.png';
import logoSm from '../assets/images/logo-sm.png';
import ProfileMenu from './ProfileMenu';

function HeaderItem({ label, isIncludeSlash }) {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {isIncludeSlash && (
        <>
          /
          {' '}
        </>
      )}
      {label.replaceAll('-', ' ')}
    </span>
  );
}

HeaderItem.defaultProps = {
  isIncludeSlash: true,
};

HeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
  isIncludeSlash: PropTypes.bool,
};

function Header({ expanded }) {
  const location = useLocation();
  const isKycFormFiller = useSelector((state) => state.auth.user.role === 4);

  const paths = useMemo(() => {
    if (location?.pathname) {
      return location?.pathname.split('/')
        .filter((item) => item.trim().length > 0);
    }
    return '';
  }, [location]);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box" aria-expanded={expanded}>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" width="40" height="40" />
              </span>
              <span className="logo-lg">
                {expanded && !isKycFormFiller && <img src={logo} alt="" style={{ maxWidth: '200px' }} />}
                {expanded && isKycFormFiller && <img src={logoOnboarding} alt="" style={{ maxWidth: '200px' }} />}
                {!expanded && <img src={logoSm} alt="" width="40" height="40" />}
              </span>
            </Link>
          </div>
          {/* <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={toggleMenu}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button> */}
        </div>
        <div
          className="d-flex justify-content-start"
          style={{
            width: '100%',
          }}
        >
          <div className="pl-4 d-flex align-items-center">
            <Link
              to="/"
              className="no-underline"
            >
              <HeaderItem label="Home" isIncludeSlash={false} />
            </Link>
            {paths.map((path, index) => {
              if (index === paths.length - 1) {
                return (
                  <HeaderItem
                    key={path}
                    label={Number.isNaN(parseInt(path, 10)) ? path : ''}
                    isIncludeSlash={Number.isNaN(parseInt(path, 10))}
                  />
                );
              }
              return (
                <Link
                  key={path}
                  to={`/${path.replace(' ', '').trim().toLowerCase()}`}
                  className="no-underline"
                >
                  <HeaderItem label={path} />
                </Link>
              );
            })}
          </div>
        </div>
        <div className="d-flex">
          <ProfileMenu />
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default Header;
