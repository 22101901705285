import React, {
  useState, useMemo, useContext, useEffect, useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { getNestedValue } from 'helpers';
import { locales } from 'translations';

export const translate = (currentLanguage = 'en') => (
  key,
  args = [],
  argObject = {},
) => {
  const currentLocale = locales?.[currentLanguage] ?? locales?.en;
  const string = getNestedValue(key, currentLocale);

  if (key && string) {
    let newString = string;

    // use count value for pluralization
    if (Object.keys(argObject).length > 0 || typeof args === 'number') {
      const count = argObject?.count ?? args;

      if (typeof count === 'number') {
        const stringArray = newString.split('|');
        const regex = new RegExp('{{%s0}}', 'g');
        switch (count) {
          case 0:
            newString = stringArray.at(0);
            break;
          case 1:
            newString = stringArray.at(1);
            break;
          default:
            newString = stringArray.at(-1).replace(regex, count);
            break;
        }
      }
    }
    // use arguments for changing string values
    if (Array.isArray(args)) {
      args.forEach((arg, index) => {
        const regex = new RegExp(`{{%s${index}}}`, 'g');
        newString = newString.replace(regex, arg);
      });
    }
    return newString;
  }
  return '';
};

const isValidLanguage = (value) => (typeof value === 'string' && value.length === 2 && locales?.[value.toLowerCase()]);

export const LanguageContext = React.createContext({
  language: 'en',
  setLanguage: () => {},
  locale: locales?.en,
  translate: () => {},
});

export function ProvideLanguage({ children }) {
  const localLanguage = localStorage.getItem('lang');

  const [language, setLanguage] = useState(isValidLanguage(localLanguage) ? localLanguage : 'en');

  const currentLocale = useMemo(() => locales?.[language], [language]);

  const onUpdateLanguage = (value) => {
    if (isValidLanguage(value)) {
      setLanguage(value.toLowerCase());
    }
  };

  const onChangeLocalStorage = useCallback(
    (storage) => {
      const localLang = storage?.storageArea?.lang;
      if (localLang !== language) {
        onUpdateLanguage(localLang);
      }
    },
    [language],
  );

  useEffect(() => {
    localStorage.setItem('lang', language);
  }, [language]);

  useEffect(() => {
    window.addEventListener('storage', onChangeLocalStorage);

    return () => {
      window.removeEventListener('storage', onChangeLocalStorage);
    };
  }, [onChangeLocalStorage]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: onUpdateLanguage,
        locale: currentLocale,
        translate: translate(language),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

ProvideLanguage.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useLanguage() {
  return useContext(LanguageContext);
}

export default ProvideLanguage;
