import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

function CancelConfirmModal({
  isOpen,
  onCancel,
  onConfirm,
  children,
  loading,
  setIsOpen,
  deleteUserBtn,
  selectedCount,
  selectedName,
  cantEdit,
}) {
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    }
  }, [loading]);

  return (
    <Modal
      style={{
        top: 'calc(30%)',
      }}
      fade={false}
      backdrop="static"
      isOpen={isOpen}
    >
      <div className="modal-body">
        {children[0] === undefined ? (
          <p>
            {
              'You have unsaved changes! \n Are you sure you want to leave this page ?'
            }
          </p>
        ) : children}
      </div>
      <div className="modal-footer">
        <button
          disabled={loading}
          type="button"
          className="btn btn-link waves-effect"
          data-dismiss="modal"
          onClick={onCancel}
          style={{
            color: 'rgba(37, 33, 59, 0.74)',
          }}
        >
          Cancel
        </button>

        {deleteUserBtn
          ? (
            <button
              disabled={loading}
              type="button"
              className="btn btn-danger waves-effect waves-light"
              style={{
                width: '25%',
              }}
              onClick={onConfirm}
            >
              {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />}
              {selectedName ? 'Delete' : `Delete all(${selectedCount})`}
            </button>
          )
          : (
            <button
              disabled={loading}
              type="button"
              className={cantEdit ? 'btn btn-primary waves-effect waves-light' : 'btn btn-danger waves-effect waves-light'}
              style={{
                width: cantEdit ? '30%' : '10%',
              }}
              onClick={onConfirm}
            >
              {cantEdit ? 'Request Access' : 'OK'}
            </button>
          )}
      </div>
    </Modal>
  );
}

CancelConfirmModal.defaultProps = {
  children: <p />,
  loading: false,
  setIsOpen: () => {},
  deleteUserBtn: false,
  selectedCount: 0,
  selectedName: '',
  cantEdit: false,
};

CancelConfirmModal.propTypes = {
  // eslint-disable-next-line
  isOpen: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool,
  setIsOpen: PropTypes.func,
  deleteUserBtn: PropTypes.bool,
  selectedCount: PropTypes.number,
  selectedName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  cantEdit: PropTypes.bool,
};

export default CancelConfirmModal;
