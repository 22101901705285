import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, Collapse,
} from 'reactstrap';
import classnames from 'classnames';
import warningSvg from '../assets/images/alert-box-outline.svg';
import EntryRow from './EntryRow';
import CancelConfirmModal from './CancelConfirmModal';

// eslint-disable-next-line
function EntryCheckboxItem({ id, name, matchedNames, handleSelected, deleteEntry, isSelected, selectedEntries }) {
  // eslint-disable-next-line
  const [checked, setChecked] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <>
      {/* <div
        className="custom-control custom-checkbox"
        style={{
          marginTop: '12px',
        }}
      >
        <input
          id={id}
          type="checkbox"
          className="custom-control-input"
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            handleSelected(id, name, !checked);
          }}
        />
        <label htmlFor={id} className="custom-control-label">
          {name}
        </label>
      </div> */}
      <CancelConfirmModal
        isOpen={deleteModal}
        name={name}
        onCancel={() => setDeleteModal(false)}
        onConfirm={() => {
          deleteEntry(name);
          setDeleteModal(false);
        }}
      >
        Are you sure you want to delete this entry? If you say yes, you cannot undo this step.
      </CancelConfirmModal>
      <Card className="mb-2">
        <CardHeader
          id="headingOne"
          style={{
            padding: '20px 20px 15px 15px',
          }}
        >
          {/* eslint-disable-next-line */}
            <span
              onClick={() => { setIsCollapsed(!isCollapsed); }}
              style={{ cursor: 'pointer' }}
              className="text-dark"
            >
              <div style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <span>
                  {isSelected ? (
                    <i
                      className={classnames({
                        bx: true,
                        'bx-check-square': true,
                      })}
                      style={{
                        fontSize: '24px',
                        color: '#34C38F',
                        marginLeft: '5px',
                        marginRight: '18px',
                      }}

                    />
                  ) : (
                    <img
                      src={warningSvg}
                      alt="Warning"
                      width="24"
                      height="24"
                      style={{
                        marginLeft: '5px',
                        marginTop: '-2px',
                        marginRight: '18px',
                      }}
                    />
                  )}
                </span>
                <span>{name}</span>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="btn btn-link mr-2 text-dark mt-0 mb-0 p-0"
                    style={{
                      fontSize: '20px',
                      color: '#74788D',
                    }}
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                  >
                    <i className={classnames({
                      bx: true,
                      'bx-no-entry': true,
                    })}
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link mr-2 text-dark mt-0 mb-0 p-0 ml-1"
                    style={{
                      fontSize: '20px',
                      color: '#74788D',
                    }}

                  >
                    <i className={classnames({
                      bx: true,
                      'bx-chevron-down': !isCollapsed,
                      'bx-chevron-up': isCollapsed,
                    })}
                    />
                  </button>
                </div>
              </div>

            </span>
        </CardHeader>

        <Collapse isOpen={isCollapsed}>
          <Card>
            <CardBody>
              {matchedNames.map((mN) => (
                <EntryRow
                  masterId={id}
                  id={mN.id}
                  name={mN.name}
                  lists={mN?.lists}
                  createdBy={mN.created_by}
                  profileData={mN}
                  handleSelected={handleSelected}
                  selectedEntries={selectedEntries}
                />
              ))}

              <div
                className="pt-4 pl-4 align-items-center"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '16px 1fr',
                }}
              >
                <input
                  type="radio"
                  id={`${id}-${name}-new`}
                  name={name}
                  value="new"
                  checked={selectedEntries.filter((ent) => (ent.masterId === id))[0]?.value !== undefined && selectedEntries.filter((ent) => (ent.masterId === id))[0]?.value === 'new'}
                  onChange={(e) => {
                    handleSelected(parseInt(e.target.id, 10), name, 'new', id);
                  }}
                />
                <label className="ml-2 mb-0" htmlFor={`${id}-${name}-new`}>Create new profile</label>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      </Card>
    </>
  );
}

EntryCheckboxItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  handleSelected: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  selectedEntries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  matchedNames: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
export default EntryCheckboxItem;
