import React from 'react';
import PropTypes from 'prop-types';

import { Tag as AntdTag } from 'antd';

import styles from './index.module.scss';

function Tag({
  children,
  className,
  icon,
  color,
  onClick,
}) {
  if (children) {
    return (
      <AntdTag
        icon={icon}
        className={[
          styles.tag,
          styles[color],
          className,
        ].join(' ')}
        onClick={onClick}
      >
        {children}
      </AntdTag>
    );
  }
  return null;
}

Tag.defaultProps = {
  className: '',
  icon: null,
  color: 'secondary',
  onClick: () => {},
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    '',
  ]),
  onClick: PropTypes.func,
};

export default Tag;
