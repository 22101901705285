import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table as AntdTable } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import Loading from '../Loading';

function Table({
  loading,
  columns,
  dataSource,
  rowKey,
  scroll,
  pagination,
  rowClassName,
  rowSelection,
  onRowClick,
  onSelectionChange,
  onSortChange,
  onPaginationChange,
}) {
  const { translate } = useLanguage();

  const onRow = useCallback(
    (record) => ({
      onClick: () => onRowClick(record), // click row
    }),
    [onRowClick],
  );

  const tableRowSelection = useMemo(() => {
    if (onSelectionChange) {
      return {
        onChange: (selectedRowKeys, selectedRows) => onSelectionChange({
          selectedRowKeys,
          selectedRows,
        }),
      };
    }
    return rowSelection;
  }, [rowSelection, onSelectionChange]);

  return (
    <AntdTable
      loading={{
        indicator: <Loading size="md" />,
        spinning: loading,
      }}
      locale={{
        triggerDesc: translate('globals.table.sort.descend'),
        triggerAsc: translate('globals.table.sort.ascend'),
        cancelSort: translate('globals.table.sort.cancel'),
      }}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      scroll={scroll}
      pagination={pagination}
      rowSelection={tableRowSelection}
      rowClassName={rowClassName}
      onRow={onRow}
      onChange={(...allFields) => {
        const { current, pageSize } = allFields[0];
        const sorter = allFields[2];
        const { action } = allFields[3];
        if (action === 'sort') onSortChange(sorter);
        if (action === 'paginate') onPaginationChange(current, pageSize);
      }}
    />
  );
}

Table.defaultProps = {
  loading: false,
  rowKey: 'id',
  scroll: {},
  pagination: {},
  rowClassName: () => '',
  rowSelection: null,
  onRowClick: () => {},
  onSelectionChange: null,
  onSortChange: () => {},
  onPaginationChange: () => {},
};

Table.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selector: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  scroll: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    y: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  pagination: PropTypes.shape({
    total: PropTypes.number,
    current: PropTypes.number,
    pageSize: PropTypes.number,
    onChange: PropTypes.func,
  }),
  rowClassName: PropTypes.func,
  rowSelection: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  onRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
};

export default Table;
