import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from 'reactstrap';

import { useAPI } from 'services/api';
import SanctionBanner from 'assets/images/sanction-banner.png';
import {
  parameterFields, resultFields, pepFields, historyFields, listFields,
} from './constants';
import 'assets/css/preview.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
/* eslint-disable camelcase */
function Preview(props) {
  const api = useAPI();
  // const location = useLocation();
  const { token: userToken } = useSelector((state) => state.auth);
  const q = useQuery();
  const [isLoaded, setIsLoaded] = useState(false);
  const previewName = useMemo(() => props.match.params.previewName, [props]);
  const id = useMemo(() => props.match.params.id, [props]);
  const token = useMemo(() => q.get('token'), [q]);
  const [previewData, setPreviewData] = useState({ results: [] });
  const [dataField, setDataField] = useState(resultFields);
  const [isPep, setIsPep] = useState(false);

  const fetchSearchResults = useCallback(
    async (includeToken) => {
      let params = `?token=${token}`;
      if (!includeToken) {
        params = '';
      }
      const { data: { search } } = await api.getSearchResults(id, params);
      let isPepData = false;
      if (search?.results.length > 0) {
        isPepData = Object.keys(search?.results[0]).some((k) => k === 'abstract');
        setIsPep(isPepData);
      }
      if (isPepData) setDataField(pepFields);
      else setDataField(resultFields);
      setPreviewData(search);
      setTimeout(() => {
        setIsLoaded(true);
      }, 1200);
    },
    [id, q, token],
  );

  const fetchSearchHistory = useCallback(
    async (includeToken) => {
      let params = `?token=${token}`;
      if (!includeToken) {
        params = '';
      }
      const { data: { searches } } = await api.getSearchHistory(id, params);
      setDataField(historyFields);
      setPreviewData({ results: searches.reverse() });
      setTimeout(() => {
        setIsLoaded(true);
      }, 1200);
    },
    [id, q, token],
  );

  const fetchListResults = useCallback(
    async (includeToken) => {
      let params = `?token=${token}`;
      if (!includeToken) {
        params = '';
      }
      const { data: { search } } = await api.getLatestListSearches(id, params);
      setDataField(listFields);
      setPreviewData(search);
      setTimeout(() => {
        setIsLoaded(true);
      }, 1200);
    },
    [id, q, token],
  );

  const resultContent = (data, allFields) => allFields
    .filter((field) => !!data[field.key]).map((field) => {
      if (field.key === 'settings'
      || field.key === 'search_options'
      || field.key === 'search_country'
      || field.key === 'clientSpecific'
      || field.key === 'comments'
      || field.key === 'allNames'
      || data[field.key] === 'abstract') {
        return field.render(data[field.key]);
      }
      if (field.key === 'country' && data[field.key].length === 0) {
        return '';
      }
      if (data[field.key] === null) {
        return field.render(data[field.key]);
      }

      return (
        <div key={field.key} className="parameter-row">
          <span>
            {field.label}
          </span>
          {field.render && field.render(data[field.key])}
          {
          !field.render && !Array.isArray(data[field.key]) && (
          <span>
            {data[field.key]}
          </span>
          )
        }
        </div>
      );
    });

  const content = (data, allFields) => allFields
    .filter((field) => Object.keys(data).includes(field.key)).map((field) => {
      if (field.key === 'settings'
       || field.key === 'search_options') {
        return field.render(data[field.key]);
      }
      if (field.key === 'country' && data[field.key].length === 0) {
        return '';
      }
      if (data[field.key] === null) {
        return field.render(data[field.key]);
      }
      return (
        <div key={field.key} className="parameter-row">
          <span>
            {field.label}
          </span>
          {field.render && field.render(data[field.key])}
          {
          !field.render && !Array.isArray(data[field.key]) && (
          <span>
            {data[field.key]}
          </span>
          )
        }
        </div>
      );
    });

  useEffect(() => {
    if (previewName === 'history') {
      fetchSearchHistory(userToken === null);
    } else if (previewName === 'searches') {
      fetchSearchResults(userToken === null);
    } else if (previewName === 'lists') {
      fetchListResults(userToken === null);
    }
  }, [id, previewName, userToken, token]);
  return (
    <div className="main-body">
      <div className="main-wrapper" id={isLoaded ? 'loadedContent' : 'waitForLoad'}>
        <table className="ml-auto mr-auto">
          <tbody>
            <tr>
              <td
                className="mt-0 pt-0 px-0"
              >
                <header><img src={SanctionBanner} width="100%" height="150" alt="Sanction Trace Banner" /></header>
                <div className="d-flex justify-content-end mt-4 d-print-none">
                  <Button
                    outline
                    className="pl-2"
                    onClick={() => window.print()}
                  >
                    <i className="mdi mdi-printer mr-2" />
                    Print
                  </Button>
                </div>
                <div className="content">
                  {previewName === 'searches' && (
                  <div className="search-parameters">
                    <h3 className="py-3">
                      Search Parameters
                    </h3>
                    <div className="search-parameters-content">
                      {content(previewData, parameterFields)}
                    </div>
                  </div>
                  )}
                  <div className="search-parameters">
                    <h3 className="py-3">
                      {previewName === 'history' && 'Search History'}

                      {previewName === 'searches' && previewData?.results?.length !== 0 && (previewData?.results_length ?? previewData?.results?.length ?? 0)}
                      {previewName === 'searches' && (previewData?.results?.length === 0 ? `No matches found for "${previewData?.name ?? ''}".` : ' results found:')}

                      {previewName === 'lists' && (previewData?.results?.length === 0 ? 'No matches found for list".' : `${previewData?.results?.length ?? 0} results found for list`)}
                    </h3>
                    {previewData?.results?.map((r) => (
                      <div
                        key={r.uuid}
                        className={classnames({
                          'search-parameters-content': !isPep,
                          'search-parameters-content-pep': isPep,
                          'mb-3': true,
                        })}
                      >
                        {previewName === 'history' && content(r, dataField)}
                        {previewName === 'searches' && resultContent(r, dataField)}
                        {previewName === 'lists' && resultContent(r?.content, dataField)}
                      </div>
                    ))}

                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>
        <footer className="preview-footer">
          Verification Code:
          {previewData?.uuid}
          {' '}
          and to verify
          &nbsp;
          <a target="_blank" rel="noopener noreferrer" href={`https://dev.sanctiontrace.com/preview/verify?code=${previewData?.uuid}`}>click here</a>
        </footer>
      </div>
    </div>
  );
}

Preview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      previewName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Preview;
