export const historyColumns = [
  {
    name: '',
    selector: 'searchAgain',
    sortable: false,
    width: '52px',
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    width: '160px',
  },
  {
    name: 'Query',
    selector: 'search_input',
    sortable: true,
  },
  {
    name: 'Fuzzy Score',
    selector: 'search_score',
    sortable: true,
    width: '120px',
  },
  {
    name: 'Filters',
    selector: 'search_options',
    sortable: true,
    width: '17%',
  },
  {
    name: 'Match Count',
    selector: 'count',
    sortable: true,
    width: '117px',
  },
  {
    name: 'User',
    selector: 'user',
    sortable: true,
    width: '150px',
  },

  {
    name: '',
    selector: 'actions',
    sortable: false,
    width: '60px',
  },
];

export const searchFilters = [
  {
    value: 'aka',
    label: 'Include AKA',
  },
  {
    value: 'phonetics',
    label: 'Include Phonetics',
  },
];

export const fuzziness = [
  {
    value: '0',
    label: 'Exact Match',
  },
  {
    value: '1',
    label: 'Moderate',
  },
  {
    value: '2',
    label: 'Loose',
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
    },
  },
};
