import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Label, Row, Input,
} from 'reactstrap';

function Declaration({
  hideNavButtons, toggleTabProgress
  ,
}) {
  const fileRef = React.createRef();
  return (
    <Form
      id="general-information"
      className="px-5"
      onSubmit={(e) => toggleTabProgress(6, e)}
    >
      <Row className="d-flex align-items-center form-header">
        <Col className="d-flex align-items-center">
          {hideNavButtons && <span className="step-number-round mr-2">01</span>}
          Declaration of commitment with regard to goods and software
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="12">
          <Label className="label-header" for="entityTitle">
            In accordance with the regulations of the Federal Republic of Germany
            which state that granting of an export license is dependent on the
            presentation of an EUC, we (I) certify that
            <ul>
              <li>
                we (I) are (am) the final end-user of the items specified in
                <b> section B</b>
                {' '}
                and any replica thereof.
              </li>
              <li>
                we (I) will provide the Federal Office for Economic Affairs
                and Export Control (BAFA) of the Federal Republic of Germany
                with a delivery statement upon its request.
              </li>
              <li>
                the items and any replica thereof will only be used for
                the purpose specified in
                {' '}
                <b>section D</b>
                .
              </li>
              <li>the items and any replica thereof will remain in country</li>
            </ul>
          </Label>
        </Col>
        <Col lg="12">
          <textarea id="1" className="form-control input-text" rows="2" />
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="12">
          <Label className="label-header" for="entityTitle">
            <b>or</b>
            , consistent with the issued authorization, will only be
            re-exported (e.g. after incorporation) to country
          </Label>
        </Col>
        <Col lg="12">
          <textarea id="1" className="form-control input-text" rows="2" />
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="12">
          <Label className="label-header" for="entityTitle">
            <ul>
              <li>
                we (I) will neither re-export the items nor export any replica
                thereof to other third countries without the prior consent of BAFA.
              </li>
              <li>
                we (I) certify not to re-export the items and any replica
                thereof to third countries without prior consent of the export
                control authority of our (my) country.
              </li>
              <li>
                the items and any replica thereof will not be re-exported/exported
                or otherwise nationally or internationally re-sold or transferred
                to a destination subject to UN, EU or OSCE embargo where that act
                would be in breach of the terms of that embargo.
              </li>
            </ul>
          </Label>
        </Col>
      </Row>
      <Row className="mt-60px">
        <Col lg="4">
          <Label className="label-header" for="legalAddress">
            Place and date
          </Label>
        </Col>
        <Col lg="8">
          <Input
            type="text"
            className="inner form-control"
          />
        </Col>
      </Row>
      <Row className="mt-60px">
        <Col lg="4">
          <Label className="label-header" for="doc1">
            Original signature of end-user
          </Label>
        </Col>
        <Col lg="8">

          <div className="custom-file">
            <input
              ref={fileRef}
              type="file"
              accept="image/png, image/jpeg"
              className="custom-file-input"
              id="doc1"
              onChange={(e) => {
                document.querySelector('#doc1-text').innerHTML = e.target.files[0].name;
              }}
            />
            <label id="doc1-text" className="custom-file-label btn-input-upload" htmlFor="doc1">Add file</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-60px">
        <Col lg="4">
          <Label className="label-header" for="doc2">
            Company stamp/Official seal
          </Label>
        </Col>
        <Col lg="8">

          <div className="custom-file">
            <input
              ref={fileRef}
              type="file"
              accept="image/png, image/jpeg"
              className="custom-file-input"
              id="doc2"
              onChange={(e) => {
                document.querySelector('#doc2-text').innerHTML = e.target.files[0].name;
              }}
            />
            <label id="doc2-text" className="custom-file-label btn-input-upload" htmlFor="doc2">Add file</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-60px">
        <Col lg="4">
          <Label className="label-header" for="doc2">
            Name and title of signer in block letters
          </Label>
        </Col>
        <Col lg="8">

          <div className="custom-file">
            <input
              ref={fileRef}
              type="file"
              accept="image/png, image/jpeg"
              className="custom-file-input"
              id="doc3"
              onChange={(e) => {
                document.querySelector('#doc3-text').innerHTML = e.target.files[0].name;
              }}
            />
            <label id="doc3-text" className="custom-file-label btn-input-upload" htmlFor="doc2">Add file</label>
          </div>
        </Col>
      </Row>
      {!hideNavButtons && (
        <Row className="mt-60px">
          <Col>
            <div className="pr-0 d-flex">
              <div className="ml-auto d-flex align-items-center">

                <button
                  className="d-flex justify-content-center align-items-center btn btn-outline-primary btn-block waves-effect waves-light mr-3"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '143px',
                    fontSize: '14px',
                  }}

                >
                  Save for later
                </button>

                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary btn-block waves-effect waves-light mt-0"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '100px',
                    fontSize: '14px',
                  }}
                >
                  Send
                  <i
                    className="bx bx-right-arrow-alt ml-1"
                    style={{
                      fontSize: '20px',
                    }}
                  />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  );
}
Declaration.defaultProps = {
  hideNavButtons: false,
};

Declaration.propTypes = {
  hideNavButtons: PropTypes.bool,
  toggleTabProgress: PropTypes.func.isRequired,
};

export default Declaration;
