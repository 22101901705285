import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import toastr from 'toastr';

import { useAPI } from 'services/api';
import { Loading, Table } from 'components/ui';
import { useLanguage } from 'contexts/LanguageContext';
import { useDate } from 'hooks';

function SourceLogs(props) {
  const api = useAPI();
  const { translate } = useLanguage();
  const { formatDate } = useDate();

  const id = useMemo(() => props?.match?.params?.id, [props]);

  const {
    isLoading: isLoadingSource,
    isFetching: isFetchingSource,
    isRefetching: isRefetchingSource,
    isError: isErrorSource,
    data: sourceData,
  } = useQuery(
    ['source', id],
    () => api.getSource(id).then((res) => res?.data),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
  );

  const {
    isLoading: isLoadingLogs,
    isFetching: isFetchingLogs,
    isRefetching: isRefetchingLogs,
    data: logsData,
  } = useQuery(
    ['source-logs', id],
    () => api.getSourceLogs(id).then((res) => res?.data?.results),
    {
      initialData: [],
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
  );

  const columns = useMemo(
    () => [
      {
        key: 'created_date',
        dataIndex: 'created_date',
        width: '160px',
        title: translate('dashboard.sources.logs.table.columns.date'),
        render: (text) => formatDate(text),
      },
      {
        key: 'details',
        dataIndex: 'details',
        title: translate('dashboard.sources.logs.table.columns.description'),
      },
    ],
    [translate, formatDate],
  );

  useEffect(() => {
    if (isErrorSource) {
      toastr.error(translate('dashboard.sources.logs.errorMessage'));
    }
  }, [translate, isErrorSource]);

  if (!id || isErrorSource) {
    return <Redirect to="/dashboard/sources" />;
  }

  if (isLoadingSource || isFetchingSource || isRefetchingSource) {
    return <Loading />;
  }

  return (
    <>
      <Row>
        <Col>
          {sourceData?.name && (
            <h4>
              {[sourceData?.name, sourceData?.description]
                .filter(Boolean)
                .join(' - ')}
            </h4>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card title="test">
            <CardBody>
              <Row className="pb-3">
                <Col>
                  {sourceData?.name && (
                    <>
                      <h5>{translate('dashboard.sources.logs.title')}</h5>
                    </>
                  )}
                </Col>
              </Row>
              <Table
                loading={isLoadingLogs || isFetchingLogs || isRefetchingLogs}
                columns={columns}
                dataSource={logsData || []}
                rowKey="created_date"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
SourceLogs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SourceLogs;
