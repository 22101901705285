import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import cytoscape from 'cytoscape';
import spread from 'cytoscape-spread';
import {
  Spinner,
  Button,
} from 'reactstrap';
import { useAPI } from '../../services/api';

// eslint-disable-next-line
// const els = [ // list of graph elements to start with
//   // nodes
//   {
//     data: { id: 'Ali Y.' },
//   },
//   {
//     data: { id: 'Yılmaz B.' },
//   },
//   {
//     data: { id: 'Kemal D.' },
//   },
//   {
//     data: { id: 'Unmaş' },
//   },
//   {
//     data: { id: 'Uğur K.' },
//   },
//   {
//     data: { id: 'Timur O.' },
//   },
//   {
//     data: { id: 'Mehmet Z.', label: 'Mehmet Z.' },
//   },
//   {
//     data: { id: 'Arslanlar Yapı Ortaklığı' },
//   },
//   { // edges
//     data: {
//       id: 'Ali Y.Unmaş',
//       source: 'Ali Y.',
//       target: 'Unmaş',
//       percent: '33%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'Yılmaz B.Unmaş',
//       source: 'Yılmaz B.',
//       target: 'Unmaş',
//       percent: '40%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'Kemal D.Unmaş',
//       source: 'Kemal D.',
//       target: 'Unmaş',
//       percent: '27%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'Uğur K.Mehmet Z.',
//       source: 'Uğur K.',
//       target: 'Mehmet Z.',
//       percent: '50%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'Timur O.Mehmet Z.',
//       source: 'Timur O.',
//       target: 'Mehmet Z.',
//       percent: '50%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'Mehmet Z.Arslanlar Yapı Ortaklığı',
//       source: 'Mehmet Z.',
//       target: 'Arslanlar Yapı Ortaklığı',
//       percent: '34%',
//     },
//   },
//   { // edges
//     data: {
//       id: 'UnmaşArslanlar Yapı Ortaklığı',
//       source: 'Unmaş',
//       target: 'Arslanlar Yapı Ortaklığı',
//       percent: '66%',
//     },
//   },
// ];

function NetworkScheme() {
  const api = useAPI();

  const { id } = useParams();

  const [scheme, setScheme] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shareHolders, setShareHolders] = useState([]);
  const [entityTitle, setEntityTitle] = useState('');
  const [layout, setLayout] = useState('concentric');

  const getShareHolders = async () => {
    setLoading(true);
    try {
      const {
        data: {
          form: {
            data: {
              entityTitle: eTitle,
              shareHolders: sHolders,
            },
          },
        },
      } = await api.getForm(id);
      setLoading(false);
      setEntityTitle(eTitle);
      setShareHolders(sHolders);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const nodeAndEdges = useMemo(() => {
    if (shareHolders.length === 0) {
      return [];
    }
    return [...shareHolders.flatMap((s) => {
      let parentName = '';
      if (s.parentId !== null) {
        parentName = shareHolders.find((h) => h.id === s.parentId).name;
      } else {
        parentName = entityTitle;
      }
      return [
        {
          data: { id: s.name },
        },
        { // edges
          data: {
            id: `${s.name}${parentName}`,
            source: s.name,
            target: parentName,
            percent: `${s.percent}%`,
          },
        }];
    }),
    {
      group: 'nodes',
      data: { id: entityTitle },
      style: { // style property overrides
        'background-color': '#221',
      },
    },
    ];
  }, [shareHolders, entityTitle]);

  useEffect(() => {
    if (nodeAndEdges.length >= shareHolders.length) {
      spread(cytoscape);
      const cy = cytoscape({
        container: document.getElementById('cy'), // container to render in
        elements: nodeAndEdges,
        style: [ // the stylesheet for the graph
          {
            selector: 'node',
            style: {
              'background-color': '#666',
              label: 'data(id)',
              shape: 'rectangle',
              'text-halign': 'center',
              padding: '12px',
              'text-valign': 'center',
              width: '400px',
              'min-height': '150px',
              height: '90px',
              color: 'white',
              'font-weight': '600',
              fontSize: '28px',
              'text-wrap': 'wrap',
              'text-max-width': '400px',
              'text-max-height': '90px',
            },
          },

          {
            selector: 'edge',
            style: {
              label: 'data(percent)',
              width: 4,
              'line-color': '#ccc',
              'target-arrow-color': '#444',
              'target-arrow-shape': 'triangle',
              'curve-style': 'unbundled-bezier',
              'font-weight': 'bold',
              fontSize: '25px',
            },
          },
        ],

        layout: {
          name: layout, // cose, circle, concentric, breadthfirst
        },

      });
      setScheme(cy);
    }
  }, [nodeAndEdges, layout]);

  useEffect(() => {
    getShareHolders();
  }, [id]);

  return (
    <>
      {/* Scheme Layouts */}
      <div className="d-flex">
        <Button
          color="primary"
          className="btn waves-effect waves-light ml-auto"
          onClick={() => {
            setLayout('cose');
          }}
        >
          Cose
        </Button>
        <Button
          color="primary"
          className="btn waves-effect waves-light ml-1"
          onClick={() => {
            setLayout('circle');
          }}
        >
          Circle
        </Button>
        <Button
          color="primary"
          className="btn waves-effect waves-light ml-1"
          onClick={() => {
            setLayout('concentric');
          }}
        >
          Concentric
        </Button>
        <Button
          color="primary"
          className="btn waves-effect waves-light ml-1"
          onClick={() => {
            setLayout('breadthfirst');
          }}
        >
          Breadthfirst
        </Button>
        <Button
          disabled={scheme === null}
          color="secondary"
          className="btn waves-effect waves-light ml-3"
          onClick={() => {
            if (scheme !== null) scheme.fit();
          }}
        >
          Reset
        </Button>
      </div>
      {loading && <Spinner className="mr-2" color="secondary" />}

      {!loading && nodeAndEdges.length === 0 && (
        <div>
          No data found!
        </div>
      )}

      <div
        id="cy"
        style={{
          height: nodeAndEdges.length === 0 ? '0vh' : '100vh',
        }}
      />
    </>
  );
}

export default NetworkScheme;
