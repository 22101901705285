import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container, Row, CardBody, Card, Col, Button, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import MapsVector from './MapsVector';
import CountryResultsModal from './CountryResultsModal';

import '../../assets/css/sanction-map.css';

import { customTableStyles, mapColumns } from './constants';
import { getIcons } from './utils';

import { changeRoute, resetRoute } from '../../store/reducers/route';
import { getMapData } from '../../store/reducers/sanction-map';

import Autocomplete from '../../components/AutoComplete';

function SanctionMap() {
  const dispatch = useDispatch();
  const {
    initialized, loading, countriesData, coloredData,
  } = useSelector((state) => state.sanctionMap);
  const [query, setQuery] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  // eslint-disable-next-line
  const [toggleModal, setToggleModal] = useState(false);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] =useState([]);
  // eslint-disable-next-line
  const [clearSelected, setClearSelected] = useState(false);

  const mapData = useMemo(() => countriesData.map(({
    country_code: code, name, sanctions,
  }) => {
    const measureSet = new Set();
    sanctions.map(({ measures }) => measures.map((m) => measureSet.add(m.type_id)));
    const allMeasures = [...measureSet];
    const adoptedBySet = new Set();
    sanctions.map((s) => adoptedBySet.add(s.adopted_by));
    const allAdoptedBy = [...adoptedBySet];
    return ({
      code,
      name,
      adopted_by: allAdoptedBy.join(', '),
      measures: (
        // eslint-disable-next-line
        <div
          style={{ float: 'left' }}
          onClick={() => {
            setSelectedRows([{ code, name }]);
            setClearSelected(true);
            setTimeout(() => {
              setClearSelected(false);
            }, 500);
            setToggleModal(true);
          }}
        >
          {getIcons(allMeasures)}
        </div>
      ),
    });
  }),
  [initialized, countriesData]);

  useEffect(() => {
    if (!initialized) {
      dispatch(getMapData());
    }
    dispatch(changeRoute('Map'));
    return () => {
      dispatch(resetRoute());
    };
  }, [initialized]);

  const onFilter = () => {
    if (query.trim() !== null) {
      const f = mapData.filter((m) => m.name.toLowerCase()
        .includes(query.trim().toLowerCase()));
      setFilteredData(f);
    } else {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (query.trim().length === 0) setFilteredData([]);
  }, [query]);

  const allCountries = mapData.map(({ name }) => name);
  return (
    <>
      <CountryResultsModal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        selectedCountries={selectedRows}
        setSelectedCountries={setSelectedRows}
      />
      <Container fluid>
        <MapsVector
          loading={loading}
          coloredData={coloredData}
          setSelectedCountry={setSelectedRows}
          setToggleModal={setToggleModal}
          setClearSelected={setClearSelected}
        />
        <Card className="mb-0">
          <CardBody>
            <Row>
              <Col>
                <Autocomplete
                  setInput={setQuery}
                  placeholder="Search..."
                  suggestions={allCountries}
                />
              </Col>
              <Col md="3">
                <Button
                  color="primary"
                  className="btn waves-effect waves-light"
                  style={{
                    width: '100%',
                  }}
                  onClick={onFilter}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className="view-btn">
          <Button
            disabled={selectedRows.length === 0}
            color="secondary"
            className="btn waves-effect waves-light"
            onClick={() => setToggleModal(true)}
          >
            View Selected
          </Button>
        </div>
        <Card>
          <CardBody>
            <DataTable
              clearSelectedRows={clearSelected}
              progressPending={loading}
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              defaultSortField="name"
              defaultSortAsc
              pagination={false}
              paginationPerPage={35}
              selectableRowsVisibleOnly
              selectableRows
              onSelectedRowsChange={(e) => {
                setSelectedRows(e.selectedRows);
              }}
              onRowClicked={(e) => {
                setSelectedRows([e]);
                setToggleModal(true);
                setClearSelected(true);
                setTimeout(() => {
                  setClearSelected(false);
                }, 500);
              }}
              columns={mapColumns}
              customStyles={customTableStyles}
              data={filteredData.length === 0 ? mapData : filteredData}
              highlightOnHover
              noHeader
            />
          </CardBody>
        </Card>
        <div className="mt-1">
          <Button
            disabled={selectedRows.length === 0}
            color="secondary"
            className="btn waves-effect waves-light"
            onClick={() => setToggleModal(true)}
          >
            View Selected
          </Button>
        </div>
      </Container>
    </>
  );
}

export default SanctionMap;
