import moment from 'moment';

export const getDiffDays = (x, y) => {
  const a = moment(new Date(x));
  const b = moment(new Date(y));
  b.diff(a, 'days'); // 1
  return b.diff(a, 'days');
};

export const formattedFromTo = (z) => {
  const day = z.split('-')[2];
  const month = z.split('-')[1];
  const year = z.split('-')[0];
  return `${day}.${month}.${year}`;
};

export const filterSearchHistory = (query, temp, searchHistory,
  fromDate, toDate, minMaxScore = [65, 100]) => {
  let fi = searchHistory;
  const isTempOptions = temp.some(
    (el) => el.label === 'Include Phonetics' || el.label === 'Include AKA',
  );
  const isUsers = temp.some(
    (el) => el?.user,
  );

  if (temp.length > 0) {
    // filter by date
    if (fromDate !== '' && toDate !== '') {
      fi = fi.filter(({ pureDate: cDate }) => {
        const date = cDate.split('T')[0];
        if (new Date(date) >= new Date(fromDate)
            && new Date(date) <= new Date(toDate)) {
          return true;
        }
        return false;
      });
    }
    // filter search query
    if (query.trim().length > 0) {
      fi = fi.filter(
        (h) => {
          // eslint-disable-next-line
           const searchName = h?.search_input ?? '';
          const userName = h?.user ?? '';
          return searchName.toLowerCase().includes(query)
          || userName.toLowerCase().includes(query);
        },
      );
    }
    // filter by aka and phonetics
    if (isTempOptions) {
      const aka = temp.find((el) => (el.value.toString()).indexOf('aka') > -1);
      const phonetics = temp.find((el) => (el.value.toString()).indexOf('phonetics') > -1);

      if (aka) {
        fi = fi.filter(
          (h) => h.search_options.includes(aka.label),
        );
      }
      if (phonetics) {
        fi = fi.filter(
          (h) => h.search_options.includes(phonetics.label),
        );
      }
    }
    // filter by user names
    if (isUsers) {
      const userNames = temp.filter(
        (el) => el?.user,
      ).map((n) => n.label.toLowerCase()).join(', ');
      fi = fi.filter(
        (h) => userNames.includes(h.user.toLowerCase()),
      );
    }

    const filteredByScore = fi.filter((h) => h.search_score >= minMaxScore[0]
    && h.search_score <= minMaxScore[1]);
    fi = filteredByScore;
  }
  return fi;
};
