import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { Tag } from 'components/ui';

import useStatuses from '../../useStatuses';

import * as classes from './index.module.scss';

function StatusFilter({ disabled, onChange }) {
  const statuses = useStatuses();

  const [selectedStatus, setSelectedStatus] = useState(null);

  const onStatusChange = (statusId = null) => () => {
    if (disabled) return;

    let currentStatus = null;

    if (statusId && !Number.isNaN(statusId)) {
      currentStatus = statusId;
    }
    setSelectedStatus(currentStatus);

    onChange('status', currentStatus);
  };

  return (
    <div className={classes['status-filter']}>
      <Tag
        className={[
          classes['status-filter-item'],
          disabled ? classes['status-filter-item--disabled'] : '',
        ].join(' ')}
        color=""
        onClick={onStatusChange()}
      >
        {!selectedStatus && <CheckOutlined />}
        All
      </Tag>
      {Object.keys(statuses).map((key) => {
        const { color, name } = statuses[key];
        return (
          <Tag
            key={`${key}-${name}-${color}`}
            className={[
              classes['status-filter-item'],
              disabled ? classes['status-filter-item--disabled'] : '',
            ].join(' ')}
            color={color}
            icon={selectedStatus === key ? <CheckOutlined /> : null}
            onClick={onStatusChange(key)}
          >
            {name}
          </Tag>
        );
      })}
    </div>
  );
}

StatusFilter.defaultProps = {
  disabled: false,
};

StatusFilter.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default StatusFilter;
