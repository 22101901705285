import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button } from 'reactstrap';
import EntryCheckboxItem from './EntryCheckboxItem';

import warningSvg from '../assets/images/alert-box-outline.svg';

function ClientDoubleEntry({
  // eslint-disable-next-line
  toggleModal, setToggleModal, doubleClients, onConfirm, handleSelected, loading, status, disabled, deleteEntry, selectedEntries,
}) {
  // const [checkedClients, setCheckedClients] = useState([]);
  return (
    <Modal size="lg" isOpen={toggleModal} toggle={() => setToggleModal(false)} centered>
      <div style={{ display: 'flex', paddingTop: '15px', paddingRight: '25px' }}>
        <button
          type="button"
          onClick={() => setToggleModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={{ marginLeft: 'auto', fontSize: '24px' }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="pt-0 pb-3">
        <div className="px-4">
          <div className="mb-3 d-flex align-items-center">
            <span
              className="modal-title"
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              DOUBLE ENTRY

            </span>
            <img
              src={warningSvg}
              alt="Warning"
              width="24"
              height="24"
              style={{
                marginLeft: '5px',
                marginTop: '-2px',
              }}
            />
          </div>
          <p>
            {
            doubleClients.length === 1
              ? 'This profile seems already existing in your other lists. Please select from the below profiles to match them or simply create a new profile for this entry.'
              : 'These profiles seem already existing in your other lists. Please select from the below profiles to match them or simply create new profiles for these entries.'
          }
          </p>
        </div>
        <div className="mx-4 mb-4 entry-checkbox-wrapper">
          {doubleClients.map((d) => (
            <EntryCheckboxItem
              isSelected={selectedEntries.some((ent) => ent.masterId === d.id)}
              selectedEntries={selectedEntries}
              deleteEntry={deleteEntry}
              handleSelected={handleSelected}
              id={d.id}
              name={d.name}
              matchedNames={d.matched_names}
            />
          ))}
        </div>
        {/* Cancel and Continue buttons */}
        <div className="px-4">
          <Button
            disabled={disabled || loading}
            color={!loading && status === 'ok' ? 'success' : 'primary'}
            className="btn waves-effect waves-light"
            onClick={() => onConfirm(true)}
            style={{
              width: '105px',
            }}
          >
            {status === 'ok' && (
            <>
              <i className="bx bx-check-double font-size-16 align-middle mr-2" />
              Saved
            </>
            )}
            {loading === true && (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
              Saving...
            </>
            )}
            {loading !== true && status !== 'ok' && 'Continue'}
          </Button>
        </div>
      </ModalBody>

    </Modal>
  );
}

ClientDoubleEntry.propTypes = {
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleModal: PropTypes.bool.isRequired,
  setToggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleSelected: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  selectedEntries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  doubleClients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default ClientDoubleEntry;
