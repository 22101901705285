/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  main: 'Home / ',
  routeText: '',
  lastPart: '',
  routeFunction: null,
};

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    resetRouteSuccess(state) {
      state.routeText = '';
      state.lastPart = '';
      state.routeFunction = null;
    },
    changeRouteSuccess(state, action) {
      state.routeText = action.payload.route;
      state.lastPart = action.payload.lastPart;
    },
  },
});

export const {
  changeRouteSuccess,
  resetRouteSuccess,
} = routeSlice.actions;

export default routeSlice.reducer;

export const changeRoute = (route, lastPart = '') => (dispatch) => {
  dispatch(changeRouteSuccess({ route, lastPart }));
};

export const resetRoute = () => (dispatch) => {
  dispatch(resetRouteSuccess());
};
