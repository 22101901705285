/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

const initialState = {
  forms: [],
  invitations: [],
  formsAndInvs: [],
  initialized: false,
  loading: false,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    fetchInvStart(state) {
      state.initialized = false;
      state.invitations = [];
    },
    fetchInvSuccess(state, action) {
      state.initialized = true;
      state.invitations = action.payload;
    },
    deleteInvSuccess(state, action) {
      const ids = action.payload;
      state.invitations = state.invitations.filter((inv) => ids
        .every((item) => item.id !== inv.id));
    },
    fetchFormsStart(state) {
      state.initialized = false;
      state.forms = [];
    },
    fetchFormsSuccess(state, action) {
      state.initialized = true;
      state.forms = action.payload;
    },
    deleteFormsSuccess(state, action) {
      const ids = action.payload;
      state.forms = state.forms.filter((inv) => ids
        .every((item) => item.id !== inv.id));
    },
    fetchFormsInvStart(state) {
      state.initialized = false;
      state.formsAndInvs = [];
      state.loading = true;
    },
    fetchFormsInvSuccess(state, action) {
      state.initialized = true;
      state.formsAndInvs = action.payload;
      state.loading = false;
    },
    deleteFormsInvSuccess(state, action) {
      const ids = action.payload;
      state.formsAndInvs = state.formsAndInvs.filter((fi) => ids
        .every((item) => item.id !== fi.id));
    },
  },
});

export const {
  fetchInvStart,
  fetchInvSuccess,
  deleteInvSuccess,
  fetchFormsStart,
  fetchFormsSuccess,
  deleteFormsSuccess,
  fetchFormsInvStart,
  fetchFormsInvSuccess,
  deleteFormsInvSuccess,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;

export const fetchFormsAndInvs = () => async (dispatch) => {
  dispatch(fetchFormsInvStart());
  let fixedInvs = [];
  let fixedForms = [];
  try {
    const { data: { invitations } } = await api.getInvitations();
    fixedInvs = invitations.map((inv) => ({ ...inv, invite: true }));
  } catch (error) {
    console.log(error);
  }
  try {
    const { data: { forms } } = await api.getForms();
    fixedForms = forms.map((f) => ({ ...f, invite: false }));
  } catch (error) {
    console.log(error);
  }
  dispatch(fetchFormsInvSuccess([...fixedInvs, ...fixedForms]));
};

export const fetchInvitations = () => async (dispatch) => {
  dispatch(fetchInvStart());
  try {
    const { data: { invitations } } = await api.getInvitations();
    dispatch(fetchInvSuccess(invitations));
  } catch (error) {
    console.log(error);
  }
};

export const deleteInvitations = (ids) => async (dispatch) => {
  try {
    await api.deleteInvitations(ids);
    dispatch(deleteInvSuccess(ids));
    dispatch(deleteFormsInvSuccess(ids));
  } catch (error) {
    console.log(error);
  }
};

export const fetchForms = () => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const { data: { forms } } = await api.getForms();
    dispatch(fetchFormsSuccess(forms));
  } catch (error) {
    console.log(error);
  }
};
export const deleteForms = (ids) => async (dispatch) => {
  try {
    await api.deleteForms(ids);
    dispatch(deleteInvSuccess(ids));
    dispatch(deleteFormsInvSuccess(ids));
  } catch (error) {
    console.log(error);
  }
};
