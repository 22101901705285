import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Modal, ModalBody, Row, Spinner,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useAPI } from '../../services/api';

import { getIcon } from './utils';
import EditableContent from './EditableContent';

const getFormattedTime = (date) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const [
    { value: month },,
    { value: day },,
    { value: year },
  ] = dateTimeFormat.formatToParts(new Date(date));

  return `${day} ${month} ${year}`;
};

function CountryResultsModal({
  toggleModal, setToggleModal, selectedCountries, setSelectedCountries,
}) {
  const api = useAPI();

  const canEdit = useSelector((state) => state.auth?.user?.role === 3);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [selectedCountriesData, setSelectedCountriesData] = useState([]);

  // const titleCase = (str) => {
  //   if (str !== undefined) {
  //     return str
  //       .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  //   }
  //   return '';
  // };

  const fields = [
    {
      key: 'adopted_by',
      label: 'Adopted By',
      render(value) {
        return (<td><p className="capitalize">{value}</p></td>);
      },
    },
    { key: 'specification', label: 'Specification' },
    { key: 'details', label: 'Info' },
    { key: 'date_of_expiration', label: 'Date of expiration' },
    {
      key: 'last_update',
      label: 'Last legal update reflected',
      render(value, id, fieldName) {
        return (<td data-id={id} id={fieldName}><p>{getFormattedTime(value)}</p></td>);
      },
    },
    {
      key: 'measures',
      label: 'Last legal update reflected',
      render(values, id, fieldName) {
        return (
          <td data-id={id} id={fieldName}>
            {values.map((v) => (
              <div className="d-flex mb-2">
                {getIcon(v.type_id, 50, 50)}
                <p className="ml-3 mb-0">
                  {canEdit ? (
                    <EditableContent
                      mId={id}
                      id={v.id}
                      fieldName={fieldName}
                      fieldContent={v.details}
                    />
                  ) : v.details}
                </p>
              </div>
            ))}
          </td>
        );
      },
    },
  ];

  const getCountryData = async () => {
    const temp = [];
    setLoading(true);
    // eslint-disable-next-line
    for (const c of selectedCountries) {
      // eslint-disable-next-line
      const { data: { sanctions } } = await api.getCountryData(c.code);
      temp.push({ ...c, sanctions });
    }
    setLoading(false);
    setSelectedCountriesData(temp);
    let body = temp.map((t) => t.sanctions.map((s) => fields
      .filter((field) => !!s[field.key]).map((field) => (
        <tr key={field.key}>
          <td className="label-header" data-id={s.id} id={field.key}>
            {field.label}
          </td>
          {
            field.render && field.render(s[field.key], s.id, field.key)
          }
          {
            !field.render && !Array.isArray(s[field.key])
            && (canEdit ? (
              <EditableContent
                id={s.id}
                fieldName={field.key}
                fieldContent={s[field.key]}
              />
            ) : <td><p>{s[field.key]}</p></td>
            )
          }
        </tr>
      ))));

    body = body.map((b, ind) => (
      <div style={{
        borderBottom: '2px solid #74788D',
      }}
      >
        {b.map((r, index) => ((
          <div
            className="pb-2 pt-1"
            style={{
              borderBottom: index === (b.length - 1) ? 'none' : '1px solid #74788D',
            }}
          >
            <tr id={`${temp[ind].name}${index}`}>
              <td className="label-header">
                Country or category
              </td>
              <td>
                {temp[ind].name}
              </td>
            </tr>
            {r}
          </div>
        )))}
      </div>
    ));

    setContent(body);
  };

  const getClickableResources = () => {
    if (selectedCountriesData !== undefined) {
      const anchorElements = selectedCountriesData.map((c) => {
        const elements = c.sanctions.map((s, index) => (
          <li className="list-group-item pl-0">
            <a href={`#${c.name}${index}`}>
              Adopted by
              {' '}
              {s.adopted_by}
            </a>
          </li>
        ));
        return (
          <div
            className="mb-4"
            style={{
              borderBottom: '1px solid rgb(238 238 238)',
            }}
          >
            <p className="country-name">
              {c.name}
            </p>
            <ul className="list-group list-group-flush anchor">
              {elements}
            </ul>
          </div>
        );
      });
      return (
        <div
          className="fixed-country-container"
          style={{
            overflowY: selectedCountriesData.length > 1 ? 'scroll' : 'none',
          }}
        >
          {anchorElements}
        </div>
      );
    }
    return '';
  };

  useEffect(() => {
    getCountryData();
  }, [selectedCountries]);

  return (
    <Modal
      isOpen={toggleModal}
      toggle={() => {
        setToggleModal(false);
        setSelectedCountries([]);
      }}
      centered
      size="xl"
    >
      <ModalBody
        className="p-0 ml-0"
        style={{
          background: '#F8F8FB',
          borderRadius: '5px',
          height: '90vh',
          overflowY: 'scroll',
        }}
      >
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: '100%',
            }}
          >
            <Spinner color="secondary" />
          </div>
        )}
        {!loading && (
        <Row
          className="p-0 m-0"
        >
          <Col md="3" className="pl-0">
            {getClickableResources()}
          </Col>
          <Col className="pt-2">
            <div>
              <button
                type="button"
                onClick={() => {
                  setToggleModal(false);
                  setSelectedCountries([]);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ marginLeft: 'auto', fontSize: '24px' }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {' '}
            {content.map((c) => (
              <div className="mt-4">
                <table
                  className="sanction-table"
                >
                  <tbody>
                    {c}
                  </tbody>
                </table>
              </div>
            ))}
          </Col>
        </Row>
        )}

      </ModalBody>
    </Modal>
  );
}

CountryResultsModal.propTypes = {
  toggleModal: PropTypes.bool.isRequired,
  setToggleModal: PropTypes.func.isRequired,
  setSelectedCountries: PropTypes.func.isRequired,
  selectedCountries: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CountryResultsModal;
