import { useState, useEffect } from 'react';

import { request } from 'helpers';
import usePrevious from 'hooks/usePrevious';
import useDebounce from 'hooks/useDebounce';

function usePagination({
  requestFunction,
  pageNumber,
  pageSize,
  extraParams,
}) {
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const previousPageSize = usePrevious(pageSize);
  const previousExtraParams = usePrevious(Object.entries(extraParams).toString());

  const loadData = async (page = 1, size = 50, flag = null) => {
    if (loading) return;
    if (!data?.[page]
      || previousPageSize !== pageSize
      || flag === 'refetch'
      || previousExtraParams !== Object.entries(extraParams).toString()) {
      setLoading(true);

      const params = {
        ...extraParams,
        page,
        page_size: size,
      };
      const [res, error] = await request(requestFunction(params));

      if (!error) {
        const { data: { results, count: dataCount } } = res;
        setCount(() => dataCount);
        setIsError(false);

        const concatenedResultsArray = []
          .concat(flag === 'refetch' ? [] : Object.keys(data).flatMap((key) => data[key]))
          .concat(results);

        setDataArray(() => concatenedResultsArray);

        setData((prevState) => ({
          ...prevState,
          [page]: results,
        }));
      } else {
        setIsError(true);
      }
      setLoading(false);
    }
  };

  const getDebouncedData = useDebounce(
    (page, size, flag) => loadData(page, size, flag),
    10000,
  );

  const refetch = () => {
    loadData(pageNumber, pageSize, 'refetch');
  };

  useEffect(() => {
    if (isError) {
      getDebouncedData(pageNumber, pageSize);
    } else {
      loadData(pageNumber, pageSize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, extraParams, isError]);

  return {
    loading,
    count,
    data,
    dataArray,
    refetch,
  };
}

usePagination.defaultProps = {
  requestFunction: new Promise((resolve) => resolve()),
  pageNumber: 1,
  pageSize: 50,
  extraParams: {},
};

export default usePagination;
