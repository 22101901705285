import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import {
  Card,
  Input,
  Button,
} from 'components/ui';

import {
  nameRules,
  titleRules,
  departmentRules,
  phoneRules,
  phoneMask,
} from './constants';

function AccountDetails({
  isDisabled,
  email,
  name,
  title,
  department,
  phone,
  onUpdateUser,
}) {
  const { translate } = useLanguage();

  const [form] = Form.useForm();

  const [isFieldsTouched, setIsFieldsTouched] = useState(false);

  const initialValues = useMemo(() => ({
    email,
    name,
    title,
    department,
    phone,
  }), [email, name, title, department, phone]);

  const formItem = useMemo(() => ({ ...translate('settings.accountDetails.form') }), [translate]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const onValuesChange = () => {
    setIsFieldsTouched(form.isFieldsTouched());
  };

  const onSubmit = (values) => {
    const payload = { ...values };
    delete payload.email;
    onUpdateUser(payload);
  };

  return (
    <Card className="_settings__account-details">
      <h5 className="_settings__account-details-header">
        {translate('settings.accountDetails.header')}
      </h5>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
      >
        <Form.Item label={formItem.email} name="email">
          <Input.Text disabled type="email" />
        </Form.Item>
        <Form.Item
          label={formItem.name.label}
          name="name"
          rules={nameRules(formItem.name.label, translate)}
        >
          <Input.Text placeholder={formItem.name.placeholder} />
        </Form.Item>
        <Form.Item
          label={formItem.title.label}
          name="title"
          rules={titleRules(formItem.title.label, translate)}
        >
          <Input.Text placeholder={formItem.title.placeholder} />
        </Form.Item>
        <Form.Item
          label={formItem.department.label}
          name="department"
          rules={departmentRules(formItem.department.label, translate)}
        >
          <Input.Text placeholder={formItem.department.placeholder} />
        </Form.Item>
        <Form.Item
          label={formItem.phone.label}
          name="phone"
          rules={phoneRules(formItem.phone.label, translate)}
        >
          <Input.Masked mask={phoneMask} placeholder={formItem.phone.placeholder} />
        </Form.Item>
        <Button
          disabled={!isFieldsTouched || isDisabled}
          type="submit"
          color="primary"
          className="_settings__account-details-button"
        >
          {formItem.save}
        </Button>
      </Form>
    </Card>
  );
}

AccountDetails.defaultProps = {
  isDisabled: false,
  email: '',
  name: '',
  title: '',
  department: '',
  phone: '',
};

AccountDetails.propTypes = {
  isDisabled: PropTypes.bool,
  email: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  department: PropTypes.string,
  phone: PropTypes.string,
  onUpdateUser: PropTypes.func.isRequired,
};

export default AccountDetails;
