import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

// load lists
const initialState = {
  initialized: false,
  error: false,
  loading: false,
  data: [],
  listCount: 0,
};

const listsSlice = createSlice({
  name: 'integratedLists',
  initialState,
  reducers: {
    listStart(state) {
      state.error = null;
      state.loading = true;
      state.initialized = false;
    },
    listSuccess(state, action) {
      state.data = action.payload.map((l) => ({ ...l, details: false }));
      state.listCount = action.payload.length;
      state.error = null;
      state.loading = false;
      state.initialized = true;
    },
    listFail(state) {
      state.error = true;
      state.loading = false;
      state.initialized = true;
    },
    listCreateSuccess(state, action) {
      state.data.push(action.payload.list);
      state.listCount = state.data.length;
    },
    deleteListStart(state) {
      state.loading = true;
    },
    deleteListSuccess(state, action) {
      const filteredList = state.data.filter(
        (list) => list.id !== parseInt(action.payload, 10),
      );
      state.data = filteredList;
      state.listCount = filteredList.length;
      state.loading = false;
    },

    deleteSomeClientsStart(state) {
      state.error = null;
      state.loading = true;
    },

    deleteSomeClientsSuccess(state, action) {
      state.data = state.data.map((row) => {
        const filtered = action.payload.fields;
        if (row.id === action.payload.listId) {
          return {
            ...row,
            fields: filtered,
          };
        }
        return row;
      });
      state.loading = false;
    },

    editListStart(state) {
      state.loading = true;
      state.status = '';
    },
    editListSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.id) {
          return {
            ...row,
            name: action.payload.name,
            sensivity: action.payload.sensivity,
            options: action.payload.options,
          };
        }
        return row;
      });
      state.loading = false;
      state.status = 'ok';
    },
    editListFail(state) {
      state.loading = false;
      state.status = 'error';
    },
    editListFinished(state) {
      state.loading = false;
      state.status = '';
    },

    getListItemSuccess(state, action) {
      const filtered = state.data.map((row) => {
        if (row.id === action.payload.data.id) {
          return {
            ...action.payload.data,
            details: true,
          };
        }
        return row;
      });
      state.data = filtered;
    },

    editClientStart(state) {
      state.loading = true;
      state.status = '';
    },
    editClientSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
    },
    editClientFinished(state) {
      state.loading = false;
      state.status = '';
    },
  },
});

export const {
  listStart,
  listSuccess,
  listFail,
  listCreateSuccess,
  deleteListStart,
  deleteListSuccess,
  deleteSomeClientsStart,
  deleteSomeClientsSuccess,
  editListStart,
  editListSuccess,
  editListFail,
  editListFinished,
  editClientStart,
  editClientSuccess,
  editClientFinished,
  getListItemSuccess,
} = listsSlice.actions;

export const iListReducer = listsSlice.reducer;

export const loadLists = (cb = null) => async (dispatch) => {
  dispatch(listStart());
  try {
    const { data: { mock_lists: lists } } = await api.getMockLists();
    dispatch(listSuccess(lists));
    if (cb) {
      cb(true);
    }
  } catch (error) {
    dispatch(listFail(true));
    if (cb) {
      cb(false);
    }
  }
};

export const loadListItem = (id, history) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data: { mock_list } } = await api.getMockList(id);
    dispatch(getListItemSuccess({ data: mock_list, id }));
  } catch (error) {
    // eslint-disable-next-line
    if (history) {
      // eslint-disable-next-line
      history.push('/integrated-lists');
    }
  }
};

// TODO:: handle errors
export const deleteList = (id) => async (dispatch) => {
  dispatch(deleteListStart());
  try {
    await api.deleteMockList(id);
    dispatch(deleteListSuccess(id));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const deleteSomeClients = (listId, filteredFields) => async (dispatch) => {
  dispatch(deleteSomeClientsStart());
  try {
    await api.updateMockClients(listId, filteredFields);
    dispatch(deleteSomeClientsSuccess({ listId, fields: filteredFields }));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const editList = (id, name, sensivity, score, options) => async (dispatch) => {
  dispatch(editListStart());
  try {
    await api.editMockList(id, name, sensivity, score, options);
    dispatch(
      editListSuccess({
        id,
        name,
        sensivity,
        score,
        options,
      }),
    );
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  } catch (error) {
    dispatch(editListFail());
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  }
};

export const editClient = (listId, id, data) => async (dispatch) => {
  dispatch(editClientStart());
  try {
    const edited = await api.editClient(listId, id, data);
    dispatch(editClientSuccess({ status: 'ok', client: edited.data.client }));
    setTimeout(() => {
      dispatch(editClientFinished());
    }, 1500);
  } catch (error) {
    console.log('error: ', error);
    dispatch(editClientFinished());
  }
};

// create lists
const createListInitialState = {
  status: '',
  loading: false,
  id: null,
  listName: '',
  clients: [],
};

const createListSlice = createSlice({
  name: 'createIList',
  initialState: createListInitialState,
  reducers: {
    createStart(state) {
      state.status = '';
      state.loading = true;
    },
    createSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
      state.id = action.payload.id;
    },
    createFail(state) {
      state.status = 'error';
      state.loading = false;
    },
    createFinished(state) {
      state.status = '';
      state.loading = false;
      state.id = null;
    },
  },
});

// actions
export const {
  createStart,
  createSuccess,
  createFail,
  createFinished,
} = createListSlice.actions;

export const createList = (listName, clients,
  score, history, setProgress = () => {}) => async (dispatch) => {
  try {
    dispatch(createStart());
    const { data: { mock_lists: mockList, status } } = await api.createMockList({
      name: listName, sensivity: 2, score, fields: clients,
    });
    const { id } = mockList[0];
    if (status === 'error') {
      dispatch(createFail(status));
    } else if (status === 'ok') {
      setProgress(70);
      const { data: { mock_list: list } } = await api.getMockList(id);
      setProgress(85);
      dispatch(createSuccess({ status, id }));
      setTimeout(() => {
        dispatch(listCreateSuccess({
          list,
        }));
        dispatch(createFinished());
        setProgress(100);
        if (history !== undefined) {
          history.push(`/integrated-lists/${list.id}`);
        }
      }, 1000);
    }
  } catch (error) {
    dispatch(createFail(error.message));
    setTimeout(() => {
      dispatch(createFinished());
    }, 1000);
  }
};

// reducer
export const createIListReducer = createListSlice.reducer;
