import React from 'react';
import PropTypes from 'prop-types';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.scss';
import { amlIndexData } from './aml-index.json';
import allCountries from '../../constants/countries';

const rankData = {};
amlIndexData.map((dcnt) => allCountries.map((cnt) => {
  if (cnt.alpha3.toUpperCase() === dcnt.alpha3) {
    Object.assign(rankData, {
      [cnt.alpha2.toUpperCase()]: parseInt(dcnt.rank, 10),
    });
  }
  return cnt;
}));

// const flagData = {};
// riskData.map((dcnt) => allCountries.map((cnt) => {
//   if (cnt.alpha3.toUpperCase() === dcnt.alpha3) {
//     Object.assign(flagData, {
//       [cnt.alpha2.toUpperCase()]: dcnt.flag,
//     });
//   }
//   return cnt;
// }));

function importAll(r) {
  const images = {};
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item);
    return item;
  });
  return images;
}

const flags = importAll(require.context('../../assets/images/countryFlags', false, /\.(png|jpe?g|svg)$/));

function Vectormap(props) {
  return (
    <div style={{
      width: '100%',
      height: '65vh',
    }}
    >
      <VectorMap
        onRegionTipShow={(e, el, code) => {
          const c = code.toLowerCase() === 'xs' ? 'so' : code.toLowerCase();
          const extension = code.toLowerCase() === 'xk' ? 'png' : 'svg';
          if (rankData[code] === undefined || props.coloredData[code] === undefined) {
            el.html(`
          <img src="${flags[`${c}.${extension}`]}" class="mr-1" style="height:15px;">
          ${el.html().toUpperCase()}
          <br/>
          <div class="mb-1"/> 
          Lack of data`);
            return;
          }
          el.html(`
          <img src="${flags[`${c}.${extension}`]}" class="mr-1" style="height:15px;">
          ${el.html().toUpperCase()}
          <br/>
          <div class="mb-1"/> 
          <b>Rank:</b> ${rankData[code]} <br/>                    
          <b>Score:</b> ${props.coloredData[code]}`);
        }}
        onRegionClick={props.onRegionClick}
        map={props.value}
        backgroundColor="transparent"
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
        regionStyle={{
          initial: {
            fill: props.color,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            fill: '#2938bc', // what colour clicked country will be
          },
          selectedHover: {},

        }}
        containerClassName="map"
        series={{
          regions: [
            {
              values: props.coloredData, // this is your data
              // control numerical values, remove to give specific colors to regions
              scale: [
                '#06db00',
                '#90e900',
                '#fad900',
                '#f9a70e',
                '#f16521',
                '#e82334',
                '#e00047',
              ], // your color game's here
              // polynomial
              normalizeFunction: 'linear',
            },
          ],
        }}
      />
    </div>
  );
}

Vectormap.propTypes = {
  coloredData: PropTypes.shape(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onRegionClick: PropTypes.func.isRequired,
};

export default Vectormap;
