import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';

const colors = [
  '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085',
  '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22',
  '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7',
  '#7f8c8d',
];

function ProfileMenu() {
  const [isMenuOpen, toggleMenu] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [style, text] = useMemo(() => {
    if (!user) {
      return [null, null];
    }
    const splitted = user.name.split(' ');
    let initials = splitted[0].charAt(0).toUpperCase();
    if (splitted.length >= 2) {
      initials = splitted[0].charAt(0).toUpperCase() + splitted[1].charAt(0).toUpperCase();
    }
    const width = 36;
    const height = 36;
    const colorIndex = (initials.charCodeAt(0) - 65) % colors.length;
    const fontSize = width / 2;
    return [{
      backgroundColor: colors[colorIndex],
      width,
      height,
      font: `${fontSize}px`,
      color: '#FFF',
      textAlign: 'center',
      lineHeight: '30px',
    }, initials];
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Dropdown
      isOpen={isMenuOpen}
      toggle={() => { toggleMenu(!isMenuOpen); }}
      className="d-inline-block"
    >
      <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
        <div className="header-profile-user rounded-circle d-inline-block" style={style}>{text}</div>
        <span className="d-none d-xl-inline-block ml-2 mr-1">{user.name}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu right>
        <Link to="/settings" className="dropdown-item">
          <i className="bx bx-wrench font-size-17 align-middle mr-1" />
          Settings
        </Link>
        <div className="dropdown-divider" />
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
          <span>Logout</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProfileMenu;
