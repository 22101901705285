import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import { Button, Icon } from 'components/ui';

function ReportDeleteModal({ disabled, reportName, onDelete }) {
  const { translate } = useLanguage();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onDelete();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        color="delete"
        className="ml-auto"
        onClick={showModal}
      >
        <Icon name="trash" />
      </Button>
      <Modal
        visible={visible}
        title={translate('savedReports.modal.delete.title', [reportName])}
        wrapClassName="py-1"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" color="cancel" className="mr-3" onClick={handleCancel}>
            {translate('globals.cancel')}
          </Button>,
          <Button key="submit" color="danger" className="px-4" onClick={handleOk}>
            {translate('globals.delete')}
          </Button>,
        ]}
      >
        {translate('savedReports.modal.delete.content', [reportName])}
      </Modal>
    </>
  );
}

ReportDeleteModal.defaultProps = {
  disabled: false,
};

ReportDeleteModal.propTypes = {
  disabled: PropTypes.bool,
  reportName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default ReportDeleteModal;
