import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './index.module.scss';

function TimelineItem({ children, date }) {
  if (children && date) {
    return (
      <li className={classes['timeline-item']}>
        <i
          className={`bx bxs-right-arrow-circle ${classes['timeline-item__icon']}`}
        />
        <p className={classes['timeline-item__content']}>
          {children}
        </p>
        <span className={classes['timeline-item__date']}>
          {date}
        </span>
      </li>
    );
  }
}

TimelineItem.defaultProps = {
  children: '',
  date: '',
};

TimelineItem.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
};

export default TimelineItem;
