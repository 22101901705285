import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  ModalFooter,
} from 'reactstrap';

import ReactSelect from './ReactSelect';
import countries from '../../constants/countries';

function getCurrentYear() {
  const today = new Date();
  return today.getFullYear();
}

const fixedCountries = countries.map((country) => ({
  value: country.alpha3,
  label: country.name,
}));
function AdvancedFilters({
  isOpen,
  onClose,
  loading,
  params,
  onSearchParamChange,
  handleKeyDown,
  clearAll,
  handleAdvancedSearch,
}) {
  const [dateType, setDateType] = useState('exact');

  const handleCloseClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleDateTypeChange = useCallback((e) => {
    setDateType(e.currentTarget.getAttribute('data-date-type'));
  }, []);

  const handleCountriesChange = useCallback((value) => {
    onSearchParamChange({ currentTarget: { name: 'country', value } });
  }, [onSearchParamChange]);

  const handleBirthDateRangeChange = useCallback((e) => {
    const { name, value } = e.currentTarget;
    onSearchParamChange({ currentTarget: { name: 'birthDate', value: { ...params.birthDate, [name]: value } } });
  }, [onSearchParamChange, params.birthDate]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={handleCloseClick}
      centered
      scrollable
    >
      <div className="d-flex pb-0 pt-2 pr-3">
        <button
          disabled={loading}
          type="button"
          onClick={handleCloseClick}
          className="close ml-auto"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="px-4">
        <Row>
          <Col>
            <label className="font-weight-bold" htmlFor="advanced-name">
              Name
            </label>
            <input
              type="text"
              id="advanced-name"
              name="query"
              className="form-control"
              placeholder="Type here..."
              value={params.query}
              onChange={onSearchParamChange}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col>
            <label className="font-weight-bold" htmlFor="advanced-id">
              ID Number
            </label>
            <input
              type="text"
              id="advanced-id"
              name="idNumber"
              className="form-control"
              placeholder="Type here..."
              value={params.idNumber}
              onChange={onSearchParamChange}
            />
          </Col>
          <Col>
            <label className="font-weight-bold" htmlFor="advanced-birth-place">
              Place of Birth
            </label>
            <input
              type="text"
              id="advanced-birth-place"
              name="birthPlace"
              className="form-control"
              placeholder="Type here..."
              value={params.birthPlace}
              onChange={onSearchParamChange}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label className="font-weight-bold" htmlFor="advanced-type">
              Type
            </label>
            <select
              id="advanced-type"
              name="type"
              className="custom-select form-control"
              value={params.type}
              onChange={onSearchParamChange}
            >
              <option value="">All Type</option>
              <option value="Corporation">Corporation</option>
              <option value="Organisation">Organisation</option>
              <option value="Individual">Individual</option>
              <option value="Vessel">Vessel</option>
              <option value="AirCraft">AirCraft</option>
            </select>
          </Col>
          <Col>
            <label className="font-weight-bold" htmlFor="advanced-countries">
              Country
            </label>
            <ReactSelect
              id="advanced-countries"
              selectData={fixedCountries}
              setSelected={handleCountriesChange}
              getSelected={params.country}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="4">
            <div className="form-group">
              <label className="font-weight-bold" htmlFor="advanced-birth-date">
                Date of Birth
              </label>
            </div>
            <div className="row mb-3  ">
              <div className="col pr-0">
                <button
                  type="button"
                  data-date-type="exact"
                  onClick={handleDateTypeChange}
                  className={`btn btn-sm w-100 ${dateType === 'exact' ? 'btn-secondary' : 'btn-link'}`}
                >
                  Exact Date
                </button>
              </div>
              <div className="col pl-0">
                <button
                  type="button"
                  data-date-type="range"
                  onClick={handleDateTypeChange}
                  className={`btn btn-sm w-100 ${dateType === 'range' ? 'btn-secondary' : 'btn-link'}`}
                >
                  Date Range
                </button>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6">
                <input
                  id="advanced-birth-date"
                  name="start"
                  className="form-control"
                  type="number"
                  placeholder="Year"
                  min={1900}
                  max={getCurrentYear()}
                  step="1"
                  value={params.birthDate.start}
                  onChange={handleBirthDateRangeChange}
                />
              </div>
              {
                dateType === 'range' && (
                  <div className="col-6">
                    <input
                      name="end"
                      disabled={params.birthDate.start.length === 0}
                      className="form-control"
                      type="number"
                      placeholder="Year"
                      min={parseInt(params.birthDate.start, 10)}
                      max={getCurrentYear()}
                      step="1"
                      value={params.birthDate.end}
                      onChange={handleBirthDateRangeChange}
                    />
                  </div>
                )
              }
            </div>
          </Col>
          <Col md="8">
            <legend className="col-form-label font-weight-bold pt-0">Other Options</legend>
            <div className="d-flex">
              <div
                className="d-block custom-control custom-checkbox mb-3 mr-4"
                style={{ lineHeight: '20px' }}
              >
                <input
                  type="checkbox"
                  id="advanced-aka"
                  name="aka"
                  className="custom-control-input"
                  onChange={onSearchParamChange}
                  checked={params.aka}
                />
                <label
                  htmlFor="advanced-aka"
                  className="custom-control-label"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  Include AKA
                </label>
              </div>
              <div
                className="d-block custom-control custom-checkbox mb-3 mr-4"
                style={{ lineHeight: '20px' }}
              >
                <input
                  type="checkbox"
                  id="advanced-phonetics"
                  name="phonetics"
                  className="custom-control-input"
                  onChange={onSearchParamChange}
                  checked={params.phonetics}
                />
                <label
                  htmlFor="advanced-phonetics"
                  className="custom-control-label"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  Include Phonetics
                </label>
              </div>
              <div
                className="d-block custom-control custom-checkbox mb-3 mr-4"
                style={{ lineHeight: '20px' }}
              >
                <input
                  type="checkbox"
                  id="advanced-noLogging"
                  name="noLogging"
                  className="custom-control-input"
                  onChange={onSearchParamChange}
                  checked={params.noLogging}
                />
                <label
                  htmlFor="advanced-noLogging"
                  className="custom-control-label"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  No-Log Search
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="px-4">
        <Button
          color="link"
          className="btn btn-link"
          onClick={() => clearAll(false)}
        >
          Reset
        </Button>
        <Button
          disabled={loading}
          color="primary"
          className="btn btn-primary w-25"
          onClick={() => handleAdvancedSearch()}
        >
          Search
        </Button>
      </ModalFooter>
    </Modal>
  );
}
AdvancedFilters.defaultProps = {
  query: '',
};

AdvancedFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    query: PropTypes.string.isRequired,
    idNumber: PropTypes.string.isRequired,
    birthPlace: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    country: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })).isRequired,
    birthDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }).isRequired,
    aka: PropTypes.bool.isRequired,
    phonetics: PropTypes.bool.isRequired,
    noLogging: PropTypes.bool.isRequired,
  }).isRequired,
  onSearchParamChange: PropTypes.func.isRequired,
  query: PropTypes.string,
  handleKeyDown: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  handleAdvancedSearch: PropTypes.func.isRequired,
};

export default AdvancedFilters;
