import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card, CardTitle, CardBody, Button, Alert, Progress,
} from 'reactstrap';
import { createList } from '../../store/reducers/automated-lists';

import CancelConfirmModal from '../../components/CancelConfirmModal';
import '../../assets/css/create-list.css';
import { changeRoute } from '../../store/reducers/route';

function isUrl(url) {
  try {
    return toString.call(url) === '[object String]' && !!(new URL(url));
  } catch (_) {
    return false;
  }
}

function CreateIntegratedList({ typeId }) {
  const {
    loading, status, listName: name,
  } = useSelector(
    (state) => state.createAutomatedList,
  );

  const [progress, setProgress] = useState(0);

  const [toggleCancel, setToggleCancel] = useState(false);

  const [listName, setListName] = useState(name ?? '');

  const [dataType, setDataType] = useState(0);

  const [dataUrl, setDataUrl] = useState('');

  const [tagColumnName, setTagColumnName] = useState('');

  const [fuzzyScore, setFuzzyScore] = useState(65);
  const [aka, setAka] = useState(true);
  const [pep, setPep] = useState(false);
  const [media, setMedia] = useState(false);

  const [targetRoute, setTargetRoute] = useState('/automated-lists');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancel = () => {
    setToggleCancel(false);
  };
  const handleConfirm = () => {
    history.push(targetRoute);
  };

  const handleScoreBlur = useCallback((e) => {
    if (parseInt(e.currentTarget.value, 10) < 65) {
      setFuzzyScore(65);
    } else if (parseInt(e.currentTarget.value, 10) > 100) {
      setFuzzyScore(100);
    }
  }, [fuzzyScore]);

  const handleCreateClients = () => {
    setProgress(30);
    dispatch(createList(listName,
      dataType, dataUrl,
      tagColumnName,
      fuzzyScore,
      // options
      {
        search_pep: pep,
        search_media: media,
      },
      // search options
      {
        aka,
      },
      history,
      setProgress));
  };

  const clickEventListener = useCallback(
    (e) => {
      e.preventDefault();
      // console.log(window.getEventListeners(document.querySelector('#sidebar-menu')));
      document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
      if (listName.trim().length > 0) {
        const el = e.target.parentElement.getElementsByTagName('a')[0];
        if (el !== undefined) {
          const tRoute = el.getAttribute('href');
          setTargetRoute(tRoute);
          setToggleCancel(true);
        }
      } else {
        try {
          const tRoute = e.target.parentElement.getElementsByTagName('a')[0].getAttribute('href');
          history.push(tRoute);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [listName],
  );

  useEffect(() => {
    document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
    document.querySelector('#sidebar-menu').addEventListener('click', clickEventListener);
    let routeText = 'Automated-Lists ';
    if (typeId === 3) routeText = 'Automated-Lists / ';
    if (typeId === 2) routeText = 'Integrated-Lists / ';
    dispatch(changeRoute(routeText, 'Add new list'));
    return () => {
      document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
      dispatch(changeRoute('Automated-Lists / '));
    };
  }, [listName]);
  console.log(typeId);
  return (
    <div>
      <CancelConfirmModal
        isOpen={toggleCancel}
        name={listName}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <Card>
        <CardBody className="d-flex">
          <div style={{
            width: '40px',
          }}
          >
            <Button
              disabled={loading}
              color="link"
              className="btn waves-effect waves-light ml-auto p-0 border-0 outline-0"
              style={{
                height: '26px',
                borderRadius: '20px',
              }}
              onClick={() => {
                if (listName) setToggleCancel(true);
                else {
                  let routeText = '/automated-Lists';
                  if (typeId === 3) routeText = '/automated-Lists';
                  if (typeId === 2) routeText = '/integrated-Lists';
                  history.push(routeText);
                }
              }}
            >
              <i
                style={{
                  color: '#74788D',
                  fontSize: '24px',
                }}
                className="bx bx-left-arrow-alt "
              />
            </Button>
          </div>
          <div style={{
            width: '100%',
            marginTop: '1.6px',
          }}
          >
            <CardTitle
              className="mt-0 d-flex"
              style={{
                color: '#25213B',
              }}
            >
              Create New List

            </CardTitle>
            {/* List name */}
            <div className="form-group row mt-4">
              <label htmlFor="list-name" className="col-md-3 col-form-label">
                List Name
              </label>
              <div className="col-md-7">
                <input
                  disabled={loading}
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete="false"
                  id="list-name"
                  name="list-name"
                  className="form-control"
                  type="search"
                  placeholder="Enter list name(must contain at least three characters)"
                  value={listName}
                  onChange={(e) => {
                    setListName(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* Minimum Name Score */}
            <div className="form-group row mt-4">
              <label htmlFor="minNameScore" className="col-md-3 col-form-label">Minimum Name Score</label>
              <div className="col-md-5">
                <div className="d-flex align-items-center">
                  <input
                    disabled={loading}
                    id="minNameScore"
                    name="minNameScore"
                    type="range"
                    min="65"
                    max="100"
                    value={fuzzyScore}
                    className="form-control-range mr-2"
                    onChange={(e) => setFuzzyScore(e.target.value)}
                  />
                  <input
                    disabled={loading}
                    name="minNameScore"
                    type="number"
                    min="65"
                    max="100"
                    value={fuzzyScore}
                    className="form-control"
                    onChange={(e) => setFuzzyScore(e.target.value)}
                    onBlur={handleScoreBlur}
                    style={{ width: '5em' }}
                  />
                </div>
              </div>
            </div>

            {/* Search options: include aka  */}
            <div className="form-group row mt-4 d-flex align-items-center">
              <label className="col-md-3 col-form-label" htmlFor="searchOptions">Search Options</label>
              <div className="col-md-8 d-flex align-items-center">
                <div className="custom-control custom-checkbox col-md-3">
                  <input
                    disabled={loading}
                    id="includeAka"
                    type="checkbox"
                    className="custom-control-input"
                    checked={aka}
                    onChange={(e) => {
                      setAka(e.target.checked);
                    }}
                  />
                  <label htmlFor="includeAka" className="custom-control-label">
                    Include AKA
                  </label>
                </div>
                <div className="custom-control custom-checkbox col-md-3">
                  <input
                    disabled={loading}
                    id="includePEP"
                    type="checkbox"
                    className="custom-control-input"
                    checked={pep}
                    onChange={(e) => {
                      setPep(e.target.checked);
                    }}
                  />
                  <label htmlFor="includePEP" className="custom-control-label">
                    Include PEP
                  </label>
                </div>
                <div className="custom-control custom-checkbox col-md-3">
                  <input
                    disabled={loading}
                    id="includeMedia"
                    type="checkbox"
                    className="custom-control-input"
                    checked={media}
                    onChange={(e) => {
                      setMedia(e.target.checked);
                    }}
                  />
                  <label htmlFor="includeMedia" className="custom-control-label">
                    Include Media
                  </label>
                </div>
              </div>
            </div>

            {/* Data type */}
            <div className="form-group row">
              <label htmlFor="data-type" className="col-md-3 col-form-label">Data Type</label>
              <div className="col-md-7">
                <select
                  id="data-type"
                  className="form-control"
                  onChange={(e) => {
                    setDataType(parseInt(e.target.value, 10));
                  }}
                >
                  <option value="0">Select data type</option>
                  <option value="1">XML</option>
                  <option value="2">XLSX</option>
                </select>
              </div>
            </div>

            {/* tag/ column name */}
            {dataType !== 0 && (
            <div className="form-group row mt-4">
              <label htmlFor="tag-column-name" className="col-md-3 col-form-label">
                {dataType === 1 && 'Tag Name'}
                {dataType === 2 && 'Column Letter'}
              </label>
              <div className="col-md-7">
                <input
                  disabled={loading}
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete="false"
                  id="tag-column-name"
                  name="tag-column-name"
                  className="form-control"
                  type="search"
                  placeholder={`Enter ${dataType === 1 ? 'tag name' : ''}${dataType === 2 ? 'column letter' : ''}`}
                  value={tagColumnName}
                  onChange={(e) => {
                    // xml tag name
                    if (dataType === 1) {
                      setTagColumnName(e.target.value);
                    }
                    // xlsx column letter
                    if (dataType === 2) {
                      setTagColumnName((e.target.value)[0]);
                    }
                  }}
                />
              </div>
            </div>
            )}

            {/* Data url */}
            {dataType !== 0 && (
            <div className="form-group row mt-4">
              <label htmlFor="data-url" className="col-md-3 col-form-label">
                Data URL
              </label>
              <div className="col-md-7">
                <input
                  disabled={loading}
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete="false"
                  id="data-url"
                  name="data-url"
                  className="form-control"
                  type="search"
                  placeholder="Enter data url"
                  value={dataUrl}
                  onChange={(e) => {
                    setDataUrl(e.target.value);
                  }}
                />
              </div>
            </div>
            )}

            {/* Actions */}
            <div className="form-group row">
              <div className="col-md-3" />
              <div className="col-md-7">
                {/* Alert for data type length */}
                { !(isUrl(dataUrl)) && dataType !== 0 && dataUrl.length > 0 && (
                  <div className="mt-0">
                    <Alert color="warning">
                      Please enter valid data url.
                    </Alert>
                  </div>
                )}
                {(loading) && <Progress className="mt-3" striped animated color="success" value={progress} />}
                {/* Save list */}
                <Button
                  disabled={(listName.trim().length < 3
                  && listName.trim() !== 0)
                  || dataType === 0
                  || !(isUrl(dataUrl))
                  || (loading)}
                  color={
                  // eslint-disable-next-line
                  status === "error"
                    ? 'danger'
                    : (!loading) && status === 'ok'
                      ? 'success'
                      : 'primary'
                }
                  className="btn waves-effect waves-light"
                  style={{
                    width: '105px',
                    marginTop: '25px',
                    cursor: listName.length >= 3 ? 'pointer' : 'not-allowed',
                  }}
                  onClick={handleCreateClients}
                >
                  {status === 'ok' && (
                    <>
                      <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                      Saved
                    </>
                  )}
                  {(loading === true)
                    && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                      Saving...
                    </>
                    )}
                  {status === 'error' && (
                    <>
                      <i className="bx bx-block font-size-16 align-middle mr-2" />
                      Error
                    </>
                  )}
                  {status !== 'error'
                  && loading !== true
                  && status !== 'ok'
                  && 'Save list'}
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

CreateIntegratedList.defaultProps = {
  typeId: 2,
};

CreateIntegratedList.propTypes = {
  typeId: PropTypes.number,
};

export default CreateIntegratedList;
