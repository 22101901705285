import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useLanguage } from 'contexts/LanguageContext';
import { useDate } from 'hooks';

import { Tag } from 'components/ui';

import ProfileDelete from './modals';

import useStatuses from './useStatuses';

function useColumns({ onDeleteProfile }) {
  const { translate } = useLanguage();
  const { formatDate } = useDate();
  const statuses = useStatuses();

  const columnTitles = useMemo(() => translate('client.list.table.columns'), [translate]);

  const columns = [
    {
      key: 'name',
      title: columnTitles?.name,
      dataIndex: ['user_client', 'name'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'status',
      title: columnTitles?.status,
      dataIndex: ['user_client', 'status'],
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (value) => {
        const currentStatus = statuses?.[value];
        if (currentStatus) {
          return <Tag color={currentStatus?.color}>{currentStatus?.name}</Tag>;
        }
        return '-';
      },
    },
    {
      key: 'date',
      title: columnTitles?.date,
      dataIndex: ['user_client', 'creation_date'],
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (value) => formatDate(value),
    },
    {
      key: 'actions',
      dataIndex: ['user_client', 'name'],
      width: '60px',
      align: 'right',
      render: (value, record) => {
        const profileId = record?.user_client?.pk;
        return (
          <ProfileDelete
            name={value}
            onAction={() => onDeleteProfile(profileId)}
          />
        );
      },
    },
  ];
  return columns;
}

useColumns.propTypes = {
  onDeleteProfile: PropTypes.func.isRequired,
};

export default useColumns;
