/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchNotifications,
  updateNotification,
  deleteNotification,
  updateListPermission,
} from 'store/notifications';
import { useLanguage } from 'contexts/LanguageContext';
import { useDate } from 'hooks';

import { Card, Loading } from 'components/ui';
import NotificationItem from './NotificationItem';

function Notifications() {
  const { translate } = useLanguage();
  const { formatDate } = useDate();

  const dispatch = useDispatch();

  const {
    pending,
    data,
  } = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const onAcceptPermission = (
    notificationId,
    listId,
    permissionId,
  ) => () => {
    dispatch(updateListPermission({
      notificationId,
      listId,
      permissionId,
    }));
  };

  const onMarkAsRead = (id) => () => {
    dispatch(updateNotification({ id, params: { is_read: true } }));
  };

  const onDelete = (id) => () => {
    dispatch(deleteNotification(id));
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Card className="_notifications">
      <h4>{translate('notifications.header')}</h4>
      {data.length === 0
        ? translate('notifications.notification', 0)
        : (
          <ul className="_notifications__list">
            {data.map(({
              id,
              type,
              content,
              date,
              details,
              is_read: isRead,
            }) => (
              <NotificationItem
                key={id}
                content={content}
                type={type}
                date={formatDate(date)}
                isRead={isRead}
                isPermissionRequired={Boolean(details?.permission_id)}
                onAcceptPermission={onAcceptPermission(
                  id,
                  details?.list_id,
                  details?.permission_id,
                )}
                onMarkAsRead={onMarkAsRead(id)}
                onDelete={onDelete(id)}
              />
            ))}
          </ul>
        )}
    </Card>
  );
}

export default Notifications;
