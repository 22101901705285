import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import {
  Card, CardBody, Input, Button, Alert,
} from 'reactstrap';

import { useAPI } from 'services/api';
import { createClients } from 'store/reducers/lists';

import ImportListTable from 'components/ImportListTable';
import ClientDoubleEntry from 'components/ClientDoubleEntry';

import 'assets/css/create-list.css';

function AddProfile(props) {
  const api = useAPI();

  const [disabled, setDisabled] = useState(true);

  const [toggleModal, setToggleModal] = useState(false);
  const [fixedClients, setFixedClients] = useState([]);
  const [existingClients, setExistingClients] = useState([]);
  const [existingCopy, setExistingCopy] = useState([]);

  const [selectedEntries, setSelectedEntries] = useState([]);

  const [checkClientsLoading, setCheckClientsLoading] = useState([]);

  const { data } = useSelector((state) => state.lists);
  const { loading, status } = useSelector((state) => state.createClient);

  const listName = data.filter((row) => parseInt(props.listId, 10) === row.id)?.[0]?.name;

  const [
    fileInput,
    setFileInput,
  ] = React.useState();
  const [
    clients,
    setClients,
  ] = React.useState([]);
  const [
    dataGrid,
    setDataGrid,
  ] = React.useState('');

  const [
    selectedColumn,
    setSelectedColumn,
  ] = React.useState([]);

  const dispatch = useDispatch();

  const checkClientNames = (textClients) => {
    try {
      if (textClients.length > 0) {
        const filteredColumn = textClients.filter((item) => item.trim().length >= 3);
        if (filteredColumn.length === textClients.length) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fileSelectedHandler = (e1) => {
    const typeArray = ['xls', 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    try {
      const { files } = e1.target;
      const f = files[0];

      if (typeArray.indexOf(f.type.toLowerCase()) > -1) {
        const reader = new FileReader();
        reader.onload = (e2) => {
          const data2 = e2.target.result;
          const readedData = XLSX.read(
            data2,
            { type: 'binary' },
          );
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];

          /* Convert excel file to array */
          const dataParse = XLSX.utils.sheet_to_row_object_array(
            ws,
            {
              header: 1,
            },
          );

          setClients(
            dataParse.map((row) => row[0]),
          );
          checkClientNames(dataParse.map((row) => row[0]));

          setDataGrid(dataParse);
        };
        reader.readAsBinaryString(f);
      } else {
        console.log('file type is not a excel file');
      }
    } catch (e) {
      setDataGrid('');
      setSelectedColumn([]);
      setFileInput({});
      // console.log('error');
    }
  };
  /* eslint no-param-reassign: ["error", { "props": false }] */
  const onChecked = (columnName) => {
    for (
      let i = 0;
      i < dataGrid[0].length;
      i += 1
    ) {
      if (dataGrid[0][i] === columnName) {
        const temp = [];
        dataGrid.map(
          (row) => {
            if (row[i] !== undefined) temp.push(row[i]);
            return null;
          },
        );

        [...document.querySelectorAll('.cell')].map((cell) => {
          cell.style.backgroundColor = 'white';
          return '';
        });

        [...document
          .querySelectorAll(`.cell${i + 1}`)]
          .map((cell) => {
            cell.style.backgroundColor = 'rgba(196, 196, 196, 0.27)';
            return '';
          });
        temp.shift();
        setSelectedColumn(temp);
        checkClientNames(temp);
      }
    }
  };

  const handleCreateClients = (exCState, allClients = []) => {
    let filtered = [];
    if (exCState) {
      filtered = fixedClients.filter((f) => (selectedEntries
        .findIndex((e) => e.name === f) === -1
        && existingCopy.findIndex((e) => e.name === f) === -1));
    } else {
      filtered = allClients;
    }
    const c = filtered.map((client) => ({ name: client }));
    const entries = selectedEntries.map((entry) => {
      if (entry?.value !== null && entry?.value !== undefined && entry.value === 'new') {
        return { name: entry.name };
      }

      return { id: entry.id };
    });
    dispatch(createClients(props.listId, [...c, ...entries]));
    setTimeout(() => {
      setToggleModal(false);
      props.setToggleModal(false);
    }, 2500);
  };

  const handleCheckClients = async (c) => {
    const changedC = c.map((e) => ({ name: e }));
    setCheckClientsLoading(true);
    setDisabled(true);
    const { data: { existing_clients: exC } } = await api.checkClients(changedC);
    setCheckClientsLoading(false);
    setDisabled(false);
    const temp = exC.map((ch, index) => ({ id: `m${index}`, ...ch }));
    setExistingClients(temp);
    setExistingCopy(temp);
    if (exC.length === 0) {
      handleCreateClients(false, c);
    } else {
      setToggleModal(true);
    }
  };

  const handleSelected = (id, clientName, textValue, masterId) => {
    const isInclude = selectedEntries.findIndex((e) => e.masterId === masterId);
    if (textValue === 'new') {
      if (isInclude === -1) {
        setSelectedEntries((prevState) => [...prevState, {
          masterId, id, name: clientName, value: 'new',
        }]);
      } else {
        setSelectedEntries((prevState) => [...prevState.filter((p) => p
          .masterId !== masterId), {
          masterId, id, name: clientName, value: 'new',
        }]);
      }
    } else if (isInclude === -1) {
      setSelectedEntries((prevState) => [...prevState, { masterId, id, name: clientName }]);
    } else if (isInclude > -1) {
      setSelectedEntries((prevState) => [...prevState.filter((p) => p
        .masterId !== masterId), { masterId, id, name: clientName }]);
    }
  };
  const deleteEntry = (cName) => {
    setSelectedEntries((prevState) => [...prevState.filter((p) => p.name !== cName)]);
    setExistingClients(existingClients.filter((e) => e.name !== cName));
  };

  return (
    <div>
      <ClientDoubleEntry
        loading={loading ?? checkClientsLoading}
        status={status}
        disabled={disabled}
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        onConfirm={handleCreateClients}
        doubleClients={existingClients}
        handleSelected={handleSelected}
        deleteEntry={deleteEntry}
        selectedEntries={selectedEntries}
      />
      <Card>
        <CardBody>
          {/* List name */}
          <div className="form-group row">
            <label htmlFor="list-name" className="col-md-2 col-form-label">
              List Name
            </label>
            <div className="col-md-10">
              <p style={{ marginTop: '7px' }}>{listName}</p>
            </div>
          </div>

          {/* Add Clients */}
          <div className="form-group row" style={{ marginTop: '40px' }}>
            <label htmlFor="fileInput2" className="col-md-2 col-form-label">
              Add Profile(s)
            </label>
            <div className="col-md-10">
              <input
                required
                accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                id="fileInput"
                type="file"
                style={{ display: 'none' }}
                ref={(fileInputRef) => setFileInput(fileInputRef)}
                onChange={(e) => {
                  fileSelectedHandler(e);
                  checkClientNames([]);
                }}
              />

              {/* Import from excel */}
              <Button
                color="primary"
                className="btn waves-effect waves-light"
                style={{
                  width: '250px',
                  marginBottom: '27px',
                }}
                onClick={() => fileInput.click()}
              >
                Import from excel
              </Button>
              {dataGrid.length > 0 ? (
                <>
                  <Button
                    color="link"
                    className="btn btn-link waves-effect"
                    style={{
                      marginBottom: '27px',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      setDataGrid([]);
                      setFileInput({});
                      setClients([]);
                      setSelectedColumn([]);
                      document.getElementById('fileInput').value = '';
                    }}
                  >
                    Clear
                  </Button>
                  <div>
                    <Alert color="primary">
                      Please select the column that you want to extract for screening.
                    </Alert>
                  </div>
                </>
              ) : (
                <Input
                  autoFocus
                  type="textarea"
                  className="form-control textArea"
                  placeholder="Paste your profile list or import from excel..."
                  rows="6"
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}
                  value={clients.join('\n')}
                  onChange={(e) => {
                    const splitClients = e.target.value
                      .split('\n')
                      .map((client) => client.replace(/\s\s+/g, ' ').trimStart());
                    setClients(splitClients);
                    checkClientNames(splitClients);
                  }}
                  onBlur={(e) => {
                    const splitClients = e.target.value
                      .split('\n')
                      .map((client) => client.replace(/\s\s+/g, ' ').trim());
                    setClients(splitClients);
                    checkClientNames(splitClients);
                  }}
                />
              )}

              {dataGrid.length > 0 && (
                <ImportListTable
                  data={dataGrid}
                  onChecked={onChecked}
                  selectedRowsCount={selectedColumn.length}
                />
              )}

              {/* Alert for client length */}
              {disabled && (
              <div className="mt-3">
                <Alert color="primary">
                  Profile names should have at least 3 letters.
                </Alert>
              </div>
              )}

              {/* Save list */}
              <Button
                disabled={disabled}
                color={(!loading && !checkClientsLoading) && status === 'ok' ? 'success' : 'primary'}
                className="btn waves-effect waves-light"
                style={{
                  width: '105px',
                  marginTop: '25px',
                }}
                onClick={() => {
                  const c = clients;
                  const s = selectedColumn;
                  if (s.length > 0) {
                    setFixedClients(() => ([...s]));
                    handleCheckClients(s);
                  } else {
                    setFixedClients(() => ([...c]));
                    handleCheckClients(c);
                  }
                }}
              >
                {status === 'ok' && (
                  <>
                    <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                    Saved
                  </>
                )}
                {(loading === true || checkClientsLoading === true) && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                    Saving...
                  </>
                )}
                {(loading !== true && checkClientsLoading !== true) && status !== 'ok' && 'Save Profile'}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

AddProfile.propTypes = {
  listId: PropTypes.number.isRequired,
  setToggleModal: PropTypes.func.isRequired,
};

export default AddProfile;
