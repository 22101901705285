import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';
import { FETCH_USER, UPDATE_USER } from 'constants/actionTypes/users';

export const fetchUser = createAsyncThunk(
  FETCH_USER,
  async (id, { rejectWithValue }) => {
    const [res, error] = await request(api.getUser(id));

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { user } } = res;
    return user;
  },
);

export const updateUser = createAsyncThunk(
  UPDATE_USER,
  async (payload, { rejectWithValue }) => {
    const { userId, ..._payload } = payload;

    const [res, error] = await request(api.updateUser(userId, _payload));

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { data } } = res;

    if (data) {
      return data;
    }
    const user = { ..._payload };
    if (user?.password) {
      delete _payload.password;
    }
    return user;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
  currentUser: {},
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.pending = true;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.currentUser = action.payload;
    },
    [fetchUser.rejected]: (state) => {
      state.pending = false;
    },
    [updateUser.pending]: (state) => {
      state.pending = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
    },
    [updateUser.rejected]: (state) => {
      state.pending = false;
    },
  },
});

export default usersSlice.reducer;
