import React from 'react';

import {
  assetFreezeAndProhibition,
  aviationAndJetFuel,
  banOnAircraftsVessels,
  banOnInvestments,
  // banOnCommitmentSupportActivities,
  banOnExportLuxury,
  // banOnProvisionBanknotesCoins,
  // banOnServicesInfrastructure,
  // banOnServicesTourism,
  // controlOnInvestment,
  // controlOnServices,
  // embargoOnArmsExport,
  // embargoOnArmsImport,
  embargoOnDualUseGoods,
  // embargoOnHeavyWaterActivities,
  embargoOnRawMetals,
  flightsAirportsAircraft,
  inspection,
  // monitoringActivities,
  otherRestrictions,
  petroleumProducts,
  // preventionCircumvention,
  prohibitionOnArmsProcurement,
  // prohibitionOnConstructNewPowerPlants,
  prohibitionOnExportPreciousMetals,
  prohibitionOnExportTelecomEquipment,
  prohibitionOnSupplyTransferExportCrudeOil,
  // prohibitionOnTransferFundsToEntities,
  prohibitionToSatisfyClaim,
  // restrictionOnCargoFlights,
  restrictionOnCertainGoods,
  restrictionOnFinancialMeasures,
  restrictionsOnAdmission,
  restrictionsOnCulturalGoods,
  restrictionsOnEquipmentInternalRepression,
  // restrictionsOnInsurance,
  restrictionsOnServices,
  trainingAndEducation,
  vigilance,
} from './icons';

export const getIcon = (typeId, width = 30, height = 30) => {
  switch (typeId) {
    case 1:
      return (
        <>
          <img src={prohibitionOnArmsProcurement} alt="measure" width={width} height={height} />
          {/* <img src={embargoOnArmsExport} alt="measure" width={width} height={height} />
          <img src={embargoOnArmsImport} alt="measure" width={width} height={height} /> */}
        </>
      );
    case 2:
      return <img src={prohibitionOnArmsProcurement} alt="measure" width={width} height={height} />;
    case 3:
      return <img src={prohibitionOnArmsProcurement} alt="measure" width={width} height={height} />;
    case 4:
      return <img src={prohibitionOnArmsProcurement} alt="measure" width={width} height={height} />;
    case 5:
      return <img src={assetFreezeAndProhibition} alt="measure" width={width} height={height} />;
    case 6:
      return <img src={embargoOnDualUseGoods} alt="measure" width={width} height={height} />;
    case 7:
      return <img src={embargoOnDualUseGoods} alt="measure" width={width} height={height} />;
    case 8:
      return <img src={restrictionOnFinancialMeasures} alt="measure" width={width} height={height} />;
    case 9:
      return <img src={flightsAirportsAircraft} alt="measure" width={width} height={height} />;
    case 10:
      return <img src={inspection} alt="measure" width={width} height={height} />;
    case 11:
      return <img src={banOnInvestments} alt="measure" width={width} height={height} />;
    case 12:
      return <img src={otherRestrictions} alt="measure" width={width} height={height} />;
    case 13:
      return <img src={banOnAircraftsVessels} alt="measure" width={width} height={height} />;
    case 14:
      return <img src={prohibitionToSatisfyClaim} alt="measure" width={width} height={height} />;
    case 15:
      return <img src={restrictionsOnAdmission} alt="measure" width={width} height={height} />;
    case 16:
      return <img src={restrictionsOnEquipmentInternalRepression} alt="measure" width={width} height={height} />;
    case 17:
      return <img src={restrictionOnCertainGoods} alt="measure" width={width} height={height} />;
    case 18:
      return <img src={aviationAndJetFuel} alt="measure" width={width} height={height} />;
    case 19:
      return <img src={prohibitionOnSupplyTransferExportCrudeOil} alt="measure" width={width} height={height} />;
    case 20:
      return <img src={restrictionsOnCulturalGoods} alt="measure" width={width} height={height} />;
    case 21:
      return <img src={embargoOnRawMetals} alt="measure" width={width} height={height} />;
    case 22:
      return <img src={restrictionOnCertainGoods} alt="measure" width={width} height={height} />;
    case 23:
      return <img src={prohibitionOnExportPreciousMetals} alt="measure" width={width} height={height} />;
    case 24:
      return <img src={banOnExportLuxury} alt="measure" width={width} height={height} />;
    case 25:
      return <img src={restrictionsOnServices} alt="measure" width={width} height={height} />;
    case 26:
      return <img src={restrictionOnCertainGoods} alt="measure" width={width} height={height} />;
    case 27:
      return <img src={petroleumProducts} alt="measure" width={width} height={height} />;
    case 28:
      return <img src={petroleumProducts} alt="measure" width={width} height={height} />;
    case 29:
      return <img src={restrictionOnCertainGoods} alt="measure" width={width} height={height} />;
    case 30:
      return <img src={prohibitionOnExportTelecomEquipment} alt="measure" width={width} height={height} />;
    case 31:
      return <img src={banOnAircraftsVessels} alt="measure" width={width} height={height} />;
    case 32:
      return <img src={restrictionOnCertainGoods} alt="measure" width={width} height={height} />;
    case 33:
      return <img src={embargoOnRawMetals} alt="measure" width={width} height={height} />;
    case 34:
      return <img src={trainingAndEducation} alt="measure" width={width} height={height} />;
    case 35:
      return <img src={restrictionsOnServices} alt="measure" width={width} height={height} />;
    case 36:
      return <img src={trainingAndEducation} alt="measure" width={width} height={height} />;
    case 37:
      return <img src={vigilance} alt="measure" width={width} height={height} />;
    default:
      return '';
  }
};

export const getIcons = (measures = []) => measures.map((m) => getIcon(m));
