import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: update function with new reducer
import { loadLists } from 'store/reducers/lists';

import { Loading } from 'components/ui';
// TODO: delete component after new design
import List from './List';

// TODO: update design
function ScreeningList() {
  const dispatch = useDispatch();

  const {
    pending,
    data,
  } = useSelector((state) => state.lists);

  useEffect(() => {
    dispatch(loadLists());
  }, [dispatch]);

  if (pending) {
    return <Loading />;
  }

  return (<List listData={data} />);
}

export default ScreeningList;
