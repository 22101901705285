import React from 'react';
import ReactApexChart from 'react-apexcharts';

import {
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

const series = [44, 55, 67, 83];
const options = {
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: '22px',
        },
        value: {
          fontSize: '16px',
        },
        total: {
          show: true,
          label: 'Total',
          formatter() {
            // By default this function returns the average of all series.
            // The below is just an example to show the use of custom formatter function
            return 249;
          },
        },
      },
    },
  },

  labels: ['Ultra High', 'High', 'Moderate', 'Low'],
  colors: ['#eb1d1e', '#ff3938', '#ffc71b', '#3fd442'],
};

function RadialChart() {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{' '}</CardTitle>
        <ReactApexChart options={options} series={series} type="radialBar" height="380" />
      </CardBody>
    </Card>
  );
}

export default RadialChart;
