import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

function buttonTexts(id, saving, completed) {
  let save = 'Save changes';
  if (id === 'new') {
    save = 'Invite';
    if (saving) {
      save = (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
          Sending...
        </>
      );
    } else if (completed) {
      save = (
        <>
          <i className="bx bx-check-double font-size-16 align-middle mr-2" />
          Sent
        </>
      );
    }
  } else if (id !== 'new') {
    save = 'Save changes';
    if (saving) {
      save = (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
          Saving...
        </>
      );
    }
    if (!saving && completed) {
      save = (
        <>
          <i className="bx bx-check-double font-size-16 align-middle mr-2" />
          Saved
        </>
      );
    }
  }
  return {
    save,
    cancel: id === 'new' ? 'Cancel' : 'Delete user',
  };
}
const buttonColor = (id, saving, completed) => {
  let color = 'secondary';
  if (id === 'new') {
    color = 'primary';
  } else {
    color = 'secondary';
  }
  if (!saving && completed) {
    color = 'success';
  }
  return color;
};
function UserItemFooter({
  id,
  onSaveClick,
  onDeleteClick,
  disabled,
  setIsAddOpen,
}) {
  const saving = useSelector((state) => state.users.saving);
  const completed = useSelector((state) => state.users.completed);
  const { save, cancel } = useMemo(() => buttonTexts(id, saving, completed),
    [id, saving, completed]);
  const btnColor = useMemo(() => buttonColor(id, saving, completed),
    [id, saving, completed]);

  return (
    <div className="mt-4">
      <Button
        data-id={id}
        size="sm"
        color={btnColor}
        disabled={saving || disabled}
        onClick={(e) => {
          setIsAddOpen(false);
          onSaveClick(e);
        }}
      >
        {save}
      </Button>
      <Button
        data-id={id}
        className="ml-3"
        size="sm"
        color={id === 'new' ? 'secondary' : 'danger'}
        onClick={(e) => {
          setIsAddOpen(false);
          onDeleteClick(e);
        }}
      >
        {cancel}
      </Button>
    </div>
  );
}

UserItemFooter.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  setIsAddOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default UserItemFooter;
