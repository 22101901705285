import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';

export const fetchSearchHistory = createAsyncThunk(
  'searchHistory/fetch',
  async (payload, { rejectWithValue }) => {
    const { userId, params } = payload;
    const [res, error] = await request(api.getSearchHistory(userId, params));

    if (error) {
      return rejectWithValue(error);
    }

    const { data: { searches } } = res;
    return searches;
  },
);

export const deleteSearchHistory = createAsyncThunk(
  'searchHistory/delete',
  async (payload, { rejectWithValue }) => {
    const { userId, ids } = payload;
    const [,, error] = await request(api.deleteSearchHistory(userId, ids));

    if (error) {
      return rejectWithValue(error);
    }
    return ids;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
};

const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSearchHistory.pending]: (state) => {
      state.pending = true;
      state.error = null;
      state.data = [];
    },
    [fetchSearchHistory.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
    [fetchSearchHistory.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [deleteSearchHistory.pending]: (state) => {
      state.error = null;
      state.data = [];
    },
    [deleteSearchHistory.fulfilled]: (state, action) => {
      state.data = state.data.filter((history) => !(action.payload
        .some(({ id }) => id === history.search_id)));
    },
    [deleteSearchHistory.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default searchHistorySlice.reducer;

// selector
export const latestSearchHistoryQueries = createSelector(
  (state) => state.searchHistory.data,
  (data) => {
    const uniqueQueries = new Set(data
      .filter((s) => s.search_name.length > 0)
      .map((s) => s.search_name));
    return Array.from(uniqueQueries).slice(0, 15);
  },
);
