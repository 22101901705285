import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import * as classes from './index.module.scss';

function Text({
  type,
  name,
  className,
  placeholder,
  disabled,
  autoComplete,
  size,
  prefix,
  allowClear,
  value,
  onChange,
  onPressEnter,
}) {
  return (
    <Input
      disabled={disabled}
      autoComplete={autoComplete}
      type={type}
      name={name}
      className={[classes.input, className].join(' ')}
      placeholder={placeholder}
      size={size}
      prefix={prefix}
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      onPressEnter={onPressEnter}
    />
  );
}

Text.defaultProps = {
  type: null,
  name: null,
  className: '',
  placeholder: '',
  disabled: false,
  autoComplete: 'off',
  size: 'default',
  prefix: null,
  allowClear: false,
  value: null,
  onChange: () => {},
  onPressEnter: () => {},
};

Text.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  size: PropTypes.string,
  prefix: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  allowClear: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
};

export default Text;
