import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { useDate } from 'hooks';
import { useLanguage } from 'contexts/LanguageContext';

function useColumns(columnTitles = {}) {
  const { formatDate } = useDate();
  const { translate } = useLanguage();

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: columnTitles?.name,
      render: (text, record) => {
        if (text && record?.url) {
          return (
            <a
              href={record?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          );
        }
        return '';
      },
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: columnTitles?.description,
    },
    {
      key: 'modified_date',
      dataIndex: 'modified_date',
      title: columnTitles?.modifiedDate,
      width: '160px',
      render: (text) => formatDate(text),
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: columnTitles?.action,
      width: '110px',
      render: (sourceId, record) => {
        const entryCount = record?.entry_count || 0;

        return (
          <Button className="p-0" disabled={!sourceId || entryCount === 0} type="link">
            <Link to={`/dashboard/sources/${sourceId}/logs`}>
              {translate('dashboard.sources.table.row.viewLogs')}
            </Link>
          </Button>
        );
      },
    },
  ];
  return columns;
}

export default useColumns;
