/* eslint-disable no-unused-vars */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from '../../services/api';
import { loadLists } from './lists';
import { loadLists as loadAutomatedLists } from './automated-lists';

const newUser = {
  id: 'new',
  name: '',
  email: '',
  permissions: {
    isAdmin: false,
    all: 'edit',
    lists: [],
  },
};

const initialState = {
  initialized: false,
  users: [],
  userCount: 0,
  error: null,
  saving: false,
  completed: false,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersSuccess(state, action) {
      state.initialized = true;
      state.users = action.payload;
      state.userCount = action.payload.length;
      state.error = null;
      state.saving = false;
    },
    fetchUsersFailure(state, action) {
      state.initialized = true;
      state.users = [];
      state.error = action.payload;
      state.saving = false;
    },
    createNewUser(state) {
      state.users.unshift(newUser);
    },
    createNewUserSuccess(state) {
      state.users = state.users.filter((u) => u.id !== 'new');
      state.error = '';
    },
    createNewUserFail(state, action) {
      state.error = action.payload;
    },
    createNewUserReset(state) {
      state.error = '';
    },
    cancelNewUser(state) {
      state.users = state.users.filter((u) => u.id !== 'new');
      state.error = '';
    },
    deleteUserSuccess(state, action) {
      state.users = state.users.filter((u) => u.id !== action.payload);
    },
    deleteInvitationSuccess(state, action) {
      state.users = state.users.filter((u) => u.id !== action.payload);
    },
    setSaving(state, action) {
      state.saving = action.payload;
    },
    setCompleted(state, action) {
      state.completed = action.payload;
    },
  },
});

export const {
  fetchUsersSuccess,
  fetchUsersFailure,
  createNewUser,
  cancelNewUser,
  createNewUserSuccess,
  createNewUserFail,
  createNewUserReset,
  deleteUserSuccess,
  deleteInvitationSuccess,
  setSaving,
  setCompleted,
} = slice.actions;

// selectors
export const usersWithPermissions = createSelector(
  (state) => state.lists.data,
  (state) => state.users.users,
  (state) => state.automatedLists.data,
  (lists, users, automatedLists) => users.map((user) => {
    const userLists = (user.permissions?.lists || []);
    // eslint-disable-next-line camelcase
    const userAutoLists = (user.permissions?.auto_lists || []);
    let allPerm = false;
    // eslint-disable-next-line
    if (user?.options?.write_all_lists) {
      allPerm = 'edit';
      // eslint-disable-next-line
    } else if (user?.options?.read_all_lists) {
      allPerm = 'view';
    }
    const fixedAutoLists = automatedLists.map((l) => ({ ...l, url: '' }));
    return {
      ...user,
      permissions: {
        ...user.permissions,
        isAdmin: false,
        all: allPerm,
        lists: [...lists, ...fixedAutoLists].map((list) => {
          let permission = null;
          // eslint-disable-next-line camelcase
          let found = userLists.find(({ list_id: id }) => id === list.id);

          if (list?.url !== undefined) {
            found = userAutoLists.find(({ list_id: id }) => id === list.id);
          }

          if (found) {
            if (found.can_read) {
              permission = 'view';
            }
            if (found.can_write) {
              permission = 'edit';
            }
          }

          if (list?.url !== undefined) {
            return {
              id: list.id,
              name: list.name,
              permission,
              isAutomated: true,
            };
          }
          return {
            id: list.id,
            name: list.name,
            permission,
            isAutomated: false,
          };
        }),
      },
    };
  }),
);

export const fetchUsers = () => async (dispatch, getState) => {
  try {
    const { lists, automatedLists } = getState();
    let initialized = false;
    if (!lists.initialized) {
      dispatch(loadLists((success) => {
        if (success) {
          dispatch(fetchUsers());
        } else {
          dispatch(fetchUsersFailure('Unable to fetch lists.'));
        }
      }));
      initialized = true;
    }
    if (!automatedLists.initialized) {
      dispatch(loadAutomatedLists((success) => {
        if (success) {
          dispatch(fetchUsers());
        } else {
          dispatch(fetchUsersFailure('Unable to fetch lists.'));
        }
      }));
      initialized = true;
    }

    if (initialized) {
      return;
    }
    const { data: { users } } = await api.fetchUsers();
    const { data: { invites } } = await api.fetchInvites();

    const allInvites = invites.map((inv) => ({ ...inv, pending: true }));
    dispatch(fetchUsersSuccess([...allInvites, ...users]));
  } catch (error) {
    dispatch(fetchUsersFailure(error.message));
  }
};

export const editPermissions = (id, permissions) => async (dispatch) => {
  dispatch(setSaving(true));

  try {
    let request = '';
    if (permissions.all === 'edit' || permissions.all === 'view') {
      const normalLists = permissions.lists
        .filter((p) => p.isAutomated === false);
      const autoLists = permissions.lists
        .filter((p) => p.isAutomated === true);
      request = {
        user_permissions: {
          read_all_lists: ['edit', 'view'].includes(permissions.all),
          write_all_lists: permissions.all === 'edit',
          delete_search_history: permissions.all === 'edit',
        },
        list_permissions: normalLists.map((list) => ({
          list_id: list.id,
          can_read: false,
          can_write: false,
        })),
        auto_list_permissions: autoLists.map((list) => ({
          list_id: list.id,
          can_read: false,
          can_write: false,
        })),
      };
    } else {
      request = {
        user_permissions: {
          read_all_lists: false,
          write_all_lists: false,
          delete_search_history: false,
        },
        list_permissions: permissions.lists
          .filter((p) => p.isAutomated === false).map((list) => ({
            list_id: list.id,
            can_read: ['edit', 'view'].includes(list.permission),
            can_write: list.permission === 'edit',
          })),
        auto_list_permissions: permissions.lists
          .filter((p) => p.isAutomated === true).map((list) => ({
            list_id: list.id,
            can_read: ['edit', 'view'].includes(list.permission),
            can_write: list.permission === 'edit',
          })),
      };
    }

    await api.editPermissions(id, request);
  } catch (error) {
    console.log('error: ', error);
  } finally {
    setTimeout(() => {
      dispatch(setSaving(false));
      dispatch(setCompleted(true));
    }, 300);
    setTimeout(() => { dispatch(setCompleted(false)); }, 1000);
  }
};

export const inviteUser = (email, permissions) => async (dispatch) => {
  dispatch(setSaving(true));
  try {
    let userPermissions = {};
    let listPermissions = [];
    if (permissions.all === false) {
      listPermissions = permissions.lists.map((list) => ({
        list_id: list.id,
        permissions: {
          can_read: ['edit', 'view'].includes(list.permission),
          can_write: list.permission === 'edit',
        },
      }));
    } else {
      userPermissions = {
        read_all_lists: ['edit', 'view'].includes(permissions.all),
        write_all_lists: ['edit'].includes(permissions.all),
        delete_search_history: ['edit'].includes(permissions.all),
      };
    }
    await api.inviteUser(email, listPermissions, userPermissions);
    dispatch(createNewUserSuccess());
  } catch (error) {
    dispatch(createNewUserFail(error.response.data.message));
    setTimeout(() => {
      dispatch(createNewUserReset());
    }, 2500);
  } finally {
    setTimeout(() => {
      dispatch(setSaving(false));
      dispatch(setCompleted(true));
    }, 300);
    setTimeout(() => { dispatch(setCompleted(false)); }, 1000);
  }
};

export const deleteUser = (id, cb) => async (dispatch) => {
  dispatch(setSaving(true));
  try {
    await api.deleteUser(id);
    dispatch(deleteUserSuccess(id));
    if (cb) cb(true);
  } catch (error) {
    if (cb) cb(false);
  } finally {
    dispatch(setSaving(false));
  }
};

export const deleteInvitation = (userId, invId) => async (dispatch) => {
  dispatch(setSaving(true));
  try {
    await api.deleteInvitation(userId, invId);
    dispatch(deleteInvitationSuccess(invId));
  } finally {
    dispatch(setSaving(false));
  }
};

export default slice.reducer;
