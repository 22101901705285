import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

// load lists
const initialState = {
  initialized: false,
  error: false,
  loading: false,
  data: [],
  listCount: 0,
  clientCount: 0,
};

const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    listStart(state) {
      state.error = null;
      state.loading = true;
      state.initialized = false;
    },
    listSuccess(state, action) {
      state.data = action.payload.map((l) => ({ ...l, details: false }));
      state.listCount = action.payload.map((l) => ({ ...l, details: false })).length;
      state.error = null;
      state.loading = false;
      state.initialized = true;
      let temp = 0;
      action.payload.map((l) => {
        temp += l.clients_count;
        return '';
      });
      state.clientCount = temp;
    },
    listFail(state) {
      state.error = true;
      state.loading = false;
      state.initialized = true;
    },
    listCreateSuccess(state, action) {
      state.data.push(action.payload.list);
      state.listCount = state.data.length;
      state.clientCount += action.payload.clientCount;
    },
    deleteListStart(state) {
      state.loading = true;
    },
    deleteListSuccess(state, action) {
      const filteredList = state.data.filter(
        (list) => list.id !== parseInt(action.payload, 10),
      );
      state.data = filteredList;
      state.listCount = filteredList.length;
      state.loading = false;
    },
    deleteClientSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.listId) {
          const temp = row.clients.filter(
            (client) => client.id !== action.payload.clientId,
          );
          return {
            ...row,
            clients: temp,
            clients_count: row.clients_count - 1,
          };
        }
        return row;
      });
      state.clientCount -= 1;
    },

    deleteSomeClientsStart(state) {
      state.error = null;
      state.loading = true;
    },

    deleteSomeClientsSuccess(state, action) {
      state.data = state.data.map((row) => {
        const temp = row.clients.filter((r) => action.payload.clients.some((z) => z !== r.id));
        if (row.id === action.payload.listId) {
          return {
            ...row,
            clients: temp,
            clients_count: row.clients_count - action.payload.clients.length,
          };
        }
        return row;
      });
      state.loading = false;
      state.clientCount -= action.payload.clients.length;
    },

    editListStart(state) {
      state.loading = true;
      state.status = '';
    },
    editListSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.id) {
          return {
            ...row,
            name: action.payload.name,
            sensivity: action.payload.sensivity,
            options: action.payload.options,
            searchOptions: action.payload.searchOptions,
          };
        }
        return row;
      });
      state.loading = false;
      state.status = 'ok';
    },
    editListFail(state) {
      state.loading = false;
      state.status = 'error';
    },
    editListFinished(state) {
      state.loading = false;
      state.status = '';
    },

    getListItemSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.data.id) {
          return {
            ...action.payload.data,
            details: true,
          };
        }
        return row;
      });
    },

    editClientStart(state) {
      state.loading = true;
      state.status = '';
    },
    editClientSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
    },
    editClientFinished(state) {
      state.loading = false;
      state.status = '';
    },
    changeEntryCount(state, action) {
      state.clientCount += action.payload;
    },
  },
});

export const {
  listStart,
  listSuccess,
  listFail,
  listCreateSuccess,
  deleteListStart,
  deleteListSuccess,
  deleteClientSuccess,
  deleteSomeClientsStart,
  deleteSomeClientsSuccess,
  editListStart,
  editListSuccess,
  editListFail,
  editListFinished,
  editClientStart,
  editClientSuccess,
  editClientFinished,
  getListItemSuccess,
  changeEntryCount,
} = listsSlice.actions;

export const listReducer = listsSlice.reducer;

export const loadLists = (cb = null) => async (dispatch) => {
  dispatch(listStart());
  try {
    const { data: { lists } } = await api.getLists();
    dispatch(listSuccess(lists));
    if (cb) {
      cb(true);
    }
  } catch (error) {
    dispatch(listFail(true));
    if (cb) {
      cb(false);
    }
  }
};

export const loadListItem = (id, history) => async (dispatch) => {
  try {
    const { data } = await api.getList(id);
    delete data.status;
    dispatch(getListItemSuccess({ data, id }));
  } catch (error) {
    if (history) {
      history.push('/lists');
    }
  }
};

// TODO:: handle errors
export const deleteList = (id) => async (dispatch) => {
  dispatch(deleteListStart());
  try {
    await api.deleteList(id);
    dispatch(deleteListSuccess(id));
  } catch (error) {
    console.log('error: ', error);
  }
};
// TODO:: handle errors
export const deleteClient = (listId, clientId) => async (dispatch) => {
  try {
    await api.deleteClient(listId, clientId);
    dispatch(deleteClientSuccess({ listId, clientId }));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const deleteSomeClients = (listId, clients) => async (dispatch) => {
  dispatch(deleteSomeClientsStart());
  try {
    await api.deleteSomeClients(listId, clients);
    dispatch(deleteSomeClientsSuccess({ listId, clients: [...clients] }));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const editList = (id, name, sensivity, score, options,
  searchOptions) => async (dispatch) => {
  dispatch(editListStart());
  try {
    await api.editList(id, name, sensivity, score, options, searchOptions);
    dispatch(
      editListSuccess({
        id,
        name,
        sensivity,
        score,
        options,
        searchOptions,
      }),
    );
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  } catch (error) {
    dispatch(editListFail());
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  }
};

export const editClient = (listId, id, data) => async (dispatch) => {
  dispatch(editClientStart());
  try {
    const edited = await api.editClient(listId, id, data);
    dispatch(editClientSuccess({ status: 'ok', client: edited.data.client }));
    setTimeout(() => {
      dispatch(editClientFinished());
    }, 1500);
  } catch (error) {
    console.log('error: ', error);
    dispatch(editClientFinished());
  }
};

// create lists
const createListInitialState = {
  status: '',
  loading: false,
  id: null,
  listName: '',
  clients: [],
};

const createListSlice = createSlice({
  name: 'createList',
  initialState: createListInitialState,
  reducers: {
    createStart(state) {
      state.status = '';
      state.loading = true;
    },
    createSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
      state.id = action.payload.id;
    },
    createFail(state) {
      state.status = 'error';
      state.loading = false;
    },
    createFinished(state) {
      state.status = '';
      state.loading = false;
      state.id = null;
    },
  },
});

// actions
export const {
  createStart,
  createSuccess,
  createFail,
  createFinished,
} = createListSlice.actions;

export const createList = (listName, clients,
  score, options, searchOptions, history, setProgress = () => {}) => async (dispatch) => {
  try {
    dispatch(createStart());
    const res = await api.createList({
      name: listName, sensivity: 2, score, options, search_options: searchOptions,
    });
    const { data: { status, list_id: id } } = res;
    if (status === 'error') {
      dispatch(createFail(status));
    } else if (status === 'ok') {
      setProgress(63);
      const {
        data: { status: createClientStatus },
      } = await api.createClients(id, clients);

      if (createClientStatus === 'error') {
        throw new Error('Unable to add clients to new list.');
      }
      setProgress(70);
      const { data: list } = await api.getList(id);
      setProgress(85);
      dispatch(createSuccess({ status, id }));
      setTimeout(() => {
        dispatch(listCreateSuccess({ list, clientCount: clients.length }));
        dispatch(createFinished());
        setProgress(100);
        if (typeof history === 'function') {
          history();
        } else if (history !== undefined) {
          history.push(`/lists/${id}`);
        }
      }, 1000);
    }
  } catch (error) {
    dispatch(createFail(error.message));
    setTimeout(() => {
      dispatch(createFinished());
    }, 1000);
  }
};

// reducer
export const createListReducer = createListSlice.reducer;

// create clients
const createClientInitialState = {
  status: '',
  loading: false,
  finished: false,
};

const createClientSlice = createSlice({
  name: 'createClient',
  initialState: createClientInitialState,
  reducers: {
    createClientStart(state) {
      state.status = '';
      state.loading = true;
      state.finished = false;
    },
    createClientSuccess(state, action) {
      state.status = action.payload;
      state.loading = false;
      state.finished = true;
    },
    createClientFail(state, action) {
      state.status = action.payload;
      state.loading = false;
      state.finished = true;
    },
    createClientFinished(state) {
      state.status = '';
      state.loading = false;
      state.finished = false;
    },
  },
});

export const {
  createClientStart,
  createClientSuccess,
  createClientFail,
  createClientFinished,
} = createClientSlice.actions;

export const createClients = (id, clients) => async (dispatch) => {
  dispatch(createClientStart());
  try {
    const { data: { status } } = await api.createClients(id, clients);
    if (status === 'error') {
      dispatch(createClientFail(status));
      dispatch(createClientFinished());
    } else if (status === 'ok') {
      dispatch(createClientSuccess(status));
      dispatch(changeEntryCount(clients.length));
      setTimeout(() => {
        dispatch(createClientFinished());
      }, 1500);
    }
  } catch (error) {
    dispatch(createClientFail('error', false));
    dispatch(createClientFinished());
  }
};

export const createClientReducer = createClientSlice.reducer;

// load client
const clientsInitialState = {
  status: '',
  loading: false,
  client: {},
};

const clientSlice = createSlice({
  name: 'client',
  initialState: clientsInitialState,
  reducers: {
    clientLoadStart(state) {
      state.status = '';
      state.loading = true;
    },
    clientLoadSuccess(state, action) {
      state.client = action.payload.client;
      state.status = action.payload.status;
      state.loading = false;
    },
    clientLoadFail(state) {
      state.status = 'error';
      state.loading = false;
    },
  },
});

export const {
  clientLoadStart,
  clientLoadSuccess,
  clientLoadFail,
  clientLoadFinished,
} = clientSlice.actions;

export const loadClient = (listId, clientId) => async (dispatch) => {
  dispatch(clientLoadStart());
  try {
    const { data } = await api.getClient(listId, clientId);
    dispatch(clientLoadSuccess({ client: data.client, status: data.status }));
  } catch (error) {
    dispatch(clientLoadFail());
  }
};

export const loadClientReducer = clientSlice.reducer;
