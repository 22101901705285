import React, {
  useState, useCallback, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';

import { useAPI } from '../../services/api';
import AddToList from './AddToList';
import AdvancedFilters from './AdvancedFilters';
import FilterLabels from './FilterLabels';

function SearchBar(props) {
  const api = useAPI();

  const { onAdvancedSearch, onSearchParamChange, params } = props;
  const loading = useSelector((state) => state.search.loading);
  const [loadHistory, setLoadHistory] = useState('not-loaded');
  const [showHistory, setShowHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [filters, setFilters] = useState([]);
  const [addToListModal, setAddToListModal] = useState(false);
  const [toggleFilterModal, setToggleFilterModal] = useState(false);
  const location = useLocation();
  const q = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const userId = useSelector((state) => state.auth.user.id);
  const searchRef = useRef();

  const handleAdvancedSearch = useCallback((text = '') => {
    setShowHistory(false);
    const invl = setInterval(() => {
      if (loading === false) {
        setTimeout(() => {
          setToggleFilterModal(false);
        }, 100);
        clearInterval(invl);
      }
    }, 700);

    const search = params.query.trim();

    if (search || text || params.idNumber) {
      onAdvancedSearch({
        name: text.trim() !== '' ? text : search,
        id_number: params.idNumber,
        score: parseInt(params.minNameScore, 10),
        birth_date: {
          start: params.birthDate.start ? `01/01/${params.birthDate.start}` : null,
          end: params.birthDate.end ? `31/12/${params.birthDate.end}` : null,
        },
        birth_place: params.birthPlace,
        entity_type: params.type.toString(),
        country: params.country.map((c) => c.label),
        settings: {
          aka: params.aka,
          no_logging: params.noLogging,
          phonetics: params.phonetics,
        },
      });
    }
  }, [
    loading,
    onAdvancedSearch,
    params.aka,
    params.minNameScore,
    params.birthDate.end,
    params.birthDate.start,
    params.birthPlace,
    params.country,
    params.idNumber,
    params.noLogging,
    params.phonetics,
    params.query,
    params.type,
  ]);

  const handleSearch = useCallback(() => {
    handleAdvancedSearch();
  }, [handleAdvancedSearch]);

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  }, [handleSearch]);

  const handleScoreBlur = useCallback((e) => {
    if (parseInt(e.currentTarget.value, 10) < 65) {
      onSearchParamChange({ currentTarget: { name: 'minNameScore', value: 65 } });
    } else if (parseInt(e.currentTarget.value, 10) > 100) {
      onSearchParamChange({ currentTarget: { name: 'minNameScore', value: 100 } });
    }
  }, [onSearchParamChange]);

  const clearAll = useCallback((resetLabel) => {
    onSearchParamChange({ currentTarget: { name: 'query', value: '' } });
    onSearchParamChange({ currentTarget: { name: 'noLogging', value: false } });
    onSearchParamChange({ currentTarget: { name: 'aka', value: !resetLabel } });
    onSearchParamChange({ currentTarget: { name: 'phonetics', value: !resetLabel } });
    onSearchParamChange({ currentTarget: { name: 'country', value: [] } });
    onSearchParamChange({ currentTarget: { name: 'birthDate', value: { start: '', end: '' } } });
    onSearchParamChange({ currentTarget: { name: 'minNameScore', value: 65 } });
    onSearchParamChange({ currentTarget: { name: 'type', value: '' } });
    onSearchParamChange({ currentTarget: { name: 'idNumber', value: '' } });
    onSearchParamChange({ currentTarget: { name: 'birthPlace', value: '' } });
  }, [onSearchParamChange]);

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  const historyItems = useMemo(() => searchHistory
    .sort((a, b) => {
      if (new Date(a.search_date) > new Date(b.search_date)) return -1;
      if (new Date(a.search_date) < new Date(b.search_date)) return 1;
      return 0;
    })
    .slice(0, 5).map((search, i) => (
      <li
        key={`${search.search_id}-${search.search_name}`}
        className="list-group-item d-flex align-items-center py-2 lih"
        style={{
          zIndex: '999',
          borderTop: i === 0 ? '0px solid #CED4DA' : 'none',
          cursor: 'pointer',
        }}
        onClick={() => {
          // eslint-disable-next-line
          if (search?.search_name.length===0 && search?.search_identity_number.length > 0) {
            // eslint-disable-next-line
            onSearchParamChange({ currentTarget: { name: 'idNumber', value: search?.search_identity_number } });
          } else {
            // eslint-disable-next-line
            onSearchParamChange({ currentTarget: { name: 'query', value: search?.search_name } });
          }

          setShowHistory(false);
          // eslint-disable-next-line
          handleAdvancedSearch(search?.search_name);
        }}
      >
        <i
          className="bx bx-history font-size-24"
          style={{
            marginRight: '7px',
            color: '#DADADA',
          }}
        />
        {/* eslint-disable-next-line */}
        {search?.search_name.length>0 ? search?.search_name : search?.search_identity_number}
      </li>
    )), [handleAdvancedSearch, onSearchParamChange, searchHistory]);
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */

  useEffect(() => {
    if (q.get('q')) {
      onSearchParamChange({ currentTarget: { name: 'query', value: q.get('q') } });
      handleAdvancedSearch();
    }
  }, [q]);

  useEffect(() => {
    async function loadSearchHistory() {
      try {
        const { data: { searches = [] } } = await api.getSearchHistory(userId);
        setSearchHistory(searches);
        setLoadHistory('loaded');
      } catch {
        setLoadHistory('failed');
      }
    }

    if (loadHistory === 'not-loaded') {
      setLoadHistory('loading');
      loadSearchHistory();
    }

    const outsideClickListener = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShowHistory(false);
      }
    };

    document.addEventListener('mousedown', outsideClickListener);
    document.addEventListener('touchstart', outsideClickListener);
    return () => {
      document.removeEventListener('mousedown', outsideClickListener);
      document.removeEventListener('touchstart', outsideClickListener);
    };
  }, [handleAdvancedSearch, loadHistory, onSearchParamChange, q, userId]);

  const onFocusBasicSearch = useCallback(() => {
    setShowHistory(loadHistory === 'loaded');
  }, [loadHistory]);

  useEffect(() => {
    const temp = [];

    const setParam = (name, value) => {
      onSearchParamChange({ currentTarget: { name, value } });
    };

    if (params.query.trim().length > 0) {
      temp.push({
        label: 'query',
        value: params.query.trim(),
        setFunction: () => { setParam('query', ''); },
      });
    }

    if (params.type.trim() !== '' && params.type.trim().length > 0) {
      temp.push({
        label: 'type',
        value: params.type,
        setFunction: () => { setParam('type', ''); },
      });
    }
    if (params.idNumber.trim() !== '' && params.idNumber.trim().length > 0) {
      temp.push({
        label: 'idNumber',
        value: params.idNumber,
        setFunction: () => { setParam('idNumber', ''); },
      });
    }
    if (params.birthPlace.trim() !== '' && params.birthPlace.trim().length > 0) {
      temp.push({
        label: 'birthPlace',
        value: params.birthPlace,
        setFunction: () => { setParam('birthPlace', ''); },
      });
    }
    if (params.birthDate.start !== '' && params.birthDate.start.length === 4) {
      if (params.birthDate.end !== '' && params.birthDate.end.length === 4) {
        temp.push({
          label: 'rangeDate',
          value: `${params.birthDate.start} - ${params.birthDate.end}`,
          setFunction: () => { setParam('birthDate', { start: '', end: '' }); },
        });
      } else {
        temp.push({
          label: 'startDate',
          value: params.birthDate.start,
          setFunction: () => { setParam('birthDate', { start: '', end: '' }); },
        });
      }
    }

    if (params?.country?.length > 0) {
      temp.push({
        label: 'countries',
        value: params.country,
        setFunction: () => { setParam('country', []); },
        changeFunction: (val) => { setParam('country', val); },
      });
    }

    if (params?.aka) {
      temp.push({
        label: 'aka',
        value: 'Include AKA',
        setFunction: () => { setParam('aka', false); },
      });
    }
    if (params?.phonetics) {
      temp.push({
        label: 'phonetics',
        value: 'Include phonetics',
        setFunction: () => { setParam('phonetics', false); },
      });
    }

    setFilters(temp);
  }, [
    onSearchParamChange,
    params.aka,
    params.birthDate.end,
    params.birthDate.start,
    params.birthPlace,
    params.country,
    params.idNumber,
    params.phonetics,
    params.query,
    params.type,
  ]);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="pr-0">
              <div className="search-box mr-2" ref={searchRef}>
                <div className="position-relative">
                  <input
                    id="name"
                    name="query"
                    type="search"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    // autoFocus
                    autoComplete="off"
                    onFocus={onFocusBasicSearch}
                    className={showHistory ? 'form-control search-input-focus' : 'form-control'}
                    placeholder="Search..."
                    value={params.query}
                    onChange={onSearchParamChange}
                    onKeyDown={handleKeyDown}
                  />
                  <i className="bx bx-search search-icon" />
                  {showHistory && searchHistory.length > 0 && (
                  <div className="position-absolute history-bar">
                    <ul className="list-group list-group-flush d-flex">
                      <p className="gray-line" />
                      <p className="white-line" />
                      {historyItems}
                    </ul>
                  </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md="auto" className="pr-0">
              <Button
                outline
                color="primary"
                className="btn d-flex align-items-center paddingY m-0 height36px"
                onClick={() => {
                  setToggleFilterModal(true);
                }}
                style={{
                  width: '100%',
                }}
              >
                <p className="p-0 m-0"> Advanced Filters</p>
                <i className="bx bx-filter font-size-24" />
              </Button>
            </Col>

            <Col lg="4" className="pr-0">
              <Button
                color="primary"
                className="font-16 btn-block height36px"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Col>
            <Col
              md="auto"
              style={{
                paddingRight: '20px',
              }}
            >
              <Button
                color="secondary"
                className="py-0 font-size-22"
                onClick={() => {
                  setAddToListModal(true);
                }}
              >
                <i className="mdi mdi-account-plus-outline" />
                {/* <img src={personAddIcon} alt="Add To List" /> */}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="search-bar">
        <div className="col col-lg-4">
          <div className="row">
            <div className="form-group col-lg-9">
              <label htmlFor="minNameScore">Fuzzy logic sensivity</label>
              <input
                id="minNameScore"
                name="minNameScore"
                type="range"
                min="65"
                max="100"
                value={params.minNameScore}
                className="form-control-range"
                onChange={onSearchParamChange}
              />
            </div>
            <div className="form-group col-lg-3">
              <input
                name="minNameScore"
                type="number"
                min="65"
                max="100"
                value={params.minNameScore}
                className="form-control mt-3"
                onChange={onSearchParamChange}
                onBlur={handleScoreBlur}
                style={{ width: '5em' }}
              />
            </div>
          </div>
        </div>
        <Col lg="4">
          <div
            className="custom-control custom-checkbox mt-4 ml-3"
            style={{ lineHeight: '20px' }}
          >
            <input
              name="noLogging"
              id="noLogging"
              type="checkbox"
              className="custom-control-input"
              onChange={onSearchParamChange}
              checked={params.noLogging}
            />
            <label htmlFor="noLogging" className="custom-control-label">No-Log Search</label>
          </div>
        </Col>
      </Row>
      <Row style={{
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '40px',
        paddingTop: '20px',
      }}
      >
        <FilterLabels filters={filters} clearAll={clearAll} />
      </Row>

      {/* Advanced filter modal */}
      <AdvancedFilters
        isOpen={toggleFilterModal}
        onClose={setToggleFilterModal}
        loading={loading}
        params={params}
        onSearchParamChange={onSearchParamChange}
        handleKeyDown={handleKeyDown}
        clearAll={clearAll}
        handleAdvancedSearch={handleAdvancedSearch}
      />

      <Modal
        isOpen={addToListModal}
        toggle={() => setAddToListModal(false)}
        centered
        size="lg"
      >
        <div className="d-flex pb-0 pt-2 pr-3">
          <button
            type="button"
            onClick={() => setAddToListModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginLeft: 'auto', fontSize: '24px' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <AddToList name={params.query} setModal={setAddToListModal} />
        </ModalBody>
      </Modal>
    </>
  );
}

SearchBar.propTypes = {
  onAdvancedSearch: PropTypes.func.isRequired,
  onSearchParamChange: PropTypes.func.isRequired,
  params: PropTypes.shape({
    query: PropTypes.string.isRequired,
    idNumber: PropTypes.string.isRequired,
    birthPlace: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    country: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })).isRequired,
    birthDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }).isRequired,
    aka: PropTypes.bool.isRequired,
    phonetics: PropTypes.bool.isRequired,
    noLogging: PropTypes.bool.isRequired,
    minNameScore: PropTypes.number.isRequired,
  }).isRequired,
};

export default SearchBar;
