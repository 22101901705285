import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const fixedDate = (date) => {
  try {
    const givenDate = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
      .formatToParts(givenDate);
    return `${day} ${month}, ${year}`;
  } catch (error) {
    return '';
  }
};

const divideLetters = (text) => {
  let splitted = text.split(/(?=[A-Z])/);
  if (text.includes('_')) {
    splitted = text.split('_');
  }
  const firstWordUpper = splitted[0][0].toUpperCase() + splitted[0].substring(1);
  let temp = '';
  if (splitted.length > 1) {
    for (let i = 1; i < splitted.length; i += 1) {
      temp += ` ${splitted[i]}`;
    }
  }
  return firstWordUpper + temp;
};

function SourceInfo({ info, setSource, type }) {
  if (type === 'sanction') {
    return (
      info?.source !== undefined && (
      <Col className="leftCol p-0 m-0">
        <div className={info?.source !== undefined ? 'p-3 sourceInfo' : 'p-3'}>
          <div className="d-flex pb-0 pt-2 pr-3">
            <button
              type="button"
              onClick={() => setSource([])}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginLeft: 'auto', fontSize: '24px' }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <h5 className="p-0 mb-4">{info?.source?.name}</h5>
          {Object.keys(info).map((key) => {
            if (key === 'name') {
              return (
                <div className="form-group row">
                  <p className="col-md-4 font-weight-bold">
                    {divideLetters(key)}
                  </p>
                  <p className="col-md-8">{info[key]}</p>
                </div>
              );
            }
            if (key === 'score') {
              return (
                <div className="form-group row">
                  <p className="col-md-4 font-weight-bold">
                    {divideLetters(key)}
                  </p>
                  <p className="col-md-8">{parseInt(info[key], 10)}</p>
                </div>
              );
            }
            return null;
          })}
          {Object.keys(info).map((key) => {
            const removedKeys = ['uuid', 'id', 'version', 'name_phonetic', 'Last_update_date', 'listed_date', 'source', 'dateofbirthbearch', 'fullname', 'score', 'original_id',
              'phoneticsscore', 'jaroscore', 'fullnamecount'];
            if (removedKeys.includes(key.toLowerCase())) {
              return '';
            }
            if (key.includes('date')) {
              return (
                <div className="form-group row">
                  <p className="col-md-4 font-weight-bold">
                    {divideLetters(key)}
                  </p>
                  <p className="col-md-8">{fixedDate(info[key])}</p>
                </div>
              );
            }
            if (typeof info[key] !== 'object') {
              return (
                <div className="form-group row">
                  <p className="col-md-4 font-weight-bold">
                    {divideLetters(key)}
                  </p>
                  <p
                    className="col-md-8"
                    dangerouslySetInnerHTML={{ __html: info[key] }}
                  />
                </div>
              );
            }
            if (
              key === 'allNames'
              || key === 'allNamesPhonetic'
              || key === 'dateOfBirthSearch'
            ) {
              return (
                <div className="form-group row">
                  <p className="col-md-4 font-weight-bold">
                    {divideLetters(key)}
                  </p>
                  <p className="col-md-8">{info[key].join(', ')}</p>
                </div>
              );
            }
            return '';
          })}
        </div>
      </Col>
      )
    );
  }
  const pepFields = [
    {
      key: 'name',
      label: 'Name: ',
    },
    {
      key: 'abstract',
      label: 'Abstract: ',
    },
    {
      key: 'birthDate',
      label: 'Birth Date: ',
      render(value) {
        return fixedDate(value);
      },
    },
    {
      key: 'birthPlace',
      label: 'Birth Place: ',
    },
    {
      key: 'title',
      label: 'Title: ',
    },
    {
      key: 'office',
      label: 'Office: ',
    },
  ];

  return (
    info !== undefined && (
    <Col className="leftCol p-0 m-0">
      <div className={info.name !== undefined ? 'p-3 sourceInfo' : 'p-3'}>
        <div className="d-flex pb-0 pt-2 pr-3">
          <button
            type="button"
            onClick={() => setSource([])}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginLeft: 'auto', fontSize: '24px' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h5 className="p-0 mb-4">PEP</h5>
        {/* for empty fields
            .filter((field) => Object.keys(info)
            .includes(field.key)) */}
        {pepFields
          .filter((field) => !!info[field.key])
          .map((f) => (
            <div className="form-group row">
              <p className="col-md-4 font-weight-bold">
                {f.label.charAt(0).toUpperCase() + f.label.slice(1)}
              </p>
              <p className="col-md-8">
                {f.render && f.render(info[f.key])}
                {!f.render && info[f.key]}
              </p>
            </div>
          ))}

      </div>
    </Col>
    )
  );
}

SourceInfo.defaultProps = {
  type: 'sanction',
};

SourceInfo.propTypes = {
  info: PropTypes.objectOf(PropTypes.string, PropTypes.array).isRequired,
  setSource: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default SourceInfo;
