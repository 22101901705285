import {
  UPDATE_USER,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_CLIENT,
  UPDATE_LIST,
  FETCH_LIST,
} from 'constants/actionTypes';

const rejectedMessage = 'An error occurred please try again later';
const translation = {
  globals: {
    cancel: 'Cancel',
    delete: 'Delete',
    unknown: 'Unknown',
    table: {
      sort: {
        ascend: 'Click to sort ascending',
        descend: 'Click to sort descending',
        cancel: 'Click to cancel sorting',
      },
    },
    date: {
      month: '0 month|1 month|{{%s0}} months',
      week: '0 week|1 week|{{%s0}} weeks',
      day: '0 day|1 day|{{%s0}} days',
      hour: '0 hour|1 hour|{{%s0}} hours',
      minute: '0 minute|1 minute|{{%s0}} minutes',
      ago: '{{%s0}} ago',
      every: 'Every {{%s0}}',
    },
  },
  form: {
    validation: {
      required: '{{%s0}} is required',
      min: '{{%s0}} must be at least {{%s1}} characters',
      invalid: '{{%s0}} is invalid',
      is: '{{%s0}} cannot be same with {{%s1}}',
      is_not: '{{%s0}} does not match with {{%s1}}',
      include: {
        number: '{{%s0}} must contain a number',
        capital: '{{%s0}} must contain a capital letter',
      },
    },
  },
  dashboard: {
    main: {
      header: 'Welcome Back|Welcome Back, {{%s0}}',
      listStatus: {
        header: 'Status',
        chart: {
          labels: {
            needAction: 'Need action',
            clear: 'Clear',
            positive: 'Positive',
          },
        },
      },
      basicInfo: {
        totalAccountNumber: 'Total account number',
        totalProfileInScreen: 'Total profile in screen',
        numberOfLists: 'Number of lists',
        lastSourceUpdate: 'Last source update',
        averageUpdatingInterval: 'Average updating interval',
      },
      lastSearchedQueries: {
        header: 'Last searched queries',
      },
      notifications: {
        header: 'Latest Notifications',
        viewMore: 'View More',
      },
      usedLists: {
        header: 'Frequently used lists',
        profile: '0 profile|1 profile|{{%s0}} profiles',
        viewDetails: 'View details',
        modal: {
          delete: {
            title: 'Delete',
            content: 'Are you sure you want to delete `{{%s0}}` ? If you say delete, you cannot undo this step.',
          },
        },
      },
      map: {
        viewMap: 'View map',
      },
    },
    sources: {
      header: 'Source Info',
      table: {
        columns: {
          name: 'Source',
          description: 'Description',
          entryCount: 'Entry Count',
          modifiedDate: 'Last Updated',
          lastSearchDate: 'Last Search',
          action: 'Action',
        },
        row: {
          viewLogs: 'View logs',
        },
      },
      logs: {
        title: 'Logs',
        table: {
          columns: {
            date: 'Date',
            description: 'Description',
          },
        },
        errorMessage: 'The source is not found',
      },
    },
    media: {
      header: 'Media Search',
      news: 'There is no news to display',
      form: {
        search: {
          label: '',
          placeholder: 'Search*',
        },
        keywords: {
          label: '',
          placeholder: 'Keywords',
        },
        source: {
          label: '',
          placeholder: 'Select source*',
        },
        submit: 'Search',
      },
    },
  },
  client: {
    count: '0 profile|1 profile|{{%s0}} profiles',
    statuses: {
      needAction: 'Need action',
      clear: 'Clear',
      positive: 'Positive',
      falsePositive: 'False positive',
      searching: 'Searching',
    },
    list: {
      header: {
        search: {
          placeholder: 'Search profiles by name',
        },
        addProfile: 'Add profile',
      },
      table: {
        columns: {
          name: 'Name/Title',
          status: 'Status',
          date: 'Creation Date',
        },
        showTotal: '{{%s0}}-{{%s1}} of {{%s2}} profiles',
      },
      modal: {
        delete: {
          title: 'Delete',
          content: 'Are you sure you want to delete `{{%s0}}` ? If you say delete, you cannot undo this step.',
        },
      },
    },
    detail: {},
  },
  screening: {
    detail: {
      profiles: {
        tab: 'Profiles',
      },
      timeline: {
        tab: 'Timeline',
        noData: 'No log found',
      },
      settings: {
        tab: 'Settings',
        form: {
          name: {
            label: 'Name',
            placeholder: 'Enter list name',
          },
          score: {
            label: 'Fuzzy Search',
          },
          searchOptions: {
            label: 'Search Options',
            includeAka: 'Include AKA',
          },
          accessSettings: {
            label: 'Access Settings',
            manage: 'Manage',
          },
          save: 'Save',
        },
      },
    },
  },
  users: {},
  notifications: {
    header: 'Notifications',
    notification: 'You have no notification!|You have 1 notification|You have {{%s0}} notifications',
    actions: {
      markAsRead: 'Mark as read',
      accept: 'Accept',
    },
  },
  savedReports: {
    header: 'Saved Reports',
    report: 'You have no report!',
    search: {
      label: '',
      placeholder: 'Search',
    },
    modal: {
      delete: {
        title: 'Delete',
        content: 'Are you sure you want to delete `{{%s0}}` ? If you say delete, you cannot undo this step.',
      },
    },
    table: {
      columns: {
        name: 'Name',
        status: 'Status',
        createdAt: 'Created at',
        createdBy: 'Created by',
      },
      processing: 'Processing',
      available: 'Available',
    },
  },
  settings: {
    accountDetails: {
      header: 'ACCOUNT DETAILS',
      form: {
        email: 'Email',
        name: {
          label: 'Name',
          placeholder: 'Enter your name',
        },
        title: {
          label: 'Title',
          placeholder: 'Enter your title',
        },
        department: {
          label: 'Department/Division',
          placeholder: 'Enter your department/division',
        },
        phone: {
          label: 'Phone Number',
          placeholder: 'Enter your phone number',
        },
        save: 'Save',
      },
    },
    resetPassword: {
      header: 'RESET PASSWORD',
      form: {
        currentPassword: {
          label: 'Current password',
          placeholder: 'Enter your current password',
        },
        newPassword: {
          label: 'New password',
          placeholder: 'Enter new password',
        },
        confirmPassword: {
          label: 'Confirm password',
          placeholder: 'Confirm your new password',
        },
        reset: 'Reset',
      },
    },
  },
  actions: {
    [UPDATE_NOTIFICATION]: {
      fulfilled: {
        is_read: 'Notification successfully marked as read',
      },
      rejected: { is_read: rejectedMessage },
    },
    [DELETE_NOTIFICATION]: {
      fulfilled: {
        default: 'Notification successfully deleted',
      },
      rejected: { default: rejectedMessage },
    },
    [UPDATE_USER]: {
      fulfilled: {
        default: 'User details successfully updated',
        password: 'Password successfully updated',
      },
      rejected: {
        default: rejectedMessage,
        password: rejectedMessage,
      },
    },
    [DELETE_CLIENT]: {
      fulfilled: {
        default: 'Profile successfully deleted',
      },
      rejected: {
        default: rejectedMessage,
      },
    },
    [UPDATE_LIST]: {
      fulfilled: {
        default: 'List settings successfully updated',
      },
      rejected: {
        default: rejectedMessage,
      },
    },
    [FETCH_LIST]: {
      rejected: {
        default: rejectedMessage,
      },
    },
  },
};

export default translation;
