import { useMemo } from 'react';

import { useLanguage } from 'contexts/LanguageContext';

function useStatuses() {
  const { translate } = useLanguage();

  const statusNames = useMemo(() => translate('client.statuses'), [translate]);

  const statuses = {
    0: {
      color: 'info',
      name: statusNames?.searching,
      disabled: true,
    },
    4: {
      color: 'success',
      name: statusNames?.clear,
      disabled: false,
    },
    5: {
      color: 'warning',
      name: statusNames?.needAction,
      disabled: false,
    },
    6: {
      color: 'danger',
      name: statusNames.positive,
      disabled: false,
    },
    7: {
      color: 'secondary',
      name: statusNames.falsePositive,
      disabled: false,
    },
  };

  return statuses;
}

export default useStatuses;
