import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { deleteClient } from 'store/clients';
import { useLanguage } from 'contexts/LanguageContext';
import { api } from 'services/api';
import { usePagination, useDebounce } from 'hooks';
import { getSortParams } from 'helpers';

import {
  Input,
  Table,
  Button,
} from 'components/ui';
import Filters from './Filters';

import useColumns from './useColumns';

import * as classes from './index.module.scss';

function ClientList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { screeningId } = useParams();
  const { pending } = useSelector((state) => state.clients);
  const { translate } = useLanguage();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  /* eslint-disable-next-line */
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [sortParams, setSortParams] = useState({});
  const [searchParams, setSearchParams] = useState({});

  const [searchValue, setSearchValue] = useState('');

  const {
    loading,
    data,
    count,
    refetch,
  } = usePagination({
    pageNumber,
    pageSize,
    extraParams: { ...sortParams, ...searchParams },
    requestFunction: (params) => api.getClients2(screeningId, params),
  });

  const onPaginationChange = (nextPage, nextPageSize) => {
    setPageSize(() => nextPageSize);
    setPageNumber(() => nextPage);
  };

  const onSortChange = (sorter) => {
    const { columnKey, order } = sorter;
    const params = getSortParams(columnKey, order);

    setPageNumber(() => 1);
    setSortParams(() => params);
  };

  const onDeleteProfile = async (clientId) => {
    await dispatch(deleteClient({ screeningId, clientId }));
    refetch();
  };

  const profileColumns = useColumns({ onDeleteProfile });

  const onSelectionChange = ({ selectedRowKeys }) => {
    setSelectedProfiles(selectedRowKeys);
  };

  const onFilterChange = (key = '', value = null) => {
    setSearchParams((prevState) => {
      const obj = { ...prevState };

      if (value !== null) {
        obj[key] = value;
      } else {
        delete obj?.[key];
      }
      return obj;
    });
    setPageNumber(() => 1);
  };

  const setDebouncedSearchParams = useDebounce(
    (key, value) => onFilterChange(key, value),
    700,
  );

  const onSearchInputChange = (e) => {
    const { target: { value } } = e;
    setSearchValue(value);

    if (!value) {
      setDebouncedSearchParams('name', null);
    }
  };

  const onDateRangeChange = ({ start, end }) => {
    setDebouncedSearchParams('date_before', start);
    setDebouncedSearchParams('date_after', end);
  };

  const onRowClick = (rowData) => {
    const { id: cId } = rowData;

    if (cId) {
      history.push(`/screening/${screeningId}/client/${cId}`);
    }
  };

  return (
    <div className={classes['client-list']}>
      <Row className={classes['client-list_header']}>
        <Col span={24}>
          <Row gutter={[16, 24]}>
            <Col xs={24} sm={12} md={9} lg={8} xl={4}>
              <Input.Text
                disabled={loading || pending}
                size="small"
                prefix={<SearchOutlined />}
                placeholder={translate('client.list.header.search.placeholder')}
                allowClear
                value={searchValue}
                onChange={onSearchInputChange}
                onPressEnter={() => onFilterChange('name', searchValue)}
              />
            </Col>
            <Col xs={24} sm={12} md={9} lg={8} xl={4}>
              <Filters.Date
                disabled={loading || pending}
                onChange={onDateRangeChange}
              />
            </Col>
            <Col
              className={classes['client-list_header-actions']}
              xs={24}
              sm={24}
              md={6}
              lg={8}
              xl={16}
            >
              <Link to={`/screening/${screeningId}/client/create`}>
                <Button
                  color="primary"
                  className={classes['client-list_header-actions_add-profile']}
                >
                  <PlusOutlined />
                  {translate('client.list.header.addProfile')}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Filters.Status
                disabled={loading || pending}
                onChange={onFilterChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey={(record) => record?.user_client?.pk}
            onSelectionChange={onSelectionChange}
            loading={pending || loading}
            dataSource={data?.[pageNumber] ?? []}
            columns={profileColumns}
            scroll={{ y: '58vh' }}
            onRowClick={onRowClick}
            onSortChange={onSortChange}
            onPaginationChange={onPaginationChange}
            pagination={{
              total: count,
              current: pageNumber,
              pageSize,
              showTotal: (total, range) => translate('client.list.table.showTotal', [
                range[0],
                range[1],
                total,
              ]),
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ClientList;
