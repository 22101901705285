import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Card, CardBody, Row, Col, Button,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { useAPI } from '../../services/api';
import { createClients, createList } from '../../store/reducers/lists';
import ClientDoubleEntry from '../../components/ClientDoubleEntry';

function AddToList(props) {
  const api = useAPI();

  const dispatch = useDispatch();
  // it is used when create a client in a new list
  const crList = useSelector((state) => state.createList);
  // it is used when create a client in existing list
  const crClient = useSelector((state) => state.createClient);

  const [allLists, setLists] = useState([]);

  const [cName, setCName] = useState(props.name);
  const [newListName, setNewListName] = useState('');
  const [selectedList, setSelectedList] = useState(0);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  // Check client
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line
  const [toggleModal, setToggleModal] = useState(false);
  // eslint-disable-next-line
  const [fixedClients, setFixedClients] = useState([]);
  // eslint-disable-next-line
  const [existingClients, setExistingClients] = useState([]);
  // eslint-disable-next-line
  const [existingCopy, setExistingCopy] = useState([]);
  // eslint-disable-next-line
  const [selectedEntries, setSelectedEntries] = useState([])

  const getLists = async () => {
    const { data: { lists } } = await api.getLists();
    setLists(lists);
  };

  useEffect(() => {
    getLists();
    let defaultIndicator = crClient;
    if (selectedList === 0) {
      defaultIndicator = crList;
    }
    setLoading(defaultIndicator.loading);
    setStatus(defaultIndicator.status);
  }, [crClient, crList]);

  useEffect(() => {
    if (selectedEntries.length === 0) {
      setDisabled(true);
    } else setDisabled(false);
  }, [selectedEntries]);

  // const searchCreateClient = () => {
  //   if (selectedList !== 0) {
  //     dispatch(createClients(selectedList, [cName]));
  //   } else {
  //     dispatch(createList(newListName, [cName]));
  //   }
  //   setTimeout(() => {
  //     props.setModal(false);
  //   }, 1500);
  // };

  const handleCreateClients = (exCState, allClients = []) => {
    let filtered = [];
    if (exCState) {
      filtered = fixedClients.filter((f) => (selectedEntries
        .findIndex((e) => e.name === f) === -1
        && existingCopy.findIndex((e) => e.name === f) === -1));
    } else {
      filtered = allClients;
    }
    const c = filtered.map((client) => ({ name: client }));
    const entries = selectedEntries.map((entry) => {
      if (entry?.value !== null && entry?.value !== undefined && entry.value === 'new') {
        return { name: entry.name };
      }

      return { id: entry.id };
    });
    if (selectedList !== 0) {
      dispatch(createClients(selectedList, [...c, ...entries]));
    } else {
      dispatch(createList(newListName, [...c, ...entries]));
    }
    setTimeout(() => {
      setToggleModal(false);
      props.setModal(false);
    }, 2500);
  };

  const handleCheckClients = async (c) => {
    const changedC = c.map((e) => ({ name: e }));
    setLoading(true);
    const { data: { existing_clients: exC } } = await api.checkClients(changedC);
    setLoading(false);
    const temp = exC.map((ch, index) => ({ id: `m${index}`, ...ch }));
    setExistingClients(temp);
    setExistingCopy(temp);
    if (exC.length === 0) {
      handleCreateClients(false, c);
    } else {
      setToggleModal(true);
    }
  };

  const handleSelected = (id, clientName, textValue, masterId) => {
    const isInclude = selectedEntries.findIndex((e) => e.masterId === masterId);
    if (textValue === 'new') {
      if (isInclude === -1) {
        setSelectedEntries((prevState) => [...prevState, {
          masterId, id, name: clientName, value: 'new',
        }]);
      } else {
        setSelectedEntries((prevState) => [...prevState.filter((p) => p
          .masterId !== masterId), {
          masterId, id, name: clientName, value: 'new',
        }]);
      }
    } else if (isInclude === -1) {
      setSelectedEntries((prevState) => [...prevState, { masterId, id, name: clientName }]);
    } else if (isInclude > -1) {
      setSelectedEntries((prevState) => [...prevState.filter((p) => p
        .masterId !== masterId), { masterId, id, name: clientName }]);
    }
  };
  const deleteEntry = (clName) => {
    setSelectedEntries((prevState) => [...prevState.filter((p) => p.name !== clName)]);
    setExistingClients(existingClients.filter((e) => e.name !== clName));
  };

  return (
    <Card>
      <ClientDoubleEntry
        loading={loading}
        status={status}
        disabled={disabled}
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        onConfirm={handleCreateClients}
        doubleClients={existingClients}
        handleSelected={handleSelected}
        deleteEntry={deleteEntry}
        selectedEntries={selectedEntries}
      />
      <CardBody>
        <div className="form-group row">
          <label
            htmlFor="client-name"
            className="col-md-auto col-form-label"
            style={{
              marginRight: '1px',
            }}
          >
            Name
          </label>
          <div
            className="ml-5"
            style={{
              width: '82.5%',
            }}
          >
            <input
              required
              id="client-name"
              name="client-name"
              className="form-control"
              type="text"
              value={cName}
              onChange={(e) => setCName(e.target.value)}
            />
          </div>
        </div>
        <Row className="mt-4 d-flex align-items-center mr-0">
          <Col>
            <div className="form-group row ml-0 mt-1">
              <label
                className="col-form-label mr-0 col-md-3 pl-0"
                htmlFor="selectedList"
              >
                Select a list
              </label>
              <select
                id="selectedList"
                className="custom-select ml-2 col-md-7"
                value={selectedList}
                onChange={(e) => {
                  setNewListName('');
                  setSelectedList(parseInt(e.target.value, 10));
                }}
              >
                <option value="0">
                  Select a list
                </option>
                {allLists.map(({ id, name }) => (
                  <option key={name} value={id}>{name}</option>
                ))}
              </select>
            </div>
          </Col>
          <p>or</p>
          <Col>
            <div className="form-group row  mt-1 ml-2">
              <label
                htmlFor="add-list"
                className="col-md-4 col-form-label pr-0"
              >
                Add new list
              </label>
              <div className="col-md-8 pl-0">
                <input
                  required
                  id="add-list"
                  name="add-list"
                  className="form-control"
                  type="text"
                  value={newListName}
                  onChange={(e) => {
                    setSelectedList(0);
                    setNewListName(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="1"> </Col>
          <Col>
            <Button
              disabled={cName?.trim().length < 3 || (selectedList === 0 && newListName.length < 3)}
              color={!loading && status === 'ok' ? 'success' : 'primary'}
              className="btn d-flex align-items-center paddingY height36px mt-3 create-new-client"
              onClick={() => {
                handleCheckClients([cName]);
              }}
            >
              {status === 'ok' && (
                <>
                  <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                  Saved
                </>
              )}
              {loading === true && (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                  Saving...
                </>
              )}
              {loading !== true && status !== 'ok' && 'Create a new profile'}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

AddToList.propTypes = {
  name: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default AddToList;
