import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.scss';

function Card({ children, className }) {
  return (
    <div className={classnames(styles.card, className)}>
      {children}
    </div>
  );
}

Card.defaultProps = {
  children: <></>,
  className: '',
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
