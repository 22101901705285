import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import { Button } from 'components/ui';
import ListDeleteModal from './ListDeleteModal';

function ListItem({ list, onDeleteList }) {
  const { translate } = useLanguage();

  return (
    <Row className="_dashboard__used-list-item">
      <Col span={14}>
        <p className="mb-2 _dashboard__used-list-item-name">{list?.name}</p>
        <p className="mb-0 _dashboard__used-list-item-profile">
          {translate('dashboard.main.usedLists.profile', list?.clients_count ?? 0)}
        </p>
      </Col>
      <Col className="px-0 ml-auto d-flex align-items-center">
        <Link to={`/lists/${list?.id}`}>
          <Button color="link">
            {translate('dashboard.main.usedLists.viewDetails')}
          </Button>
        </Link>
        <ListDeleteModal listName={list?.name} onDelete={onDeleteList} />
      </Col>
    </Row>
  );
}

ListItem.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    clients_count: PropTypes.number.isRequired,
  }).isRequired,
  onDeleteList: PropTypes.func.isRequired,
};

export default ListItem;
