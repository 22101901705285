import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { authLogin, authCheckState, resetForgotPassword } from '../../store/reducers/auth';

import '../../assets/css/login.css';

import onbText from '../../assets/images/onboarding/onboarding-text.png';
import city from '../../assets/images/onboarding/sean-pollock-PhYq704ffdA-unsplash 1.png';

function Login() {
  const [iEmail, setIEmail] = useState(localStorage.getItem('email'));

  const { error, loading } = useSelector(
    (state) => state.auth,
  );
  console.log(error);
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
    } = e.target.elements;

    localStorage.setItem('email', email);
    dispatch(authLogin(email, password, history));
  }

  useEffect(() => () => {
    dispatch(resetForgotPassword());
  }, [dispatch]);

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  return (
    <div
      className="account-pages main-login"
    >
      <div style={{
        display: 'flex',
        paddingTop: '143px',
        height: '100%',
      }}
      >
        <Container>
          <Row
            className="justify-content-center"
            style={{
              position: 'relative',
            }}
          >
            <Col
              md="auto"
              className="ml-0 p-0"
              style={{
                position: 'relative',
                right: '0px',
                top: '-40.148px',
                zIndex: '1',
                minWidth: '200px',
              }}
            >
              <div className="bg-blue">
                <div className="bg-city">
                  <div className="st-icon-onboarding" />
                  <img
                    src={onbText}
                    alt="Onboarding"
                    width="100"
                    style={{
                      justifySelf: 'center',
                    }}
                  />
                  <img
                    className="bg-city-img"
                    src={city}
                    alt="city"
                    width="100"
                    style={{
                      justifySelf: 'center',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col
              md={8}
              lg={6}
              xl={5}
              className="pl-0"
            >
              <Card
                className="overflow-hidden px-4"
                style={{
                  borderRadius: '0px 4px 4px 0px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <div>
                  <Row style={{
                    paddingTop: '17px',
                    paddingBottom: '40px',
                    height: '10px',
                  }}
                  >
                    <Col xs="12">
                      <div
                        className="p-4 d-flex justify-content-start"
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#495057',
                        }}
                      >
                        <p className="ml-1">
                          Sign in to continue to ST Digital Onboarding Platform
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody
                  className="pt-4"
                  style={{
                    background: 'white',
                    borderRadius: '4px',
                  }}
                >
                  <div className="p-2 pt-4">
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">Invalid email or password.</Alert>
                      )}
                      <div className="form-group">
                        <Label htmlFor="email">Email</Label>
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                          }}
                        >
                          <Input
                            autoFocus={localStorage.getItem('email') === null}
                            required
                            id="email"
                            name="email"
                            type="email"
                            value={iEmail}
                            onChange={(e) => setIEmail(e.target.value)}
                            placeholder="Enter email"
                            defaultValue={localStorage.getItem('email')}
                          />

                          {iEmail !== null && iEmail.length > 0 && (
                          /* eslint-disable-next-line */
                          <span
                            className="closeButton"
                            onClick={() => {
                              localStorage.setItem('email', '');
                              setIEmail('');
                            }}
                          >
                            <i className="mdi mdi-close" />
                          </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <Label htmlFor="password">Password</Label>
                        <Input
                          autoFocus={localStorage.getItem('email') !== null}
                          required
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter password"
                        />
                      </div>
                      <div className="mt-5">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          Sign In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Login;
