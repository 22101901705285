import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardTitle,
  Button,
} from 'reactstrap';

import UserItem from './UserItem';
import DeleteConfirmModal from './DeleteConfirmModal';
import {
  fetchUsers,
  createNewUser,
  cancelNewUser,
  inviteUser,
  editPermissions,
  usersWithPermissions,
  deleteUser,
  deleteInvitation,
} from '../../store/reducers/users';

import { changeRoute, resetRoute } from '../../store/reducers/route';

const headerFontSize = {
  fontSize: '1.8rem',
};

function Users({ fullWidth }) {
  const dispatch = useDispatch();
  const { initialized, saving } = useSelector((state) => state.users);

  const { id: userId } = useSelector((state) => state.auth.user);
  // const lists = useSelector((state) => state.lists.data);
  // const automatedLists = useSelector((state) => state.automatedLists.data);
  const emailDomain = useSelector((state) => `@${state.auth.user.email.split('@')[1]}`);
  const users = useSelector(usersWithPermissions);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInvModal, setDeleteInvModal] = useState(false);
  // eslint-disable-next-line
  const [inv, setInv] = useState({ name: 'Example', id: 2 });

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchUsers());
    }
    return () => {
      dispatch(cancelNewUser());
    };
  }, [dispatch, initialized]);

  const handleNewUserClick = useCallback(() => {
    dispatch(createNewUser());
    setActiveItem('new');
  }, [dispatch]);

  const handleSave = useCallback((id, permissions, email = null) => {
    if (id === 'new' && email) {
      dispatch(inviteUser(email, permissions));
      setTimeout(() => {
        dispatch(fetchUsers());
      }, 1000);
      return;
    }
    dispatch(editPermissions(id, permissions));
  }, [dispatch]);

  const handleDelete = useCallback((id) => {
    if (id === 'new') {
      dispatch(cancelNewUser());
      setActiveItem(null);
      return;
    }

    const user = users.find((u) => u.id.toString() === id);
    if (user) {
      setDeleteConfirmModal(user);
    }
  }, [dispatch, users]);

  const handlePermissionsClick = useCallback((e) => {
    const id = e.currentTarget.getAttribute('data-id');
    if (activeItem === 'new') {
      dispatch(cancelNewUser());
    }
    setActiveItem(activeItem === id ? null : id);
  }, [activeItem, dispatch]);

  const handleDeleteCancel = useCallback(() => {
    setDeleteConfirmModal(false);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    dispatch(deleteUser(deleteConfirmModal.id, (success) => {
      if (success) {
        setDeleteConfirmModal(false);
      }
    }));
  }, [deleteConfirmModal.id, dispatch]);

  const handleDeleteInvitation = useCallback(async () => {
    dispatch(deleteInvitation(userId, inv.id));
    setDeleteInvModal(false);
  }, [userId, inv]);

  useEffect(() => {
    dispatch(changeRoute('Users'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  if (!initialized) {
    return (<p>Please wait...</p>);
  }

  return (
    <>
      <Row>
        <Col className={fullWidth ? '' : 'offset-md-1'} md={fullWidth ? 12 : 8}>
          <Card className="p-5" body>
            <CardTitle className="mt-0">
              <Row>
                <Col sm={9}>
                  <h1 style={headerFontSize}>Users</h1>
                </Col>

                <Col sm={3} className="pl-0 pr-4">
                  <Button
                    disabled={saving || isAddOpen}
                    block
                    color="primary"
                    onClick={() => {
                      setIsAddOpen(true);
                      handleNewUserClick();
                    }}
                  >
                    Add new user
                  </Button>
                </Col>

              </Row>
            </CardTitle>
            <hr />
            {
              users.map(({
                id,
                name,
                email,
                permissions,
                pending,
              }) => (
                <UserItem
                  key={`user${id}-${Math.floor(Math.random() * 1000)}`}
                  id={id}
                  name={name}
                  email={email}
                  permissions={permissions}
                  pending={pending}
                  // lists={lists}
                  onClick={handlePermissionsClick}
                  isOpen={activeItem === id.toString()}
                  onSave={handleSave}
                  onDelete={handleDelete}
                  emailDomain={emailDomain}
                  setDeleteInvModal={setDeleteInvModal}
                  setInv={setInv}
                  setIsAddOpen={setIsAddOpen}
                />
              ))
            }
          </Card>
        </Col>
      </Row>
      {
        deleteConfirmModal && (
          <DeleteConfirmModal
            isOpen
            name={deleteConfirmModal.name}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )
      }
      { deleteInvModal && (
      <DeleteConfirmModal
        isOpen
        name="example"
        onCancel={() => setDeleteInvModal(false)}
        onConfirm={handleDeleteInvitation}
      />
      )}
    </>
  );
}
Users.defaultProps = {
  fullWidth: false,
};

Users.propTypes = {
  fullWidth: PropTypes.bool,
};

export default Users;
