import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';
import {
  DELETE_CLIENT,
} from 'constants/actionTypes/clients';

export const deleteClient = createAsyncThunk(
  DELETE_CLIENT,
  async (payload, { rejectWithValue }) => {
    const { listId, clientId } = payload;
    const [, error] = await request(api.deleteClient(listId, clientId));

    if (error) {
      return rejectWithValue(error);
    }

    return clientId;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [deleteClient.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [deleteClient.fulfilled]: (state) => {
      state.pending = false;
    },
    [deleteClient.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

export default clientsSlice.reducer;
