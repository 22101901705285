import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/ui';

import * as classes from './index.module.scss';

const {
  Date: {
    Range: DateRange,
  },
} = Input;

function DateFilter({ onChange }) {
  return (
    <div>
      <DateRange
        size="lg"
        className={classes['date-filter']}
        onChange={onChange}
      />
    </div>
  );
}

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DateFilter;
