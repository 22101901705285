import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import qs from 'qs';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';
import '../../assets/css/onboarding.css';
import onbText from '../../assets/images/onboarding/onboarding-text.png';
import city from '../../assets/images/onboarding/sean-pollock-PhYq704ffdA-unsplash 1.png';
import { useAPI } from '../../services/api';
import { resetStates } from '../../store/reducers/auth';

function useQuery() {
  return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
}

function InviteSignup() {
  const api = useAPI();

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    token, email: userEmail, name: userName, surname: userSurname, organization_title: orgTitle,
    sender_name: senderName,
    sender_organization: senderOrganization,
  } = useQuery();
  const { loading } = useSelector(
    (state) => state.auth,
  );
  // eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [validations, setValidations] = useState([false, false, false, false]);
  const [checkMatch, setCheckMatch] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      localStorage.clear();
      localStorage.setItem('email', userEmail);
      const {
        password: { value: password },
      } = e.target.elements;
      if (checkMatch && validations.reduce((acc, cur) => acc + cur) === 3) {
        const { data: { status } } = await api.kycRegister({
          token,
          password,
        });

        if (status === 'ok') {
          setError(false);
          setSuccess(true);
          setTimeout(() => {
            history.push('/kyc/login');
          }, 3000);
        }
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
      console.log(err);
    }
  }

  const validatePassword = (e) => {
    const password = e.target.value;
    let passwordMatch = '';
    if (e.target.id === 'password') {
      passwordMatch = document.querySelector('#password-confirm').value;
      setValidations([
        password.length > 7,
        password.search(/[A-Z]/) > -1,
        password.search(/[0-9]/) > -1,
      ]);
    } else if (e.target.id === 'password-confirm') {
      passwordMatch = document.querySelector('#password').value;
    }
    setCheckMatch((password === passwordMatch)
    && password.trim().length > 0
    && passwordMatch.trim().length > 0);
  };

  const getStatusIcon = (v) => (v ? (
    <i className="bx bxs-check-square align-middle mr-2 success-icon" />
  ) : (
    <i className="bx bxs-x-square align-middle mr-2 error-icon" />
  ));

  useEffect(() => {
    dispatch(resetStates());
  }, [token]);

  return (
    <div
      className="account-pages invited-signup"
    >
      <div style={{
        display: 'flex',
        paddingTop: '143px',
        height: '100%',
      }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md="auto"
              className="ml-0 p-0"
              style={{
                position: 'relative',
                right: '0px',
                top: '-40.5px',
                zIndex: '1',
                minWidth: '200px',
              }}
            >
              <div className="bg-blue">
                <div className="bg-city">
                  <div className="st-icon-onboarding" />
                  <img
                    src={onbText}
                    alt="Onboarding"
                    width="100"
                    style={{
                      justifySelf: 'center',
                    }}
                  />
                  <img
                    className="bg-city-img"
                    src={city}
                    alt="city"
                    width="100"
                    style={{
                      justifySelf: 'center',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col
              md={10}
              lg={8}
              xl={7}
              className="pl-0"
            >
              <Card
                className="overflow-hidden mb-0 h-100"
                style={{
                  borderRadius: '0px 4px 4px 0px',
                  width: '100%',
                  padding: '40px 60px 20px 35px',
                  height: '95.6%',
                }}
              >
                <div>
                  <div
                    className="pl-4"
                  >
                    <p
                      className="ml-1"
                      style={{
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '56px',
                        color: '#495057',
                      }}
                    >
                      You’ve been invited!
                    </p>
                    <p
                      className="ml-1"
                      style={{
                        fontSize: '16px',
                        fontWeight: 'normal',
                        lineHeight: '19px',
                        color: '#495057',
                      }}
                    >
                      You have been invited by
                      {' '}
                      {senderName}
                      {' '}
                      of
                      {' '}
                      {senderOrganization}
                      {' '}
                      for onboarding. Please create an account
                      and fill out the KYC form, and upload
                      documents to the platform.This platform
                      is managed by ST Digital Onboarding Platform
                      on behalf of
                      {' '}
                      {senderOrganization}
                      .
                    </p>
                  </div>
                </div>
                <CardBody
                  className="pt-4"
                  style={{
                    background: 'white',
                    borderRadius: '4px',
                  }}
                >
                  <div className="p-2 pt-0">
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">Invalid token or password.</Alert>
                      )}
                      {success && (
                        <div className="row">
                          <Alert color="success" className="col pr-0">Your account has been created successfully.</Alert>
                        </div>
                      )}
                      <div className="form-group row d-flex">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="name">Name</Label>
                        <Input
                          disabled
                          className="col-8"
                          required
                          id="name"
                          name="name"
                          type="text"
                          value={userName}
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="form-group row d-flex">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="name">Surname</Label>
                        <Input
                          disabled
                          className="col-8"
                          required
                          id="surname"
                          name="surname"
                          type="text"
                          value={userSurname}
                          placeholder="Enter surname"
                        />
                      </div>
                      <div className="form-group row d-flex">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="email">Email</Label>
                        <Input
                          disabled
                          required
                          className="col-8"
                          id="email"
                          name="email"
                          type="email"
                          value={userEmail}
                          placeholder="Enter email"
                        />
                      </div>
                      <div className="form-group row">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="organization">Title of organization</Label>
                        <Input
                          disabled
                          className="col-8"
                          required
                          id="organization"
                          name="organization"
                          type="text"
                          value={orgTitle}
                          placeholder="Enter title of organization"
                        />
                      </div>
                      <div className="form-group row">
                        <div
                          className="col"
                          style={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '32px',
                          }}
                        >
                          Please set your password to access this form at any time.
                        </div>
                      </div>
                      <div className="form-group row">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="password">Password</Label>
                        <Input
                          required
                          className="col-8"
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter password"
                          onChange={validatePassword}
                        />
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item"
                          >
                            {getStatusIcon(validations[0])}
                            {' '}
                            must be at least 8
                            characters
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(validations[1])}
                            {' '}
                            must contain a capital
                            letter
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(validations[2])}
                            {' '}
                            must contain a number
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(checkMatch)}
                            {' '}
                            must password match
                          </li>
                        </ul>
                      </div>
                      <div className="form-group row">
                        <Label className="col-4 mb-0 align-self-center" htmlFor="password-confirm">Confirm Password</Label>
                        <Input
                          disabled={!!error}
                          required
                          id="password-confirm"
                          name="password-confirm"
                          className="col-8"
                          type="password"
                          placeholder="Enter password again"
                          onChange={validatePassword}
                        />
                      </div>
                      <div className="mt-4 row">
                        <div className="col pr-0">
                          <button
                            disabled={loading
                            || success
                            || !checkMatch
                            || validations.reduce((acc, cur) => acc + cur) < 3}
                            className={classnames({
                              btn: true,
                              'btn-block': true,
                              'waves-effect': true,
                              'waves-light': true,
                              'btn-primary': !success,
                              'btn-success': success,
                            })}
                            type="submit"
                            style={{
                              height: '49px',
                            }}
                          >
                            {success ? 'You have been redirected to login...' : 'Sign up'}
                          </button>
                        </div>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default InviteSignup;
