import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
} from 'reactstrap';

import classnames from 'classnames';

const dateTimeFormat = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

function ListRow({
  rowData, deleteList, setId, setListName, getPerms,
}) {
  const [isGetPerm, setIsGetPerm] = useState(false);
  const getFormattedTime = (date) => {
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);

    return `${day} ${month} ${year}`;
  };
  return (
    <tr key={`row-${rowData.id}`} style={{ backgroundColor: '#FFFFFF' }}>
      <td id={rowData.name}>
        <h5 className="text-truncate font-size-14">
          <Link to={`/screening/${rowData.id}`} className="text-dark">
            {rowData.name}
          </Link>
        </h5>
        <p className="text-muted mb-0">{`${rowData.clients_count} entities`}</p>
      </td>
      <td id={rowData.states.join(',')}>
        {rowData.states !== undefined
          && rowData.states.map((state) => (
            <Link key={`row-label-${state}-${rowData.id}`} to={`/screening/${rowData.id}`} className="text-dark">
              <span
                className={classnames({
                  badge: true,
                  'font-size-12': true,
                  'py-2': true,
                  'px-4': true,
                  'mr-1': true,
                  'font-weight-normal': true,
                  'badge-soft-success': state.indexOf('Clear') >= 0,
                  'badge-soft-warning': state.indexOf('Need Action') >= 0,
                  'badge-soft-secondary':
                    state.indexOf('False Positive') >= 0
                    || state.indexOf('Search Completed') >= 0,
                  'badge-soft-danger':
                    state.indexOf('Found') >= 0
                    || (state.indexOf('Positive') >= 0
                      && state.indexOf('False Positive') === -1),
                  'badge-soft-searching':
                    state.indexOf('Searching') >= 0
                    || state.includes('Not Searched'),
                })}
              >
                {state === 'Not Searched' ? 'Searching' : state}
              </span>
            </Link>
          ))}
      </td>
      <td id={rowData.creation_date}>
        {getFormattedTime(new Date(rowData.creation_date))}
      </td>
      <td id={rowData.creator_name}>{rowData.creator_name}</td>
      <td>
        <div style={{ display: 'flex', placeItems: 'center' }}>
          <Button
            type="button"
            color="link"
            className="btn btn-link"
            data-id={rowData.id}
            style={{
              width: '24px',
              placeItems: 'center',
              marginRight: '39.5px',
            }}
            onClick={async () => {
              setId(rowData.id);
              setListName(rowData.name);
              setIsGetPerm(true);
              await getPerms(rowData.id);
              setIsGetPerm(false);
              deleteList(true);
            }}
          >
            { isGetPerm
              ? (
                <i
                  className="mdi mdi-loading mdi-spin delete-loading-spinner"
                  style={{
                    marginLeft: '-12px',
                  }}
                />
              )
              : (
                <i
                  className="mdi mdi-delete-outline delete"
                  style={{
                    marginLeft: '-12px',
                  }}
                />
              ) }
          </Button>
          <Link to={`/screening/${rowData.id}`} className="text-dark">
            <Button
              type="button"
              color="primary"
              className="btn waves-effect waves-light"
              style={{
                width: '109px',
                height: '36px',
              }}
            >
              View Details
            </Button>
          </Link>
        </div>
      </td>
    </tr>
  );
}

ListRow.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    clients_count: PropTypes.number.isRequired,
    creation_date: PropTypes.string.isRequired,
    creator_name: PropTypes.string,
    states: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  deleteList: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  setListName: PropTypes.func.isRequired,
  getPerms: PropTypes.func.isRequired,
};

export default ListRow;
