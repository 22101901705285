import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request, sortByDate } from 'helpers';

export const fetchMedia = createAsyncThunk(
  'media/fetch',
  async (payload, { rejectWithValue }) => {
    const { sourceId, query, keywords } = payload;
    const params = {
      input: query,
      keywords,
      media_source: sourceId,
    };
    const [res, error] = await request(api.searchExperimentalAdverseMedia(params));

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { results } } = res;
    return ({
      data: sortByDate(results),
      query,
      keywords,
      sourceId,
    });
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
  query: null,
  keywords: null,
  sourceId: null,
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    fetchMediaClear(state) {
      state.data = [];
      state.query = null;
      state.keywords = null;
      state.sourceId = null;
    },
  },
  extraReducers: {
    [fetchMedia.pending]: (state) => {
      state.pending = true;
      state.data = [];
      state.error = null;
    },
    [fetchMedia.fulfilled]: (state, action) => {
      const {
        data,
        query,
        keywords,
        sourceId,
      } = action.payload;

      state.pending = false;
      state.data = data;
      state.query = query;
      state.keywords = keywords;
      state.sourceId = sourceId;
    },
    [fetchMedia.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

export const {
  fetchMediaClear,
} = mediaSlice.actions;

export default mediaSlice.reducer;
