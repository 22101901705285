import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from 'reactstrap';

import classnames from 'classnames';
import { useAPI } from '../../services/api';
import '../../assets/css/client.css';

function ClientDropdown({
  listId, clientData, cantEdit, requestAccessToggle,
}) {
  const api = useAPI();

  const [toggle, setToggle] = useState(false);
  // eslint-disable-next-line
  const [status, setStatus] = useState(5);
  // eslint-disable-next-line
  const changeStatus = async (stat) => {
    const { data } = await api
      .changeAutomatedClientStatus(listId, clientData.auto_list_client_id, stat);
    if (data.status === 'ok') {
      setStatus(stat);
    } else { setStatus(undefined); }
  };

  useEffect(() => {
    if ([4, 5, 6, 7].includes(clientData?.status)) {
      setStatus(clientData?.status);
    } else {
      setStatus(5);
    }
  }, [clientData?.status]);

  return (
    <Dropdown
      isOpen={toggle}
      toggle={() => {
        if (cantEdit) {
          requestAccessToggle(true);
        } else {
          setToggle(!toggle);
        }
      }}
    >
      <DropdownToggle className="border-0 p-0 bg-white bg-warning-c text-warning">
        <span
          className={classnames({
            badge: true,
            'font-size-12': true,
            'py-2': true,
            'px-4': true,
            'font-weight-normal': true,
            /* eslint-disable react/prop-types */
            'badge-soft-success': status === 4,
            'badge-soft-warning': status === 5,
            'badge-soft-danger': status === 6 || status === undefined,
            'badge-soft-secondary': status === 7,
            /* eslint-enable react/prop-types */
          })}
        >
          {status === 4 && 'Clear'}
          {(status === 5) && 'Need Action'}
          {status === 6 && 'Positive'}
          {status === 7 && 'False Positive'}
          {status === undefined && 'error'}
          <i className="mdi mdi-chevron-down" />
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => { changeStatus(5); }}
        >
          Need action
        </DropdownItem>
        <DropdownItem onClick={() => { changeStatus(6); }}>
          Positive
        </DropdownItem>
        <DropdownItem onClick={() => { changeStatus(7); }}>
          False positive
        </DropdownItem>
        <DropdownItem onClick={() => { changeStatus(4); }}>
          Clear
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

ClientDropdown.propTypes = {
  listId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  clientData: PropTypes.shape({
    auto_list_client_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};

export default ClientDropdown;
