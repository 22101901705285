import React from 'react';
import PropTypes from 'prop-types';
import { Slider as AntdSlider } from 'antd';

function Slider({
  disabled,
  className,
  min,
  max,
  value,
  onChange,
}) {
  const onInputChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <AntdSlider
      disabled={disabled}
      className={[className].join(' ')}
      min={min}
      max={max}
      value={typeof value === 'number' ? value : 0}
      onChange={onInputChange}
    />
  );
}

Slider.defaultProps = {
  disabled: false,
  className: '',
  min: 0,
  max: 100,
  value: null,
  onChange: () => {},
};

Slider.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default Slider;
