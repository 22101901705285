import React, {
  useState, useEffect, Suspense, lazy, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import {
  Spinner,
} from 'reactstrap';

// New Pages - Start

import DashboardMain, {
  DashboardSourceList,
  DashboardSourceDetail,
  DashboardSourceLogs,
  DashboardMediaList,
} from 'containers/Dashboard';

import Notifications from 'containers/Notifications';
import SavedReports from 'containers/SavedReports';
import {
  ScreeningList,
  ScreeningDetail,
  ScreeningCreate,
} from 'containers/Screening';
import {
  ClientList,
  ClientDetail,
  ClientCreate,
} from 'containers/Client';

// New Pages - End

import { authCheckState } from './store/reducers/auth';
import DashboardLayout from './containers/DashboardLayout';
import Login from './containers/Login';

import ListsLayout from './containers/ListsLayout';

import SanctionMap from './containers/SanctionMap/SanctionMap';
import FactBookMap from './containers/FactBookMap/FactBookMap';
import CorruptionMap from './containers/CorruptionMap/CorruptionMap';
import AmlIndexMap from './containers/AmlIndexMap/AmlIndexMap';

import Search from './containers/Search/Search';
import SearchHistory from './containers/SearchHistory/SearchHistory';
import Users from './containers/Users/Users';
import Logout from './containers/Logout';

import ResetPassword from './containers/ResetPassword';
import ForgotPassword from './containers/Forgot';
import Registration from './containers/Registration';
import Preview from './containers/Preview/Preview';
import VerifyFiles from './containers/Preview/VerifyFiles';
import AcceptCard from './containers/AcceptCard';
// Onboarding
// import InviteLogin from './containers/Onboarding/InviteLogin';
import KycLogin from './containers/Onboarding/KycLogin';
import InviteSignup from './containers/Onboarding/InviteSignup';
import CreateList from './components/CreateList';
// import InviteHistory from './containers/Onboarding/InviteHistory';
// import GeneralForm from './containers/Onboarding/GeneralForm';

import Settings from './containers/Settings';
// import IntegratedListTable from './containers/IntegratedList/IntegratedListTable';
// import CreateIntegratedList from './containers/IntegratedList/CreateIntegratedList';
// import IntegratedListsLayout from './containers/IntegratedList/ListsLayout';
// automated lists
import CreateAutomatedList from './containers/AutomatedLists/CreateAutomatedList';
import AutomatedListsLayout from './containers/AutomatedLists/AutomatedListsLayout';
// automated lists
import OrgChart from './containers/OrgChart/OrgChart';
import OrgChart2 from './containers/OrgChart/OrgChart copy';

import NetworkScheme from './containers/OrgChart/NetworkScheme';

import EndUseCertificate from './containers/EndUseCertificate/GeneralForm';

import TransactionHistory from './containers/TransactionMonitoring/TransactionHistory';

import OnboardingDashboard from './containers/Dashboard/Onboarding';

import 'assets/scss/theme.scss';
import 'scss/index.scss';

const InviteHistory = lazy(() => import('./containers/Onboarding/InviteHistory'));
const GeneralForm = lazy(() => import('./containers/Onboarding/GeneralForm'));
const ThirdPartyInviteHistory = lazy(() => import('./containers/ThirdPartyManagement/InviteHistory'));
const ThirdPartyForm = lazy(() => import('./containers/ThirdPartyManagement/GeneralForm'));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner className="mr-2" color="secondary" />
    </div>
  );
}
const kocOrganization = 'koç';
function App() {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const isAdmin = useSelector((state) => state.auth?.user?.role !== null
    && state.auth?.user?.role === 3);
  const isKycFormFiller = useSelector((state) => state.auth?.user?.role === 4);
  const userOrganization = useSelector((state) => state.auth?.user?.organization?.name
    && state.auth?.user?.organization?.name.toLowerCase());
  const location = useLocation();

  const checkUrlParameters = () => {
    if (query.get('q') !== null) {
      const q = query.get('q');
      const s = query.get('s');
      const p = query.get('phonetics');
      const aka = query.get('aka');
      return `/login?q=${q}&s=${s}&phonetics=${p}&aka=${aka}`;
    }
    return '/login';
  };

  const isBrisa = useMemo(() => userOrganization === 'brisa', [userOrganization]);

  useEffect(() => {
    if (loading && !isAuthenticated) {
      dispatch(authCheckState());
    }
    setLoading(false);
  }, [dispatch, isAuthenticated, loading]);

  if (loading) {
    return (<p>Loading</p>);
  }

  if (
    !isAuthenticated
    && !([
      '/users/password/reset',
      '/login',
      '/forgot-password',
      '/registration',
      '/exports/previews',
      '/preview/verify',
      '/permission/approve',
      '/kyc/login',
      '/kyc/registration',
    ].includes(location.pathname))
    && location.pathname.indexOf('exports/previews') === -1
  ) {
    const url = checkUrlParameters();
    return <Redirect to={url} />;
  }

  if (isKycFormFiller) {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/kyc/login" component={KycLogin} />
        {/* <Route path="/kyc/login" component={InviteLogin} /> */}
        <Route path="/kyc/registration" component={InviteSignup} />
        <Route path="/kyc/registration" component={InviteSignup} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/users/password/reset" component={ResetPassword} />
        <Route path="/permission/approve" component={AcceptCard} />
        <Route path="/">
          <DashboardLayout>
            <Route exact path="/">
              <Redirect to="/onboarding" />
            </Route>
            <Switch>
              <Route path="/onboarding">
                <Suspense fallback={<Loading />}>
                  <InviteHistory />
                </Suspense>
              </Route>
              <Route exact path="/form/">
                <Suspense fallback={<Loading />}>
                  <GeneralForm />
                </Suspense>
              </Route>
              <Route path="/form/:id/edit">
                <Suspense fallback={<Loading />}>
                  <GeneralForm />
                </Suspense>
              </Route>
              <Route path="/form/:id">
                <Suspense fallback={<Loading />}>
                  <GeneralForm />
                </Suspense>
              </Route>
              <Route path="/settings" component={Settings} />
              <Route>
                <p>404</p>
              </Route>
            </Switch>
          </DashboardLayout>
        </Route>
        <Route>
          <p>404</p>
        </Route>
      </Switch>
    );
  }

  if (userOrganization !== undefined && userOrganization.includes(kocOrganization)) {
    return (
      <Switch>
        <Route path="/exports/previews/:previewName/:id" component={Preview} />
        <Route path="/preview/verify" component={VerifyFiles} />
        <Route exact path="/login" component={Login} />
        <Route path="/kyc/login" component={KycLogin} />
        {/* <Route path="/kyc/login" component={InviteLogin} /> */}
        <Route path="/kyc/registration" component={InviteSignup} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/users/password/reset" component={ResetPassword} />
        <Route path="/permission/approve" component={AcceptCard} />
        <Route path="/">
          <DashboardLayout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/dashboard" component={OnboardingDashboard} />
              {isAdmin && <Route path="/dashboard/sources/:id/logs" component={DashboardSourceLogs} />}
              {isAdmin && <Route path="/dashboard/sources/:id" component={DashboardSourceDetail} />}
              {isAdmin && <Route exact path="/dashboard/sources" component={DashboardSourceList} />}
              {isAdmin && <Route path="/dashboard/media" component={DashboardMediaList} />}

              <Route path="/end-use-certificate" component={EndUseCertificate} />
              <Route path="/onboarding">
                <Suspense fallback={<Loading />}>
                  <InviteHistory />
                </Suspense>
              </Route>
              <Route exact path="/form/">
                <Suspense fallback={<Loading />}>
                  <GeneralForm />
                </Suspense>
              </Route>
              {/* organization chart */}
              <Route path="/form/chart/network/:id">
                <Suspense fallback={<Loading />}>
                  <NetworkScheme />
                </Suspense>
              </Route>
              <Route path="/form/chart/1/:id">
                <Suspense fallback={<Loading />}>
                  <OrgChart2 />
                </Suspense>
              </Route>
              <Route path="/form/chart/:id">
                <Suspense fallback={<Loading />}>
                  <OrgChart />
                </Suspense>
              </Route>
              {/* organization chart */}
              <Route path="/form/:id">
                <Suspense fallback={<Loading />}>
                  <GeneralForm />
                </Suspense>
              </Route>

              {/* Third Party Management - Start */}
              <Route path="/third-party-management/form/:id">
                <Suspense fallback={<Loading />}>
                  <ThirdPartyForm />
                </Suspense>
              </Route>
              <Route path="/third-party-management">
                <Suspense fallback={<Loading />}>
                  <ThirdPartyInviteHistory />
                </Suspense>
              </Route>
              {/* Third Party Management - End */}
              {/* Transaction Monitoring - Start */}
              <Route path="/transaction-monitoring">
                <Suspense fallback={<Loading />}>
                  <TransactionHistory />
                </Suspense>
              </Route>
              {/* Transaction Monitoring - End */}

              <Route path="/notifications" component={Notifications} />
              <Route path="/users" component={Users} />
              <Route path="/saved-reports" component={SavedReports} />
              <Route path="/settings" component={Settings} />
              {/* Automated Lists */}
              <Route path="/automated-lists/create">
                <CreateAutomatedList typeId={3} />
              </Route>
              <Route path="/automated-lists">
                <AutomatedListsLayout typeId={3} />
              </Route>
              <Route>
                <p>404</p>
              </Route>
            </Switch>
          </DashboardLayout>
        </Route>
        <Route>
          <p>404</p>
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/exports/previews/:previewName/:id" component={Preview} />
      <Route path="/preview/verify" component={VerifyFiles} />
      <Route exact path="/login" component={Login} />
      <Route path="/kyc/login" component={KycLogin} />
      {/* <Route path="/kyc/login" component={InviteLogin} /> */}
      <Route path="/kyc/registration" component={InviteSignup} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route path="/users/password/reset" component={ResetPassword} />
      <Route path="/permission/approve" component={AcceptCard} />
      <Route path="/">
        <DashboardLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={DashboardMain} />
            {(isAdmin || isBrisa) && <Route path="/dashboard/sources/:id/logs" component={DashboardSourceLogs} />}
            {(isAdmin || isBrisa) && <Route path="/dashboard/sources/:id" component={DashboardSourceDetail} />}
            {(isAdmin || isBrisa) && <Route exact path="/dashboard/sources" component={DashboardSourceList} />}
            {isAdmin && <Route exact path="/dashboard/media" component={DashboardMediaList} />}

            <Route path="/end-use-certificate" component={EndUseCertificate} />
            <Route path="/onboarding">
              <Suspense fallback={<Loading />}>
                <InviteHistory />
              </Suspense>
            </Route>
            <Route exact path="/form/">
              <Suspense fallback={<Loading />}>
                <GeneralForm />
              </Suspense>
            </Route>
            {/* organization chart */}
            <Route path="/form/chart/network/:id">
              <Suspense fallback={<Loading />}>
                <NetworkScheme />
              </Suspense>
            </Route>
            <Route path="/form/chart/1/:id">
              <Suspense fallback={<Loading />}>
                <OrgChart2 />
              </Suspense>
            </Route>
            <Route path="/form/chart/:id">
              <Suspense fallback={<Loading />}>
                <OrgChart />
              </Suspense>
            </Route>
            {/* organization chart */}
            <Route path="/form/:id">
              <Suspense fallback={<Loading />}>
                <GeneralForm />
              </Suspense>
            </Route>

            {/* Third Party Management - Start */}
            <Route path="/third-party-management/form/:id">
              <Suspense fallback={<Loading />}>
                <ThirdPartyForm />
              </Suspense>
            </Route>
            <Route path="/third-party-management">
              <Suspense fallback={<Loading />}>
                <ThirdPartyInviteHistory />
              </Suspense>
            </Route>
            {/* Third Party Management - End */}
            {/* Transaction Monitoring - Start */}
            <Route path="/transaction-monitoring">
              <Suspense fallback={<Loading />}>
                <TransactionHistory />
              </Suspense>
            </Route>
            {/* Transaction Monitoring - End */}
            <Route path="/search" component={Search} />
            <Route path="/search-history" component={SearchHistory} />
            <Route path="/lists/create" component={CreateList} />
            <Route path="/lists" component={ListsLayout} />

            <Route path="/screening/:screeningId/client/create" component={ClientCreate} />
            <Route path="/screening/:screeningId/client/:clientId" component={ClientDetail} />
            <Route path="/screening/:screeningId/client" component={ClientList} />
            <Route path="/screening/create" component={ScreeningCreate} />
            <Route path="/screening/:screeningId" component={ScreeningDetail} />
            <Route exact path="/screening" component={ScreeningList} />

            <Route path="/map" component={SanctionMap} />
            <Route path="/corruption-map" component={CorruptionMap} />
            <Route path="/world-factbook-map" component={FactBookMap} />
            <Route path="/aml-index" component={AmlIndexMap} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/users" component={Users} />
            <Route path="/saved-reports" component={SavedReports} />
            <Route exact path="/settings" component={Settings} />
            {true && (
              <>
                {/* Integrated Lists */}
                {/* <Route path="/integrated-lists/table/:id" component={IntegratedListTable} />
                <Route exact path="/integrated-lists/create" component={CreateIntegratedList} />
                <Route path="/integrated-lists" component={IntegratedListsLayout} /> */}
                <Route path="/integrated-lists/create">
                  <CreateAutomatedList typeId={2} />
                </Route>
                <Route path="/integrated-lists">
                  <AutomatedListsLayout typeId={2} />
                </Route>
                {/* Automated Lists */}
                <Route path="/automated-lists/create">
                  <CreateAutomatedList typeId={3} />
                </Route>
                <Route path="/automated-lists">
                  <AutomatedListsLayout typeId={3} />
                </Route>
              </>
            )}
            <Route>
              <p>404</p>
            </Route>
          </Switch>
        </DashboardLayout>
      </Route>
      <Route>
        <p>404</p>
      </Route>
    </Switch>
  );
}

export default App;
