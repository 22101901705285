import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container, Row, Col, Spinner,
} from 'reactstrap';
import { useAPI } from '../../services/api';
import { loadListItem } from '../../store/reducers/automated-lists';

import CardWelcome from '../../components/CardWelcome';
import CardListDetails from './CardListDetails';
import Timeline from './Timeline';

import CardProfileList from './CardProfileList';
import CancelConfirmModal from '../../components/CancelConfirmModal';

import '../../assets/css/lists.css';
import { changeRoute, resetRoute } from '../../store/reducers/route';

function ListDetailLayout() {
  const api = useAPI();

  const { id } = useParams();
  const isSubUser = useSelector((state) => state.auth.user.role === 1);
  const history = useHistory();
  const list = useSelector(({ automatedLists }) => automatedLists
    .data.find((l) => l.id === parseInt(id, 10)));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const details = list?.details ?? null;
  const [cantEdit, setCantEdit] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [progress, setProgress] = useState(100);

  const checkState = async () => {
    const { data: { progress: progrss } } = await api.getAutomatedStates(id);
    setProgress(parseInt(progrss, 10));
  };

  const getPerms = async (lId) => {
    const {
      data: {
        list_permissions: listPermissions,
        user_permissions: userPermissions,
      },
    } = await api.getAutomatedListPermissions(lId);

    if (isSubUser) {
      let cantEditStatus = true;
      // eslint-disable-next-line
      if(listPermissions?.permissions?.can_read && listPermissions?.permissions?.can_write) {
        cantEditStatus = false;
        // eslint-disable-next-line
      } else if (userPermissions?.read_all_lists && userPermissions?.write_all_lists) {
        cantEditStatus = false;
      }
      setCantEdit(cantEditStatus);
    }
  };

  useEffect(() => {
    if (!details) {
      setLoading(true);
      dispatch(loadListItem(id, history));
    } else {
      setLoading(false);
      let routeText = 'Automated-Lists ';
      if (list.type_id === 3) routeText = 'Automated-Lists / ';
      if (list.type_id === 2) routeText = 'Integrated-Lists / ';
      dispatch(changeRoute(routeText));
      dispatch(changeRoute(routeText, list.name));
    }
    getPerms(id);
    return () => {
      dispatch(resetRoute());
    };
  }, [dispatch, history, id, details]);

  useEffect(() => {
    checkState();
    const loadInterval = setInterval(async () => {
      const { data: { states, progress: progrss } } = await api.getAutomatedStates(id);
      setProgress(progrss);
      const searching = states.filter((s) => {
        if (s.toLowerCase() === 'not searched' || s.toLowerCase() === 'searching') {
          return true;
        }
        return false;
      });
      if (searching.length === 0) {
        setProgress(100);
        dispatch(loadListItem(id));
        clearInterval(loadInterval);
      }

      // if (searching.length === 0) {
      //   dispatch(loadListItem(listId));
      //   clearInterval(loadInterval);
      // }
    }, 5000);
    return () => {
      clearInterval(loadInterval);
    };
  }, [id, details, loadListItem, list]);

  const requestAccess = async () => {
    try {
      await api.requestAccessToAutomatedList(list.id);
    } catch (error) {
      console.log(error);
    }
    setConfirmToggle(false);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="mr-2" color="secondary" />
      </div>
    );
  }

  return (
    <Container fluid>
      <CancelConfirmModal
        isOpen={confirmToggle}
        onConfirm={requestAccess}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn={!cantEdit}
        selectedName
        cantEdit={cantEdit}
      >
        {cantEdit ? 'You do not have required permission to edit.' : 'Are you sure you want to delete' }
        {' '}
        {cantEdit ? '' : 'If you say yes, you cannot undo this step.'}
      </CancelConfirmModal>
      <Row className="mt-1">
        <Col md="5">
          <CardWelcome
            listId={list.id}
            listName={list.name}
            creatorName={list.creator_name}
          />
          <CardListDetails
            disabled={progress !== 100}
            listType={list.type_id}
            listName={list.name}
            sensivity={list.sensivity}
            listId={list.id}
            options={list.options}
            searchOptions={list.search_options}
            score={list.score}
            type={list.type}
            url={list.url}
            tagColumnName={list.data_column}
            cantEdit={cantEdit}
            requestAccess={requestAccess}
          />
          <Timeline listId={parseInt(id, 10)} listData={list} />
        </Col>

        <Col md="7">
          <CardProfileList
            listId={list.id}
            listData={list}
            cantEdit={cantEdit}
            requestAccess={requestAccess}
            requestAccessToggle={setConfirmToggle}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ListDetailLayout;
