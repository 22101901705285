import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Label, Row,
} from 'reactstrap';

function EndUse({
  hideNavButtons, toggleTabProgress
  ,
}) {
  const [endUse1, setEndUse1] = useState(null);
  const [endUse2, setEndUse2] = useState(null);
  const [endUse3, setEndUse3] = useState(null);
  return (
    <Form
      id="general-information"
      className="px-5"
      onSubmit={(e) => toggleTabProgress(5, e)}
    >
      <Row className="d-flex align-items-center form-header">
        <Col className="d-flex align-items-center">
          {hideNavButtons && <span className="step-number-round mr-2">01</span>}
          End-Use
        </Col>
      </Row>
      <Row className="mt-30px">
        <Col lg="4">
          <Label className="label-header" for="entityTitle">
            Intended end-use of items (
            <b>section B</b>
            )
          </Label>
        </Col>
        <Col lg="8">
          <textarea id="1" className="form-control input-text" rows="2" />
        </Col>
      </Row>
      <Row
        className="mt-30px"
      >
        <Col lg="12">
          <Label className="label-header" for="isEntityPublicOwned">
            We (I) certify that the items (
            <b>section B</b>
            ) and any replica thereof (in case of technology
            also goods derived from derived goods of the items)
            {' '}
            <b>will not be used</b>
            , in their entirety
            or in part, in connection with
            <ul>
              <li>
                the development, production, handling, operation, maintenance, storage, detection,
                identification or dissemination of chemical, biological, radiological or
                nuclear weapons / other explosive devices or the development, production,
                maintenance or storage of missiles
                / other systems capable of delivering such weapons, or
              </li>
              <li>
                the development, production, handling, operation, maintenance or storage of cluster
                munitions or anti-personnel mines or
              </li>
              <li>Integer molestie lorem at massa</li>
              <li>human rights violations. </li>
            </ul>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg="8" />
        <Col lg="4" className="d-flex input-text">
          <div
            className="custom-control custom-checkbox"

          >
            <input
              name="endUse2"
              id="endUse2-Yes"
              type="checkbox"
              className="custom-control-input"
              checked={endUse2 !== null && endUse2}
              onChange={() => {
                setEndUse2(true);
              }}
            />
            <label htmlFor="endUse2-Yes" className="custom-control-label">
              Yes
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-3">
            <input
              name="endUse2"
              id="endUse2-No"
              type="checkbox"
              className="custom-control-input"
              checked={endUse2 !== null && !endUse2}
              onChange={() => {
                setEndUse2(false);
              }}
            />
            <label htmlFor="endUse2-No" className="custom-control-label ml-16">
              No
            </label>
          </div>
        </Col>
      </Row>

      <Row
        className="mt-30px"
      >
        <Col lg="8">
          <Label className="label-header" for="isEntityPublicOwned">
            The end-user is part of armed forces or internal security forces.
          </Label>
        </Col>
        <Col
          lg="4"
          className="d-flex input-text"
          style={{
            marginTop: '3.5px',
          }}
        >
          <div className="custom-control custom-checkbox">
            <input
              name="endUse1"
              id="endUse1-Yes"
              type="checkbox"
              className="custom-control-input"
              checked={endUse1 !== null && endUse1}
              onChange={() => {
                setEndUse1(true);
              }}
            />
            <label htmlFor="endUse1-Yes" className="custom-control-label">
              Yes
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-3">
            <input
              name="endUse1"
              id="endUse1-No"
              type="checkbox"
              className="custom-control-input"
              checked={endUse1 !== null && !endUse1}
              onChange={() => {
                setEndUse1(false);
              }}
            />
            <label htmlFor="endUse1-No" className="custom-control-label ml-16">
              No
            </label>
          </div>

        </Col>
      </Row>
      <Row
        className="mt-30px"
      >
        <Col lg="8">
          <Label className="label-header" for="isEntityPublicOwned">
            The above-mentioned items and any replica thereof (in case of
            technology also goods derived from derived goods of the items)
            will be used for civil purposes only.
          </Label>
        </Col>
        <Col
          lg="4"
          className="d-flex input-text"
          style={{
            marginTop: '3.5px',
          }}
        >
          <div className="custom-control custom-checkbox">
            <input
              name="endUse3"
              id="endUse3-Yes"
              type="checkbox"
              className="custom-control-input"
              checked={endUse3 !== null && endUse3}
              onChange={() => {
                setEndUse3(true);
              }}
            />
            <label htmlFor="endUse3-Yes" className="custom-control-label">
              Yes
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-3">
            <input
              name="endUse3"
              id="endUse3-No"
              type="checkbox"
              className="custom-control-input"
              checked={endUse3 !== null && !endUse3}
              onChange={() => {
                setEndUse3(false);
              }}
            />
            <label htmlFor="endUse3-No" className="custom-control-label ml-16">
              No
            </label>
          </div>

        </Col>
      </Row>
      {!hideNavButtons && (
        <Row className="mt-60px">
          <Col>
            <div className="pr-0 d-flex">
              <div className="ml-auto d-flex align-items-center">

                <button
                  className="d-flex justify-content-center align-items-center btn btn-outline-primary btn-block waves-effect waves-light mr-3"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '143px',
                    fontSize: '14px',
                  }}

                >
                  Save for later
                </button>

                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary btn-block waves-effect waves-light mt-0"
                  type="submit"
                  style={{
                    height: '49px',
                    width: '100px',
                    fontSize: '14px',
                  }}
                >
                  Next
                  <i
                    className="bx bx-right-arrow-alt ml-1"
                    style={{
                      fontSize: '20px',
                    }}
                  />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  );
}
EndUse.defaultProps = {
  hideNavButtons: false,
};

EndUse.propTypes = {
  hideNavButtons: PropTypes.bool,
  toggleTabProgress: PropTypes.func.isRequired,
};

export default EndUse;
