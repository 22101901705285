import React from 'react';
import PropTypes from 'prop-types';

function ReportLink({ children, isAvailable, url }) {
  if (!isAvailable) {
    return (
      <span className="mr-2">
        {children}
      </span>
    );
  }
  return (
    <a
      href={url}
      className="mr-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

ReportLink.defaultProps = {
  url: null,
};

ReportLink.propTypes = {
  children: PropTypes.node.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

export default ReportLink;
