import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';
import {
  FETCH_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_LISTS_PERMISSION,
} from 'constants/actionTypes/notifications';

export const fetchNotifications = createAsyncThunk(
  FETCH_NOTIFICATIONS,
  async (_, { rejectWithValue }) => {
    const [res, error] = await request(api.getNotifications());

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { notifications, unread } } = res;

    return {
      data: notifications.slice().reverse(),
      unread,
    };
  },
);

export const updateNotification = createAsyncThunk(
  UPDATE_NOTIFICATION,
  async (payload, { rejectWithValue }) => {
    const { id, params } = payload;
    const [,, error] = await request(api.updateNotification(id, params));

    if (error) {
      return rejectWithValue(error);
    }

    return { id, ...params };
  },
);

export const deleteNotification = createAsyncThunk(
  DELETE_NOTIFICATION,
  async (id, { rejectWithValue }) => {
    const [,, error] = await request(api.deleteNotification(id));

    if (error) {
      return rejectWithValue(error);
    }

    return id;
  },
);

export const updateListPermission = createAsyncThunk(
  UPDATE_LISTS_PERMISSION,
  async (payload, { rejectWithValue }) => {
    const { notificationId, listId, permissionId } = payload;
    const [,, error] = await request(api.acceptRequestAccess(listId, permissionId));

    if (error) {
      return rejectWithValue(error);
    }

    return notificationId;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
  unread: 0,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotifications.pending]: (state) => {
      state.pending = true;
      state.error = null;
      state.data = [];
      state.unread = 0;
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      const { data, unread } = action.payload;

      state.pending = false;
      state.data = data;
      state.unread = unread;
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [updateNotification.fulfilled]: (state, action) => {
      const { id, ...params } = action.payload;

      state.data = state.data.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            ...params,
          };
        }
        return item;
      });
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.data = state.data.filter((item) => item?.id !== action.payload);
    },
    [updateListPermission.fulfilled]: (state, action) => {
      const notificationId = action.payload;
      state.data = state.data.map((item) => {
        if (item?.id === notificationId) {
          const notification = { ...item };
          delete notification?.details?.['permission_id'];
          return notification;
        }
        return item;
      });
    },
  },
});

// selector
export const latestNotifications = createSelector(
  (state) => state.notifications.data,
  (data) => data.slice(0, 5),
);

export default notificationsSlice.reducer;
