import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetch',
  async (_, { rejectWithValue }) => {
    const [res, error] = await request(api.getDashboardInfo());

    if (error) {
      return rejectWithValue(error);
    }
    const { data } = res;
    return data;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDashboardData.pending]: (state) => {
      state.pending = true;
      state.data = {};
      state.error = null;
    },
    [fetchDashboardData.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
    [fetchDashboardData.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

// selector
export const dashboardData = createSelector(
  (state) => state.dashboard.data,
  (data) => {
    const listInfo = data?.lists_info;
    const allLists = listInfo?.most_accessed_lists ?? [];
    const totalListCount = listInfo?.list_count ?? 0;
    const totalListProfileCount = data?.clients_info?.total_clients_count ?? 0;
    const totalAccountCount = data?.users_info?.total_accounts_count ?? 0;

    const needActionCount = listInfo?.need_action_count ?? 0;
    const clearCount = listInfo?.clear_count ?? 0;
    const positiveCount = listInfo?.positive_count ?? 0;

    const sourceInfo = data?.sources_info;
    const lastSourceUpdate = sourceInfo?.last_source_update ?? '';
    const averageUpdatingInterval = sourceInfo?.source_update_interval ?? 0;

    return {
      allLists,
      totalListProfileCount,
      totalListCount,
      totalAccountCount,
      needActionCount,
      clearCount,
      positiveCount,
      lastSourceUpdate,
      averageUpdatingInterval,
    };
  },
);

export default dashboardSlice.reducer;
