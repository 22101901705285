export const corruptionMapColumns = [
  {
    name: 'Country Name',
    selector: 'name',
    sortable: true,
    minWidth: '260px',
  },
  {
    name: 'Region',
    selector: 'region',
    sortable: true,
    minWidth: '135px',
  },
  {
    name: 'Score',
    selector: 'score',
    sortable: true,
  },
  {
    name: 'Rank',
    selector: 'rank',
    sortable: true,
  },
  {
    name: 'Risk Evaluation',
    selector: 'risk',
    sortable: true,
    width: '135px',
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
    },
  },
};

export const riskData = [
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/dk.svg',
    alpha3: 'DNK',
    countryName: 'Denmark',
    region: 'Europe',
    score: '5.41',
    rank: '1',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/no.svg',
    alpha3: 'NOR',
    countryName: 'Norway',
    region: 'Europe',
    score: '5.41',
    rank: '2',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/fi.svg',
    alpha3: 'FIN',
    countryName: 'Finland',
    region: 'Europe',
    score: '5.74',
    rank: '3',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/se.svg',
    alpha3: 'SWE',
    countryName: 'Sweden',
    region: 'Europe',
    score: '8.80',
    rank: '4',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/nz.svg',
    alpha3: 'NZL',
    countryName: 'New Zealand',
    region: 'Oceania',
    score: '9.46',
    rank: '5',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ee.svg',
    alpha3: 'EST',
    countryName: 'Estonia',
    region: 'Europe',
    score: '10.90',
    rank: '6',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/nl.svg',
    alpha3: 'NLD',
    countryName: 'Netherlands',
    region: 'Europe',
    score: '12.84',
    rank: '7',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ch.svg',
    alpha3: 'CHE',
    countryName: 'Switzerland',
    region: 'Europe',
    score: '14.19',
    rank: '8',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lu.svg',
    alpha3: 'LUX',
    countryName: 'Luxembourg',
    region: 'Europe',
    score: '14.99',
    rank: '9',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/au.svg',
    alpha3: 'AUS',
    countryName: 'Australia',
    region: 'Oceania',
    score: '15.67',
    rank: '10',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gb.svg',
    alpha3: 'GBR',
    countryName: 'United Kingdom',
    region: 'Europe',
    score: '15.76',
    rank: '11',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/de.svg',
    alpha3: 'DEU',
    countryName: 'Germany',
    region: 'Europe',
    score: '16.11',
    rank: '12',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/at.svg',
    alpha3: 'AUT',
    countryName: 'Austria',
    region: 'Europe',
    score: '16.35',
    rank: '13',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/is.svg',
    alpha3: 'ISL',
    countryName: 'Iceland',
    region: 'Europe',
    score: '16.43',
    rank: '14',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/be.svg',
    alpha3: 'BEL',
    countryName: 'Belgium',
    region: 'Europe',
    score: '17.69',
    rank: '15',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ca.svg',
    alpha3: 'CAN',
    countryName: 'Canada',
    region: 'North America',
    score: '17.84',
    rank: '16',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sg.svg',
    alpha3: 'SGP',
    countryName: 'Singapore',
    region: 'Asia',
    score: '18.70',
    rank: '17',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/jp.svg',
    alpha3: 'JPN',
    countryName: 'Japan',
    region: 'Asia',
    score: '19.12',
    rank: '18',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pt.svg',
    alpha3: 'PRT',
    countryName: 'Portugal',
    region: 'Europe',
    score: '21.78',
    rank: '19',
    risk: 'Very Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/il.svg',
    alpha3: 'ISR',
    countryName: 'Israel',
    region: 'Asia',
    score: '22.08',
    rank: '20',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/fr.svg',
    alpha3: 'FRA',
    countryName: 'France',
    region: 'Europe',
    score: '22.31',
    rank: '21',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/uy.svg',
    alpha3: 'URY',
    countryName: 'Uruguay',
    region: 'South America',
    score: '23.63',
    rank: '22',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ie.svg',
    alpha3: 'IRL',
    countryName: 'Ireland',
    region: 'Europe',
    score: '23.68',
    rank: '23',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/es.svg',
    alpha3: 'ESP',
    countryName: 'Spain',
    region: 'Europe',
    score: '23.68',
    rank: '24',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/hk.svg',
    alpha3: 'HKG',
    countryName: 'Hong Kong SAR, China',
    region: 'Asia',
    score: '23.69',
    rank: '25',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/si.svg',
    alpha3: 'SVN',
    countryName: 'Slovenia',
    region: 'Europe',
    score: '24.11',
    rank: '26',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cl.svg',
    alpha3: 'CHL',
    countryName: 'Chile',
    region: 'South America',
    score: '24.42',
    rank: '27',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kr.svg',
    alpha3: 'KOR',
    countryName: 'Korea, Rep.',
    region: 'Asia',
    score: '25.17',
    rank: '28',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cz.svg',
    alpha3: 'CZE',
    countryName: 'Czech Republic',
    region: 'Europe',
    score: '25.87',
    rank: '29',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/li.svg',
    alpha3: 'LIE',
    countryName: 'Liechtenstein',
    region: 'Europe',
    score: '25.90',
    rank: '30',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/us.svg',
    alpha3: 'USA',
    countryName: 'United States',
    region: 'North America',
    score: '26.11',
    rank: '31',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gl.svg',
    alpha3: 'GRL',
    countryName: 'Greenland',
    region: 'Europe',
    score: '28.75',
    rank: '32',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cy.svg',
    alpha3: 'CYP',
    countryName: 'Cyprus',
    region: 'Europe',
    score: '28.88',
    rank: '33',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pl.svg',
    alpha3: 'POL',
    countryName: 'Poland',
    region: 'Europe',
    score: '29.06',
    rank: '34',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bt.svg',
    alpha3: 'BTN',
    countryName: 'Bhutan',
    region: 'Asia',
    score: '29.32',
    rank: '35',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lt.svg',
    alpha3: 'LTU',
    countryName: 'Lithuania',
    region: 'Europe',
    score: '29.35',
    rank: '36',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lv.svg',
    alpha3: 'LVA',
    countryName: 'Latvia',
    region: 'Europe',
    score: '29.61',
    rank: '37',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cr.svg',
    alpha3: 'CRI',
    countryName: 'Costa Rica',
    region: 'North America',
    score: '29.70',
    rank: '38',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/dm.svg',
    alpha3: 'DMA',
    countryName: 'Dominica',
    region: 'North America',
    score: '30.42',
    rank: '39',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sk.svg',
    alpha3: 'SVK',
    countryName: 'Slovak Republic',
    region: 'Europe',
    score: '31.28',
    rank: '40',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/it.svg',
    alpha3: 'ITA',
    countryName: 'Italy',
    region: 'Europe',
    score: '31.56',
    rank: '41',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gr.svg',
    alpha3: 'GRC',
    countryName: 'Greece',
    region: 'Europe',
    score: '32.09',
    rank: '42',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lc.svg',
    alpha3: 'LCA',
    countryName: 'St. Lucia',
    region: 'North America',
    score: '32.25',
    rank: '43',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sc.svg',
    alpha3: 'SYC',
    countryName: 'Seychelles',
    region: 'Africa',
    score: '32.46',
    rank: '44',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mt.svg',
    alpha3: 'MLT',
    countryName: 'Malta',
    region: 'Europe',
    score: '32.90',
    rank: '45',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bm.svg',
    alpha3: 'BMU',
    countryName: 'Bermuda',
    region: 'North America',
    score: '33.21',
    rank: '46',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gd.svg',
    alpha3: 'GRD',
    countryName: 'Grenada',
    region: 'North America',
    score: '33.66',
    rank: '47',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bn.svg',
    alpha3: 'BRN',
    countryName: 'Brunei Darussalam',
    region: 'Asia',
    score: '33.73',
    rank: '48',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/fj.svg',
    alpha3: 'FJI',
    countryName: 'Fiji',
    region: 'Oceania',
    score: '34.05',
    rank: '49',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ge.svg',
    alpha3: 'GEO',
    countryName: 'Georgia',
    region: 'Europe',
    score: '34.06',
    rank: '50',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cv.svg',
    alpha3: 'CPV',
    countryName: 'Cabo Verde',
    region: 'Africa',
    score: '34.48',
    rank: '51',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/hr.svg',
    alpha3: 'HRV',
    countryName: 'Croatia',
    region: 'Europe',
    score: '34.49',
    rank: '52',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/vi.svg',
    alpha3: 'VIR',
    countryName: 'Virgin Islands (U.S.)',
    region: 'North America',
    score: '34.66',
    rank: '53',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mu.svg',
    alpha3: 'MUS',
    countryName: 'Mauritius',
    region: 'Africa',
    score: '34.86',
    rank: '54',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bw.svg',
    alpha3: 'BWA',
    countryName: 'Botswana',
    region: 'Africa',
    score: '34.99',
    rank: '55',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ag.svg',
    alpha3: 'ATG',
    countryName: 'Antigua and Barbuda',
    region: 'North America',
    score: '35.43',
    rank: '56',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/za.svg',
    alpha3: 'ZAF',
    countryName: 'South Africa',
    region: 'Africa',
    score: '35.43',
    rank: '57',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ae.svg',
    alpha3: 'ARE',
    countryName: 'United Arab Emirates',
    region: 'Asia',
    score: '35.83',
    rank: '58',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bg.svg',
    alpha3: 'BGR',
    countryName: 'Bulgaria',
    region: 'Europe',
    score: '36.59',
    rank: '59',
    risk: 'Low',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tw.svg',
    alpha3: 'TWN',
    countryName: 'Taiwan',
    region: 'Asia',
    score: '37.62',
    rank: '60',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ro.svg',
    alpha3: 'ROU',
    countryName: 'Romania',
    region: 'Europe',
    score: '38.53',
    rank: '61',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mo.svg',
    alpha3: 'MAC',
    countryName: 'Macao SAR, China',
    region: 'Asia',
    score: '38.86',
    rank: '62',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ki.svg',
    alpha3: 'KIR',
    countryName: 'Kiribati',
    region: 'Oceania',
    score: '39.52',
    rank: '63',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ws.svg',
    alpha3: 'WSM',
    countryName: 'Samoa',
    region: 'Oceania',
    score: '40.19',
    rank: '64',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ar.svg',
    alpha3: 'ARG',
    countryName: 'Argentina',
    region: 'South America',
    score: '40.34',
    rank: '65',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/jo.svg',
    alpha3: 'JOR',
    countryName: 'Jordan',
    region: 'Asia',
    score: '40.41',
    rank: '66',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/qa.svg',
    alpha3: 'QAT',
    countryName: 'Qatar',
    region: 'Asia',
    score: '40.51',
    rank: '67',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/vc.svg',
    alpha3: 'VCT',
    countryName: 'St. Vincent and the Grenadines',
    region: 'North America',
    score: '40.64',
    rank: '68',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/fm.svg',
    alpha3: 'FSM',
    countryName: 'Micronesia, Fed. Sts.',
    region: 'Oceania',
    score: '41.60',
    rank: '69',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/na.svg',
    alpha3: 'NAM',
    countryName: 'Namibia',
    region: 'Africa',
    score: '41.74',
    rank: '70',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/vu.svg',
    alpha3: 'VUT',
    countryName: 'Vanuatu',
    region: 'Oceania',
    score: '42.02',
    rank: '71',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/hu.svg',
    alpha3: 'HUN',
    countryName: 'Hungary',
    region: 'Europe',
    score: '42.17',
    rank: '72',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/my.svg',
    alpha3: 'MYS',
    countryName: 'Malaysia',
    region: 'Asia',
    score: '42.96',
    rank: '73',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/rw.svg',
    alpha3: 'RWA',
    countryName: 'Rwanda',
    region: 'Africa',
    score: '43.02',
    rank: '74',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tt.svg',
    alpha3: 'TTO',
    countryName: 'Trinidad and Tobago',
    region: 'North America',
    score: '43.04',
    rank: '75',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/co.svg',
    alpha3: 'COL',
    countryName: 'Colombia',
    region: 'South America',
    score: '43.11',
    rank: '76',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ky.svg',
    alpha3: 'CYM',
    countryName: 'Cayman Islands',
    region: 'North America',
    score: '43.25',
    rank: '77',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/me.svg',
    alpha3: 'MNE',
    countryName: 'Montenegro',
    region: 'Europe',
    score: '43.26',
    rank: '78',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/am.svg',
    alpha3: 'ARM',
    countryName: 'Armenia',
    region: 'Europe',
    score: '43.98',
    rank: '79',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bs.svg',
    alpha3: 'BHS',
    countryName: 'Bahamas, The',
    region: 'North America',
    score: '44.11',
    rank: '80',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mk.svg',
    alpha3: 'MKD',
    countryName: 'Macedonia, FYR',
    region: 'Europe',
    score: '44.13',
    rank: '81',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tv.svg',
    alpha3: 'TUV',
    countryName: 'Tuvalu',
    region: 'Oceania',
    score: '44.29',
    rank: '82',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/jm.svg',
    alpha3: 'JAM',
    countryName: 'Jamaica',
    region: 'North America',
    score: '44.47',
    rank: '83',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bh.svg',
    alpha3: 'BHR',
    countryName: 'Bahrain',
    region: 'Asia',
    score: '44.52',
    rank: '84',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/br.svg',
    alpha3: 'BRA',
    countryName: 'Brazil',
    region: 'South America',
    score: '44.61',
    rank: '85',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sa.svg',
    alpha3: 'SAU',
    countryName: 'Saudi Arabia',
    region: 'Asia',
    score: '44.93',
    rank: '86',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kn.svg',
    alpha3: 'KNA',
    countryName: 'St. Kitts and Nevis',
    region: 'North America',
    score: '44.93',
    rank: '87',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bb.svg',
    alpha3: 'BRB',
    countryName: 'Barbados',
    region: 'North America',
    score: '45.38',
    rank: '88',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sv.svg',
    alpha3: 'SLV',
    countryName: 'El Salvador',
    region: 'North America',
    score: '45.92',
    rank: '89',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pe.svg',
    alpha3: 'PER',
    countryName: 'Peru',
    region: 'South America',
    score: '46.37',
    rank: '90',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tn.svg',
    alpha3: 'TUN',
    countryName: 'Tunisia',
    region: 'Africa',
    score: '46.43',
    rank: '91',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gh.svg',
    alpha3: 'GHA',
    countryName: 'Ghana',
    region: 'Africa',
    score: '46.53',
    rank: '92',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ec.svg',
    alpha3: 'ECU',
    countryName: 'Ecuador',
    region: 'South America',
    score: '46.80',
    rank: '93',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bz.svg',
    alpha3: 'BLZ',
    countryName: 'Belize',
    region: 'North America',
    score: '46.80',
    rank: '94',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/om.svg',
    alpha3: 'OMN',
    countryName: 'Oman',
    region: 'Asia',
    score: '47.18',
    rank: '95',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lk.svg',
    alpha3: 'LKA',
    countryName: 'Sri Lanka',
    region: 'Asia',
    score: '47.20',
    rank: '96',
    risk: 'Medium',
  },
  {
    flag: 'https://images.jifo.co/40314066_1604077831032.png',
    alpha3: 'XKX',
    countryName: 'Kosovo',
    region: 'Europe',
    score: '47.87',
    rank: '97',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pw.svg',
    alpha3: 'PLW',
    countryName: 'Palau',
    region: 'Oceania',
    score: '47.99',
    rank: '98',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/do.svg',
    alpha3: 'DOM',
    countryName: 'Dominican Republic',
    region: 'North America',
    score: '48.03',
    rank: '99',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/id.svg',
    alpha3: 'IDN',
    countryName: 'Indonesia',
    region: 'Asia',
    score: '48.07',
    rank: '100',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kz.svg',
    alpha3: 'KAZ',
    countryName: 'Kazakhstan',
    region: 'Asia',
    score: '48.54',
    rank: '101',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/in.svg',
    alpha3: 'IND',
    countryName: 'India',
    region: 'Asia',
    score: '48.57',
    rank: '102',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gt.svg',
    alpha3: 'GTM',
    countryName: 'Guatemala',
    region: 'North America',
    score: '48.58',
    rank: '103',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mh.svg',
    alpha3: 'MHL',
    countryName: 'Marshall Islands',
    region: 'Oceania',
    score: '48.60',
    rank: '104',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/th.svg',
    alpha3: 'THA',
    countryName: 'Thailand',
    region: 'Asia',
    score: '48.62',
    rank: '105',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gy.svg',
    alpha3: 'GUY',
    countryName: 'Guyana',
    region: 'South America',
    score: '48.66',
    rank: '106',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/rs.svg',
    alpha3: 'SRB',
    countryName: 'Serbia',
    region: 'Europe',
    score: '48.83',
    rank: '107',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gm.svg',
    alpha3: 'GMB',
    countryName: 'Gambia, The',
    region: 'Africa',
    score: '48.98',
    rank: '108',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pa.svg',
    alpha3: 'PAN',
    countryName: 'Panama',
    region: 'North America',
    score: '49.44',
    rank: '109',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tr.svg',
    alpha3: 'TUR',
    countryName: 'Turkey',
    region: 'Asia',
    score: '49.69',
    rank: '110',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ph.svg',
    alpha3: 'PHL',
    countryName: 'Philippines',
    region: 'Asia',
    score: '50.01',
    rank: '111',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mn.svg',
    alpha3: 'MNG',
    countryName: 'Mongolia',
    region: 'Asia',
    score: '50.05',
    rank: '112',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ba.svg',
    alpha3: 'BIH',
    countryName: 'Bosnia and Herzegovina',
    region: 'Europe',
    score: '51.22',
    rank: '113',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mx.svg',
    alpha3: 'MEX',
    countryName: 'Mexico',
    region: 'North America',
    score: '51.60',
    rank: '114',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ma.svg',
    alpha3: 'MAR',
    countryName: 'Morocco',
    region: 'Africa',
    score: '51.66',
    rank: '115',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/st.svg',
    alpha3: 'STP',
    countryName: 'Sao Tome and Principe',
    region: 'Africa',
    score: '51.71',
    rank: '116',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sn.svg',
    alpha3: 'SEN',
    countryName: 'Senegal',
    region: 'Africa',
    score: '51.82',
    rank: '117',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/eg.svg',
    alpha3: 'EGY',
    countryName: 'Egypt, Arab Rep.',
    region: 'Africa',
    score: '51.93',
    rank: '118',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/md.svg',
    alpha3: 'MDA',
    countryName: 'Moldova',
    region: 'Europe',
    score: '52.12',
    rank: '119',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mw.svg',
    alpha3: 'MWI',
    countryName: 'Malawi',
    region: 'Africa',
    score: '52.19',
    rank: '120',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/nr.svg',
    alpha3: 'NRU',
    countryName: 'Nauru',
    region: 'Oceania',
    score: '52.23',
    rank: '121',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mv.svg',
    alpha3: 'MDV',
    countryName: 'Maldives',
    region: 'Asia',
    score: '52.66',
    rank: '122',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tl.svg',
    alpha3: 'TLS',
    countryName: 'Timor-Leste',
    region: 'Asia',
    score: '52.80',
    rank: '123',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ls.svg',
    alpha3: 'LSO',
    countryName: 'Lesotho',
    region: 'Africa',
    score: '53.33',
    rank: '124',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sb.svg',
    alpha3: 'SLB',
    countryName: 'Solomon Islands',
    region: 'Oceania',
    score: '53.42',
    rank: '125',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bj.svg',
    alpha3: 'BEN',
    countryName: 'Benin',
    region: 'Africa',
    score: '53.70',
    rank: '126',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kw.svg',
    alpha3: 'KWT',
    countryName: 'Kuwait',
    region: 'Asia',
    score: '54.12',
    rank: '127',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tz.svg',
    alpha3: 'TZA',
    countryName: 'Tanzania',
    region: 'Africa',
    score: '54.39',
    rank: '128',
    risk: 'Medium',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/hn.svg',
    alpha3: 'HND',
    countryName: 'Honduras',
    region: 'North America',
    score: '54.59',
    rank: '129',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ru.svg',
    alpha3: 'RUS',
    countryName: 'Russian Federation',
    region: 'Europe',
    score: '54.63',
    rank: '130',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/by.svg',
    alpha3: 'BLR',
    countryName: 'Belarus',
    region: 'Europe',
    score: '54.89',
    rank: '131',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ua.svg',
    alpha3: 'UKR',
    countryName: 'Ukraine',
    region: 'Europe',
    score: '54.93',
    rank: '132',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sr.svg',
    alpha3: 'SUR',
    countryName: 'Suriname',
    region: 'South America',
    score: '55.36',
    rank: '133',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ga.svg',
    alpha3: 'GAB',
    countryName: 'Gabon',
    region: 'Africa',
    score: '55.49',
    rank: '134',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/np.svg',
    alpha3: 'NPL',
    countryName: 'Nepal',
    region: 'Asia',
    score: '55.61',
    rank: '135',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bf.svg',
    alpha3: 'BFA',
    countryName: 'Burkina Faso',
    region: 'Africa',
    score: '55.76',
    rank: '136',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/az.svg',
    alpha3: 'AZE',
    countryName: 'Azerbaijan',
    region: 'Europe',
    score: '55.82',
    rank: '137',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/zm.svg',
    alpha3: 'ZMB',
    countryName: 'Zambia',
    region: 'Africa',
    score: '55.98',
    rank: '138',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/al.svg',
    alpha3: 'ALB',
    countryName: 'Albania',
    region: 'Europe',
    score: '56.02',
    rank: '139',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cn.svg',
    alpha3: 'CHN',
    countryName: 'China',
    region: 'Asia',
    score: '56.32',
    rank: '140',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pg.svg',
    alpha3: 'PNG',
    countryName: 'Papua New Guinea',
    region: 'Oceania',
    score: '56.51',
    rank: '141',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/dz.svg',
    alpha3: 'DZA',
    countryName: 'Algeria',
    region: 'Africa',
    score: '56.77',
    rank: '142',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/to.svg',
    alpha3: 'TON',
    countryName: 'Tonga',
    region: 'Oceania',
    score: '57.07',
    rank: '143',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/uz.svg',
    alpha3: 'UZB',
    countryName: 'Uzbekistan',
    region: 'Asia',
    score: '57.26',
    rank: '144',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/py.svg',
    alpha3: 'PRY',
    countryName: 'Paraguay',
    region: 'South America',
    score: '57.92',
    rank: '145',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tg.svg',
    alpha3: 'TGO',
    countryName: 'Togo',
    region: 'Africa',
    score: '58.45',
    rank: '146',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mg.svg',
    alpha3: 'MDG',
    countryName: 'Madagascar',
    region: 'Africa',
    score: '58.48',
    rank: '147',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kg.svg',
    alpha3: 'KGZ',
    countryName: 'Kyrgyz Republic',
    region: 'Asia',
    score: '58.99',
    rank: '148',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/vn.svg',
    alpha3: 'VNM',
    countryName: 'Vietnam',
    region: 'Asia',
    score: '58.99',
    rank: '149',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bo.svg',
    alpha3: 'BOL',
    countryName: 'Bolivia',
    region: 'South America',
    score: '59.01',
    rank: '150',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ne.svg',
    alpha3: 'NER',
    countryName: 'Niger',
    region: 'Africa',
    score: '59.40',
    rank: '151',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cu.svg',
    alpha3: 'CUB',
    countryName: 'Cuba',
    region: 'North America',
    score: '59.88',
    rank: '152',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ci.svg',
    alpha3: 'CIV',
    countryName: "Cote d'Ivoire",
    region: 'Africa',
    score: '60.05',
    rank: '153',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lb.svg',
    alpha3: 'LBN',
    countryName: 'Lebanon',
    region: 'Asia',
    score: '60.70',
    rank: '154',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ke.svg',
    alpha3: 'KEN',
    countryName: 'Kenya',
    region: 'Africa',
    score: '60.74',
    rank: '155',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sz.svg',
    alpha3: 'SWZ',
    countryName: 'Swaziland',
    region: 'Africa',
    score: '60.77',
    rank: '156',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/et.svg',
    alpha3: 'ETH',
    countryName: 'Ethiopia',
    region: 'Africa',
    score: '61.11',
    rank: '157',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bd.svg',
    alpha3: 'BGD',
    countryName: 'Bangladesh',
    region: 'Asia',
    score: '61.35',
    rank: '158',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/lr.svg',
    alpha3: 'LBR',
    countryName: 'Liberia',
    region: 'Africa',
    score: '61.60',
    rank: '159',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/pk.svg',
    alpha3: 'PAK',
    countryName: 'Pakistan',
    region: 'Asia',
    score: '61.64',
    rank: '160',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mz.svg',
    alpha3: 'MOZ',
    countryName: 'Mozambique',
    region: 'Africa',
    score: '61.79',
    rank: '161',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ng.svg',
    alpha3: 'NGA',
    countryName: 'Nigeria',
    region: 'Africa',
    score: '61.81',
    rank: '162',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/dj.svg',
    alpha3: 'DJI',
    countryName: 'Djibouti',
    region: 'Africa',
    score: '62.04',
    rank: '163',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/zw.svg',
    alpha3: 'ZWE',
    countryName: 'Zimbabwe',
    region: 'Africa',
    score: '62.16',
    rank: '164',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ni.svg',
    alpha3: 'NIC',
    countryName: 'Nicaragua',
    region: 'North America',
    score: '62.58',
    rank: '165',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tj.svg',
    alpha3: 'TJK',
    countryName: 'Tajikistan',
    region: 'Asia',
    score: '62.93',
    rank: '166',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sl.svg',
    alpha3: 'SLE',
    countryName: 'Sierra Leone',
    region: 'Africa',
    score: '63.19',
    rank: '167',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ao.svg',
    alpha3: 'AGO',
    countryName: 'Angola',
    region: 'Africa',
    score: '63.68',
    rank: '168',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/iq.svg',
    alpha3: 'IRQ',
    countryName: 'Iraq',
    region: 'Asia',
    score: '64.16',
    rank: '169',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/td.svg',
    alpha3: 'TCD',
    countryName: 'Chad',
    region: 'Africa',
    score: '64.33',
    rank: '170',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gn.svg',
    alpha3: 'GIN',
    countryName: 'Guinea',
    region: 'Africa',
    score: '64.61',
    rank: '171',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mr.svg',
    alpha3: 'MRT',
    countryName: 'Mauritania',
    region: 'Africa',
    score: '65.19',
    rank: '172',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ug.svg',
    alpha3: 'UGA',
    countryName: 'Uganda',
    region: 'Africa',
    score: '65.59',
    rank: '173',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/bi.svg',
    alpha3: 'BDI',
    countryName: 'Burundi',
    region: 'Africa',
    score: '65.69',
    rank: '174',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cf.svg',
    alpha3: 'CAF',
    countryName: 'Central African Republic',
    region: 'Africa',
    score: '65.80',
    rank: '175',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/km.svg',
    alpha3: 'COM',
    countryName: 'Comoros',
    region: 'Africa',
    score: '65.87',
    rank: '176',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ml.svg',
    alpha3: 'MLI',
    countryName: 'Mali',
    region: 'Africa',
    score: '66.24',
    rank: '177',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sd.svg',
    alpha3: 'SDN',
    countryName: 'Sudan',
    region: 'Africa',
    score: '66.76',
    rank: '178',
    risk: 'High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cm.svg',
    alpha3: 'CMR',
    countryName: 'Cameroon',
    region: 'Africa',
    score: '66.87',
    rank: '179',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/mm.svg',
    alpha3: 'MMR',
    countryName: 'Myanmar',
    region: 'Asia',
    score: '66.95',
    rank: '180',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/la.svg',
    alpha3: 'LAO',
    countryName: 'Lao PDR',
    region: 'Asia',
    score: '68.14',
    rank: '181',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ve.svg',
    alpha3: 'VEN',
    countryName: 'Venezuela, RB',
    region: 'South America',
    score: '69.24',
    rank: '182',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ht.svg',
    alpha3: 'HTI',
    countryName: 'Haiti',
    region: 'North America',
    score: '70.45',
    rank: '183',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cg.svg',
    alpha3: 'COG',
    countryName: 'Congo, Rep.',
    region: 'Africa',
    score: '70.58',
    rank: '184',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gw.svg',
    alpha3: 'GNB',
    countryName: 'Guinea-Bissau',
    region: 'Africa',
    score: '71.13',
    rank: '185',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/gq.svg',
    alpha3: 'GNQ',
    countryName: 'Equatorial Guinea',
    region: 'Africa',
    score: '71.32',
    rank: '186',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/tm.svg',
    alpha3: 'TKM',
    countryName: 'Turkmenistan',
    region: 'Asia',
    score: '72.10',
    rank: '187',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ir.svg',
    alpha3: 'IRN',
    countryName: 'Iran, Islamic Rep.',
    region: 'Asia',
    score: '72.14',
    rank: '188',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/cd.svg',
    alpha3: 'COD',
    countryName: 'Congo, Dem. Rep.',
    region: 'Africa',
    score: '72.71',
    rank: '189',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/af.svg',
    alpha3: 'AFG',
    countryName: 'Afghanistan',
    region: 'Asia',
    score: '73.12',
    rank: '190',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kh.svg',
    alpha3: 'KHM',
    countryName: 'Cambodia',
    region: 'Asia',
    score: '73.41',
    rank: '191',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/er.svg',
    alpha3: 'ERI',
    countryName: 'Eritrea',
    region: 'Africa',
    score: '74.16',
    rank: '192',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ss.svg',
    alpha3: 'SSD',
    countryName: 'South Sudan',
    region: 'Africa',
    score: '75.56',
    rank: '193',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ly.svg',
    alpha3: 'LBY',
    countryName: 'Libya',
    region: 'Africa',
    score: '76.62',
    rank: '194',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/ye.svg',
    alpha3: 'YEM',
    countryName: 'Yemen, Rep.',
    region: 'Asia',
    score: '77.82',
    rank: '195',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/kp.svg',
    alpha3: 'PRK',
    countryName: 'Korea, Dem. Rep.',
    region: 'Asia',
    score: '83.86',
    rank: '196',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/so.svg',
    alpha3: 'SOM',
    countryName: 'Somalia',
    region: 'Africa',
    score: '85.49',
    rank: '197',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/so.svg',
    alpha3: 'SOM',
    countryName: 'Somalia',
    region: 'Africa',
    score: '85.49',
    rank: '197',
    risk: 'Very High',
  },
  {
    flag: 'https://d3n6z2r41sustx.cloudfront.net/flags_lipis/sy.svg',
    alpha3: 'SYR',
    countryName: 'Syrian Arab Republic',
    region: 'Asia',
    score: '87.56',
    rank: '198',
    risk: 'Very High',
  },
];
