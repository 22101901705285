import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Tabs } from 'antd';

import { fetchList, currentList } from 'store/lists';
import { useLanguage } from 'contexts/LanguageContext';

import { Card } from 'components/ui';
import Profiles from './Profiles';
import Timeline from './Timeline';
import Settings from './Settings';

import * as classes from './index.module.scss';

const { TabPane } = Tabs;

function ScreeningDetail() {
  const dispatch = useDispatch();
  const { screeningId: id } = useParams();
  const { translate } = useLanguage();

  const {
    pending,
    error,
    list: {
      name = '',
      clients_count: clientsCount = 0,
    },
  } = useSelector(currentList);

  useEffect(() => {
    dispatch(fetchList(id));
  }, [dispatch, id]);

  if (error) {
    return <Redirect to="/screening" />;
  }

  return (
    <section className={classes['list-detail']}>
      <div className={classes['list-detail-header']}>
        <h2 className={classes['list-detail-header-name']}>{name}</h2>
        <span className={classes['list-detail-header-profile_count']}>
          {translate('screening.detail.profiles.count', clientsCount)}
        </span>
      </div>
      <Card className={classes['list-detail-card']}>
        <Tabs defaultActiveKey={1}>
          <TabPane tab={translate('screening.detail.profiles.tab')} key="1" disabled={pending}>
            <Profiles />
          </TabPane>
          <TabPane tab={translate('screening.detail.timeline.tab')} key="2" disabled={pending}>
            <Timeline />
          </TabPane>
          <TabPane tab={translate('screening.detail.settings.tab')} key="3" disabled={pending}>
            <Settings />
          </TabPane>
        </Tabs>
      </Card>
    </section>
  );
}

export default ScreeningDetail;
