import Text from './Text';
import Password from './Password';
import Masked from './Masked';
import DateInput from './Date';
import Slider from './Slider';
import Checkbox from './Checkbox';

const Input = Text;
Input.Text = Text;
Input.Password = Password;
Input.Masked = Masked;
Input.Date = DateInput;
Input.Slider = Slider;
Input.Checkbox = Checkbox;

export default Input;
