import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import {
//   Col, Row,
// } from 'reactstrap';
import { treeMaker } from '../../assets/tree-maker/tree_maker-min';
import '../../assets/tree-maker/tree_maker-min.css';
import { useAPI } from '../../services/api';

function assign(obj, prop, value) {
  if (typeof prop === 'string') prop = prop.split('.');

  if (prop.length > 1) {
    const e = prop.shift();
    assign(obj[e] = Object.prototype.toString.call(obj[e]) === '[object Object]'
      ? obj[e]
      : {},
    prop,
    value);
  } else {
    obj[prop[0]] = value;
    return obj;
  }
  return obj;
}
const createDataTree = (dataset) => {
  const hashTable = Object.create(null);
  dataset.forEach((aData) => {
    hashTable[aData.id] = { ...aData, childNodes: [] };
  });
  const dataTree = [];
  let i = 1;
  dataset.forEach((aData) => {
    if (aData.parentId) {
      hashTable[aData.parentId].childNodes
        .push({ ...hashTable[aData.id] });
    } else {
      dataTree.push({ ...hashTable[aData.id], x: i * 600, y: 0 });
      i += 1;
    }
  });
  return dataTree;
};

const treeToArray = (nodes, arr, level = 1, tree, stringLevel = '1') => {
  if (!nodes) {
    return [];
  }
  if (!arr) {
    arr = [];
  }
  if (!tree) {
    tree = {};
  }
  for (let i = 0; i < nodes.length; i += 1) {
    if (nodes[i].parentId === null) {
      arr.push({ ...nodes[i], normalId: 1 });
      tree[1] = {};
    } else {
      arr.push({ ...nodes[i], normalId: level + i });
      tree = assign(tree, `${stringLevel}.${level + i}`, '');
    }
    const c = nodes.filter((n) => nodes[i].parentId === n.parentId).length;
    if (nodes[i].childNodes) {
      treeToArray(nodes[i].childNodes, arr, level + c, tree, `${stringLevel}.${level + i}`);
    }
  }
  return { arr, tree };
};

function OrgChart() {
  const api = useAPI();

  const { id } = useParams();

  const createOrgChart = (elementId, shareHolders = []) => {
    const dataTree = createDataTree(shareHolders);
    console.log(dataTree);
    const { arr: tArray, tree: tr } = treeToArray(dataTree);
    const treeParamsObj = { };
    tArray.map((sH) => {
      treeParamsObj[sH.normalId] = { trad: sH.name };
      return '';
    });
    treeMaker(tr['1'], {
      id: 'test-chart',
      treeParams: treeParamsObj,
      card_click(element) {
        console.log('clicked ', element);
      },
    });
  };

  const getShareHolders = async () => {
    try {
      const {
        data: {
          form: {
            data: {
              entityTitle,
              shareHolders: sHolders,
            },
          },
        },
      } = await api.getForm(id);
      const dataWithTitle = [{
        id: 'main',
        parentId: null,
        name: entityTitle,
        type: 'entity',
        percent: '100',
      }, ...sHolders.map((s) => {
        if (s.parentId === null) return ({ ...s, parentId: 'main' });
        return s;
      })];
      console.log(sHolders);
      // createOrgChart('chart', sHolders);
      createOrgChart('chart', dataWithTitle);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShareHolders();
    window.addEventListener('resize', () => {
      window.location.reload();
    });
  }, []);

  return (
    <div>
      <div id="test-chart" />

      <div id="chart" />
      OrgChart
    </div>
  );
}

export default OrgChart;
