import { notification } from 'antd';

import {
  UPDATE_USER,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_CLIENT,
  UPDATE_LIST,
  FETCH_LIST,
} from 'constants/actionTypes';
import { translate } from 'contexts/LanguageContext';

const getDefaultValue = (item) => item?.default ?? '';

const getDefaultMessage = (messages) => {
  const message = getDefaultValue(messages);

  if (message) return { message };
  return null;
};

const cases = {
  [UPDATE_USER]: (messages, payload) => {
    let message = getDefaultValue(messages);
    if (payload?.password) {
      message = messages.password;
    }
    if (message) return { message };
    return null;
  },
  [UPDATE_NOTIFICATION]: (messages, payload) => {
    let message = getDefaultValue(messages);
    if (payload?.is_read === true) {
      message = messages.is_read;
    }

    if (message) return { message };
    return null;
  },
  [DELETE_NOTIFICATION]: getDefaultMessage,
  [DELETE_CLIENT]: getDefaultMessage,
  [UPDATE_LIST]: getDefaultMessage,
  [FETCH_LIST]: getDefaultMessage,
};

// store => next => action
export const notificationMiddleware = () => (next) => (action) => {
  const currentLanguage = localStorage.getItem('lang');
  const $t = translate(currentLanguage);

  const [actionType, actionStatus] = action.type.split('/');

  if (cases?.[actionType]) {
    const localeMessages = $t(`actions.${actionType}.${actionStatus}`);
    const obj = cases?.[actionType]?.(localeMessages, action.payload);

    if (obj) {
      if (actionStatus === 'fulfilled') {
        notification.success(obj);
      }
      if (actionStatus === 'rejected') {
        notification.error(obj);
      }
    }
  }

  const result = next(action);
  return result;
};

export default notificationMiddleware;
