export const mapColumns = [
  {
    name: 'Country or category',
    selector: 'name',
    sortable: true,
    maxWidth: '260px',
  },
  {
    name: 'Adopted By',
    selector: 'adopted_by',
    sortable: true,
    maxWidth: '120px',
  },
  {
    name: 'Restrictive measures',
    selector: 'measures',
    sortable: false,
    maxWidth: '25vw',
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
    },
  },
};
