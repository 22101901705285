import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Spinner,
} from 'reactstrap';
import Vector from './VectorMap';

import countries from '../../constants/countries';

function MapsVector({
  coloredData, setSelectedCountry, setToggleModal, setClearSelected,
  loading,
}) {
  // eslint-disable-next-line
  const onRegionClick = (props, countryCode) => {
    [...document.querySelectorAll('.jvectormap-tip')].map((label) => {
      label.style.display = 'none';
      return '';
    });

    const isAvailableMeasures = Object.keys(coloredData).includes(countryCode);

    const selected = countries.filter((c) => c.alpha2 === countryCode.toLowerCase())[0];
    setSelectedCountry([{ code: selected.alpha3.toUpperCase(), name: selected.name }]);
    if (isAvailableMeasures) setToggleModal(true);
    setClearSelected(true);
    setTimeout(() => {
      setClearSelected(false);
    }, 500);
  };

  return (
    <>
      {loading && (
      <Spinner
        className="mt-5"
        color="secondary"
        style={{
          position: 'fixed',
          width: '80px',
          height: '80px',
          left: '55vw',
          top: '35%',
          zIndex: '5',
        }}
      />
      )}
      <Card>
        <CardBody>
          {/* <CardTitle>Sanction World Map</CardTitle> */}
          <div id="world-map-markers" className="vector-map-height">
            <Vector
              coloredData={coloredData}
              value="world_mill"
              width="500"
              color="rgba(116, 120, 141, 0.5)"
              onRegionClick={onRegionClick}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

MapsVector.propTypes = {
  coloredData: PropTypes.shape(PropTypes.object).isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setToggleModal: PropTypes.func.isRequired,
  setClearSelected: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MapsVector;
