import React from 'react';

import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';

import OnboardingWelcome from './OnboardingWelcome';
import LineChart from './LineChart';
import RadialChart from './RadialChart';
import DonutChart from './DonutChart';

function Onboarding() {
  const { user: { name } } = useSelector((state) => state.auth);
  // console.log(user);
  return (
    <div>
      <Row>
        <Col>
          <OnboardingWelcome name={name} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <DonutChart />
        </Col>
        <Col xl="6">
          <RadialChart />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <LineChart />
        </Col>
      </Row>
    </div>
  );
}

export default Onboarding;
