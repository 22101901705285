import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';

import { useAPI } from '../services/api';
// import { authLogin } from '../store/reducers/auth';

import '../assets/css/registration.css';
import { resetStates } from '../store/reducers/auth';

import stBanner from '../assets/images/sanction-banner.png';

function useQuery() {
  return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
}

async function fetchAndSetInvitation(token, fetchInvitation, setState) {
  try {
    const { data: { invitation } } = await fetchInvitation(token);
    setState({
      loading: false,
      error: null,
      invitation,
    });
  } catch (error) {
    let message = 'An error occurred. Please try again later.';
    switch (error.response.status) {
      case 404:
        message = 'Invitation not found. Please contact your administrator';
        break;
      default:
        break;
    }

    setState({
      loading: false,
      error: message,
      invitation: null,
    });
  }
}

function Registration() {
  const { token } = useQuery();
  const { fetchInvitation, register } = useAPI();
  const dispatch = useDispatch();
  const history = useHistory();
  const [{ invitation, error }, setInvitation] = useState({
    loading: true,
    error: null,
    invitation: null,
  });
  // eslint-disable-next-line
  const [validations, setValidations] = useState([false,false,false,false]);
  const [loading, setLoading] = useState(false);
  const [checkMatch, setCheckMatch] = useState(false);
  const [errors, setErrors] = useState({
    message: null,
    fields: [],
  });

  const [registerStatus, setRegisterStatus] = useState(0);

  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('token');
    fetchAndSetInvitation(token, fetchInvitation, setInvitation);
  }, [fetchInvitation, setInvitation, token]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    localStorage.removeItem('user');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('token');
    dispatch(resetStates());
    const {
      email: { value: email },
      name: { value: name },
      password: { value: password },
      'password-confirm': { value: passwordConfirm },
    } = e.target.elements;

    if (password !== passwordConfirm) {
      setErrors({
        message: 'Please make sure your passwords match!',
        fields: ['password', 'password-confirm'],
      });

      return;
    }
    setLoading(true);
    try {
      const { data: { status } } = await register(token, name, password);
      if (status === 'ok') {
        localStorage.setItem('email', email);
        setRegisterStatus(1);
        setTimeout(() => {
          setRegisterStatus(2);
        }, 2000);
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      }
      // dispatch(authLogin(invitation.email, password, history));
    } catch (apiErr) {
      setErrors({
        message: 'An error occurred. Please try again later.',
        fields: [],
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, history, invitation, register, token]);

  const validatePassword = (e) => {
    const password = e.target.value;
    let passwordMatch = '';
    if (e.target.id === 'password') {
      passwordMatch = document.querySelector('#password-confirm').value;
      setValidations([
        password.length > 7,
        password.search(/[A-Z]/) > -1,
        password.search(/[0-9]/) > -1,
      ]);
    } else if (e.target.id === 'password-confirm') {
      passwordMatch = document.querySelector('#password').value;
    }
    setCheckMatch((password === passwordMatch)
    && password.trim().length > 0
    && passwordMatch.trim().length > 0);
  };

  const getStatusIcon = (v) => (v ? (
    <i className="bx bxs-check-square align-middle mr-2 success-icon" />
  ) : (
    <i className="bx bxs-x-square align-middle mr-2 error-icon" />
  ));

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col xs="12">
                    <img
                      src={stBanner}
                      alt="Sanction Trace Banner"
                      className="m-0 p-0"
                      width="100%"
                      height="85"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="text-primary p-4">
                      <p>Please fill the form below to create an account.</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-4">
                <div className="p-2">
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    {error && <Alert color="danger">{error}</Alert>}
                    {errors.message && (
                      <Alert color="danger">{errors.message}</Alert>
                    )}
                    {!error
                    && (
                    <>
                      <div className="form-group">
                        <Label htmlFor="name">Name</Label>
                        <Input
                          disabled={!!error}
                          required
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="form-group">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          disabled={!!error}
                          required
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter email"
                          readOnly
                          value={invitation?.email || ''}
                        />
                      </div>
                      <div className="form-group">
                        <Label htmlFor="password">Password</Label>
                        <Input
                          className={
                          errors.fields.includes('password') ? 'is-invalid' : ''
                        }
                          disabled={!!error}
                          required
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter password"
                          onChange={validatePassword}
                        />

                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item"
                          >
                            {getStatusIcon(validations[0])}
                            {' '}
                            must be at least 8
                            characters
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(validations[1])}
                            {' '}
                            must contain a capital
                            letter
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(validations[2])}
                            {' '}
                            must contain a number
                          </li>
                          <li className="list-group-item">
                            {getStatusIcon(checkMatch)}
                            {' '}
                            must password match
                          </li>
                        </ul>
                      </div>
                      <div className="form-group">
                        <Label htmlFor="password-confirm">Confirm Password</Label>
                        <Input
                          className={
                          errors.fields.includes('password-confirm')
                            ? 'is-invalid'
                            : ''
                        }
                          disabled={!!error}
                          required
                          id="password-confirm"
                          name="password-confirm"
                          type="password"
                          placeholder="Enter password again"
                          onChange={validatePassword}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          disabled={
                          (!!error
                            || loading
                            || validations.reduce((acc, cur) => acc + cur)) < 3
                            || registerStatus === 2
                            || registerStatus === 1
                            || !checkMatch
                        }
                          className={classnames({
                            btn: true,
                            'btn-block': true,
                            'waves-effect': true,
                            'waves-light': true,
                            'btn-primary': registerStatus === 0,
                            'btn-info': registerStatus === 2,
                            'btn-success': registerStatus === 1,
                          })}
                          type="submit"
                        >
                          {registerStatus === 2 && 'You have been redirected to login...'}
                          {registerStatus === 1
                          && 'Your account has been successfully created.'}

                          {registerStatus === 0 && 'Register'}
                        </button>
                      </div>
                    </>
                    )}
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Registration;
