import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';
import allCountries from '../../constants/countries';

const initialState = {
  initialized: false,
  countriesData: [],
  coloredData: {},
  error: false,
  loading: false,
  status: '',
};

const mapSlice = createSlice({
  name: 'sanctionMap',
  initialState,
  reducers: {
    mapStart(state) {
      state.initialized = false;
      state.error = false;
      state.loading = true;
      state.status = '';
      state.coloredData = {};
    },
    mapSuccess(state, action) {
      state.countriesData = action.payload.countriesData;
      state.error = false;
      state.loading = false;
      state.status = 'ok';
      state.initialized = true;
      state.coloredData = action.payload.coloredData;
    },
  },
});

export const {
  mapStart,
  mapSuccess,
} = mapSlice.actions;

export default mapSlice.reducer;

export const getMapData = () => async (dispatch) => {
  dispatch(mapStart());
  try {
    const { data: { countries } } = await api.getMapData();
    const temp = {};
    countries.map((dcnt) => allCountries.map((cnt) => {
      if (cnt.alpha3.toLowerCase() === dcnt.country_code.toLowerCase()) {
        if (dcnt.total_severity !== null) {
          Object.assign(temp, {
            [cnt.alpha2.toUpperCase()]: dcnt.total_severity,
          });
        }
      }
      return cnt;
    }));
    dispatch(mapSuccess({ coloredData: temp, countriesData: countries }));
  } catch (error) {
    console.log(error);
  }
};

export const a = '';
