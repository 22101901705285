import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authLogout } from '../store/reducers/auth';

function Logout() {
  const dispatch = useDispatch();

  const clearCache = (reloadAfterClear = true) => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name);
        });
      });

      if (reloadAfterClear) window.location.reload();
    }
  };

  useEffect(() => {
    dispatch(authLogout());
    return () => {
      // window.location.reload(true);
      clearCache();
    };
  });

  return null;
}

export default Logout;
