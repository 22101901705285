import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchListLogs, currentList } from 'store/lists';
import { useLanguage } from 'contexts/LanguageContext';
import { useDate } from 'hooks';

import { isValidDate } from 'helpers';

import { Loading } from 'components/ui';
import TimelineItem from './TimelineItem';

import * as classes from './index.module.scss';

function Timeline() {
  const dispatch = useDispatch();
  const { screeningId } = useParams();
  const { translate } = useLanguage();
  const { formatDate } = useDate();

  const { pending, list: { logs } } = useSelector(currentList);

  const logsByYear = useMemo(() => {
    try {
      const sortedByYear = {};
      logs.forEach((log) => {
        const logDate = isValidDate(log?.creation_date)
          ? new Date(log?.creation_date)
          : new Date();

        const year = logDate.getFullYear();

        sortedByYear[year] = sortedByYear[year]
          ? sortedByYear[year].concat([log])
          : [log];
      });
      return sortedByYear;
    } catch (error) {
      return {};
    }
  }, [logs]);

  useEffect(() => {
    dispatch(fetchListLogs(screeningId));
  }, [dispatch, screeningId]);

  if (pending) {
    return <Loading />;
  }

  if (logsByYear && Object.keys(logsByYear).length > 0) {
    return Object.keys(logsByYear)
      .sort((a, b) => b.localeCompare(a))
      .map((year) => (
        <div key={year} className={classes['timeline-group']}>
          <h6 className={classes['timeline-group-title']}>{year}</h6>
          <ul className={classes['timeline-group-items']}>
            {Array.isArray(logs) && logs
              .map(({ id, log, creation_date: date }) => (
                <TimelineItem key={id} date={formatDate(date)}>
                  {log}
                </TimelineItem>
              ))}
          </ul>
        </div>
      ));
  }

  return translate('screening.detail.timeline.noData');
}

export default Timeline;
