import React, { useState } from 'react';
import {
  useLocation,
} from 'react-router-dom';
import qs from 'qs';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Alert,
  Button,
} from 'reactstrap';
import '../../assets/css/preview.css';
import FileCard from './FileCard';
import { useAPI } from '../../services/api';

function useQuery() {
  return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
}

function VerifyFiles() {
  const api = useAPI();

  const { code } = useQuery();
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const [search, setSearch] = useState(code ?? '');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const handleVerify = async () => {
    setFile(null);
    if (search.trim().length > 0) {
      setLoading(true);
      try {
        const { data: { report } } = await api.checkPdfReport(search);
        setLoading(false);
        setStatus('ok');
        setFile(report);
      } catch (err) {
        setLoading(false);
        setStatus('error');
        setFile(null);
      } finally {
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      }
    }
  };

  return (
    <div
      className="account-pages main-login d-grid align-items-center justify-content-center"
    >
      <Card
        className="overflow-hidden verify-card"
        style={{
          width: '100%',
        }}
      >
        <CardBody
          className="pt-4"
          style={{
            background: 'white',
            borderRadius: '4px',
          }}
        >
          <Row className="px-2">
            <Col xs="12">
              <p
                className="mb-0"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#495057',
                }}
              >
                Verify your files
              </p>
            </Col>
          </Row>
          {status === 'error' && (
            <Row className="mt-3 mb-0 px-2">
              <Col>
                <Alert className="mb-0" color="danger">Invalid code</Alert>
              </Col>
            </Row>
          )}

          <Row className="p-2 pt-3 pb-0">
            <Col md="8" className="form-group">
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                }}
              >
                <Input
                  required
                  id="search"
                  name="search"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Enter or paste your code"
                />

                {search.length > 0 && (
                  /* eslint-disable-next-line */
                  <span
                    className="closeButton"
                    onClick={() => {
                      setSearch('');
                    }}
                  >
                    <i className="mdi mdi-close" />
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <Button
                disabled={loading || status === 'ok'}
                color={
                  // eslint-disable-next-line
                  status === "error" ? "danger" :
                  // eslint-disable-next-line
                    !loading && status === "ok"
                      ? 'success'
                      : 'primary'
                }
                className="btn waves-effect waves-light"
                style={{
                  width: '100%',
                }}
                onClick={handleVerify}
              >
                {status === 'ok' && (
                <>
                  <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                  Verified
                </>
                )}
                {loading === true && status === null && (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                  Checking...
                </>
                )}

                {status === 'error' && (
                <>
                  <i className="bx bx-block font-size-16 align-middle mr-2" />
                  Error
                </>
                )}
                {status !== 'error'
              && !loading
              && status !== 'ok'
              && 'Verify'}
              </Button>
            </Col>
          </Row>
          {file
          && (
          <Row className="px-2">
            <Col>
              <FileCard file={file} isFileSize={false} />
            </Col>
          </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default VerifyFiles;
