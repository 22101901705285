import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useLanguage } from 'contexts/LanguageContext';
import { useDate } from 'hooks';

import { Card, Button } from 'components/ui';
import NotificationItem from './NotificationItem';

function LatestNotifications({ notifications }) {
  const { translate } = useLanguage();
  const { formatDate } = useDate();

  return (
    <Card className="_dashboard__latest_notifications">
      <h3 className="_dashboard__latest_notifications-card-title">
        {translate('dashboard.main.notifications.header')}
      </h3>
      {notifications.length === 0 && 'You have no notification.'}
      {notifications.length !== 0 && (
        <>
          <ul className="_dashboard__latest_notifications-list">
            {notifications.map((log) => (
              <NotificationItem
                key={log?.id}
                type={log?.type}
                date={formatDate(log?.date)}
                content={log?.content}
              />
            ))}
          </ul>
          <div className="_dashboard__latest_notifications-view-more">
            <Link to="/notifications">
              <Button color="primary">
                {translate('dashboard.main.notifications.viewMore')}
                <i className="mdi mdi-arrow-right ml-2" />
              </Button>
            </Link>
          </div>
        </>
      )}
    </Card>
  );
}

LatestNotifications.defaultProps = {
  notifications: [],
};

LatestNotifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  })),
};

export default LatestNotifications;
