/* eslint-disable arrow-body-style */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from 'reactstrap';

import AddToList from './AddToList';

// const availableFields = [
//   'source', 'fullName', 'entityType', 'dateOfBirthSearch',
//   'lastUpdated', 'comments', 'clientSpecific',
// ];

const fixedDate = (date) => {
  try {
    const givenDate = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
      .formatToParts(givenDate);
    return `${day} ${month} ${year}`;
  } catch (error) {
    return '';
  }
};

const fields = [
  {
    key: 'source',
    label: 'Source',
    render(value) {
      return (<td key="source"><p>{value?.name}</p></td>);
    },
  },
  { key: 'name', label: 'Full Name' },
  { key: 'entity_type', label: 'Type' },
  {
    key: 'identification',
    label: 'Identification',
    render(values) {
      if (values.length !== undefined) {
        if (typeof values === 'string') {
          return (
            <td
              key="identification"
              dangerouslySetInnerHTML={{
                __html: values,
              }}
            />
          );
        }
        return (
          <td>
            {
              values.map((val) => {
                return Object.keys(val).map((key) => {
                  const value = val[key];
                  return (<p key={key}>{`${key}: ${value}`}</p>);
                });
              })
            }
          </td>
        );
      }
      return (
        <td>
          {
            Object.keys(values).map((key) => {
              const value = values[key];
              return (<p key={key}>{`${key}: ${value}`}</p>);
            })
          }
        </td>
      );
    },
  },
  {
    key: 'dateOfBirth',
    label: 'Date of Birth',
    render(value) {
      return (<td key="dateOfBirth"><p>{fixedDate(value)}</p></td>);
    },
  },
  { key: 'placeOfBirth', label: 'Place of Birth' },
  { key: 'nationality', label: 'Nationality' },
  { key: 'citizenship', label: 'Citizenship' },
  { key: 'addresses', label: 'Address' },
  {
    key: 'last_update_date',
    label: 'Last Updated',
    render(value) {
      return (<td key="last_update_date"><p>{fixedDate(value)}</p></td>);
    },
  },
  { key: 'comments', label: 'Comments' },
  {
    key: 'jaroScore',
    label: 'Jaro Score',
    render(values) {
      return (<td key="jaroScore"><p>{values}</p></td>);
    },
  },
  {
    key: 'phoneticsScore',
    label: 'Phonetics Score',
    render(values) {
      return (<td key="phoneticsScore"><p>{values}</p></td>);
    },
  },
  {
    key: 'preferred_jaro_score:',
    label: 'Preferred Jaro Score',
    render(values) {
      return (<td key="preferred_jaro_score"><p>{values}</p></td>);
    },
  },
  {
    key: 'preferred_name',
    label: 'Preferred Name',
    render(values) {
      return (<td key="preferred_name"><p>{values}</p></td>);
    },
  },
  {
    key: 'preferred_score',
    label: 'Preferred Score',
    render(values) {
      return (<td key="preferred_score"><p>{values}</p></td>);
    },
  },
  {
    key: 'preferred_phonetics_score',
    label: 'Preferred Phonetics Score',
    render(values) {
      return (<td key="preferred_phonetics_score"><p>{values}</p></td>);
    },
  },
  {
    key: 'preferred_jaro_score',
    label: 'Preferred Jaro Score',
    render(values) {
      return (<td key="preferred_jaro_score"><p>{values}</p></td>);
    },
  },
  {
    key: 'clientSpecific',
    label: 'Additional Information',
    render(values) {
      return (
        <td
          key="clientSpecific"
          dangerouslySetInnerHTML={{
            __html: values,
          }}
        />
      );
    },
  },
  {
    key: 'all_names',
    label: 'Aliases',
    render(values) {
      return (
        <td key="all_names">
          {
          Object.keys(values).map((key) => {
            const value = values[key].toLowerCase();
            return (
              <>
                <p key={key} className="capitalize">
                  {`${value}`}
                </p>
              </>
            );
          })
        }
        </td>
      );
    },
  },
];

function SearchResultModal({
  isOpen,
  data,
  type,
  onClose,
}) {
  const [addToListModal, setAddToListModal] = useState(false);

  if (!isOpen) {
    return null;
  }

  let body = null;
  let title = null;

  if (type === 'sanctions') {
    title = data.name;
    body = fields.filter((field) => !!data[field.key]).map((field) => {
      return (
        <tr key={field.key}>
          <td style={{ fontWeight: 500 }}>{field.label}</td>
          {
            field.render && field.render(data[field.key])
          }
          {
            !field.render && Array.isArray(data[field.key]) && (
              <td
                className="capitalize"
                dangerouslySetInnerHTML={{
                  __html: data[field.key].join('<br />'),
                }}
              />
            )
          }
          {
            !field.render && !Array.isArray(data[field.key]) && (<td className="capitalize">{field.key === 'source' ? data[field.key] : data[field.key].toLowerCase()}</td>)
          }
        </tr>
      );
    });
  } else {
    title = data?.name?.value;
    body = Object.keys(data).map((key) => {
      if (key === 'thumbnail') return '';
      const keySplit = key.match(/([A-Z]?[^A-Z]*)/g);
      const valueSplit = data[key].value.split(' ').map((v) => {
        if (v.toLowerCase() === 'of') return 'of';
        if (key === 'abstract') return v;
        if (key === 'birth_date') return fixedDate(v);

        return v;
      }).join(' ');
      return (
        <tr key={key}>
          <td
            className="capitalize"
            style={{
              fontWeight: 500,
              minWidth: '100px',
            }}
          >
            {keySplit.join(' ').replace('Of', 'of')}
          </td>
          <td>{valueSplit}</td>
        </tr>
      );
    });
  }
  return (
    <>
      <Modal
        centered
        scrollable
        size="lg"
        className="search-modal w-50"
        isOpen={isOpen}
        toggle={onClose}
      >
        <ModalHeader toggle={onClose}>{title}</ModalHeader>
        <ModalBody className="pb-0">
          <Table>
            <tbody>{body}</tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            className="add-list-button"
            onClick={() => {
              setAddToListModal(true);
            }}
          >
            Add to list
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={addToListModal}
        toggle={setAddToListModal}
        centered
        size="lg"
      >
        <div className="d-flex pb-0 pt-2 pr-3">
          <button
            type="button"
            onClick={() => setAddToListModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginLeft: 'auto', fontSize: '24px' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <AddToList
            name={data.name ?? ''}
            setModal={setAddToListModal}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

SearchResultModal.defaultProps = {
  data: null,
  type: ['sanctions', 'pep'],
};

SearchResultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ]),
  type: PropTypes.oneOf(['sanctions', 'pep']),
  onClose: PropTypes.func.isRequired,
};

export default SearchResultModal;
