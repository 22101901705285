import Dashboard from './main';
import { DashboardSourceList, DashboardSourceDetail, DashboardSourceLogs } from './Sources';
import { DashboardMediaList } from './Media';

export {
  Dashboard,
  DashboardSourceList,
  DashboardSourceDetail,
  DashboardSourceLogs,
  DashboardMediaList,
};
export default Dashboard;
