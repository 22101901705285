import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Row, Col, Card, CardBody, Input, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import { useAPI } from 'services/api';

const getFormattedTime = () => {};
const sourceDeltaColumns = [
  {
    name: 'Name',
    selector: 'fullName',
    sortable: true,
  },
  {
    name: 'Last Updated',
    selector: 'date',
    width: '160px',
    sortable: true,
  },
  {
    name: 'Entity Type',
    selector: 'entityType',
    width: '110px',
    sortable: true,
  },
  {
    name: 'Nationality',
    selector: 'nationality',
    width: '160px',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    width: '100px',
    sortable: true,
  },
];

function SourceDetail(props) {
  const api = useAPI();

  const id = useMemo(() => props.match.params.id, [props]);

  const [sourceData, setSourceData] = useState({});
  const [deltaData, setDeltaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const data = useMemo(() => deltaData.filter((d) => {
    if (query.trim().length > 0) {
      if (d.fullName.toLowerCase().includes(query.trim().toLowerCase())
    || d.action.toLowerCase().includes(query.trim().toLowerCase())
    || d.nationality.toLowerCase().includes(query.trim().toLowerCase())
    || d.entityType.toLowerCase().includes(query.trim().toLowerCase())) {
        return true;
      }
      return false;
    }
    return true;
  }), [deltaData, query]);

  const getSourceDelta = async () => {
    setLoading(true);
    try {
      const {
        data: {
          source,
          source_delta_data: {
            changed_items: changedItems,
            new_items: newItems,
            removed_items: removedItems,
          },
        },
      } = await api.getSourceData(id);
      setLoading(false);
      setSourceData(source);
      setDeltaData([
        ...changedItems.map((c) => ({
          ...c,
          date: getFormattedTime(c?.lastUpdated),
          nationality: typeof c?.nationality === 'undefined' ? '' : c.nationality,
          entityType: typeof c?.entityType === 'undefined' ? '' : c.entityType,
          action: 'Changed',
        })),
        ...newItems.map((c) => ({
          ...c,
          date: getFormattedTime(c?.lastUpdated),
          nationality: typeof c?.nationality === 'undefined' ? '' : c.nationality,
          entityType: typeof c?.entityType === 'undefined' ? '' : c.entityType,
          action: 'New',
        })),
        ...removedItems.map((c) => ({
          ...c,
          date: getFormattedTime(c?.lastUpdated),
          entityType: typeof c?.entityType === 'undefined' ? '' : c.entityType,
          nationality: typeof c?.nationality === 'undefined' ? '' : c.nationality,
          action: 'Removed',
        }))]);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSourceDelta();
  }, [id]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row className="pb-3">
              <Col>
                {sourceData?.name && (
                <p style={{
                  fontSize: '20px',
                }}
                >
                  {sourceData.name}
                  &nbsp;
                  -
                  &nbsp;
                  {sourceData.description}
                </p>
)}
              </Col>
            </Row>
            <Row className="pb-3">
              <Col md="4">
                <Input
                  disabled={loading}
                  autoFocus
                  type="search-history"
                  className="form-control"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Col>
            </Row>
            <DataTable
              progressPending={loading}
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              paginationRowsPerPageOptions={[20, 25, 30, 40, 50, 100, 150]}
              paginationPerPage={50}
              columns={sourceDeltaColumns}
              data={data}
              pagination
              highlightOnHover
              noHeader
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
SourceDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SourceDetail;
