import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntdCheckbox } from 'antd';

function Checkbox({
  children,
  disabled,
  className,
  value,
  onChange,
}) {
  const onInputChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <AntdCheckbox
      disabled={disabled}
      className={[className].join(' ')}
      checked={value}
      onChange={onInputChange}
    >
      {children}
    </AntdCheckbox>
  );
}

Checkbox.defaultProps = {
  disabled: false,
  className: '',
  value: false,
  onChange: () => {},
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
