import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAPI } from '../../services/api';
import '../../assets/css/client.css';

function ProfileRow({
  listId, clientId, resultData, cantEdit, requestAccessToggle,
  setResults,
}) {
  const api = useAPI();
  // eslint-disable-next-line
  const [status, setStatus] = useState(resultData?.is_tagged ?? false);

  const changeReferencedStatus = async (stt) => {
    const { data } = await api
      .changeReferencedStatusAutomated(listId, clientId, resultData.id, stt);
    if (data.status === 'ok') {
      setStatus(stt);
      setResults((prevState) => prevState.map((s) => {
        if (s.id === resultData.id) {
          return {
            ...s,
            is_tagged: stt,
          };
        }
        return s;
      }));
    }
  };
  return (

    <div className="d-flex align-items-center custom-control custom-checkbox">
      <input
        disabled={cantEdit}
        id="referenced"
        type="checkbox"
        className="custom-control-input"
        checked={status}
        onChange={(e) => {
          console.log(cantEdit);
          if (cantEdit) {
            requestAccessToggle(true);
            console.log('zaa');
          } else {
            console.log('burad');
            changeReferencedStatus(e.target.checked);
          }
        }}
      />
      {/* eslint-disable-next-line */}
      <label 

        onClick={() => {
          if (cantEdit) {
            requestAccessToggle(true);
          } else {
            changeReferencedStatus(!status);
          }
        }}
        className="custom-control-label"
        style={{
          paddingTop: '1.6px',
        }}
      >
        Referenced
      </label>
    </div>
  );
}

ProfileRow.propTypes = {
  resultData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line
    status: PropTypes.any.isRequired,
  }).isRequired,
  cantEdit: PropTypes.bool.isRequired,
  listId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
  setResults: PropTypes.func.isRequired,
};

export default ProfileRow;
