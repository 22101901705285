import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { fetchReports, deleteReport } from 'store/reports';
import { useLanguage } from 'contexts/LanguageContext';

import {
  Loading,
  Card,
  Table,
  Input,
} from 'components/ui';

import useColumns from './useColumns';

function SavedReportsLayout() {
  const dispatch = useDispatch();
  const { translate } = useLanguage();

  const userId = useSelector((state) => state.auth.user.id);
  const { pending, data } = useSelector((state) => state.reports);

  // eslint-disable-next-line
  const [query, setQuery] = useState('');

  const filteredReports = useMemo(() => {
    if (query.length > 0) {
      return data.filter((r) => {
        const isAvailable = r?.is_available ? 'available' : 'processing';
        const searchName = (r?.search?.name ?? '').toLowerCase();
        const createdBy = (r?.created_by ?? '').toLowerCase();
        return (
          searchName.includes(query)
          || createdBy.includes(query)
          || isAvailable.includes(query)
        );
      });
    }
    return data;
  }, [query, data]);

  const tableValues = useMemo(() => ({ ...translate('savedReports.table') }), [translate]);

  const onDelete = useCallback(
    (reportId) => {
      dispatch(deleteReport({
        userId, reportId,
      }));
    },
    [dispatch, userId],
  );

  useEffect(() => {
    dispatch(fetchReports(userId));
  }, [dispatch, userId]);

  const columns = useColumns(tableValues, onDelete);

  const onQueryChange = (e) => {
    setQuery(e?.target?.value);
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Card>
      <h4>{translate('savedReports.header')}</h4>
      {data.length === 0
        ? translate('savedReports.report', 0)
        : (
          <>
            <Row className="mt-4 mb-4">
              <Col xs={24} sm={16} md={8} lg={8} xl={4}>
                <Input.Text
                  placeholder={translate('savedReports.search.placeholder')}
                  value={query}
                  onChange={onQueryChange}
                />
              </Col>
            </Row>
            <Table
              loading={pending}
              columns={columns}
              dataSource={filteredReports}
            />
          </>
        )}
    </Card>
  );
}

export default SavedReportsLayout;
