import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container, Row, CardBody, Card, Col, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import MapsVector from './MapsVector';

import '../../assets/css/sanction-map.css';

import { customTableStyles, amlIndexColumns } from './constants';
import { amlIndexData } from './aml-index.json';

import { changeRoute, resetRoute } from '../../store/reducers/route';
import countries from '../../constants/countries';

import Autocomplete from '../../components/AutoComplete';

const coloredData = {};
amlIndexData.map((dcnt) => countries.map((cnt) => {
  if (cnt.alpha3.toUpperCase() === dcnt.alpha3) {
    Object.assign(coloredData, {
      [cnt.alpha2.toUpperCase()]: parseFloat(dcnt.overallScore, 10).toFixed(2),
    });
  }
  return cnt;
}));

const getRiskText = (score) => {
  try {
    if (score >= 7) {
      return 'High';
    }
    if (score >= 5) {
      return 'Moderate';
    }

    return 'Low';
  } catch (error) {
    return '';
  }
};

function CorruptionMap() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const mapData = useMemo(() => amlIndexData.filter((r) => {
    if (query.trim().length > 0) {
      return r?.country.toLowerCase()
        .includes(query.trim().toLowerCase())
        || r?.region.toLowerCase()
          .includes(query.trim().toLowerCase());
    }
    return true;
  }).map(({
    country, region, overallScore: score, rank,
  }) => ({
    name: country.charAt(0).toUpperCase() + country.slice(1).toLowerCase(),
    region,
    score: parseFloat(score).toFixed(2),
    rank: parseInt(rank, 10),
    risk: getRiskText(parseFloat(score).toFixed(2)),
  })),
  [query]);

  useEffect(() => {
    dispatch(changeRoute('Aml-Index'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);
  const allCountries = amlIndexData
    .map(({ country }) => country
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join());

  return (
    <>
      <Container fluid>
        <MapsVector
          coloredData={coloredData}
        />
        <Card className="mb-0">
          <CardBody>
            <Row>
              <Col>
                <Autocomplete
                  setInput={setQuery}
                  placeholder="Search..."
                  suggestions={allCountries}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <DataTable
              noDataComponent="There are no country to display"
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              defaultSortField="score"
              defaultSortAsc={false}
              pagination
              paginationPerPage={35}
              selectableRowsVisibleOnly
              selectableRows={false}
              columns={amlIndexColumns}
              customStyles={customTableStyles}
              data={mapData}
              highlightOnHover
              noHeader
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CorruptionMap;
