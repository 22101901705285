import React from 'react';

import {
  formattedDate,
  sortString,
  sortDate,
  getNestedValue,
} from 'helpers';

import {
  Button,
  Icon,
  Tag,
} from 'components/ui';

import ReportLink from './ReportLink';
import ReportDeleteModal from './ReportDeleteModal';

function useColumns(tableValues = {}, onDelete = () => {}) {
  const columns = [
    {
      key: 'name',
      dataIndex: ['search', 'name'],
      title: tableValues?.columns?.name,
      sorter: (a, b) => sortString('search.name', a, b),
      render: (value, record) => (
        <span className="d-flex align-items-center">
          <ReportLink
            isAvailable={Boolean(record?.is_available)}
            url={record?.url}
          >
            <Button
              disabled={!record?.is_available}
              className="d-flex"
            >
              <Icon name="eye" />
            </Button>
          </ReportLink>
          {value ?? '-'}
        </span>
      ),
    },
    {
      key: 'status',
      dataIndex: 'is_available',
      title: tableValues?.columns?.status,
      sorter: (a, b) => -Number(a?.is_available) + Number(b?.is_available),
      render: (value) => (value
        ? <Tag color="success">{tableValues?.available}</Tag>
        : <Tag color="info">{tableValues?.processing}</Tag>),
    },
    {
      key: 'creation_date',
      dataIndex: 'creation_date',
      title: tableValues?.columns?.createdAt,
      defaultSortOrder: 'descend',
      sorter: (a, b) => sortDate('creation_date', a, b),
      render: (value) => formattedDate(value),
    },
    {
      key: 'created_by',
      dataIndex: 'created_by',
      title: tableValues?.columns?.createdBy,
      sorter: (a, b) => sortString('created_by', a, b),
    },
    {
      key: 'actions',
      render: (record) => (
        <ReportDeleteModal
          disabled={!record?.is_available}
          reportName={getNestedValue('search.name', record) ?? ''}
          onDelete={() => onDelete(record?.id)}
        />
      ),
    },
  ];
  return columns;
}

export default useColumns;
