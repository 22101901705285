import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function FilterLabels({ filters, clearAll }) {
  return (
    <>
      {filters.map((filt) => {
        if (filt?.label === 'countries') {
          return filt.value.map((c) => (
            <span key={c.value} className="badge badge-soft-primary font-size-12 mt-2 py-2 pl-3 pr-2 d-flex align-items-center font-weight-normal mr-2 search-history-label">
              {c.label}
              <button
                id={c.value}
                type="button"
                className="close ml-3 justify-self-end"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => filt.changeFunction(filt?.value.filter((v) => v.value !== c.value))}
              >
                <span
                  id={c.value}
                  aria-hidden="true"
                  style={{
                    color: '#5A8DBF',
                  }}
                >
                  ×
                </span>
              </button>
            </span>
          ));
        }
        return (
          <span key={filt.value} className="badge badge-soft-primary font-size-12 mt-2 py-2 pl-3 pr-2 d-flex align-items-center font-weight-normal mr-2 search-history-label">
            {filt.value}
            <button
              id={typeof filt === 'string' ? 'search' : filt.value}
              data-id={filt.user}
              type="button"
              className="close ml-3 justify-self-end"
              data-dismiss="alert"
              aria-label="Close"
              onClick={filt.setFunction}
            >
              <span
                id={typeof filt === 'string' ? filt : filt.value}
                data-id={filt.user}
                aria-hidden="true"
                style={{
                  color: '#5A8DBF',
                }}
              >
                ×
              </span>
            </button>
          </span>
        );
      })}
      {filters.length > 0 && (
        <Button
          color="link"
          className="btn btn-link waves-effect mt-2 btn-clearAll"
          onClick={() => clearAll(true)}
        >
          CLEAR ALL
        </Button>
      )}
    </>
  );
}

FilterLabels.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })),
    ]),
    setFunction: PropTypes.func,
  })).isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default FilterLabels;
