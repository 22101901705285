import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  Card, CardTitle, CardBody, Input, Button, Alert, Progress,
} from 'reactstrap';

import { useAPI } from '../services/api';
import { createList } from '../store/reducers/lists';

import ImportListTable from './ImportListTable';
import CancelConfirmModal from './CancelConfirmModal';
import '../assets/css/create-list.css';
import { changeRoute } from '../store/reducers/route';
import ClientDoubleEntry from './ClientDoubleEntry';

function CreateList() {
  const api = useAPI();
  const {
    loading, status, listName: name, clients: clientList,
  } = useSelector(
    (state) => state.createList,
  );

  const [checkClientsLoading, setCheckClientsLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const [toggleCancel, setToggleCancel] = useState(false);

  const [toggleEntry, setToggleEntry] = useState(false);
  const [fixedClients, setFixedClients] = useState([]);
  const [existingClients, setExistingClients] = useState([]);
  const [existingCopy, setExistingCopy] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [fileInput, setFileInput] = useState();
  const [clients, setClients] = useState(clientList ?? []);
  const [dataGrid, setDataGrid] = useState('');
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [listName, setListName] = useState(name ?? '');

  const [fuzzyScore, setFuzzyScore] = useState(65);
  const [aka, setAka] = useState(true);
  const [pep, setPep] = useState(false);
  const [media, setMedia] = useState(false);

  const [targetRoute, setTargetRoute] = useState('/lists');

  const [errorForAbove10, setErrorForAbove10] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const checkClientNames = (textClients) => {
    try {
      if (textClients.length > 0) {
        const filteredColumn = textClients.filter((item) => item.trim().length >= 3);
        if (filteredColumn.length === textClients.length) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fileSelectedHandler = (e1) => {
    const typeArray = [
      'xls',
      'xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    try {
      const { files } = e1.target;
      const f = files[0];
      if (typeArray.indexOf(f.type.toLowerCase()) > -1) {
        const reader = new FileReader();
        reader.onload = (e2) => {
          const data = e2.target.result;
          const readedData = XLSX.read(data, { type: 'binary' });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];

          /* Convert excel file to array */
          const dataParse = XLSX.utils.sheet_to_row_object_array(ws, {
            header: 1,
            defval: '',
          });

          setClients(dataParse.map((row) => row[0]));
          checkClientNames(dataParse.map((row) => row[0]));

          setDataGrid(dataParse);
        };
        reader.readAsBinaryString(f);
      } else {
        console.log('file type is not a excel file');
      }
    } catch (e) {
      setDataGrid('');
      setSelectedColumn([]);
      setFileInput({});
    }
  };
  /* eslint no-param-reassign: ["error", { "props": false }] */
  const onChecked = (columnName) => {
    for (let i = 0; i < dataGrid[0].length; i += 1) {
      if (dataGrid[0][i] === columnName) {
        const temp = [];
        dataGrid.map((row) => {
          if (row[i] !== undefined) temp.push(row[i]);
          return null;
        });

        [...document.querySelectorAll('.cell')].map((cell) => {
          cell.style.backgroundColor = 'white';
          return '';
        });

        [...document.querySelectorAll(`.cell${i + 1}`)].map((cell) => {
          cell.style.backgroundColor = 'rgba(196, 196, 196, 0.27)';
          return '';
        });
        temp.shift();
        setSelectedColumn(temp);
        checkClientNames(temp);
      }
    }
  };

  const handleCancel = () => {
    setToggleCancel(false);
  };
  const handleConfirm = () => {
    history.push(targetRoute);
  };

  const handleScoreBlur = useCallback((e) => {
    if (parseInt(e.currentTarget.value, 10) < 65) {
      setFuzzyScore(65);
    } else if (parseInt(e.currentTarget.value, 10) > 100) {
      setFuzzyScore(100);
    }
  }, [fuzzyScore]);

  const handleCreateClients = (exCState, allClients = []) => {
    let filtered = [];
    if (exCState) {
      filtered = fixedClients.filter((f) => (selectedEntries
        .findIndex((e) => e.name === f) === -1
        && existingCopy.findIndex((e) => e.name === f) === -1));
    } else {
      filtered = allClients;
    }
    const c = filtered.map((client) => ({ name: client }));
    const entries = selectedEntries.map((entry) => {
      if (entry?.value !== null && entry?.value !== undefined && entry.value === 'new') {
        return { name: entry.name };
      }

      return { id: entry.id };
    });
    setProgress(50);
    dispatch(createList(listName, [...c, ...entries], fuzzyScore,
      { search_pep: pep, search_media: media }, {
        aka,
      }, history, setProgress));
    setTimeout(() => {
      setToggleEntry(false);
    }, 2500);
  };

  const handleCheckClients = async (c) => {
    const changedC = c.map((e) => ({ name: e }));
    setProgress(15);
    setCheckClientsLoading(true);
    const { data: { existing_clients: exC } } = await api.checkClients(changedC);
    setCheckClientsLoading(false);
    setProgress(35);
    if (exC.length === 0) {
      handleCreateClients(false, c);
    } else {
      setToggleEntry(true);
    }
    const temp = exC.map((ch, index) => ({ id: `m${index}`, ...ch }));
    setExistingClients(temp);
    setExistingCopy(temp);
  };

  const handleSelected = (id, clientName, textValue, masterId) => {
    const isInclude = selectedEntries.findIndex((e) => e.masterId === masterId);
    if (textValue === 'new') {
      if (isInclude === -1) {
        setSelectedEntries((prevState) => [...prevState, {
          masterId, id, name: clientName, value: 'new',
        }]);
      } else {
        setSelectedEntries((prevState) => [...prevState.filter((p) => p
          .masterId !== masterId), {
          masterId, id, name: clientName, value: 'new',
        }]);
      }
    } else if (isInclude === -1) {
      setSelectedEntries((prevState) => [...prevState, { masterId, id, name: clientName }]);
    } else if (isInclude > -1) {
      setSelectedEntries((prevState) => [...prevState.filter((p) => p
        .masterId !== masterId), { masterId, id, name: clientName }]);
    }
  };

  const deleteEntry = (cName) => {
    setSelectedEntries((prevState) => [...prevState.filter((p) => p.name !== cName)]);
    setExistingClients(existingClients.filter((e) => e.name !== cName));
  };

  useEffect(() => {
    checkClientNames(clients ?? selectedColumn);
  }, [setClients]);

  const clickEventListener = useCallback(
    (e) => {
      e.preventDefault();
      // console.log(window.getEventListeners(document.querySelector('#sidebar-menu')));
      document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
      if (listName.trim().length > 0
      || selectedColumn.filter((c) => c.trim().length !== 0).length > 0
      || clients.filter((c) => c.trim().length !== 0).length > 0) {
        try {
          const tRoute = e.target.parentElement.getElementsByTagName('a')[0].getAttribute('href') ?? '/lists';
          setTargetRoute(tRoute);
        } catch (err) {
          console.log(err);
        }
        setToggleCancel(true);
      } else {
        try {
          const tRoute = e.target.parentElement.getElementsByTagName('a')[0].getAttribute('href');
          history.push(tRoute);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [listName, clients, selectedColumn],
  );

  useEffect(() => {
    document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
    document.querySelector('#sidebar-menu').addEventListener('click', clickEventListener);
    dispatch(changeRoute('Lists / ', 'Add new list'));
    return () => {
      document.querySelector('#sidebar-menu').removeEventListener('click', clickEventListener);
      dispatch(changeRoute('Lists / '));
    };
  }, [listName, clients, selectedColumn]);

  return (
    <div>
      <CancelConfirmModal
        isOpen={errorForAbove10}
        onConfirm={() => {
          setErrorForAbove10(false);
        }}
        onCancel={() => {
          setErrorForAbove10(false);
        }}
      >
        This is a trial and it was intended to create lists
        more than 10 profiles. Please do contact to your
        system administrator to get the full access.
      </CancelConfirmModal>
      <CancelConfirmModal
        isOpen={toggleCancel}
        name={listName}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <ClientDoubleEntry
        loading={loading || checkClientsLoading}
        status={status}
        disabled={disabled}
        toggleModal={toggleEntry}
        setToggleModal={setToggleEntry}
        onConfirm={handleCreateClients}
        doubleClients={existingClients}
        handleSelected={handleSelected}
        deleteEntry={deleteEntry}
        selectedEntries={selectedEntries}
      />
      <Card>
        <CardBody className="d-flex">
          <div style={{
            width: '40px',
          }}
          >
            <Button
              disabled={loading || checkClientsLoading}
              color="link"
              className="btn waves-effect waves-light ml-auto p-0 border-0 outline-0"
              style={{
                height: '26px',
                borderRadius: '20px',
              }}
              onClick={() => {
                if (listName
                || selectedColumn.length > 0
                || clients.length > 0) setToggleCancel(true);
                else {
                  history.push('/lists');
                }
              }}
            >
              <i
                style={{
                  color: '#74788D',
                  fontSize: '24px',
                }}
                className="bx bx-left-arrow-alt "
              />
            </Button>
          </div>
          <div style={{
            width: '100%',
            marginTop: '1.6px',
          }}
          >
            <CardTitle
              className="mt-0 d-flex"
              style={{
                color: '#25213B',
              }}
            >
              Create New List

            </CardTitle>
            {/* List name */}
            <div className="form-group row mt-4">
              <label htmlFor="list-name" className="col-md-3 col-form-label">
                List Name
              </label>
              <div className="col-md-7">
                <input
                  disabled={loading || checkClientsLoading}
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete={false}
                  id="list-name"
                  name="list-name"
                  className="form-control"
                  type="search"
                  placeholder="Enter list name(must contain at least three characters)"
                  value={listName}
                  onChange={(e) => {
                    setListName(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* Minimum Name Score */}
            <div className="form-group row mt-4">
              <label htmlFor="minNameScore" className="col-md-3 col-form-label">Minimum Name Score</label>
              <div className="col-md-5">
                <div className="d-flex align-items-center">
                  <input
                    disabled={loading || checkClientsLoading}
                    id="minNameScore"
                    name="minNameScore"
                    type="range"
                    min="65"
                    max="100"
                    value={fuzzyScore}
                    className="form-control-range mr-2"
                    onChange={(e) => setFuzzyScore(e.target.value)}
                  />
                  <input
                    disabled={loading || checkClientsLoading}
                    name="minNameScore"
                    type="number"
                    min="65"
                    max="100"
                    value={fuzzyScore}
                    className="form-control"
                    onChange={(e) => setFuzzyScore(e.target.value)}
                    onBlur={handleScoreBlur}
                    style={{ width: '5em' }}
                  />
                </div>
              </div>
            </div>

            {/* Search options: include aka  */}
            <div className="form-group row mt-4 d-flex align-items-center">
              <label className="col-md-3 col-form-label" htmlFor="searchOptions">Search Options</label>
              <div className="col-md-8 d-flex align-items-center">
                <div className="custom-control custom-checkbox col-md-3">
                  <input
                    disabled={loading || checkClientsLoading}
                    id="includeAka"
                    type="checkbox"
                    className="custom-control-input"
                    checked={aka}
                    onClick={() => setAka(!aka)}
                  />
                  <label htmlFor="includeAka" className="custom-control-label">
                    Include AKA
                  </label>
                </div>
                {false && (
                  <>
                    <div className="custom-control custom-checkbox col-md-3">
                      <input
                        disabled={loading || checkClientsLoading}
                        id="incPep"
                        type="checkbox"
                        className="custom-control-input"
                        checked={pep}
                        onChange={() => setPep(!pep)}
                      />
                      <label htmlFor="incPep" className="custom-control-label">
                        Include PEP
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox col-md-3">
                      <input
                        disabled={loading || checkClientsLoading}
                        id="incMedia"
                        type="checkbox"
                        className="custom-control-input"
                        checked={media}
                        onChange={() => setMedia(!media)}
                      />
                      <label htmlFor="incMedia" className="custom-control-label">
                        Include Media
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Add Clients */}
            <div className="form-group row" style={{ marginTop: '40px' }}>
              <label disabled={loading || checkClientsLoading} htmlFor="fileInput2" className="col-md-3 col-form-label">
                Add Profile(s)
              </label>
              <div className="col-md-7">
                <input
                  accept=".xls,.xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  required
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  ref={(fileInputRef) => setFileInput(fileInputRef)}
                  onChange={(e) => {
                    fileSelectedHandler(e);
                    checkClientNames([]);
                  }}
                />

                {/* Import from excel */}
                <Button
                  disabled={loading || checkClientsLoading}
                  color="primary"
                  className="btn waves-effect waves-light"
                  style={{
                    width: '250px',
                    marginBottom: '27px',
                  }}
                  onClick={() => fileInput.click()}
                >
                  Import from excel
                </Button>
                {dataGrid.length === 0 && clients.length > 0 && (
                  <Button
                    disabled={loading || checkClientsLoading}
                    color="link"
                    className="btn btn-link waves-effect"
                    style={{
                      marginBottom: '27px',
                      marginLeft: '10px',
                      paddingLeft: 'auto',
                    }}
                    onClick={() => {
                      setDataGrid([]);
                      setFileInput({});
                      setClients([]);
                      setSelectedColumn([]);
                      document.getElementById('fileInput').value = '';
                    }}
                  >
                    Clear
                  </Button>
                )}
                {dataGrid.length > 0 ? (
                  <>
                    <Button
                      disabled={loading || checkClientsLoading}
                      color="link"
                      className="btn btn-link waves-effect"
                      style={{
                        marginBottom: '27px',
                        marginLeft: '10px',
                      }}
                      onClick={() => {
                        setDataGrid([]);
                        setFileInput({});
                        setClients([]);
                        setSelectedColumn([]);
                        document.getElementById('fileInput').value = '';
                      }}
                    >
                      Clear
                    </Button>
                    <div>
                      <Alert color="primary">
                        Please select the column that you want to extract for screening.
                      </Alert>
                    </div>
                  </>
                ) : (
                  <Input
                    disabled={loading || checkClientsLoading}
                    type="textarea"
                    className="form-control textArea"
                    placeholder="Paste your profile list or import from excel..."
                    rows="8"
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                    value={clients.join('\n')}
                    onChange={(e) => {
                      const splitClients = e.target.value
                        .split('\n')
                        .map((client) => client.replace(/\s\s+/g, ' ').trimStart());

                      setClients(splitClients);
                      checkClientNames(splitClients);
                    }}
                    onBlur={(e) => {
                      const splitClients = e.target.value
                        .split('\n')
                        .map((client) => client.replace(/\s\s+/g, ' ').trim());
                      setClients(splitClients);
                      checkClientNames(splitClients);
                    }}
                    onPaste={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const splitClients = (clients.join('\n') + e.clipboardData.getData('text/plain'))
                        .split('\n')
                        .map((client) => client.replace(/\s\s+/g, ' ').trim())
                        .filter((c) => c.trim().length !== 0);
                      setClients(splitClients);
                    }}
                  />
                )}

                {dataGrid.length > 0 && (
                  <ImportListTable
                    disabled={loading || checkClientsLoading}
                    data={dataGrid}
                    onChecked={onChecked}
                    selectedRowsCount={selectedColumn.length}
                  />
                )}

                {/* Alert for client length */}
                {disabled && (
                  <div className="mt-3">
                    <Alert color="primary">
                      Profile names should have at least 3 letters.
                    </Alert>
                  </div>
                )}
                {(loading || checkClientsLoading) && <Progress className="mt-3" striped animated color="success" value={progress} />}
                {/* Save list */}
                <Button
                  disabled={(listName.trim().length < 3
                  && listName.trim() !== 0)
                  || (loading || checkClientsLoading)}
                  color={
                  // eslint-disable-next-line
                  status === "error"
                    ? 'danger'
                    : (!loading && !checkClientsLoading) && status === 'ok'
                      ? 'success'
                      : 'primary'
                }
                  className="btn waves-effect waves-light"
                  style={{
                    width: '105px',
                    marginTop: '25px',
                    cursor: listName.length >= 3 ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    const c = clients.filter((cs) => cs.length >= 3);
                    const s = selectedColumn.filter((cs) => cs.length >= 3);

                    if (s.length > 0) {
                      setFixedClients(s);
                      handleCheckClients(s);
                    } else {
                      setFixedClients(c);
                      handleCheckClients(c);
                    }
                  }}
                >
                  {status === 'ok' && (
                    <>
                      <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                      Saved
                    </>
                  )}
                  {(loading === true || checkClientsLoading === true)
                    && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                      Saving...
                    </>
                    )}
                  {status === 'error' && (
                    <>
                      <i className="bx bx-block font-size-16 align-middle mr-2" />
                      Error
                    </>
                  )}
                  {status !== 'error'
                  && loading !== true
                  && checkClientsLoading !== true
                  && status !== 'ok'
                  && 'Save list'}
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateList;
