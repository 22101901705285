import React from 'react';
import PropTypes from 'prop-types';

import { useLanguage } from 'contexts/LanguageContext';

import { Card } from 'components/ui';
import ListItem from './ListItem';

function UsedLists({ lists, onDeleteList }) {
  const { translate } = useLanguage();

  return (
    <Card className="_dashboard__used-list">
      <h4 className="card-title">
        {translate('dashboard.main.usedLists.header')}
      </h4>
      <div>
        {lists?.map((list) => (
          <ListItem
            key={list?.id}
            list={list}
            onDeleteList={onDeleteList(list?.id)}
          />
        ))}
      </div>
    </Card>
  );
}
UsedLists.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    clients_count: PropTypes.number.isRequired,
  })).isRequired,
  onDeleteList: PropTypes.func.isRequired,
};

export default UsedLists;
