/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  useQuery,
} from 'react-query';
import {
  Card,
  CardBody,
  CardTitle,
  Modal,
  Input,
  Spinner,
} from 'reactstrap';

import DataTable from 'react-data-table-component';

import {
  loadListItem,
} from '../../store/reducers/automated-lists';

import ClientDetails from './ClientDetails';

import '../../assets/css/client.css';
import { useAPI } from '../../services/api';

const getFormattedTime = (date) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const [
    { value: month },,
    { value: day },,
    { value: year },
  ] = dateTimeFormat.formatToParts(date);

  const hh = date.getHours();
  const hours = hh < 10 ? `0${hh}` : hh;
  const m = date.getMinutes();
  const minutes = m < 10 ? `0${m}` : m;
  return `${day} ${month} ${year} at ${hours}:${minutes}`;
};

function CardProfileList({
  listId, cantEdit, requestAccessToggle,
}) {
  const api = useAPI();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: clientData,
  } = useQuery(['clients', listId, page, rowsPerPage], () => api.getClients(listId, page, rowsPerPage), { keepPreviousData: true });

  const { clients, totalRows } = useMemo(() => {
    if (clientData) {
      const {
        data: {
          previous,
          next,
          results,
          count,
        },
      } = clientData;
      return {
        previous, next, clients: results, totalRows: count,
      };
    }
    return { previous: null, next: null, clients: [] };
  }, [clientData]);

  let loadInterval;
  const list = useSelector(({ automatedLists: { data } }) => data
    .find((l) => l.id === parseInt(listId, 10)));

  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  const [filterQuery, setFilterQuery] = useState('');
  // eslint-disable-next-line
  const [filteredClients, setFilteredClients] = useState([]);

  const [detailModal, setDetailModal] = useState(false);

  const [baseId, setbaseId] = useState(0);
  const [clientName, setClientName] = useState('');

  const checkState = async () => {
    const { data: { progress: progrss } } = await api.getAutomatedStates(listId);
    setProgress(parseInt(progrss, 10));
  };

  const setStatusInterval = () => {
    loadInterval = setInterval(async () => {
      const { data: { states, progress: progrss } } = await api.getAutomatedStates(listId);
      setProgress(parseInt(progrss, 10));
      const searching = states.filter((s) => {
        if (s.toLowerCase() === 'not searched' || s.toLowerCase() === 'searching') {
          return true;
        }
        return false;
      });
      if (searching.length === 0 && progrss === 100) {
        setProgress(100);
        dispatch(loadListItem(listId));
        clearInterval(loadInterval);
      }
    }, 5000);
  };

  useEffect(() => {
    dispatch(loadListItem(listId));
    checkState();
    setStatusInterval();
    // eslint-disable-next-line
    return () => {
      clearInterval(loadInterval);
    };
  }, [detailModal]);

  const handleViewDetails = useCallback((e) => {
    const id = typeof e === 'number' ? e : e.currentTarget.getAttribute('data-id');
    setDetailModal(id);
  }, []);

  const data = useMemo(
    () => {
      const listClients = clients ?? [];
      return listClients.map(({
        pk: normalId,
        user_client: {
          pk: id,
          name,
          status,
          creation_date: creationDate,
          is_tailing: tail,
        },
      }) => {
        const getStatusText = (statusCode) => {
          switch (statusCode) {
            case 0:
              return 'Searching';
            case 1:
              return 'Error';
            case 2:
              return 'Searching';
            case 3:
              return 'Searching';
            case 4:
              return 'Clear ';
            case 5:
              return 'Need Action ';
            case 6:
              return 'Positive';
            case 7:
              return 'False Positive';
            default:
              return 'Error';
          }
        };
        const temp = tail === true ? ['Surveilling', getStatusText(status)] : [getStatusText(status)];
        return ({
          id,
          normalId,
          name,
          sttas: temp,
          states: temp.map((state, ind) => (
          // eslint-disable-next-line
          <span
            // eslint-disable-next-line
            key={ind}
            data-id={id}
            onClick={(e) => {
              setbaseId(normalId);
              setClientName(name);
              handleViewDetails(e);
            }}
            className={classnames({
              badge: true,
              'font-size-12': true,
              'py-2': true,
              'px-4': true,
              'mx-1': true,
              'my-1': true,
              'font-weight-normal': true,
              /* eslint-disable react/prop-types */
              'badge-soft-primary': state.indexOf('Surveilling') >= 0,
              'badge-soft-success': state.indexOf('Clear') >= 0,
              'badge-soft-warning': state.indexOf('Need Action') >= 0,
              'badge-soft-secondary': state.indexOf('False Positive') >= 0,
              'badge-soft-danger': (state.indexOf('Positive') >= 0
                  && state.indexOf('False') === -1),
              'badge-soft-searching':
                state.indexOf('Searching') >= 0
                || state === null,
              /* eslint-enable react/prop-types */
            })}
          >
            {state}
          </span>
          )),
          pureDate: new Date(creationDate),
          creationDate: getFormattedTime(new Date(creationDate)),
          actions: (
            <div className="d-flex align-items-center">
              <button
                data-id={id}
                type="button"
                className="btn btn-primary py-0 view-details"
                onClick={(e) => {
                  setbaseId(normalId);
                  setClientName(name);
                  handleViewDetails(e);
                }}
              >
                View Details
              </button>
            </div>
          ),
        });
      });
    },
    [clients],
  );

  const clientColumns = [
    {
      name: 'Name/Title',
      selector: 'name',
      sortable: true,
      // maxWidth: '500px',
    },
    {
      name: 'Status',
      selector: 'states',
      width: '160px',
      maxWidth: '450px',
      sortable: true,
    },
    {
      name: 'Created at',
      selector: 'creationDate',
      width: '155px',
      sortable: true,
    },
    {
      name: '',
      selector: 'actions',
      width: '220px',
      paddingRight: '0px',
    },
  ];

  const customTableStyles = {
    headCells: {
      style: {
        background: '#F8F9FA',
        color: 'black',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        height: '44px',
      },
      sortFocus: {
        color: '#FFFFFF',
      },
    },
    rows: {
      style: {
        fontWeight: '700',
        cursor: 'pointer',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#CDE1FF',
      },
    },
  };

  const filterData = (q) => {
    setFilterQuery(q);

    const tempFiltered = data.filter((d) => (d.name.toLowerCase().includes(q.toLowerCase())
      || d.sttas.filter((st) => st.toLowerCase().includes(q.toLowerCase())).length > 0));

    setFilteredClients(tempFiltered);
  };

  const onSortRows = (rows, field, direction) => {
    const ab = rows.sort((a, b) => {
      if (field === 'creationDate') {
        if (new Date(a.pureDate) < new Date(b.pureDate)) {
          return -1;
        }
        if (new Date(a) > new Date(b)) {
          return 1;
        }
      } else if (field === 'states') {
        if (a.sttas.join(', ') < b.sttas.join(', ')) {
          return -1;
        }
        if (a.sttas.join(', ') > b.sttas.join(', ')) {
          return 1;
        }
      } else if (a[field] !== undefined && b[field] !== undefined) {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return -1;
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return 1;
        }
      }
      return 0;
    });
    if (direction === 'asc') return ab.slice(0);
    return ab.slice(0).reverse();
  };

  const onChangePage = (number) => {
    setPage(number);
  };
  const onChangeRowsPerPage = (number) => {
    setRowsPerPage(number);
  };

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 d-flex align-items-start">
            <span>Profile List</span>
          </CardTitle>
          <div className="mb-2 pb-2 pt-3 d-flex align-items-center">
            <div className="w-50 mr-auto">
              <Input
                disabled={progress !== 100}
                autoFocus
                autoComplete="off"
                id="filter"
                type="search"
                className="form-control"
                placeholder="Search..."
                value={filterQuery}
                onChange={(e) => filterData(e.target.value)}
              />
            </div>
            {progress !== 100 && (
            <div className="text-muted d-flex align-items-center mr-2">
              <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2" />
              {progress}
              %
            </div>
            )}
          </div>

          {list.states.filter((s) => s.toLowerCase().includes('searching')).length > 0
              && (
              <div
                className="d-flex align-items-center justify-content-end"
                style={{
                  gap: '15px',
                }}
              >
                  { list.states.filter((s) => s.toLowerCase().includes('searching')).map((s) => (
                    <div className="text-muted d-flex align-items-center pb-4">
                      <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2" />
                      {s}
                      ...
                    </div>
                  ))}
              </div>
              )}
          <div className="overflow-auto">
            <DataTable
              disabled={progress !== 100}
              noDataComponent="There are no profiles to display"
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50]}
              overflowY
              allowOverflow
              overflowYOffset="200px"
              sortFunction={onSortRows}
              defaultSortField="creationDate"
              defaultSortAsc={false}
              onRowClicked={(e) => {
                setbaseId(e.normalId);
                setClientName(e.name);
                handleViewDetails(e.id);
              }}
              columns={clientColumns}
              customStyles={customTableStyles}
              data={
                filteredClients.length > 0 || filterQuery.trim().length > 0
                  ? filteredClients
                  : data.slice(0)
              }
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              highlightOnHover
              noHeader
            />
          </div>
        </CardBody>
      </Card>
      {/* Client details */}
      <Modal
        size="xl"
        isOpen={detailModal}
        toggle={() => setDetailModal(false)}
        centered
      >
        <ClientDetails
          clientName={clientName}
          cantEdit={cantEdit}
          listId={listId}
          clientId={detailModal}
          baseId={baseId}
          toggle={setDetailModal}
          requestAccessToggle={requestAccessToggle}
        />
      </Modal>
    </>
  );
}

CardProfileList.propTypes = {
  listId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};

export default CardProfileList;
