import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIMask } from 'react-imask';

import * as classes from './index.module.scss';

// +{9\\0}(000) 000 0000
function Masked({
  mask,
  className,
  placeholder,
  value,
  onChange,
}) {
  const {
    ref,
    unmaskedValue,
    setUnmaskedValue,
  } = useIMask({
    mask,
    lazy: true,
    overwrite: true,
  });

  useEffect(() => {
    setUnmaskedValue(value);
  }, [setUnmaskedValue, value]);

  return (
    <input
      ref={ref}
      className={[classes.masked, className].join(' ')}
      placeholder={placeholder}
      onChange={() => onChange(unmaskedValue)}
    />
  );
}

Masked.defaultProps = {
  className: '',
  placeholder: '',
  value: null,
  onChange: () => {},
};

Masked.propTypes = {
  mask: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Masked;
