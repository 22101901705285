import React, {
  useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  Row,
  Nav,
  NavItem,
  NavLink,
  Col,
  TabContent,
  TabPane,
  Button,
  CardBody,
  Card, CardTitle, CardSubtitle,
  Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import { elapsedTime } from 'helpers';
import {
  tabs,
  pepColumns,
  sanctionColumns,
  customTableStyles,
} from './constants';

import SearchResultModal from './SearchResultModal';

import { requestFile, makePepSearch, makeAdverseMediaSearch } from '../../store/reducers/search';
import { useAPI } from '../../services/api';

function SearchResults({
  results, pepResults, adverseMediaResults, status, setShareModal, searchParams,
}) {
  const api = useAPI();

  toastr.options = {
    timeOut: 0,
    extendedTimeOut: 0,
    closeButton: true,
  };
  const dispatch = useDispatch();
  // const history = useHistory();
  const userId = useSelector((state) => state.auth.user.id);
  const lastSearchId = useSelector((state) => state.search.lastSearchId);
  let searchId = lastSearchId;
  // const lastReportId = useSelector((state) => state.search.lastReportId);
  const ploading = useSelector((state) => state.search.ploading);
  const pepName = useSelector((state) => state.search.pepName);
  const adverseMediaName = useSelector((state) => state.search.adverseMediaName);
  const adloading = useSelector((state) => state.search.adloading);

  const [generateLoading, setGenerateLoading] = useState(false);

  const [lastSearch, setLastSearch] = useState('');

  const [activeTab, setActiveTab] = useState('sanctions');
  // const [selectedResults, setSelectedResults] = useState([]);
  const [resultModal, setResultModal] = useState({ isOpen: false, type: 'sanctions' });

  const [requestBtn, setRequestBtn] = useState(false);

  const [includeImages, setIncludeImages] = useState(false);

  // const [redirect, setRedirect] = useState(false);

  const handleTabClick = (e) => {
    const { id } = e.currentTarget;
    if (searchParams.query !== undefined) {
      if (id === 'pep' && pepName !== searchParams.query) {
        dispatch(makePepSearch(searchParams.query));
      }
      if (id === 'adverseMedia' && (adverseMediaName !== searchParams.query || e?.includeImages !== undefined)) {
        dispatch(makeAdverseMediaSearch(searchParams.query, e?.includeImages ?? false));
      }
    }
    setActiveTab(id);
  };

  // const handleRowSelect = useMemo(() => ({ selectedRows }) => {
  //   setSelectedResults(selectedRows.map((r) => r.id));
  // }, [setSelectedResults]);
  // console.log('selectedResults: ', selectedResults);

  const fixedDate = (date) => {
    try {
      const givenDate = new Date(date);
      const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
      const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat
        .formatToParts(givenDate);
      return `${day} ${month} ${year}`;
    } catch (error) {
      return '';
    }
  };

  const handleResultClick = (type, id) => {
    const targetResult = type === 'sanctions' ? results : pepResults;
    const result = targetResult.find((r) => {
      if (type === 'sanctions') {
        return r.uuid === id;
      }
      return r.name.value === id;
    });

    if (!result) {
      return;
    }
    setResultModal({
      isOpen: true,
      data: result,
      type,
    });
  };

  const columns = (activeTab === 'sanctions' ? sanctionColumns : pepColumns);
  const titleCase = (str) => {
    if (str !== undefined) return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    return '';
  };
  // eslint-disable-next-line
  const data = useMemo(() => {
    if (activeTab === 'sanctions') {
      return results
        .filter((result) => parseInt(result.score, 10) >= searchParams.minNameScore)
        .map((result) => ({
          id: result.uuid,
          // eslint-disable-next-line
          name: titleCase(result?.name ?? ''),
          type: result.entity_type,
          source: result?.source?.name,
          nationality: titleCase(result?.citizenship ?? result?.nationality ?? ''),
          score: parseInt(result.score, 10),
        }));
    }
    if (activeTab === 'pep') {
      return pepResults.map((result) => ({
        id: result.name.value,
        name: result.name.value,
        office: result.office.value,
        gender: result.gender?.value,
        birthplace: result.birth_place?.value,
        birthdate: fixedDate(result.birth_date?.value),
        nationality: '',
      }));
    }
    return [];
  }, [activeTab, adverseMediaResults, pepResults, results, searchParams.minNameScore]);

  const getAllReports = (type) => {
    const reportInterval = setInterval(async () => {
      const { data: { reports: allRep } } = await api.getGeneratedReports(userId);
      const report = allRep.filter((r) => r?.search?.id === searchId)[0];
      // eslint-disable-next-line
      if (report?.is_available) {
        toastr.options = {
          timeOut: 0,
          extendedTimeOut: 0,
          closeButton: true,
          onclick() { window.open(report.url, '_blank'); },
        };
        toastr.clear();
        toastr.success('Single search result report is ready to download. Please click here to download the report.', `DOWNLOAD ${type.toUpperCase()} REPORT`);
        clearInterval(reportInterval);
      }
    }, 2000);
  };

  const handleGenerate = async (type = 'pdf') => {
    if (lastSearchId !== null && lastSearch.trim().length > 0) {
      if (activeTab === 'sanctions') {
        setGenerateLoading(true);
        const { data: { search } } = await api.advancedSearch({
          name: searchParams.query,
          id_number: searchParams.idNumber,
          score: parseInt(searchParams.minNameScore, 10),
          birth_date: {
            start: searchParams.birthDate.start ? `01/01/${searchParams.birthDate.start}` : null,
            end: searchParams.birthDate.end ? `31/12/${searchParams.birthDate.end}` : null,
          },
          birth_place: searchParams.birthPlace,
          entity_type: searchParams.type.toString(),
          country: searchParams.country.map((c) => c.label),
          settings: {
            aka: searchParams.aka,
            // no log when make new search for filtered pdf file
            no_logging: true,
            phonetics: searchParams.phonetics,
          },
        });
        searchId = search.id;
        setGenerateLoading(false);
      } else {
        searchId = lastSearchId;
      }
      dispatch(requestFile(searchId, type));
      if (lastSearch.trim().length > 0) {
        toastr.info('Single search result report is being generated. Please wait...', `PREPARING TO DOWNLOAD ${type.toUpperCase()}`);
        getAllReports(type);
      }
    }
  };

  useEffect(() => {
    if (results.length === 0 && status === 'ok') {
      setLastSearch(searchParams.query);
    }
    if (status === 'ok') setLastSearch(searchParams.query);
  }, [results, status]);

  // useEffect(() => {
  //   if (redirect) {
  //     setTimeout(() => {
  //       history.push('/saved-reports');
  //     }, 2000);
  //   }
  // }, [redirect]);

  const noResultsFound = () => (
    <div className="px-5">
      <Row>
        <Col
          className="d-flex"
          style={{
            borderBottom: '2px solid #50a5f1',
            borderTop: '2px solid #50a5f1',
          }}
        >
          <div>
            <i
              className="mdi mdi-information"
              style={{
                fontSize: '60px',
                color: '#50a5f1',
              }}
            />
          </div>
          <div
            className="align-self-center mt-3 ml-2"
            style={{
              fontSize: '16px',
              fontWeight: '500',
            }}
          >
            <p>
              No matches found for  &quot;
              {lastSearch ?? searchParams.idNumber}
              &quot;.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <h3>Search Tips</h3>
          <ul
            className="list-group list-group-flush ml-3"
            style={{
              fontSize: '14px',
            }}
          >
            <li>
              Check your spelling and try again
            </li>
            <li>Lower the sensitivity bar of fuzzy logic</li>
            <li>Try to eliminate one or more term </li>
            <li>
              Try to omit one or more name component e.g.
              try “Aly Soliman” instead of “Aly Soliman Massoud Abdul Sayed”
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        <div style={{
          fontWeight: '500',
          width: '130px',
        }}
        >
          <p>Need assistance?</p>
          <a href="mailto:info@sanctiontrace.com">
            <Button
              color="info"
              className="d-flex mr-2"
              style={{
                fontWeight: '500',
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Contact Us
            </Button>
          </a>
        </div>
      </Row>
    </div>
  );
  return (
    <Card>
      <CardBody className="pt-2 pr-2">
        <Row className="d-flex">
          <div>
            <Nav tabs className="nav-tabs-custom mb-2">
              {tabs.map((tab) => (
                <NavItem key={tab.id}>
                  <NavLink
                    id={tab.id}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                      fontSize: '14px',
                    }}
                    className={activeTab === tab.id ? 'active tab-active' : ''}
                    onClick={handleTabClick}
                  >
                    <span className="d-none d-sm-block">{tab.title}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <div className="d-flex ml-auto mr-1 p-2">
            {activeTab === 'sanctions' && (
              <Dropdown
                isOpen={requestBtn}
                toggle={() => setRequestBtn(!requestBtn)}
              >
                <DropdownToggle
                  disabled={lastSearch.trim().length === 0 || generateLoading}
                  className="btn d-flex mr-2 align-items-center btn-secondary"
                  caret
                  style={{
                    paddingTop: '3px',
                    paddingBottom: '2px',
                  }}
                >
                  <i
                    className="mdi mdi-download-outline"
                    style={{
                      fontSize: '18px',
                      marginRight: '11px',
                      marginTop: '3px',
                    }}
                  />
                  {generateLoading ? 'Generating...' : 'Generate Report'}
                  <i className="mdi mdi-chevron-down ml-auto" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="dropdown-item-report"
                    onClick={() => handleGenerate('excel')}
                  >
                    <i
                      className="mdi mdi-microsoft-excel mr-2"
                    />
                    <span>Excel</span>
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-report"
                    tag={Link}
                    to={`/exports/previews/searches/${searchId}`}
                    target="_blank"
                  >
                    <i
                      className="mdi mdi-file-pdf-box-outline mr-2"
                    />
                    <span>Pdf</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            <Button
              color="secondary"
              className="font-16 d-flex mr-2 searchShare"
              onClick={() => setShareModal(true)}
            >
              <i className="bx bx-share-alt font-size-20" />
              Share
            </Button>
          </div>
          <Col lg="12" className="mt-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="adverseMedia" className={lastSearch.trim().length === 0 ? '' : 'pr-2 mr-1'}>
                <div>
                  <div className="custom-control custom-checkbox pb-3">
                    <input
                      disabled={adloading}
                      id="includeImagesCheckbox"
                      type="checkbox"
                      className="custom-control-input"
                      checked={includeImages}
                      onChange={() => {
                        setIncludeImages(!includeImages);
                        handleTabClick({
                          currentTarget: {
                            id: 'adverseMedia',
                          },
                          includeImages: !includeImages,
                        });
                      }}
                    />
                    <label htmlFor="includeImagesCheckbox" className="custom-control-label">
                      Include Images
                    </label>
                  </div>
                </div>
                {adloading && lastSearch.trim().length > 0
                  && (
                    <div className="d-flex align-items-center justify-content-center">
                      <Spinner className="mr-2" color="secondary" />
                    </div>
                  )}
                {(lastSearch.trim().length > 0 && adverseMediaResults.length === 0 && !adloading)
                  && noResultsFound()}
                {lastSearch.trim().length === 0 && (
                  <div className="d-flex justify-content-center">
                    <p>Please make a search</p>
                    {' '}
                  </div>
                )}
                {
                  adverseMediaResults.length > 0 && lastSearch.trim().length > 0 && (
                    adverseMediaResults.map((r) => (
                      <Card className="adverseMediaNewsCard">
                        <CardBody className="d-flex pb-2 justify-content-start">
                          <div className="mr-3">
                            <CardSubtitle
                              tag="h6"
                              className="mb-2 d-flex align-items-center adverMediaNewsSource"
                            >
                              {/* <img
                                src={r.sourceLogo}
                                alt="source logo"
                              /> */}
                              <span>
                                {r?.source?.name}
                              </span>
                            </CardSubtitle>
                            <CardTitle>
                              <a
                                className="adverseMediaNewsTitle"
                                href={r.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {r.title}
                              </a>
                            </CardTitle>
                            <span className="mr-2 text-muted adverseMediaNewsDetails">
                              {r.description}
                            </span>
                            <p className="mt-2 mb-2 text-muted adverMediaNewsTimeDifference">
                              {elapsedTime(r.date)}
                            </p>
                          </div>
                          {r?.image !== undefined && r?.image.length > 0 && (
                          <div className="ml-auto">
                            <img
                              src={r.image}
                              className="adverMediaNewsImg"
                              alt={r.title}
                            />
                          </div>
)}
                        </CardBody>
                      </Card>
                    ))
                  )
                }
              </TabPane>
              <TabPane tabId={activeTab !== 'adverseMedia' && activeTab}>
                <DataTable
                  noDataComponent={((lastSearch.trim().length > 0
                    || searchParams.idNumber.length > 0)
                    && data.length === 0)
                    ? noResultsFound()
                    : <p>Please make a search</p>}
                  progressPending={ploading}
                  progressComponent={<Spinner className="mr-2" color="secondary" />}
                  defaultSortField="score"
                  defaultSortAsc={false}
                  onRowClicked={(e) => {
                    handleResultClick(activeTab, e.id);
                  }}
                  columns={columns}
                  customStyles={customTableStyles}
                  data={data}
                  pagination
                  highlightOnHover
                  noHeader
                />
              </TabPane>
            </TabContent>
          </Col>
          <SearchResultModal
            isOpen={resultModal.isOpen}
            data={resultModal.data}
            type={resultModal.type}
            onClose={() => {
              setResultModal({ isOpen: false });
            }}
          />
        </Row>
      </CardBody>
    </Card>
  );
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  pepResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  adverseMediaResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  setShareModal: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    query: PropTypes.string,
    minNameScore: PropTypes.number,
    idNumber: PropTypes.string,
    birthPlace: PropTypes.string,
    type: PropTypes.string,
    birthDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    country: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    aka: PropTypes.bool,
    phonetics: PropTypes.bool,
    noLogging: PropTypes.bool,
  }).isRequired,
};

export default SearchResults;
