import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';

import * as classes from './index.module.scss';

const { RangePicker } = DatePicker;

const dateFormatList = ['YYYY-MM-DD', 'DD/MM/YYYY', 'DD/MM/YY'];

function DateRange({
  className,
  size,
  format,
  onChange,
}) {
  const onRangeChange = (value, dateString) => {
    const start = value?.[0] ? moment(value[0]).format(dateFormatList[0]) : null;
    const end = value?.[1] ? moment(value[1]).format(dateFormatList[0]) : null;

    onChange({
      value,
      dateString,
      start,
      end,
    });
  };

  return (
    <RangePicker
      className={[
        classes['date-range'],
        classes[`date-range--${size}`],
        className,
      ].join(' ')}
      format={format}
      onChange={onRangeChange}
    />
  );
}

DateRange.defaultProps = {
  className: 'test',
  size: 'md',
  format: dateFormatList[1],
  onChange: () => {},
};

DateRange.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  format: PropTypes.oneOf(dateFormatList),
  onChange: PropTypes.func,
};

export default DateRange;
