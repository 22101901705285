// import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  units,
  formatNumberToTwoDigit,
  isValidDate,
} from 'helpers';

export function useDate() {
  const { language, translate } = useLanguage();

  const secondsToString = (seconds = 0) => {
    const unitName = Object.keys(units).find((unit) => (seconds >= units[unit]));
    const unitTime = Math.round(seconds / units[unitName]);

    if (unitTime >= 1) {
      return translate(`globals.date.${unitName}`, unitTime);
    }
    return null;
  };

  const elapsedTime = (startTime = new Date()) => {
    const endTime = new Date();
    const timeDiff = (endTime - new Date(startTime)) / 1000; // ms to seconds

    const timeString = secondsToString(timeDiff);
    if (timeString) {
      return translate('globals.date.ago', [timeString]);
    }
    return null;
  };

  const formatTime = (date = null) => {
    if (!date && !isValidDate(date)) {
      return {};
    }
    const d = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat(language, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });

    const { day, month, year } = dateTimeFormat.formatToParts(d)
      .reduce((prev, curr) => {
        prev[curr?.type] = curr.value;
        return prev;
      }, {});

    const hours = formatNumberToTwoDigit(d.getHours()) ?? 0;
    const minutes = formatNumberToTwoDigit(d.getMinutes()) ?? 0;

    return {
      minutes,
      hours,
      day: day ?? 0,
      month: month ?? 0,
      year: year ?? 0,
    };
  };

  const formatDate = (date = '') => {
    const {
      minutes,
      hours,
      day,
      month,
      year,
    } = formatTime(date);

    if (minutes && hours && day && month && year) {
      return `${day} ${month} ${year} ${hours}:${minutes}`;
    }
    return '';
  };

  return ({
    secondsToString,
    elapsedTime,
    formatTime,
    formatDate,
  });
}

export default useDate;
