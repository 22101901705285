import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container, Row, CardBody, Card, Col, Button, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import MapsVector from './MapsVector';
import CountryResultsModal from './CountryResultsModal';

import '../../assets/css/sanction-map.css';

import { customTableStyles, mapColumns } from './constants';

import { changeRoute, resetRoute } from '../../store/reducers/route';
import Autocomplete from '../../components/AutoComplete';

import CancelConfirmModal from '../../components/CancelConfirmModal';
import { useAPI } from '../../services/api';

function SanctionMap() {
  const api = useAPI();

  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  const [query, setQuery] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  // eslint-disable-next-line
  const [toggleModal, setToggleModal] = useState(false);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  // eslint-disable-next-line
  const [clearSelected, setClearSelected] = useState(false);

  const [errorForAbove10, setErrorForAbove10] = useState(false);

  const [mapData, setMapData] = useState([]);

  const [allCountryNames, setAllCountryNames] = useState([]);

  const getAllCountries = async () => {
    const { data: { countries: allCountries } } = await api.getFactbookCountries();
    setMapData(allCountries);
    setAllCountryNames(allCountries.map(({ name }) => name));
    setInitialized(true);
  };

  useEffect(() => {
    dispatch(changeRoute('World-Factbook-Map'));
    if (!initialized) {
      getAllCountries();
    }
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  const onFilter = () => {
    if (query.trim() !== null) {
      const f = mapData.filter((m) => m.name.toLowerCase()
        .includes(query.trim().toLowerCase()));
      setFilteredData(f);
    } else {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (query.trim().length === 0) setFilteredData([]);
  }, [query]);

  return (
    <>
      <CancelConfirmModal
        // isOpen={(selectedRows[0]?.code.toLowerCase() !== 'tu' && toggleModal) || errorForAbove10}
        isOpen={errorForAbove10}
        onConfirm={() => {
          setErrorForAbove10(false);
          setToggleModal(false);
        }}
        onCancel={() => {
          setErrorForAbove10(false);
          setToggleModal(false);
        }}
      >
        You are currently enjoying your free trial.
        Please contact to your system administrator
        to get full access and view all countries.
      </CancelConfirmModal>
      <CountryResultsModal
        // toggleModal={toggleModal && selectedRows[0]?.code.toLowerCase() === 'tu'}
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        selectedCountries={selectedRows}
        setSelectedCountries={setSelectedRows}
      />
      <Container fluid>
        <MapsVector
          coloredData={{}}
          setSelectedCountry={setSelectedRows}
          mapData={mapData}
          setToggleModal={setToggleModal}
          setClearSelected={setClearSelected}
        />
        <Card className="mb-0">
          <CardBody>
            <Row>
              <Col>
                <Autocomplete
                  setInput={setQuery}
                  placeholder="Search..."
                  suggestions={allCountryNames}
                />
              </Col>
              <Col md="3">
                <Button
                  color="primary"
                  className="btn waves-effect waves-light"
                  style={{
                    width: '100%',
                  }}
                  onClick={onFilter}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <DataTable
              clearSelectedRows={clearSelected}
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              defaultSortField="name"
              defaultSortAsc
              pagination
              paginationPerPage={35}
              onSelectedRowsChange={(e) => {
                setSelectedRows(e.selectedRows);
              }}
              onRowClicked={(e) => {
                setSelectedRows([e]);
                setToggleModal(true);
                // setErrorForAbove10(true);
                setClearSelected(true);
                setTimeout(() => {
                  setClearSelected(false);
                }, 500);
              }}
              columns={mapColumns}
              customStyles={customTableStyles}
              data={filteredData.length === 0 ? mapData : filteredData}
              highlightOnHover
              noHeader
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default SanctionMap;
