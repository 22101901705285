export const transactionCustomTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    style: {
      height: '65px',
      fontWeight: '700',
      cursor: 'pointer',
    },
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
    },
  },
};

export const transactionColumns = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    width: '155px',
  },
  {
    name: 'Sender',
    selector: 'sender',
    sortable: true,
    minWidth: '170px',
  },
  {
    name: 'Beneficiary',
    selector: 'beneficiary',
    sortable: true,
    minWidth: '170px',
    overflowY: true,
  },
  {
    name: 'Wallet No',
    selector: 'walletNo',
    sortable: true,
    width: '110px',
  },
  {
    name: 'Payment Type',
    selector: 'paymentType',
    sortable: true,
    minWidth: '155px',
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    minWidth: '60px',
  },
  {
    name: 'Currency',
    selector: 'currency',
    sortable: true,
    width: '85px',
  },
  {
    name: 'Sender IP',
    selector: 'senderIP',
    sortable: true,
    width: '130px',
  },
  {
    name: 'Beneficiary IP',
    selector: 'beneficiaryIP',
    sortable: true,
    width: '131px',
  },
  {
    name: 'Elapsed Time',
    selector: 'elapsedTime',
    sortable: true,
    width: '128px',
  },
];

export const getFormattedTime = (d, isHour = true) => {
  try {
    const date = new Date(d);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month }, ,
      { value: day }, ,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);

    const hh = date.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = date.getMinutes();
    const minutes = m < 10 ? `0${m}` : m;
    if (isHour) return `${day} ${month} ${year} at ${hours}:${minutes}`;
    return `${day} ${month} ${year}`;
  } catch (error) {
    return '';
  }
};

export const getCurrentDate = (range) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  if (range) return `${yyyy}/${mm}/${dd}`;
  return `${yyyy}-${mm}-${dd}`;
};

export const filterTransactionHistory = ({
  history, query, filters, fromDate, toDate, minAmount, maxAmount,
  selectedTransactionCurrencies,
  selectedTransactionSenders,
  selectedTransactionBeneficiaries,
}) => {
  let filtered = history;
  if (filters.length > 0) {
    // filter by amount
    filtered = history.filter(({ amount }) => (amount >= minAmount && amount <= maxAmount));

    // filter by date
    if (fromDate !== '' && toDate !== '') {
      filtered = filtered.filter((f) => {
        try {
          const date = new Date(f.date).setHours(0, 0, 0, 0);
          const frDate = new Date(fromDate).setHours(0, 0, 0, 0);
          const tDate = new Date(toDate).setHours(0, 0, 0, 0);
          if (new Date(date) >= new Date(frDate)
          && new Date(date) <= new Date(tDate)) {
            return true;
          }
          return false;
        } catch (error) {
          return true;
        }
      });
    }
    // filter search query
    if (query.trim().length > 0) {
      filtered = filtered.filter(
        (f) => {
          const lower = (text) => text.toLowerCase().trim();
          const trimmedQuery = lower(query).trim();
          return lower(f?.sender ?? '').includes(trimmedQuery)
          || lower(f?.beneficiary ?? '').includes(trimmedQuery)
          || lower(f?.walletNo ?? '').includes(trimmedQuery)
          || lower(f?.paymentType ?? '').includes(trimmedQuery)
          || lower(f?.senderIP ?? '').includes(trimmedQuery)
          || lower(f?.beneficiaryIP ?? '').includes(trimmedQuery)
          || lower(f?.elapsedTime ?? '').includes(trimmedQuery);
        },
      );
    }
    if (selectedTransactionCurrencies.length > 0) {
      const currencies = selectedTransactionCurrencies.map(({ value }) => value);
      filtered = filtered.filter(
        ({ currency }) => currencies.includes(currency),
      );
    }
    if (selectedTransactionSenders.length > 0) {
      const senders = selectedTransactionSenders.map(({ value }) => value);
      filtered = filtered.filter(
        ({ sender }) => senders.includes(sender),
      );
    }
    if (selectedTransactionBeneficiaries.length > 0) {
      const beneficiaries = selectedTransactionBeneficiaries.map(({ value }) => value);
      filtered = filtered.filter(
        ({ beneficiary }) => beneficiaries.includes(beneficiary),
      );
    }
    return filtered;
  }

  return history;
};
