import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

const fixedDate = (date) => {
  const givenDate = new Date(date);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
    .formatToParts(givenDate);

  return `${day} ${month} ${year}`;
};

// eslint-disable-next-line
function EntryRow({ masterId, id, name, lists, createdBy, profileData, handleSelected, selectedEntries }) {
  const profileDetailRef = useRef();
  const [toggleLists, setToggleLists] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);

  const EntryProfileDetails = ({ profile }) => (
    <div
      ref={profileDetailRef}
      className={classnames({
        'profile-details-modal': true,
        'd-block': toggleDetails,
        'd-none': !toggleDetails,
      })}
    >
      <div className="form-group row">
        <button
          type="button"
          onClick={() => setToggleDetails(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={{
            height: '24px',
            marginLeft: 'auto',
            marginTop: '-1px',
            fontSize: '24px',
            fontWeight: 400,
            color: '#B9C6E0',
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="profile-details-modal-content">
        <div className="form-group row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">Name</span>
          <span className="col-md-10 color-black line-height-15">{profile?.name}</span>
        </div>
        <div className="form-group row profile-details-row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">ID number</span>
          {/* eslint-disable-next-line */}
          <span className="col-md-10 color-black line-height-15">{profile?.id_number ?? 'N/A'}</span>
        </div>
        <div className="form-group row profile-details-row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">Email</span>
          <span className="col-md-10 color-black line-height-15">{profile?.email ?? 'N/A'}</span>
        </div>
        <div className="form-group row profile-details-row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">Date of birth</span>
          <span className="col-md-10 color-black line-height-15">{profile?.birth_date !== null ? fixedDate(profile.birth_date) : 'N/A'}</span>
        </div>
        <div className="form-group row profile-details-row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">Telephone</span>
          <span className="col-md-10 color-black line-height-15">{profile.phone ?? 'N/A'}</span>
        </div>
        <div className="form-group row profile-details-row">
          <span className="col-md-2 color-black font-weight-500 line-height-15">Notes</span>
          <span className="col-md-10 color-black line-height-15">{profile.notes ?? 'N/A'}</span>
        </div>
      </div>
    </div>
  );
  EntryProfileDetails.propTypes = {
    profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id_number: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      birth_date: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      notes: PropTypes.string.isRequired,
    }).isRequired,
  };

  useEffect(() => {
    const outsideClickListener = (e) => {
      if (profileDetailRef.current && !profileDetailRef.current.contains(e.target)) {
        setToggleDetails(false);
      }
    };

    document.addEventListener('mousedown', outsideClickListener);
    document.addEventListener('touchstart', outsideClickListener);
    return () => {
      document.removeEventListener('mousedown', outsideClickListener);
      document.removeEventListener('touchstart', outsideClickListener);
    };
  }, [id]);

  return (
    <>
      <EntryProfileDetails profile={profileData} />
      <div
        className="entry-profiles"
      >
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id={id}
            name={name}
            value={name}
            checked={selectedEntries.some((ent) => ent.id === id) && selectedEntries.filter((ent) => (ent.id === id))[0]?.value !== 'new'}
            onChange={(e) => {
              handleSelected(parseInt(e.target.id, 10), name, name, masterId);
            }}
          />
          <label htmlFor={id}>&nbsp;</label>
        </div>
        <div
          className="d-grid"
        >
          <span className="text-muted mb-8">
            Profile
          </span>
          <span>
            {name}
          </span>
        </div>
        <div className="d-grid">
          <span className="text-muted mb-8">Lists</span>
          <Dropdown

            isOpen={toggleLists}
            toggle={() => setToggleLists(!toggleLists)}
          >
            <DropdownToggle tag="button" className="btn btn-link pl-0 pt-0">
              {lists.map((list) => list.list_name).join(', ').length > 19
                ? `${lists.map((list) => list.list_name).join(', ').substring(0, 20)}...`
                : lists.map((list) => list.list_name).join(', ')}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                className="dropdown-lists-header d-flex align-items-center"
                header
              >
                <span className="font-size-13">Lists</span>
                <button
                  type="button"
                  onClick={() => setToggleLists(false)}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    height: '24px',
                    marginLeft: 'auto',
                    marginTop: '-1px',
                    fontSize: '24px',
                    fontWeight: 400,
                    color: '#B9C6E0',
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </DropdownItem>
              {lists.map((list) => (
                <DropdownItem className="dropdown-lists-item">
                  <Link to={`/lists/${list.list_id}`} target="_blank">
                    {list.list_name}
                  </Link>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {' '}
        </div>
        <div className="d-grid">
          <span className="text-muted mb-8">Created by</span>
          <span>{createdBy}</span>
        </div>
        <div className="d-grid align-items-center">
          <Button
            color="primary"
            className="btn waves-effect waves-light text-white"
            style={{
              display: 'grid',
              alignContent: 'center',
              height: '27px',
              width: '107px',
              padding: '0px',
            }}
            onClick={() => {
              setToggleDetails(true);
            }}
          >
            View details
          </Button>
        </div>
      </div>
    </>
  );
}

EntryRow.propTypes = {
  masterId: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  handleSelected: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({
    list_id: PropTypes.number.isRequired,
    list_name: PropTypes.string.isRequired,
    list_created_by: PropTypes.string.isRequired,
  })).isRequired,
  selectedEntries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  profileData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id_number: PropTypes.string.isRequired,
    birth_date: PropTypes.string.isRequired,
    creation_date: PropTypes.string.isRequired,
    created_by: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
  }).isRequired,
};

export default EntryRow;
