import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

function DashboardLayout({ children }) {
  // eslint-disable-next-line
  const [expanded, setExpanded] = useState(true);
  return (
    <div id="layout-wrapper">
      <Header expanded={expanded} />
      <Sidebar expanded={expanded} setExpanded={setExpanded} />
      <div className="main-content" aria-expanded={expanded}>
        <div
          className="page-content pb-0"
        >
          {children}
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
