import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { fetchUser, updateUser } from 'store/users';

import { Loading } from 'components/ui';
import ResetPassword from './ResetPassword';
import AccountDetails from './AccountDetails';

function Settings() {
  const dispatch = useDispatch();
  const { id: userId } = useSelector((state) => state.auth.user);
  // TODO: update users2 selector
  const {
    pending,
    currentUser: {
      email,
      name,
      title,
      department,
      phone,
    },
  } = useSelector((state) => state.users2);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId]);

  const onUpdateUser = (values) => {
    dispatch(updateUser({ userId, ...values }));
  };

  if (pending) {
    return <Loading />;
  }
  return (
    <Row gutter={[16, 16]} justify="center">
      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
        <AccountDetails
          isDisabled={pending}
          email={email}
          name={name}
          title={title}
          department={department}
          phone={phone}
          onUpdateUser={onUpdateUser}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
        <ResetPassword isDisabled={pending} onUpdateUser={onUpdateUser} />
      </Col>
    </Row>
  );
}

export default Settings;
