import React from 'react';
import PropTypes from 'prop-types';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.scss';

function Vectormap(props) {
  return (
    <div style={{
      width: '100%',
      height: '65vh',
    }}
    >
      <VectorMap
        onRegionClick={props.onRegionClick}
        map={props.value}
        backgroundColor="transparent"
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
        regionStyle={{
          initial: {
            fill: props.color,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            fill: '#2938bc', // what colour clicked country will be
          },
          selectedHover: {},

        }}
        containerClassName="map"
        series={{
          regions: [
            {
              values: props.coloredData, // this is your data
              // control numerical values, remove to give specific colors to regions
              scale: ['#D1E1FA', '#141F2F'], // your color game's here
              normalizeFunction: 'polynomial',
            },
          ],
        }}
      />
    </div>
  );
}

Vectormap.propTypes = {
  coloredData: PropTypes.shape(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onRegionClick: PropTypes.func.isRequired,
};

export default Vectormap;
