import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSources } from 'store/sources';
import { useLanguage } from 'contexts/LanguageContext';

import { Card, Table } from 'components/ui';

import useColumns from './useColumns';

function Sources() {
  const { translate } = useLanguage();

  const dispatch = useDispatch();
  const { pending, data } = useSelector((state) => state.sources);

  const columnTitles = useMemo(() => translate('dashboard.sources.table.columns'), [translate]);
  const sourceColumns = useColumns(columnTitles);

  useEffect(() => {
    dispatch(fetchSources());
  }, [dispatch]);

  return (
    <Card className="_dashboard__sources">
      <h3>{translate('dashboard.sources.header')}</h3>
      <Table
        loading={pending}
        columns={sourceColumns}
        dataSource={data}
        rowKey="id"
      />
      <div className="mt-3">
        <div className="copyright">
          <p>Copyright © 2020 Sanction Trace.</p>
        </div>
      </div>
    </Card>
  );
}

export default Sources;
