import React from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';

// TODO: delete component after new design
import AddProfile from './AddProfile';

// TODO: update design
function ClientCreate() {
  const history = useHistory();
  const { screeningId } = useParams();

  if (!screeningId) {
    return <Redirect to="/screening" />;
  }

  return (
    <AddProfile
      listId={screeningId}
      setToggleModal={() => {
        history.push(`/screening/${screeningId}`);
      }}
    />
  );
}

export default ClientCreate;
