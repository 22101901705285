import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardTitle, Media, Button, Modal,
} from 'reactstrap';

import { useAPI } from '../../services/api';

function Timeline({ listId, listData }) {
  const api = useAPI();

  const [logs, setLogs] = useState(null);
  const [timelineModal, setTimelineModal] = useState(false);

  // eslint-disable-next-line
  const [years, setYears] = useState([]);

  const getLogs = useCallback(async () => {
    try {
      const {
        // eslint-disable-next-line
        data: { list_logs },
      } = await api.getAutomatedListLogs(listId);
      // const allYears = new Set();
      // allYears.add

      const allYears = new Set();
      // Check different years
      list_logs.map((l) => {
        const year = new Date(l.creation_date).getFullYear();
        allYears.add(year);
        return year;
      });

      const logsByYear = {};
      // Add logs to an object by year
      allYears.forEach((year) => {
        const yearLogs = list_logs.filter((l) => {
          const logYear = new Date(l.creation_date).getFullYear();
          return logYear === year;
        });
        // eslint-disable-next-line
        Object.assign(logsByYear,{[year]:yearLogs});
        return year;
      });
      setLogs(logsByYear);
    } catch (error) {
      setLogs([]);
    }

    // eslint-disable-next-line
  }, [listId, listData]);

  useEffect(() => {
    getLogs();
  }, [getLogs, listData]);

  const logElement = (logText, logDate, mt, logId) => {
    const date = new Date(logDate);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const hh = date.getHours();
    const hours = hh < 10 ? 0 + hh : hh;
    const m = date.getMinutes();
    const minute = m < 10 ? `0${m}` : m;
    return (
      <li key={logId.toString()} className="event-list active">
        <div
          className="event-timeline-dot"
          style={{ display: 'flex', marginTop: mt }}
        >
          <i
            style={{ color: 'rgba(76, 132, 241, 0.75)' }}
            className="bx bxs-right-arrow-circle font-size-18"
          />
          <Media className={mt !== '0px' ? 'ml-3 mr-2' : 'ml-3 mr-2'}>
            <Media body>
              <div style={{ wordBreak: 'normal' }}>{logText}</div>
            </Media>
          </Media>
          <p
            className={
              mt !== '0px'
                ? 'font-size-14 ml-auto mr-4'
                : 'font-size-14 ml-auto'
            }
            style={{ whiteSpace: 'nowrap' }}
          >
            {day}
            {' '}
            {month}
            ,
            {' '}
            {hours}
            :
            {minute}
          </p>
        </div>
      </li>
    );
  };
  const currentClients = listData?.clients ?? [];
  const t = currentClients.length > 10 ? 10 : currentClients.length;
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <>
      <Card style={{ paddingRight: '5px' }}>
        <CardBody>
          <CardTitle className="mb-2">List Timeline</CardTitle>

          { logs !== null && Object.keys(logs).sort((a, b) => b - a).map((y, ind) => (
            <div key={y}>
              {ind === 0
                && (
                <div key={Math.floor(Math.random() * Math.floor(1000)) + 3}>
                  <p className="mb-3 font-weight-bold">{y}</p>
                  <ul className="verti-timeline list-unstyled">
                    {logs[y].map((data, index) => {
                      if (index >= 5) {
                        return '';
                      }
                      return logElement(data.log, data.creation_date, '0px', `t${data.id}`);
                    })}
                  </ul>
                </div>
                )}
            </div>
          ))}

          { logs !== null && (logs[`${currentYear}`]?.length - 1 >= t) && (
            <div
              className="text-center"
              style={{
                paddingTop: '2px',
              }}
            >
              <Button
                color="primary"
                className="btn waves-effect waves-light"
                style={{ height: '36px' }}
                onClick={() => setTimelineModal(true)}
              >
                View More
                {' '}
                <i className="mdi mdi-arrow-right ml-1" />
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        size="xl"
        isOpen={timelineModal}
        toggle={() => setTimelineModal(false)}
        centered
      >
        <Card>
          <CardBody>
            <CardTitle>
              <div style={{ display: 'flex', padding: '0px' }}>
                <h5 className="modal-title mt-0">List Timeline</h5>
                {/* eslint-disable-next-line */}
                <div
                  className="d-flex ml-auto"
                  style={{
                    alignItems: 'center',
                  }}
                >
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    marginLeft: 'auto',
                    fontSize: '24px',
                    padding: '0px',
                  }}
                  onClick={() => {
                    setTimelineModal(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </CardTitle>
            <div
              style={{
                maxHeight: '70vh',
                overflowY: 'auto',
              }}
            >
              { logs !== null && Object
                .keys(logs).sort((a, b) => b - a).map((y) => (
                  <div key={y}>
                    <h6 className="mt-3 ml-1">{y}</h6>
                    <ul className="verti-timeline list-unstyled mt-4 ml-1">
                      {logs[y].map((data) => logElement(data.log, data.creation_date, '12px', `s${data.id}`))}
                    </ul>
                  </div>
                ))}
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

Timeline.propTypes = {
  listId: PropTypes.number.isRequired,
  listData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    clients: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      score: PropTypes.number,
      id_number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      creation_date: PropTypes.string.isRequired,
      is_tailing: PropTypes.bool.isRequired,
      status: PropTypes.number.isRequired,

    })).isRequired,
    clients_count: PropTypes.number.isRequired,
    creation_date: PropTypes.string.isRequired,
    creator_name: PropTypes.string.isRequired,
    details: PropTypes.bool.isRequired,
    score: PropTypes.number.isRequired,
    sensivity: PropTypes.number.isRequired,
    states: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};

export default Timeline;
