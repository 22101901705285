import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

function Welcome({ userName }) {
  const { translate } = useLanguage();

  return (
    <Row>
      <Col>
        <p className="_dashboard__welcome">
          {translate('dashboard.main.header', [userName], { count: userName ? 1 : 0 })}
        </p>
      </Col>
    </Row>
  );
}

Welcome.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Welcome;
