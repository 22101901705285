import React from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from 'contexts/LanguageContext';

import { Card, Button } from 'components/ui';

import image from './map.png';

function Map() {
  const { translate } = useLanguage();

  return (
    <Card className="_dashboard__map">
      <div>
        <Link to="/map">
          <img src={image} width="100%" height="100%" alt="Sanction Map" />
        </Link>
      </div>
      <Link
        to="/map"
        className="_dashboard__map-view-map"
      >
        <Button color="primary">
          {translate('dashboard.main.map.viewMap')}
        </Button>
      </Link>
    </Card>
  );
}

export default Map;
