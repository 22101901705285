import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import Button from 'components/ui/Button';

function Modal({
  children,
  visible,
  title,
  footer,
  onOk,
  onCancel,
}) {
  const { translate } = useLanguage();

  return (
    <AntdModal
      visible={visible}
      title={title}
      wrapClassName="py-1"
      onCancel={onCancel}
      onOk={onOk}
      footer={footer || [
        <Button key="back" color="cancel" className="mr-3" onClick={onCancel}>
          {translate('globals.cancel')}
        </Button>,
        <Button key="submit" color="danger" className="px-4" onClick={onOk}>
          {translate('globals.delete')}
        </Button>,
      ]}
    >
      {children}
    </AntdModal>
  );
}

Modal.defaultProps = {
  visible: false,
  footer: null,
  onCancel: () => {},
  onOk: () => {},
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
};
export default Modal;
