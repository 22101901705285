import ClientCreate from './Create';
import ClientList from './List';
import ClientDetail from './Detail';

const Client = ClientList;
Client.List = ClientList;
Client.Detail = ClientDetail;
Client.Create = ClientCreate;

export {
  ClientList,
  ClientDetail,
  ClientCreate,
};

export default Client;
