import StatusFilter from './Status';
import DateFilter from './Date';

const Filters = StatusFilter;

Filters.Status = StatusFilter;
Filters.Date = DateFilter;

export {
  StatusFilter,
  DateFilter,
};

export default Filters;
