import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import {
  Card, CardBody, Button, Col, Row,
} from 'reactstrap';
import { useAPI } from '../services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function AcceptCard() {
  const api = useAPI();

  const q = useQuery();

  const [status, setStatus] = useState(null);

  const [btnColor, setBtnColor] = useState('primary');
  const [btnText, setBtnText] = useState('Accept');
  const acceptPermission = async () => {
    const listId = q.get('list_id');
    const permId = q.get('perm_id');
    const token = q.get('token');
    const setError = () => {
      setStatus('error');
      setBtnColor('danger');
    };

    try {
      if (listId !== null && permId !== null && token !== null) {
        const { data: { status: stt } } = await api.acceptRequestAccess(listId, permId, `?token=${token}`);
        if (stt === 'ok') {
          setStatus('success');
          setBtnColor('success');
          setBtnText('Success');
        } else {
          setError();
          setBtnText('Invalid Url');
        }
      } else {
        setError();
        setBtnText('Invalid Url');
      }
    } catch (error) {
      setError();
      setBtnText(error.response.data.message);
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: '90vh',
      }}
    >
      <Card style={{
        width: '400px',
      }}
      >
        <CardBody>
          <div>
            <Row className="pt-3">
              <Col className="d-flex justify-content-center">
                <i
                  className={classnames({
                    'align-middle': true,
                    bx: true,
                    'bx-check-circle': status === 'success',
                    'bxs-user': status === null,
                    'bx-error-circle': status === 'error',
                  })}
                  style={{
                    transition: '0.3s',
                    fontSize: '85px',
                    // eslint-disable-next-line
                    color: status === null ? '#74788D' : (
                      status === 'error' ? '#f46a6a' : '#34c38f'
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col style={{
                textAlign: 'center',
              }}
              >
                Please click the accept button to allow
                {' '}
                <span className="fw-500">{q.get('email') ?? ''}</span>
                {' '}
                to edit.
              </Col>
            </Row>
            <Row className="py-2">
              <Col className="d-flex justify-content-center">
                <Button
                  disabled={status === 'success' || status === 'error'}
                  color={btnColor}
                  className="btn waves-effect waves-light"
                  style={{
                    width: '250px',
                  }}
                  onClick={acceptPermission}
                >
                  {btnText}
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default AcceptCard;
