import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { authLogin, authCheckState, resetForgotPassword } from '../store/reducers/auth';

import '../assets/css/login.css';

import stImage from '../assets/images/st-image.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Login() {
  const query = useQuery();
  /* eslint-disable-next-line */
  const [searchUrl, setSearchUrl] = useState('');
  const [iEmail, setIEmail] = useState(localStorage.getItem('email') ?? '');

  const { error, loading } = useSelector(
    (state) => state.auth,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
    } = e.target.elements;

    localStorage.setItem('email', email);
    const redirectPath = query.get('form_id') ? `/form/${query.get('form_id')}` : searchUrl;
    dispatch(authLogin(email, password, history, redirectPath));
  }

  useEffect(() => () => {
    dispatch(resetForgotPassword());
  }, [dispatch]);

  useEffect(() => {
    if (query.get('q') !== null) {
      const q = query.get('q');
      const s = query.get('s');
      const p = query.get('phonetics');
      const aka = query.get('aka');
      setSearchUrl(`search?q=${q}&s=${s}&phonetics=${p}&aka=${aka}`);
    }
    dispatch(authCheckState());
  }, []);
  return (
    <div
      className="account-pages main-login"
    >
      <div style={{
        display: 'flex',
        paddingTop: '143px',
        height: '100%',
      }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md="auto"
              className="ml-3 p-0 stImage"
              style={{
                position: 'relative',
                left: '15px',
                top: error ? '-13px' : '-11px',
                zIndex: '1',
              }}
            >
              <img
                src={stImage}
                alt="Sanction Trace Login"
                className="m-0 p-0"
                width="377"
                height={error ? '105.5%' : '423'}
              />
            </Col>
            <Col
              md={8}
              lg={6}
              xl={5}
              className="pl-0 login-col"
            >
              <Card
                className="overflow-hidden"
                style={{
                  borderRadius: '0px 4px 4px 0px',
                  width: '100%',
                }}
              >
                <div>
                  <Row style={{
                    paddingTop: '17px',
                    paddingBottom: '15px',
                    height: '10px',
                  }}
                  >
                    <Col xs="12">
                      <div
                        className="p-4 d-flex justify-content-start"
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#495057',
                        }}
                      >
                        <p className="ml-1">
                          Sign in to continue to Sanction Trace
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody
                  className="pt-4"
                  style={{
                    background: 'white',
                    borderRadius: '4px',
                  }}
                >
                  <div className="p-2 pt-4">
                    <Form id="login-form" className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">Invalid email or password.</Alert>
                      )}
                      <div className="form-group">
                        <Label htmlFor="email">Email</Label>
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                          }}
                        >
                          <Input
                            autoFocus={localStorage.getItem('email') === null}
                            required
                            id="email"
                            name="email"
                            type="email"
                            value={iEmail}
                            onChange={(e) => setIEmail(e.target.value)}
                            placeholder="Enter email"
                          />

                          {iEmail !== null && iEmail.length > 0 && (
                          /* eslint-disable-next-line */
                          <span
                            className="closeButton"
                            onClick={() => {
                              localStorage.setItem('email', '');
                              setIEmail('');
                            }}
                          >
                            <i className="mdi mdi-close" />
                          </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <Label htmlFor="password">Password</Label>
                        <Input
                          autoFocus={localStorage.getItem('email') !== null}
                          required
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter password"
                        />
                      </div>
                      <div className="mt-5">
                        <button
                          id="signin-btn"
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          Sign In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Login;
