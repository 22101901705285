import React from 'react';
import PropTypes from 'prop-types';

function BasicInfo({
  iconClass, title, data, subtitle,
}) {
  return (
    <div className="_dashboard__basic-info mini-stats-wid">
      <div className="d-flex align-items-center">
        <div className="pt-0">
          <p className="_dashboard__basic-info-title">{title}</p>
          <p className="_dashboard__basic-info-data">{data}</p>
          {subtitle && (
            <p className="_dashboard__basic-info-subtitle">
              {subtitle}
            </p>
          )}
        </div>
        <div className="_dashboard__basic-info-icon-wrapper">
          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
            <span className="avatar-title">
              <i className={iconClass} />
            </span>
          </div>
        </div>
      </div>
    </div>

  );
}

BasicInfo.defaultProps = {
  data: '-',
  subtitle: null,
};

BasicInfo.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default BasicInfo;
