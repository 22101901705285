import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row, Col, Card,
} from 'reactstrap';

import map from '../assets/images/lists/map.png';
import pattern from '../assets/images/lists/what-the-hex.png';

function CardWelcome({ listId, listName, creatorName }) {
  const { data } = useSelector((state) => state.lists);
  const [wlistName, setListName] = useState(listName);

  useEffect(() => {
    const temp = data.filter((row) => row.id === parseInt(listId, 10))[0];
    setListName(temp?.name ?? '');
  }, [data, listId]);

  return (
    <>
      <Card
        className="overflow-hidden m-0"
        style={{
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
      >
        <div
          style={{
            background:
              'linear-gradient(198.8deg, #4482CC 6.16%, #447ECC 10.44%, #4553C7 59.99%, #3B44BC 74.8%, #2B2DAB 103.29%, #2525A5 121.26%',
          }}
        >
          <Row
            className="align-self-center"
            style={{
              background: `url(${pattern})`,
              zIndex: 3,
              paddingTop: '14px',
              paddingBottom: '5px',
              paddingLeft: '15px',
            }}
          >
            <Col
              xs="7"
              className="align-self-center"
            >
              <h5 style={{ color: 'white' }}>
                Created by
                {' '}
                {creatorName}
              </h5>
              <h4 style={{ color: 'white' }}>{wlistName}</h4>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={map} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

export default CardWelcome;

CardWelcome.propTypes = {
  listId: PropTypes.number.isRequired,
  listName: PropTypes.string.isRequired,
  creatorName: PropTypes.string.isRequired,
};
