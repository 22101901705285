import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DataTable from 'react-data-table-component';
import {
  Card, CardBody, Row, Col, Spinner,
} from 'reactstrap';
import { useAPI } from '../services/api';
import '../assets/css/client-documents.css';
import FileCard from './FileCard';
import CancelConfirmModal from './CancelConfirmModal';

const getFormattedTime = (date, dmy = false) => {
  try {
    const d = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(d);

    const hh = d.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = d.getMinutes();
    const minutes = m < 10 ? `0${m}` : m;
    if (dmy) {
      return `${day} ${month} ${year}`;
    }
    return `${day} ${month} ${year} at ${hours}.${minutes}`;
  } catch (error) {
    return '';
  }
};

const onSortRows = (rows, field, direction) => {
  const ab = rows.sort((a, b) => {
    if (field === 'date') {
      if (new Date(a.pureDate) < new Date(b.pureDate)) {
        return -1;
      }
      if (new Date(a) > new Date(b)) {
        return 1;
      }
    } else if (field === 'docName') {
      if (a.pureName.toLowerCase() < b.pureName.toLowerCase()) {
        return -1;
      }
      if (a.pureName.toLowerCase() > b.pureName.toLowerCase()) {
        return 1;
      }
    } else if (field === 'size') {
      if (a.pureSize < b.pureSize) {
        return -1;
      }
      if (a.pureSize > b.pureSize) {
        return 1;
      }
    }
    return 0;
  });
  if (direction === 'asc') return ab.slice(0);
  return ab.slice(0).reverse();
};

function ClientDocuments({
  listId, clientId, cantEdit, requestAccessToggle,
}) {
  const api = useAPI();

  const docColumns = [
    {
      name: '',
      selector: 'pdfIcon',
      width: '72px',
      sortable: false,
    },
    {
      name: 'Documents',
      selector: 'docName',
      sortable: true,
    },
    {
      name: 'Uploaded date',
      selector: 'date',
      width: '135px',
      sortable: true,
    },
    {
      name: 'Size',
      selector: 'size',
      width: '120px',
      sortable: true,
    },
    {
      name: 'Action',
      selector: 'action',
      width: '125px',
      sortable: false,
    },
  ];
  const customTableStyles = {
    headCells: {
      style: {
        background: '#F8F8F8',
        color: '#495057',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '13px',
        lineHeight: '15px',
        height: '43px',
      },
      sortFocus: {
        color: '#FFFFFF',
      },
    },
    rows: {
      style: {
        fontWeight: '700',
        border: 'none',
        background: '#F8F8F8',
        marginTop: '11px',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#f0f0f0',
      },
    },
  };

  const fileRef = React.createRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  // eslint-disable-next-line
  const [completed, setCompleted] = useState(false);
  // eslint-disable-next-line
  const [initialized, setInitialized] = useState(false);
  // eslint-disable-next-line
  const [status, setStatus] = useState(null);
  // eslint-disable-next-line
  const [documents, setDocuments] = useState([]);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [confirmToggle, setConfirmToggle] = useState('');
  const [docId, setDocId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const getDocuments = async () => {
    if (typeof listId === 'number' && typeof clientId === 'number') {
      const { data: { documents: docs } } = await api.getClientDocuments(listId, clientId);
      setDocuments(docs.reverse());
    }
  };

  const deleteFile = async () => {
    await api.deleteClientDocument(listId, clientId, docId);
    setDocuments((prevState) => prevState.filter((d) => d?.id !== docId));
    setConfirmToggle(false);
  };

  const fixedData = useMemo(() => documents.filter((d) => {
    if (searchText.trim().length === 0) {
      return true;
    }
    if (d.filename.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  }).map((d) => ({
    pdfIcon: (
      // eslint-disable-next-line
      <div>
        <i className="bx bxs-file-blank doc-table-file-icon align-middle" />
      </div>),
    pureName: d.filename,
    docName: (
    // eslint-disable-next-line
      <div className="doc-filename-creator">
        <p>{d.filename}</p>
        <p>{d.created_by.name}</p>
      </div>),
    pureDate: d.creation_date,
    date: <p className="doc-table-cell">{getFormattedTime(d.creation_date, true)}</p>,
    pureSize: parseInt(d.size, 10),
    size: (
      <p className="doc-table-cell">
        {parseInt(d.size, 10)}
        {' '}
        Kb
      </p>),
    action: (
      // eslint-disable-next-line
      <div className="ml-auto">
        <button
          type="button"
          className="btn btn-link waves-effect waves-light px-0"
          onClick={() => {
            window.open(d?.url, '_blank');
          }}
        >
          <i className="mdi mdi-file-eye doc-file-eye" />
        </button>
        <button
          type="button"
          className="btn btn-link py-0 px-0 ml-4"
          onClick={() => {
            setSelectedFileName(d.filename);
            setDocId(d.id);
            setConfirmToggle(true);
          }}
        >
          <i className="mdi mdi-delete doc-file-delete" />
        </button>
      </div>),
  })), [searchText, documents]);

  const uploadFiles = async (pdfFiles) => {
    if (!cantEdit) {
      setInitialized(true);
      setProgress(20);
      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('percentCompleted', percentCompleted);
          if (percentCompleted === 100) {
            setTimeout(() => {
              setProgress(percentCompleted);
            }, 1000);
            setTimeout(() => {
              setProgress(-1);
            }, 1600);
          }
        },
      };
      try {
        const data = new FormData();
        for (let i = 0; i < pdfFiles.length; i += 1) {
          data.append('documents', pdfFiles[i], pdfFiles[i].name);
        }
        const { data: { documents: docs } } = await api
          .postClientDocuments(listId, clientId, data, config);
        setTimeout(() => {
          setCompleted(true);
          setInitialized(false);
          setSelectedFiles((prevState) => {
            if (docs.length < prevState.length) {
              let errorFiles = prevState.filter((s) => docs.some((d) => d.filename !== s.name));
              const dcs = docs.map((d) => ({ ...d, isError: false, uploaded: true }));
              errorFiles = errorFiles.map((f) => ({ file: f, isError: true, uploaded: false }));
              return [...errorFiles, ...dcs];
            }
            return docs.map((d) => ({ ...d, isError: false, uploaded: true }));
          });
        }, 1000);
        setTimeout(() => {
          setCompleted(false);
          getDocuments();
        }, 1600);
      } catch (e) {
        setTimeout(() => {
          setProgress(-1);
          setStatus('error');
          setCompleted(true);
          setInitialized(false);
          setSelectedFiles((prevState) => prevState
            .map((d) => ({ file: d.file, isError: true, uploaded: false })));
        }, 1000);
        setTimeout(() => {
          setCompleted(false);
        }, 1600);
        console.log('error');
      }
    }
  };

  const fileSelectedHandler = (e1) => {
    const typeArray = [
      'application/pdf',
    ];
    try {
      const { files } = e1.target;
      const pdfFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        if (typeArray.indexOf(files[i].type.toLowerCase()) > -1) {
          pdfFiles.push(files[i]);
        }
      }
      setSelectedFiles(pdfFiles.map((f) => ({ file: f, uploaded: false, isError: false })));
      uploadFiles(pdfFiles);
    } catch (e) {
      console.log('error');
    }
  };
  useEffect(() => {
    getDocuments();
  }, [listId, clientId]);
  useEffect(() => {
    getDocuments();
  }, []);
  return (
    <div>
      <CancelConfirmModal
        isOpen={confirmToggle}
        onConfirm={deleteFile}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn
        selectedName
      >
        Are you sure you want to delete
        {' '}
        `
        <b>{selectedFileName}</b>
        `
        {' '}
        ? If you say yes, you cannot undo this step.
      </CancelConfirmModal>
      <Card>
        <CardBody className="pt-2">
          <Row>
            <Col>
              <p className="selected-files-header pt-0 pb-3">Upload document</p>
            </Col>
          </Row>
          <Row>
            <Col md="9" className="pr-0">
              <div className="custom-file">
                <input
                  ref={fileRef}
                  disabled={initialized || cantEdit}
                  type="file"
                  accept="application/pdf"
                  className="custom-file-input"
                  id="documents"
                  multiple
                  onChange={(e) => {
                    fileSelectedHandler(e);
                  }}
                />
                <label className="custom-file-label" htmlFor="client-documents">Click to upload files...</label>
              </div>
            </Col>
            <Col md="3" className="pl-0">
              <button
                disabled={initialized}
                type="button"
                className={classnames({
                  'waves-effect': true,
                  'waves-light': true,
                  btn: true,
                  'btn-primary': true,

                })}
                onClick={() => {
                  if (cantEdit) {
                    requestAccessToggle(true);
                  } else {
                    fileRef.current.click();
                  }
                }}
                style={{
                  width: '100%',
                  borderRadius: '0px 4px 4px 0px',
                }}
              >
                Upload
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {selectedFiles.length > 0 && (
      <Row>
        <Col>
          <div className="list-group list-group-flush upload-file-name-wrapper px-4">
            {selectedFiles.map((f, idx) => {
              if (selectedFiles.length - 1 === idx) {
                return (
                  <>
                    <FileCard
                      file={f}
                      progress={progress}
                      status={status}
                      setInitialized={setInitialized}
                      setCompleted={setCompleted}
                      listId={listId}
                      clientId={clientId}
                      getDocuments={getDocuments}
                      setStatus={setStatus}
                      setSelectedFiles={setSelectedFiles}
                      setProgress={setProgress}
                      setDocuments={setDocuments}
                    />
                    <div style={{
                      height: '1px',
                      borderBottom: '1px solid #CED4DA',
                    }}
                    />
                  </>
                );
              }
              return (
                <FileCard
                  file={f}
                  progress={progress}
                  status={status}
                  setInitialized={setInitialized}
                  setCompleted={setCompleted}
                  listId={listId}
                  clientId={clientId}
                  getDocuments={getDocuments}
                  setStatus={setStatus}
                  setSelectedFiles={setSelectedFiles}
                  setProgress={setProgress}
                  setDocuments={setDocuments}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      )}
      <Row>
        <Col className="doc-table-wrapper">
          <p className="selected-files-header">{documents.length > 0 ? 'Documents' : 'No documents found' }</p>
          <input
            autoComplete="on"
            className="form-control"
            type="text"
            placeholder="Search..."
            style={{ width: '277px' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="doc-file-list">
            <DataTable
              allowOverflow
              overflowY
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              paginationPerPage={30}
              defaultSortField="date"
              defaultSortAsc={false}
              sortFunction={onSortRows}
              columns={docColumns}
              customStyles={customTableStyles}
              data={fixedData}
              pagination
              highlightOnHover
              noHeader
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

ClientDocuments.propTypes = {
  listId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};
export default ClientDocuments;
