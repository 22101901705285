/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import {
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Button,
  Input,
  Spinner,
} from 'reactstrap';

import DataTable from 'react-data-table-component';
import { removeEmpty } from 'helpers';
import { useDebounce } from 'hooks';
import CancelConfirmModal from './CancelConfirmModal';

import {
  deleteClient,
  deleteSomeClients,
  loadListItem,
} from '../store/reducers/lists';

import AddProfile from './AddProfile';
import ClientDetails from './ClientDetails';

import '../assets/css/client.css';
import { useAPI } from '../services/api';

const getFormattedTime = (date) => {
  try {
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);

    const hh = date.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = date.getMinutes();
    const minutes = m < 10 ? `0${m}` : m;
    return `${day} ${month} ${year} at ${hours}:${minutes}`;
  } catch (error) {
    return '';
  }
};

function CardProfileList({ listId, cantEdit, requestAccessToggle }) {
  const api = useAPI();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterQuery, setFilterQuery] = useState('');
  const [queryParams, setQueryParams] = useState({
    ordering: '-creation_date',
    search: '',
  });
  const {
    data: clientData,
    isLoading: isClientLoading,
    isFetching: isClientFetching,
    isRefetching: isClientRefetching,
  } = useQuery(
    ['clients', listId, page, rowsPerPage, queryParams],
    () => api.getClients(listId, page, rowsPerPage, {
      ...removeEmpty(queryParams),
    }),
    { keepPreviousData: true },
  );

  const { clients, totalRows } = useMemo(() => {
    if (clientData) {
      const {
        data: {
          previous, next, results, count,
        },
      } = clientData;
      return {
        previous,
        next,
        clients: results,
        totalRows: count,
      };
    }
    return { previous: null, next: null, clients: [] };
  }, [clientData]);

  let loadInterval;
  const list = useSelector(({ lists: { data } }) => data
    .find((l) => l.id === parseInt(listId, 10)));

  const loading = useSelector((state) => state.lists.loading);

  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  const [selectedRows, setSelectedRows] = useState({});

  const [selectedName, setSelectedName] = useState('');

  const [selectCount, setSelectCount] = useState(0);

  const [toggleModal, setToggleModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [clearSelected, setClearSelected] = useState(false);

  const [confirmToggle, setConfirmToggle] = useState(false);
  const [confirmAllToggle, setConfirmAllToggle] = useState(false);
  const [baseId, setbaseId] = useState(0);
  const [clientName, setClientName] = useState('');

  const [dataId, setDataId] = useState(0);

  const checkState = async () => {
    const {
      data: { progress: progrss },
    } = await api.getStates(listId);
    setProgress(parseInt(progrss, 10));
  };

  const setStatusInterval = () => {
    loadInterval = setInterval(async () => {
      const {
        data: { states, progress: progrss },
      } = await api.getStates(listId);
      setProgress(parseInt(progrss, 10));
      const searching = states.filter((s) => {
        if (
          s.toLowerCase() === 'not searched'
          || s.toLowerCase() === 'searching'
          || s.toLowerCase().includes('searching')
        ) {
          return true;
        }
        return false;
      });
      if (searching.length === 0 && progrss === 100) {
        setProgress(100);
        dispatch(loadListItem(listId));
        clearInterval(loadInterval);
      }
    }, 5000);
  };

  useEffect(() => {
    dispatch(loadListItem(listId));
    checkState();
    setStatusInterval();
    // eslint-disable-next-line
    return () => {
      clearInterval(loadInterval);
    };
  }, [listId, detailModal, clearSelected]);

  useEffect(() => {
    checkState();
  }, [list]);

  const handleDeleteSelected = () => {
    /* Delete selected rows with */
    if (selectedRows.length > 0) {
      dispatch(deleteSomeClients(listId, selectedRows));
    }
    setClearSelected(true);
    setTimeout(() => {
      setClearSelected(false);
    }, 100);
  };

  const handleDelete = useCallback(() => {
    setClearSelected(true);
    setTimeout(() => {
      setClearSelected(false);
    }, 100);
    const id = dataId;
    const client = list.clients.find(
      (c) => c.user_client_id === parseInt(id, 10),
    );
    if (!client) {
      return;
    }
    dispatch(deleteClient(list.id, client.user_client_id));
  }, [dispatch, list?.clients, list?.id, dataId]);

  const handleViewDetails = (e) => {
    const id = typeof e === 'number' ? e : e.currentTarget.getAttribute('data-id');
    setDetailModal(id);
  };

  const handleNewClientClick = useCallback(() => {
    setToggleModal(true);
  }, []);

  const showConfirmAlert = (e) => {
    setDataId(e.currentTarget.getAttribute('data-id'));
    setSelectedName(e.currentTarget.id);
    setConfirmToggle(true);
  };

  const data = useMemo(() => {
    const listClients = clients ?? [];
    return listClients.map(
      ({
        pk: normalId,
        user_client: {
          pk: id,
          name,
          status,
          creation_date: creationDate,
          is_tailing: tail,
        },
      }) => {
        const getStatusText = (statusCode) => {
          switch (statusCode) {
            case 0:
              return 'Searching';
            case 1:
              return 'Error';
            case 2:
              return 'Searching';
            case 3:
              return 'Searching';
            case 4:
              return 'Clear ';
            case 5:
              return 'Need Action ';
            case 6:
              return 'Positive';
            case 7:
              return 'False Positive';
            default:
              return 'Error';
          }
        };
        const temp = tail === true
          ? ['Surveilling', getStatusText(status)]
          : [getStatusText(status)];
        return {
          id,
          normalId,
          name,
          sttas: temp,
          states: temp.map((state) => (
            // eslint-disable-next-line
            <span
              key={id}
              data-id={id}
              onClick={(e) => {
                e.persist();
                setbaseId(normalId);
                setClientName(name);
                handleViewDetails(e);
              }}
              className={classnames({
                badge: true,
                'font-size-12': true,
                'py-2': true,
                'px-4': true,
                'mx-1': true,
                'my-1': true,
                'font-weight-normal': true,
                /* eslint-disable react/prop-types */
                'badge-soft-primary': state.indexOf('Surveilling') >= 0,
                'badge-soft-success': state.indexOf('Clear') >= 0,
                'badge-soft-warning': state.indexOf('Need Action') >= 0,
                'badge-soft-secondary': state.indexOf('False Positive') >= 0,
                'badge-soft-danger':
                  state.indexOf('Positive') >= 0
                  && state.indexOf('False') === -1,
                'badge-soft-searching':
                  state.indexOf('Searching') >= 0 || state === null,
                /* eslint-enable react/prop-types */
              })}
            >
              {state}
            </span>
          )),
          pureDate: new Date(creationDate),
          creationDate: getFormattedTime(new Date(creationDate)),
          actions: (
            <div className="d-flex align-items-center">
              <button
                data-id={id}
                id={name}
                type="button"
                className="btn btn-link py-0 mr-2"
                onClick={(e) => {
                  if (cantEdit) {
                    requestAccessToggle(true);
                  } else {
                    showConfirmAlert(e);
                  }
                }}
              >
                <i className="mdi mdi-delete-outline font-size-24 delete" />
              </button>
              <button
                data-id={id}
                type="button"
                className="btn btn-primary py-0 view-details"
                onClick={(e) => {
                  e.persist();
                  setbaseId(normalId);
                  setClientName(name);
                  handleViewDetails(e);
                }}
              >
                View Details
              </button>
            </div>
          ),
        };
      },
    );
  }, [clients]);

  const onSortRows = useCallback((column, order) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ordering: `${order === 'asc' ? '' : '-'}${
        column?.sortField || column?.selector
      }`,
    }));
  }, []);

  const clientColumns = [
    {
      name: 'Name/Title',
      selector: 'name',
      sortable: true,
      sortField: 'name',
      // maxWidth: '500px',
    },
    {
      name: 'Status',
      selector: 'states',
      width: '160px',
      maxWidth: '450px',
      sortable: true,
      sortField: 'status',
    },
    {
      name: 'Created at',
      selector: 'creationDate',
      width: '155px',
      sortable: true,
      sortField: 'creation_date',
    },
    {
      name: '',
      selector: 'actions',
      width: '220px',
      paddingRight: '0px',
    },
  ];

  const customTableStyles = {
    headCells: {
      style: {
        background: '#F8F9FA',
        color: 'black',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        height: '44px',
      },
      sortFocus: {
        color: '#FFFFFF',
      },
    },
    rows: {
      style: {
        fontWeight: '700',
        cursor: 'pointer',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#CDE1FF',
      },
    },
  };

  const setDebouncedFilterQuery = useDebounce(
    (value) => setQueryParams((prevState) => ({ ...prevState, search: value })),
    600,
  );

  const filterData = (q) => {
    setFilterQuery(q);
    setDebouncedFilterQuery(q);
  };

  // load list item clientlar status için kontrol edilecek
  const onToggleModal = (val) => {
    setStatusInterval();
    setToggleModal(val);
    return () => {
      clearInterval(loadInterval);
    };
  };

  const onChangePage = (number) => {
    setPage(number);
  };
  const onChangeRowsPerPage = (number) => {
    setRowsPerPage(number);
  };

  return (
    <>
      <CancelConfirmModal
        isOpen={confirmToggle}
        onConfirm={() => {
          setConfirmToggle(false);
          handleDelete();
        }}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn
        selectedName
      >
        Are you sure want to delete `
        <b>{selectedName}</b>
        ` ? If you say yes,
        you cannot undo this step.
      </CancelConfirmModal>

      <CancelConfirmModal
        isOpen={confirmAllToggle}
        onConfirm={() => {
          handleDeleteSelected();
        }}
        onCancel={() => {
          setConfirmAllToggle(false);
        }}
        loading={loading}
        setIsOpen={setConfirmAllToggle}
        deleteUserBtn
        selectedCount={selectCount}
      >
        Are you sure want to delete
        {' '}
        <b>
          {selectCount}
          {' '}
          clients
        </b>
        ? If you say
        yes, you cannot undo this step.
      </CancelConfirmModal>

      <Card>
        <CardBody>
          <CardTitle className="mb-4 d-flex align-items-start">
            <span>Profile List</span>
            <div className="ml-auto">
              <button
                disabled={cantEdit}
                type="button"
                className="btn btn-link mr-2 text-dark mt-0 mb-0 pt-0 pb-0"
                style={{
                  visibility: selectedRows.length > 0 ? 'visible' : 'hidden',
                  opacity: selectedRows.length > 0 ? 100 : 0,
                  transition: '0.1s',
                }}
                onClick={() => {
                  setSelectCount(selectedRows.length);
                  setConfirmAllToggle(true);
                }}
              >
                <i className="mdi mdi-delete-outline font-size-24 delete" />
              </button>

              <Button
                disabled={progress !== 100}
                color="primary"
                className="btn waves-effect waves-light mr-2"
                style={{
                  width: '109px',
                  height: '36px',
                }}
                onClick={() => {
                  if (cantEdit) {
                    requestAccessToggle(true);
                  } else {
                    handleNewClientClick();
                  }
                }}
              >
                Add profile
              </Button>
            </div>
          </CardTitle>
          <div className="mb-2 pb-2 pt-3 d-flex align-items-center">
            <div className="w-50 mr-auto">
              <Input
                disabled={
                  progress !== 100
                  || isClientLoading
                  || isClientFetching
                  || isClientRefetching
                }
                autoFocus
                autoComplete="off"
                id="filter"
                type="search"
                className="form-control"
                placeholder="Search..."
                value={filterQuery}
                onChange={(e) => filterData(e.target.value)}
              />
            </div>

            {progress !== 100 && (
              <div className="text-muted d-flex align-items-center mr-2">
                <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2" />
                {progress}
                %
              </div>
            )}
          </div>
          {list.states.filter((s) => s.toLowerCase().includes('searching'))
            .length > 0 && (
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                gap: '15px',
              }}
            >
              {list.states
                .filter((s) => s.toLowerCase().includes('searching'))
                .map((s) => (
                  <div className="text-muted d-flex align-items-center pb-4">
                    <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2" />
                    {s}
                    ...
                  </div>
                ))}
            </div>
          )}
          <div className="overflow-auto">
            <DataTable
              disabled={progress !== 100}
              noDataComponent="There are no profiles to display"
              progressComponent={<Spinner className="mr-2" color="secondary" />}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50]}
              overflowY
              allowOverflow
              overflowYOffset="200px"
              sortServer
              onSort={onSortRows}
              defaultSortField="creationDate"
              defaultSortAsc={false}
              clearSelectedRows={clearSelected}
              selectableRowsVisibleOnly
              selectableRows={!cantEdit}
              onSelectedRowsChange={(e) => {
                setSelectedRows(e.selectedRows.map((r) => ({ id: r.id })));
              }}
              onRowClicked={(e) => {
                setbaseId(e.normalId);
                setClientName(e.name);
                handleViewDetails(e.id);
              }}
              columns={clientColumns}
              customStyles={customTableStyles}
              data={data.slice(0)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              highlightOnHover
              noHeader
            />
          </div>
        </CardBody>
      </Card>
      {/* Add new clients */}
      <Modal
        size="xl"
        isOpen={toggleModal}
        toggle={() => setToggleModal(false)}
        centered
      >
        <div style={{ display: 'flex', padding: '21px 35px' }}>
          <h5 className="modal-title mt-0">Add new profile</h5>
          <button
            type="button"
            onClick={() => setToggleModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginLeft: 'auto', fontSize: '24px' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <AddProfile listId={listId} setToggleModal={onToggleModal} />
        </ModalBody>
      </Modal>
      {/* Client details */}
      <Modal
        size="xl"
        isOpen={detailModal}
        toggle={() => setDetailModal(false)}
        centered
      >
        <ClientDetails
          clientName={clientName}
          cantEdit={cantEdit}
          listId={listId}
          clientId={detailModal}
          baseId={baseId}
          toggle={setDetailModal}
          requestAccessToggle={requestAccessToggle}
        />
      </Modal>
    </>
  );
}

CardProfileList.propTypes = {
  listId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};

export default CardProfileList;
