/* eslint-disable no-unused-vars */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from '../../services/api';

const initialState = {
  token: null,
  error: null,
  loading: false,
  user: null,
  status: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart(state) {
      state.token = null;
      state.error = null;
      state.user = null;
      state.loading = true;
    },
    authSuccess(state, action) {
      state.error = null;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loading = false;
    },
    authFail(state, action) {
      state.token = null;
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    authLogout(state) {
      state.error = null;
      state.loading = false;
      state.user = null;
      state.token = null;

      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate');
      localStorage.removeItem('user');
      api.setToken(null);
    },
    userSuccess(state, action) {
      state.user = action.payload;
      state.error = null;
    },
    userFailure(state, action) {
      state.user = null;
      state.error = action.payload;
    },

    startForgotPassword(state, action) {
      state.loading = true;
      state.status = action.payload;
    },
    successForgotPassword(state, action) {
      state.loading = false;
      state.status = action.payload;
    },
    failForgotPassword(state, action) {
      state.loading = false;
      state.status = action.payload;
    },
    resetForgotPassword(state) {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    resetStatesSuccess(state) {
      state.token = null;
      state.error = null;
      state.loading = false;
      state.user = null;
      state.status = null;
    },
  },
});

export const {
  authStart,
  authSuccess,
  authFail,
  authLogout,
  userSuccess,
  userFailure,
  startForgotPassword,
  successForgotPassword,
  failForgotPassword,
  resetForgotPassword,
  resetStatesSuccess,
} = authSlice.actions;

// selectors
export const isDemo = createSelector(
  (state) => state.auth.user.states,
  (states) => states.map((state) => {
    if (state === 'Demo') return true;
    return false;
  }),
);

export const authCheckState = () => (dispatch) => {
  const token = localStorage.getItem('token');
  if (token === undefined) {
    dispatch(authLogout());
  } else {
    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    if (expirationDate <= new Date()) {
      dispatch(authLogout());
    } else {
      api.setToken(token);
      dispatch(
        authSuccess({ token, user: JSON.parse(localStorage.getItem('user')) }),
      );
    }
  }
};

export const authLogin = (email, password, history, redirectPath = '') => async (dispatch) => {
  dispatch(authStart());
  try {
    const res = await api.login(email, password);

    const { token, user } = res.data;
    const expirationDate = new Date(new Date().getTime() + 3600 * 1000);

    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);
    localStorage.setItem('user', JSON.stringify(user));
    api.setToken(token);

    dispatch(authSuccess({ token, user }));
    dispatch(userSuccess(user));

    if (redirectPath.length > 0) {
      if (redirectPath.includes('form_id')) {
        history.push(redirectPath);
      } else history.push(redirectPath);
    } else if (user?.role === 4) {
      history.push('/onboarding');
    } else history.push('/dashboard');
  } catch (error) {
    dispatch(authFail(error.message));
    dispatch(userFailure(error.message));
  }
};

export const resetPassword = (email, token) => async (dispatch) => {
  dispatch(startForgotPassword());
  try {
    const { status } = await api.resetPassword(email, token);
    if (status === 200) {
      dispatch(successForgotPassword('ok'));
    } else {
      dispatch(failForgotPassword('error'));
    }
  } catch (error) {
    dispatch(failForgotPassword('error'));
  }
};
export const forgotPassword = (email) => async (dispatch) => {
  dispatch(startForgotPassword());
  try {
    const { data } = await api.forgotPassword(email);
    dispatch(successForgotPassword(data.status));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const resetStates = () => (dispatch) => {
  dispatch(resetStatesSuccess());
};

export default authSlice.reducer;
