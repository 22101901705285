import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Spinner,
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  Card,
  CardBody,
  ButtonDropdown,
  // ModalBody,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import ReactSelect from '../../components/Onboarding/ReactSelect';
import { changeRoute, resetRoute } from '../../store/reducers/route';
import '../../assets/css/form.css';
import FilterLabels from '../Onboarding/FilterLabels';
import {
  getCurrentDate,
  transactionColumns,
  transactionCustomTableStyles,
  filterTransactionHistory,
  getFormattedTime,
} from './constants';

const history = [{
  date: 'Thu Apr 22 2021 12:06:33 GMT+0300 (GMT+03:00)',
  sender: 'Arda Gürata',
  beneficiary: 'Eren Hançer',
  walletNo: '6543796',
  paymentType: 'Maaş/Kira/Hediye',
  amount: '200',
  currency: 'TL',
  senderIP: '101.513.23.5',
  beneficiaryIP: '125.5.21.45',
  elapsedTime: '4sn',
},
{
  date: 'Wed Apr 21 2021 12:29:34 GMT+0300 (GMT+03:00)',
  sender: 'Mehmet Ali',
  beneficiary: 'Ziya Gül',
  walletNo: '5543246',
  paymentType: 'Kredi Kartı Borcu',
  amount: '300.1',
  currency: 'USD',
  senderIP: '161.13.10.5',
  beneficiaryIP: '99.25.19.45',
  elapsedTime: '2sn',
},
];

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function InviteHistory() {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  // eslint-disable-next-line
  const [dataId, setDataId] = useState(null);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  // eslint-disable-next-line
  const [clearSelected, setClearSelected] = useState(false);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateDropdown, setDateDropdown] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(100);

  const [selectedTransactionCurrencies, setSelectedTransactionCurrencies] = useState([]);
  const [selectedTransactionSenders, setSelectedTransactionSenders] = useState([]);
  const [selectedTransactionBeneficiaries, setSelectedTransactionBeneficiaries] = useState([]);

  const onSortRows = (rows, field, direction) => {
    //  eslint-disable-next-line
    const ab = rows.sort((a, b) => {
      if (field === 'date') {
        if (new Date(a.date.props.id) < new Date(b.date.props.id)) {
          return -1;
        }
        if (new Date(a.date.props.id) > new Date(b.date.props.id)) {
          return 1;
        }
        return 0;
      }
      if (field === 'amount') {
        if (parseInt(a.date.props.id, 10) < parseInt(b.date.props.id, 10)) {
          return -1;
        }
        if (parseInt(a.date.props.id, 10) > parseInt(b.date.props.id, 10)) {
          return 1;
        }
        return 0;
      }
      if (a[field]?.props?.id < b[field]?.props?.id) {
        return -1;
      }
      if (a[field]?.props?.id > b[field]?.props?.id) {
        return 1;
      }
      return 0;
    });
    if (direction === 'asc') return ab.slice(0);
    return ab.slice(0).reverse();
  };

  const { defaultMaxAmount } = useMemo(() => {
    const amounts = history.map(({ amount }) => amount);
    const min = Math.min(...amounts);
    const max = Math.max(...amounts);
    return ({ defaultMaxAmount: max, defaultMinAmount: min });
  }, [history]);

  useEffect(() => {
    setMaxAmount(defaultMaxAmount);
  }, [defaultMaxAmount]);

  const onAmountChange = (e) => {
    e.persist();
    console.log(e.target.value);
    if (e.target.id === 'minAmount' || e.target.name === 'minAmount') {
      if (parseInt(e.target.value, 10) > maxAmount) {
        setMinAmount(maxAmount - 1);
      } else {
        const fixedMin = parseFloat(e.target.value, 10).toFixed(2);
        setMinAmount(fixedMin);
      }
    }
    if (e.target.id === 'maxAmount' || e.target.name === 'maxAmount') {
      if (parseInt(e.target.value, 10) <= minAmount) {
        setMaxAmount(minAmount + 1);
      } else {
        const fixedMax = parseFloat(e.target.value, 10).toFixed(2);
        setMaxAmount(fixedMax);
      }
    }
  };
  const handleScoreBlur = useCallback((e) => {
    e.persist();
    if (e.target.id === 'minAmount' || e.target.name === 'minAmount') {
      if (parseInt(e.target.value, 10) < 0) {
        setMinAmount(0);
      } else if (parseFloat(e.target.value, 10).toFixed(2) > maxAmount) {
        setMinAmount(maxAmount - 1);
      }
    }
    // if (e.target.id === 'maxAmount' || e.target.name === 'maxAmount') {
    //   if (parseInt(e.target.value, 10) < minAmount) {
    //     setMaxAmount(minAmount + 1);
    //   } else if (parseInt(e.target.value, 10) > 100) {
    //     setMaxAmount(100);
    //   }
    // }
  }, [minAmount, maxAmount]);

  const transactionCurrencies = useMemo(() => history
    .map(({ currency }) => currency)
    .filter(onlyUnique)
    .map((c) => ({
      value: c,
      label: c,
    })), [history]);

  const transactionSenders = useMemo(() => history
    .map(({ sender }) => sender)
    .filter(onlyUnique)
    .map((c) => ({
      value: c,
      label: c,
    })), [history]);

  const transactionBeneficiary = useMemo(() => history
    .map(({ beneficiary }) => beneficiary)
    .filter(onlyUnique)
    .map((c) => ({
      value: c,
      label: c,
    })), [history]);

  const filteredTransactionHistory = useMemo(() => filterTransactionHistory({
    history,
    filters,
    query: search,
    fromDate,
    toDate,
    minAmount: parseFloat(minAmount),
    maxAmount: parseFloat(maxAmount),
    selectedTransactionCurrencies,
    selectedTransactionSenders,
    selectedTransactionBeneficiaries,
  }), [history, filters, search,
    fromDate, toDate, minAmount, maxAmount,
    selectedTransactionCurrencies,
    selectedTransactionSenders,
    selectedTransactionBeneficiaries]);

  const fixedData = filteredTransactionHistory.map((d) => ({
    // eslint-disable-next-line
    date: <div id={d?.date}>{getFormattedTime(d?.date) ?? ''}</div>,
    sender: <div id={d?.sender}>{d?.sender ?? ''}</div>,
    beneficiary: <div id={d?.beneficiary}>{d?.beneficiary ?? ''}</div>,
    walletNo: <div id={d?.walletNo}>{d?.walletNo ?? ''}</div>,
    paymentType: <div id={d?.paymentType}>{d?.paymentType ?? ''}</div>,
    amount: <div id={d?.amount ?? 0}>{d?.amount ?? 0}</div>,
    currency: <div id={d?.currency}>{d?.currency ?? ''}</div>,
    senderIP: <div id={d?.senderIP}>{d?.senderIP ?? ''}</div>,
    beneficiaryIP: <div id={d?.beneficiaryIP}>{d?.beneficiaryIP ?? ''}</div>,
    elapsedTime: <div id={d?.elapsedTime}>{d?.elapsedTime ?? ''}</div>,
    actions: (
      <div className="d-flex">
        Actions
      </div>),
  }));

  const clearAllFilters = () => {
    setSearch('');
    setFromDate('');
    setToDate('');
    setMinAmount(0);
    setMaxAmount(defaultMaxAmount);
    setSelectedTransactionCurrencies([]);
    setSelectedTransactionSenders([]);
    setSelectedTransactionBeneficiaries([]);
  };

  useEffect(() => {
    const temp = [];
    // search query
    if (search.trim().length > 0) {
      temp.push({
        label: 'query',
        value: search.trim(),
        setFunction: () => { setSearch(''); },
      });
    }
    // date
    if (toDate !== '') {
      temp.push({
        label: 'date',
        value: `${getFormattedTime(fromDate, false)} - ${getFormattedTime(toDate, false)}`,
        setFunction: () => {
          setFromDate('');
          setToDate('');
        },
      });
    }
    if (selectedTransactionCurrencies.length > 0) {
      temp.push({
        label: 'currencies',
        value: selectedTransactionCurrencies.map(({ value }) => value).join(', '),
        setFunction: () => {
          setSelectedTransactionCurrencies([]);
        },
      });
    }
    if (selectedTransactionSenders.length > 0) {
      temp.push({
        label: 'senders',
        value: selectedTransactionSenders.map(({ label }) => label).join(', '),
        setFunction: () => {
          setSelectedTransactionSenders([]);
        },
      });
    }
    if (selectedTransactionBeneficiaries.length > 0) {
      temp.push({
        label: 'beneficiaries',
        value: selectedTransactionBeneficiaries.map(({ label }) => label).join(', '),
        setFunction: () => {
          setSelectedTransactionBeneficiaries([]);
        },
      });
    }
    temp.push({
      label: 'minMaxAmount',
      value: `${minAmount}-${maxAmount}`,
      setFunction: () => {
        setMinAmount(0);
        setMaxAmount(defaultMaxAmount);
      },
    });
    setFilters(temp);
  }, [search, fromDate, toDate, selectedTransactionCurrencies, minAmount, maxAmount,
    selectedTransactionSenders, selectedTransactionBeneficiaries]);

  useEffect(() => {
    dispatch(changeRoute('Transaction-Monitoring'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  return (
    <div>
      <Container fluid>
        <Card>
          <CardBody>
            <div
              className="mb-3 d-flex row align-items-center"
              style={{
                paddingLeft: '13px',
                paddingRight: '13px',
              }}
            >
              <input
                autoComplete="on"
                className="form-control col fs-13 lh-22 mb-3"
                type="text"
                placeholder="Search by keywords"
                style={{
                  height: '38px',
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="col-md-auto mb-3">
                <ButtonDropdown
                  className="w-100 button-dropdown height-dropdown"
                  isOpen={dateDropdown}
                  toggle={() => setDateDropdown(!dateDropdown)}
                >
                  <DropdownToggle caret color="white" className="btn d-flex align-items-center date-range fs-13 lh-22 text-color">
                    Date Range
                    <i
                      className="bx bxs-down-arrow ml-2"
                      style={{
                        fontSize: '10px',
                        color: dateDropdown ? 'rgb(139, 139, 139)' : '#CED4DA',
                        transition: '0.1s',
                      }}
                    />
                  </DropdownToggle>
                  <DropdownMenu className="date-range-dropdown-menu">
                    <CardBody>
                      <Row>
                        <Col>
                          <div className="mr-2">
                            <div>
                              <label className="font-weight-bold" htmlFor="s1">
                                From
                              </label>
                              <div className="form-group row ml-0 mt-3">
                                <div>
                                  <input
                                    className="form-control"
                                    type="date"
                                    defaultValue={getCurrentDate()}
                                    id="from-date"
                                    min="1900-01-01"
                                    max={getCurrentDate()}
                                    value={fromDate}
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mr-2">
                            <div>
                              <label className="font-weight-bold" htmlFor="s1">
                                To
                              </label>
                              <div className="form-group row ml-0 mt-3">
                                <div>
                                  <input
                                    disabled={fromDate.length === 0}
                                    className="form-control"
                                    type="date"
                                    defaultValue={getCurrentDate()}
                                    id="to-date"
                                    min={fromDate}
                                    max={getCurrentDate()}
                                    value={toDate}
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <div className="col-md-auto pl-0 mb-3">
                <ReactSelect
                  header="Currencies"
                  selectData={transactionCurrencies}
                  setSelected={setSelectedTransactionCurrencies}
                  getSelected={selectedTransactionCurrencies}
                  borderRight="1px solid #ced4da"
                />
              </div>
              <div className="col-md-2 pl-0 mb-3">
                <ReactSelect
                  header="Senders"
                  selectData={transactionSenders}
                  setSelected={setSelectedTransactionSenders}
                  getSelected={selectedTransactionSenders}
                  borderRight="1px solid #ced4da"
                />
              </div>
              <div className="col-md-2 pl-0 mb-3">
                <ReactSelect
                  header="Beneficiaries"
                  selectData={transactionBeneficiary}
                  setSelected={setSelectedTransactionBeneficiaries}
                  getSelected={selectedTransactionBeneficiaries}
                  borderRight="1px solid #ced4da"
                />
              </div>
              <Col
                md="auto"
                className="d-flex align-items-center px-0 mb-3"
              >
                <div className="d-flex align-items-center">
                  <label htmlFor="minAmount" className="mb-0 pr-2">Amount (min-max)</label>
                  <input
                    name="minAmount"
                    type="number"
                    min={0}
                    className="form-control"
                    value={minAmount}
                    onChange={onAmountChange}
                    onBlur={(e) => handleScoreBlur(e)}
                    style={{ width: '8.5em' }}
                  />
                </div>
                <div className="d-flex align-items-center pl-2">
                  <label htmlFor="maxAmount" className="mb-0 pr-2">-</label>
                  <input
                    name="maxAmount"
                    type="number"
                    min={minAmount}
                    className="form-control"
                    value={maxAmount}
                    onChange={onAmountChange}
                    onBlur={(e) => handleScoreBlur(e)}
                    style={{ width: '8.5em' }}
                  />
                </div>
              </Col>
            </div>
            <div>
              <Row style={{
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingBottom: '20px',
                paddingTop: '0px',
              }}
              >
                <FilterLabels filters={filters} clearAll={clearAllFilters} />
              </Row>
            </div>
            <div>
              <DataTable
                clearSelectedRows={clearSelected}
                progressPending={loading}
                progressComponent={<Spinner className="mr-2" color="secondary" />}
                defaultSortField="date"
                defaultSortAsc={false}
                columns={transactionColumns}
                customStyles={transactionCustomTableStyles}
                sortFunction={onSortRows}
                selectableRowsVisibleOnly
                selectableRows={false}
                onSelectedRowsChange={(e) => {
                  setSelectedRows(e.selectedRows.map((r) => ({ id: r.id })));
                  const rowDataId = e.selectedRows.map((r) => r.id).length === 0 ? null : 'selected';
                  setDataId(rowDataId);
                }}
                onRowClicked={(e) => {
                  setDataId(e.id);
                  // history.push(`/third-party-management/form/${e.id}`);
                }}
                data={fixedData}
                pagination
                highlightOnHover
                noHeader
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default InviteHistory;
