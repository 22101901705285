import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

// load lists
const initialState = {
  initialized: false,
  error: false,
  loading: false,
  data: [],
  listCount: 0,
  clientCount: 0,
};

const listsSlice = createSlice({
  name: 'automatedLists',
  initialState,
  reducers: {
    listStart(state) {
      state.error = null;
      state.loading = true;
      state.initialized = false;
    },
    listSuccess(state, action) {
      state.data = action.payload.map((l) => ({ ...l, details: false }));
      state.listCount = action.payload.map((l) => ({ ...l, details: false })).length;
      state.error = null;
      state.loading = false;
      state.initialized = true;
      let temp = 0;
      action.payload.map((l) => {
        temp += l.clients_count;
        return '';
      });
      state.clientCount = temp;
    },
    listFail(state) {
      state.error = true;
      state.loading = false;
      state.initialized = true;
    },
    listCreateSuccess(state, action) {
      state.data.push(action.payload.list);
      state.listCount = state.data.length;
      state.clientCount += action.payload.clientCount;
    },
    deleteListStart(state) {
      state.loading = true;
    },
    deleteListSuccess(state, action) {
      const filteredList = state.data.filter(
        (list) => list.id !== parseInt(action.payload, 10),
      );
      state.data = filteredList;
      state.listCount = filteredList.length;
      state.loading = false;
    },
    deleteClientSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.listId) {
          const temp = row.clients.filter(
            (client) => client.id !== action.payload.clientId,
          );
          return {
            ...row,
            clients: temp,
            clients_count: row.clients_count - 1,
          };
        }
        return row;
      });
      state.clientCount -= 1;
    },

    deleteSomeClientsStart(state) {
      state.error = null;
      state.loading = true;
    },

    deleteSomeClientsSuccess(state, action) {
      state.data = state.data.map((row) => {
        const temp = row.clients.filter((r) => action.payload.clients.some((z) => z !== r.id));
        if (row.id === action.payload.listId) {
          return {
            ...row,
            clients: temp,
            clients_count: row.clients_count - action.payload.clients.length,
          };
        }
        return row;
      });
      state.loading = false;
      state.clientCount -= action.payload.clients.length;
    },

    editListStart(state) {
      state.loading = true;
      state.status = '';
    },
    editListSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.id) {
          if (action.payload.listType === 3) {
            return {
              ...row,
              name: action.payload.name,
              sensivity: action.payload.sensivity,
              score: action.payload.score,
              options: action.payload.options,
              search_options: action.payload.search_options,
            };
          }
          return {
            ...row,
            name: action.payload.name,
            sensivity: action.payload.sensivity,
            score: action.payload.score,
            options: action.payload.options,
            search_options: action.payload.search_options,
            type: action.payload.type,
            url: action.payload.url,
            data_column: action.payload.data_column,
          };
        }
        return row;
      });
      state.loading = false;
      state.status = 'ok';
    },
    editListFail(state) {
      state.loading = false;
      state.status = 'error';
    },
    editListFinished(state) {
      state.loading = false;
      state.status = '';
    },

    getListItemSuccess(state, action) {
      state.data = state.data.map((row) => {
        if (row.id === action.payload.data.id) {
          return {
            ...action.payload.data,
            details: true,
          };
        }
        return row;
      });
    },

    editClientStart(state) {
      state.loading = true;
      state.status = '';
    },
    editClientSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
    },
    editClientFinished(state) {
      state.loading = false;
      state.status = '';
    },
    changeEntryCount(state, action) {
      state.clientCount += action.payload;
    },
  },
});

export const {
  listStart,
  listSuccess,
  listFail,
  listCreateSuccess,
  deleteListStart,
  deleteListSuccess,
  deleteClientSuccess,
  deleteSomeClientsStart,
  deleteSomeClientsSuccess,
  editListStart,
  editListSuccess,
  editListFail,
  editListFinished,
  editClientStart,
  editClientSuccess,
  editClientFinished,
  getListItemSuccess,
  changeEntryCount,
} = listsSlice.actions;

export const automatedListReducer = listsSlice.reducer;

export const loadLists = (cb = null) => async (dispatch) => {
  dispatch(listStart());
  try {
    const { data: { lists } } = await api.getAutomatedLists();
    const { data: { lists: onboardingLists } } = await api.getOnboardingLists();
    dispatch(listSuccess([...lists, ...onboardingLists]));
    if (cb) {
      cb(true);
    }
  } catch (error) {
    dispatch(listFail(true));
    if (cb) {
      cb(false);
    }
  }
};

export const loadListItem = (id, history) => async (dispatch) => {
  try {
    const { data } = await api.getAutomatedList(id);
    delete data.status;
    dispatch(getListItemSuccess({ data, id }));
  } catch (error) {
    if (history) {
      history.push('/automated-lists');
    }
  }
};

// TODO:: handle errors
export const deleteList = (id, closeFunction = () => {}) => async (dispatch) => {
  dispatch(deleteListStart());
  try {
    await api.deleteAutomatedList(id);
    dispatch(deleteListSuccess(id));
    closeFunction();
  } catch (error) {
    console.log('error: ', error);
  }
};

export const editList = (id,
  name,
  sensivity,
  score,
  type,
  url,
  dataColumn,
  options, searchOptions = {}, listType = 2) => async (dispatch) => {
  dispatch(editListStart());
  try {
    let data = {};
    // added for onboarding list edit
    if (listType === 3) {
      data = {
        id,
        name,
        sensivity,
        score,
        options,
        search_options: searchOptions,
      };
    } else {
      data = {
        id,
        name,
        sensivity,
        score,
        type,
        url,
        data_column: type === 2 ? parseInt(dataColumn, 10) : dataColumn,
        options,
        search_options: searchOptions,
      };
    }
    await api.editAutomatedList(id, data);
    // await api.pushAutomatedClients(id);
    dispatch(editListSuccess({ ...data, listType }));
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  } catch (error) {
    dispatch(editListFail());
    setTimeout(() => {
      dispatch(editListFinished());
    }, 1500);
  }
};

export const editClient = (listId, id, data) => async (dispatch) => {
  dispatch(editClientStart());
  try {
    const edited = await api.editAutomatedClient(listId, id, data);
    dispatch(editClientSuccess({ status: 'ok', client: edited.data.client }));
    setTimeout(() => {
      dispatch(editClientFinished());
    }, 1500);
  } catch (error) {
    console.log('error: ', error);
    dispatch(editClientFinished());
  }
};

// create lists
const createListInitialState = {
  status: '',
  loading: false,
  id: null,
  listName: '',
  clients: [],
};

const createListSlice = createSlice({
  name: 'createAutomatedList',
  initialState: createListInitialState,
  reducers: {
    createStart(state) {
      state.status = '';
      state.loading = true;
    },
    createSuccess(state, action) {
      state.loading = false;
      state.status = action.payload.status;
      state.id = action.payload.id;
    },
    createFail(state) {
      state.status = 'error';
      state.loading = false;
    },
    createFinished(state) {
      state.status = '';
      state.loading = false;
      state.id = null;
    },
  },
});

// actions
export const {
  createStart,
  createSuccess,
  createFail,
  createFinished,
} = createListSlice.actions;

export const createList = (listName, type, url, tagColumnName,
  score, options, searchOptions, history, setProgress = () => {}) => async (dispatch) => {
  try {
    dispatch(createStart());
    const { data: { status, list_id: id } } = await api.createAutomatedList({
      name: listName,
      sensivity: 2,
      score,
      type,
      url,
      // data_column: tagColumnName,
      // for excel file
      data_column: type === 2 ? parseInt(tagColumnName, 10) : tagColumnName,
      options,
      search_options: searchOptions,
    });
    if (status === 'error') {
      dispatch(createFail(status));
    } else if (status === 'ok') {
      setProgress(70);
      const { data: list } = await api.getAutomatedList(id);
      setProgress(85);
      try {
        await api.pushAutomatedClients(id);
      } catch (err) {
        console.error(err);
      }
      dispatch(createSuccess({ status, id }));
      setTimeout(() => {
        dispatch(listCreateSuccess({
          list,
        }));
        dispatch(createFinished());
        setProgress(100);
        if (history !== undefined) {
          history.push(`/automated-lists/${list.id}`);
        }
      }, 1000);
    }
  } catch (error) {
    dispatch(createFail(error.message));
    setTimeout(() => {
      dispatch(createFinished());
    }, 1000);
  }
};

// reducer
export const createAutomatedListReducer = createListSlice.reducer;

// load client
const clientsInitialState = {
  status: '',
  loading: false,
  client: {},
};

const clientSlice = createSlice({
  name: 'clientAutomated',
  initialState: clientsInitialState,
  reducers: {
    clientLoadStart(state) {
      state.status = '';
      state.loading = true;
    },
    clientLoadSuccess(state, action) {
      state.client = action.payload.client;
      state.status = action.payload.status;
      state.loading = false;
    },
    clientLoadFail(state) {
      state.status = 'error';
      state.loading = false;
    },
  },
});

export const {
  clientLoadStart,
  clientLoadSuccess,
  clientLoadFail,
  clientLoadFinished,
} = clientSlice.actions;

export const loadClient = (listId, clientId) => async (dispatch) => {
  dispatch(clientLoadStart());
  try {
    const { data } = await api.getAutomatedClient(listId, clientId);
    dispatch(clientLoadSuccess({ client: data.client, status: data.status }));
  } catch (error) {
    dispatch(clientLoadFail());
  }
};

export const loadAutomatedClientReducer = clientSlice.reducer;
