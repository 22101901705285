import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'antd';

import { updateList, currentList } from 'store/lists';
import { useLanguage } from 'contexts/LanguageContext';

import { Input, Button } from 'components/ui';

import { nameRules } from './constants';

import * as classes from './index.module.scss';

function Settings() {
  const dispatch = useDispatch();
  const { translate } = useLanguage();
  const [form] = Form.useForm();

  const {
    pending,
    list: {
      id,
      name,
      score,
      search_options: { aka: includeAka } = {},
    },
  } = useSelector(currentList);

  const [isFieldsTouched, setIsFieldsTouched] = useState(false);

  const initialValues = useMemo(() => ({
    name,
    score,
    aka: includeAka,
  }), [name, score, includeAka]);

  const formItem = useMemo(() => translate('screening.detail.settings.form'), [translate]);

  const onValuesChange = () => {
    setIsFieldsTouched(form.isFieldsTouched());
  };

  const onSubmit = (values) => {
    const { aka, ...rest } = values;

    dispatch(updateList({
      id,
      data: {
        ...rest,
        search_options: { aka },
      },
    }));
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <div className={classes.settings}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
      >
        <Row gutter={[72, 10]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            <Form.Item
              name="name"
              label={formItem?.name?.label}
              rules={nameRules(formItem.name.label, translate)}
            >
              <Input.Text
                disabled={pending}
                placeholder={formItem?.name?.placeholder}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            <Form.Item
              name="aka"
              label={formItem?.searchOptions?.label}
            >
              <Input.Checkbox disabled={pending}>
                {formItem?.searchOptions?.includeAka}
              </Input.Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            <Form.Item
              name="score"
              label={formItem?.score?.label}
            >
              <Input.Slider disabled={pending} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            <Form.Item
              label={formItem?.accessSettings?.label}
            >
              <Link to="/users">
                {formItem?.accessSettings?.manage}
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={classes['settings-submit']}>
            <Button
              disabled={!isFieldsTouched || pending}
              type="submit"
              color="primary"
            >
              {formItem.save}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Settings;
