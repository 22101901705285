import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import ReactApexChart from 'react-apexcharts';

import { useLanguage } from 'contexts/LanguageContext';

import { Card } from 'components/ui';
import StatusLabel from './StatusLabel';

function ListStatus({ series }) {
  const { translate } = useLanguage();

  const { needAction, clear, positive } = useMemo(() => translate('dashboard.main.listStatus.chart.labels'), [translate]);

  const chart = useMemo(() => ({
    options: {
      labels: [needAction, clear, positive],
      colors: ['#f1b44c', '#34C38F', '#F46A6A'],
      legend: { show: !1 },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
          },
        },
      },
    },
  }), [needAction, clear, positive]);

  return (
    <Card className="_dashboard__list-status">
      <h4 className="card-title">
        {translate('dashboard.main.listStatus.header')}
      </h4>
      <div>
        <div id="donut-chart" className="apex-charts">
          <ReactApexChart
            options={chart.options}
            series={series}
            type="donut"
            height={240}
          />
        </div>
        <div className="text-center text-muted">
          <Row>
            {series && chart.options.labels.map((label, index) => (
              <StatusLabel
                key={label}
                color={chart.options.colors[index]}
                label={label}
                value={series[index]}
              />
            ))}
          </Row>
        </div>
      </div>
    </Card>
  );
}

ListStatus.propTypes = {
  series: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ListStatus;
