import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Table,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';

function PermissionTable({
  lists,
  isAllSelected,
  onSelectAllChange,
  onItemCheckChange,
  onListPermissionChange,
}) {
  const saving = useSelector((state) => state.users.saving);
  return (
    <Row>
      <Col md="12">
        <div className="table-responsive">
          <Table className="table-bordered mb-0">
            <thead>
              <tr>
                <th>
                  <div className="custom-control custom-checkbox">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="select-all"
                      value="all"
                      disabled={saving}
                      checked={isAllSelected}
                      onChange={onSelectAllChange}
                    />
                    <Label className="custom-control-label" htmlFor="select-all" />
                  </div>
                </th>
                <th>List</th>
                <th>Edit</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {
                lists.map((list) => (
                  <tr key={`list-${list.id}`}>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id={`list-${list.id}`}
                          value={list.id}
                          disabled={saving}
                          checked={!!list.permission}
                          onChange={onItemCheckChange}
                        />
                        <Label className="custom-control-label" htmlFor={`list-${list.id}`}>&nbsp;</Label>
                      </div>
                    </td>
                    <td>{list.name}</td>
                    <td>
                      <div className="custom-control custom-radio">
                        <Input
                          type="radio"
                          className="custom-control-input"
                          id={`permission-${list.id}-edit`}
                          data-id={list.id}
                          value="edit"
                          disabled={saving}
                          checked={list.permission === 'edit'}
                          onChange={onListPermissionChange}
                        />
                        <Label className="custom-control-label" htmlFor={`permission-${list.id}-edit`}>&nbsp;</Label>
                      </div>
                    </td>
                    <td>
                      <div className="custom-control custom-radio">
                        <Input
                          type="radio"
                          className="custom-control-input"
                          id={`permission-${list.id}-view`}
                          data-id={list.id}
                          value="view"
                          disabled={saving}
                          checked={list.permission === 'view'}
                          onChange={onListPermissionChange}
                        />
                        <Label className="custom-control-label" htmlFor={`permission-${list.id}-view`}>&nbsp;</Label>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
}

PermissionTable.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      permission: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  onSelectAllChange: PropTypes.func.isRequired,
  onItemCheckChange: PropTypes.func.isRequired,
  onListPermissionChange: PropTypes.func.isRequired,
};

export default PermissionTable;
