import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

const Option = (props) => (
  <div>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <components.Option {...props}>
      <div>
        <input
          id={props.label}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{
            position: 'relative',
            top: '1.55px',
            width: '25px',
          }}
        />
        {' '}
        <label
          htmlFor={props.label}
          style={{
            position: 'relative',
            margin: 0,
          }}
        >
          {props.label}
        </label>
      </div>
    </components.Option>
  </div>
);
Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

const MultiValue = (props) => {
  const labelToBeDisplayed = `${props.data.label}, `;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};
MultiValue.propTypes = {
  data: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
};

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
const ValueContainer = ({ children, ...props }) => {
  const values = props.getValue();

  if (values.length === 1) {
    return (
      <components.ValueContainer className="value-container" {...props}>
        {values[0].label}
      </components.ValueContainer>
    );
  }

  if (values.length > 1) {
    return (
      <components.ValueContainer className="value-container" {...props}>
        {`${values[0].label} and others`}
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer className="value-container" {...props}>
      {children}
    </components.ValueContainer>
  );
};
/* eslint-enable react/prop-types, react/jsx-props-no-spreading */

const style = { height: '25px' };

function ReactSelect(props) {
  const optionsData = useMemo(() => {
    if (props.getSelected !== undefined && props?.getSelected?.length > 0) {
      return [
        { label: 'Selected', options: props.getSelected },
        { label: 'Countries', options: props.selectData },
      ];
    }
    return [{ label: 'Countries', options: props.selectData }];
  }, [props]);

  const handleSelect = useCallback((value) => { props.setSelected(value); }, [props]);

  return (
    <Select
      inputId={props.id}
      options={optionsData}
      isMulti
      closeMenuOnSelect
      hideSelectedOptions
      value={props.getSelected}
      components={{
        Option,
        MultiValue,
        ValueContainer,
      }}
      onChange={handleSelect}
      style={style}
    />
  );
}

ReactSelect.propTypes = {
  id: PropTypes.string.isRequired,
  selectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelected: PropTypes.func.isRequired,
  getSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ReactSelect;
