import React, {
  useEffect, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { fetchDashboardData, dashboardData } from 'store/dashboard';
import { fetchNotifications, latestNotifications } from 'store/notifications';
import { fetchSearchHistory, latestSearchHistoryQueries } from 'store/searchHistory';
import { deleteList } from 'store/reducers/lists';

import { useDate } from 'hooks';
import { useLanguage } from 'contexts/LanguageContext';

import { Loading, Card } from 'components/ui';
import Welcome from './Welcome';
import ListStatus from './ListStatus';
import UsedLists from './UsedLists';
import Map from './Map';
import BasicInfo from './BasicInfo';
import LatestNotifications from './LatestNotifications';
import LastSearchedQueries from './LastSearchedQueries';

function Dashboard() {
  const { translate } = useLanguage();
  const {
    elapsedTime,
    formatDate,
    secondsToString,
  } = useDate();

  const dispatch = useDispatch();

  const { user: { id: userId, name: userName } } = useSelector((state) => state.auth);
  const { pending: dashboardPending } = useSelector((state) => state.dashboard);
  const { pending: notificationsPending } = useSelector((state) => state.notifications);
  const { pending: searchHistoryPending } = useSelector((state) => state.searchHistory);

  const notifications = useSelector(latestNotifications);
  const searchHistoryQueries = useSelector(latestSearchHistoryQueries);
  const {
    allLists,
    totalListProfileCount,
    totalListCount,
    totalAccountCount,
    needActionCount,
    clearCount,
    positiveCount,
    lastSourceUpdate,
    averageUpdatingInterval,
  } = useSelector(dashboardData);

  const [filtered, setFiltered] = useState([]);

  const chartSeries = useMemo(() => [needActionCount, clearCount, positiveCount],
    [needActionCount, clearCount, positiveCount]);

  const frequentlyUsedLists = useMemo(() => allLists.filter((item) => !filtered
    .some((f) => f === item.id)).slice(0, 5),
  [allLists, filtered]);

  const averageUpdatingIntervalFormatted = useMemo(() => {
    const dateString = secondsToString(averageUpdatingInterval);
    if (dateString) return translate('globals.date.every', [dateString]);
    return '-';
  }, [translate, secondsToString, averageUpdatingInterval]);

  useEffect(() => {
    dispatch(fetchDashboardData());
    dispatch(fetchNotifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSearchHistory({
      userId,
      params: '?page=1&per_page=20&order_by=-date',
    }));
  }, [dispatch, userId]);

  const onDeleteList = (listId) => () => {
    dispatch(deleteList(listId));
    setFiltered((prevState) => [...prevState, listId]);
  };

  return (
    <div className="_dashboard">
      <Row>
        <Col>
          <Welcome userName={userName} />
        </Col>
      </Row>
      {(dashboardPending || notificationsPending || searchHistoryPending)
        ? <Loading />
        : (
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={14}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <ListStatus series={chartSeries} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Row gutter={[24, 24]}>
                    <Col span="24">
                      <Card>
                        <BasicInfo
                          iconClass="mdi mdi-account-group"
                          title={translate('dashboard.main.basicInfo.totalAccountNumber')}
                          data={totalAccountCount}
                        />
                      </Card>
                    </Col>
                    <Col span="24">
                      <Card className="_dashboard__update-stats">
                        <BasicInfo
                          iconClass="bx bx-revision"
                          title={translate('dashboard.main.basicInfo.lastSourceUpdate')}
                          data={elapsedTime(lastSourceUpdate)}
                          subtitle={formatDate(lastSourceUpdate)}
                        />
                        <BasicInfo
                          iconClass="bx bx-revision"
                          title={translate('dashboard.main.basicInfo.averageUpdatingInterval')}
                          data={averageUpdatingIntervalFormatted}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <UsedLists lists={frequentlyUsedLists} onDeleteList={onDeleteList} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Map />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Card>
                    <BasicInfo
                      iconClass="bx bx-copy-alt"
                      title={translate('dashboard.main.basicInfo.totalProfileInScreen')}
                      data={totalListProfileCount}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Card>
                    <BasicInfo
                      iconClass="mdi mdi-clipboard-list-outline"
                      title={translate('dashboard.main.basicInfo.numberOfLists')}
                      data={totalListCount}
                    />
                  </Card>
                </Col>
                <Col span={24}>
                  <LatestNotifications notifications={notifications} />
                </Col>
                <Col span={24}>
                  <LastSearchedQueries queries={searchHistoryQueries} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
    </div>
  );
}

export default Dashboard;
