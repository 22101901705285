/* eslint-disable react/button-has-type */
import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

function Button({
  children,
  type,
  color,
  className,
  disabled,
  onClick,
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={classnames('ui__button', color, className)}
      onClick={disabled ? () => {} : onClick}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  children: <></>,
  type: 'button',
  color: '',
  className: '',
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'button',
    'submit',
  ]),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'link',
    'delete',
    'cancel',
    '',
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
