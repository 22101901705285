import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { api } from 'services/api';

import { Loading } from 'components/ui';

// TODO: delete component after new design
import ClientDetails from './ClientDetails';

// TODO: update design
function ClientDetail() {
  const { screeningId, clientId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [currenctClient, setCurrentClient] = useState(null);

  const getClient = async () => {
    const { data: { results: clients } } = await api.getClients2(screeningId, { page_size: 9999 });
    // eslint-disable-next-line eqeqeq
    const client = clients.find((result) => result?.id == clientId);

    if (client) {
      setCurrentClient(client);
    }
  };

  useEffect(() => {
    getClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currenctClient) {
    return <Loading />;
  }

  return (
    <ClientDetails
      clientName={currenctClient?.user_client?.name}
      cantEdit={false}
      listId={screeningId}
      clientId={currenctClient?.user_client?.pk}
      baseId={clientId}
    />
  );
}

export default ClientDetail;
