import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Link,
} from 'react-router-dom';
import { Card, Spinner, Button } from 'reactstrap';
import List from './List';
import Map from '../../assets/images/lists/bgbig.png';

import '../../assets/css/lists.css';
import { loadLists, loadListItem } from '../../store/reducers/automated-lists';
import { changeRoute, resetRoute } from '../../store/reducers/route';
import ListDetailLayout from './AutomatedListDetailLayout';

function ListLayout({ typeId }) {
  const { data: lists, initialized } = useSelector((state) => state.automatedLists);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  // eslint-disable-next-line
  const filteredListsByTypeId = useMemo(() => lists.filter(({type_id}) => type_id === typeId), [lists, typeId]);
  const isIntegratedTable = useMemo(() => {
    const location = window.location.pathname.split('/');
    return location.includes('table') || location.includes('create');
  }, [window.location.pathname, path]);

  useEffect(() => {
    if (!initialized) {
      dispatch(loadLists());
    } else {
      lists.map((list) => {
        dispatch(loadListItem(list.id));
        return '';
      });
    }
    let routeText = 'Automated-Lists ';
    if (typeId === 3) routeText = 'Automated-Lists ';
    if (typeId === 2) routeText = 'Integrated-Lists ';
    dispatch(changeRoute(routeText));
    return () => {
      dispatch(resetRoute());
    };
    // eslint-disable-next-line
  }, [dispatch, initialized, path]);

  if (!initialized) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="mr-2" color="secondary" />
      </div>
    );
  }

  const getNoDataCard = () => (
    <div className="noData">
      <Card className="listCard">
        <h3>There is no list you have created</h3>
        <br />
        <p>If you want create a new list, just click.</p>
        <div>
          <Link to="/integrated-lists/create">
            <Button
              color="primary"
              className="addListBtn btn waves-effect waves-light"
            >
              Add new list
            </Button>
          </Link>
        </div>
        <br />
        <br />
        <div id="imgContainer">
          <img src={Map} alt="no data" />
        </div>
      </Card>
    </div>
  );
  return (
    <Switch>
      <Route exact path={path}>

        {filteredListsByTypeId.length === 0 ? (
          getNoDataCard()
        ) : (
          <List listData={filteredListsByTypeId} typeId={typeId} />
        )}
      </Route>
      { !isIntegratedTable
      && <Route path={`${path}/:id`} component={ListDetailLayout} />}
    </Switch>
  );
}

ListLayout.defaultProps = {
  typeId: 2,
};

ListLayout.propTypes = {
  typeId: PropTypes.number,
};

export default ListLayout;
