import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Button,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { editList, loadListItem } from '../store/reducers/lists';

import { useAPI } from '../services/api';

export default function CardListDetails(props) {
  const api = useAPI();

  const [listName, setListName] = useState(props.listName);
  const [checked, setChecked] = useState(2);
  const [aka, setAka] = useState(true);
  const [pep, setPep] = useState(false);
  const [media, setMedia] = useState(false);
  const [fuzzyScore, setFuzzyScore] = useState(props.score);
  const [phonetics, setPhonetics] = useState(true);
  const [reqStatus, setReqStatus] = useState(null);

  const { loading, status } = useSelector((state) => state.lists);
  const { role } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handleScoreBlur = useCallback((e) => {
    if (parseInt(e.currentTarget.value, 10) < 65) {
      setFuzzyScore(65);
    } else if (parseInt(e.currentTarget.value, 10) > 100) {
      setFuzzyScore(100);
    }
  }, [fuzzyScore]);

  useEffect(() => {
    const sensivity = props?.sensivity ?? 65;
    setChecked(parseInt(sensivity, 10));
    setAka(props?.searchOptions?.aka ?? false);
    // eslint-disable-next-line
    setPep(props?.options?.search_pep ?? false);
    // eslint-disable-next-line
    setMedia(props?.options?.search_media ?? false);
    setPhonetics(props?.searchOptions?.phonetics ?? false);
  }, [setChecked, props.sensivity, props.options, props.score]);

  const onSave = () => {
    dispatch(
      editList(props.listId, listName, checked, parseInt(fuzzyScore, 10),
        {
          search_pep: pep,
          search_media: media,
        }, {
          phonetics,
          aka,
        }),
    );
    const loadInterval = setInterval(async () => {
      const { data: { states } } = await api.getStates(props.listId);
      const searching = states.filter((s) => {
        if (s.toLowerCase() === 'not searched' || s.toLowerCase() === 'searching' || s.toLowerCase().includes('searching')) {
          return true;
        }
        return false;
      });
      if (searching.length === 0) {
        dispatch(loadListItem(props.listId));
        clearInterval(loadInterval);
      }
    }, 5000);

    return () => {
      clearInterval(loadInterval);
    };
  };

  return (
    <Card style={{
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    }}
    >
      <CardBody
        style={{
          padding: '21px',
        }}
      >

        <div className="form-group row">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label"
          >
            Name
          </label>
          <div className="col-md-8">
            <input
              disabled={props.cantEdit || props.disabled}
              className="form-control"
              type="text"
              defaultValue={props.listName}
              onChange={(e) => {
                setListName(e.target.value);
              }}
            />
          </div>
        </div>
        {/* Score */}
        <div className="form-group row">
          <label className="form-group col-md-4 d-flex align-items-center" htmlFor="minNameScore">Fuzzy Search</label>
          <div className="form-group col-md-8 d-flex align-items-center">
            <div
              className="pr-2"
              style={{
                width: '100%',
              }}
            >
              <input
                disabled={props.cantEdit || props.disabled}
                id="minNameScore"
                name="minNameScore"
                type="range"
                min="65"
                max="100"
                value={fuzzyScore}
                className="form-control-range"
                onChange={(e) => setFuzzyScore(e.target.value)}
              />
            </div>
            <div>
              <input
                disabled={props.cantEdit || props.disabled}
                name="minNameScore"
                type="number"
                min="65"
                max="100"
                value={fuzzyScore}
                className="form-control"
                onChange={(e) => setFuzzyScore(e.target.value)}
                onBlur={handleScoreBlur}
                style={{ width: '5em' }}
              />
            </div>
          </div>
        </div>
        {/* Search Options */}
        <div className="form-group row pb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label pt-0 pb-0 d-flex align-items-start"
          >
            Search Options
          </label>
          <div className="custom-control col-md-8 pl-2 d-flex align-items-center">
            <div
              className="custom-checkbox pl-1"
            >
              <div className="custom-control custom-checkbox">
                <input
                  disabled={props.cantEdit || props.disabled}
                  id="chck1"
                  type="checkbox"
                  className="custom-control-input"
                  checked={aka}
                  onChange={() => setAka(!aka)}
                />
                <label htmlFor="chck1" className="custom-control-label">
                  Include AKA
                </label>
              </div>
              {false && (
                <>
                  <div className="custom-control custom-checkbox mt-3">
                    <input
                      disabled={props.cantEdit || props.disabled}
                      id="incPep"
                      type="checkbox"
                      className="custom-control-input"
                      checked={pep}
                      onChange={() => setPep(!pep)}
                    />
                    <label htmlFor="incPep" className="custom-control-label">
                      Include PEP
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mt-3">
                    <input
                      disabled={props.cantEdit || props.disabled}
                      id="incMedia"
                      type="checkbox"
                      className="custom-control-input"
                      checked={media}
                      onChange={() => setMedia(!media)}
                    />
                    <label htmlFor="incMedia" className="custom-control-label">
                      Include Media
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Access Settings */}
        {!props.cantEdit && (
        <div className="form-group row pb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label pt-0 pb-0 d-flex align-items-center"
          >
            Access Settings
          </label>
          <div className="custom-control col-md-8 pl-2 d-flex align-items-center">
            <div
              className="custom-checkbox pl-1"
            >
              <Link to="/users" style={{ marginLeft: 'auto' }}>
                <Button
                  disabled={props.cantEdit || props.disabled}
                  color="link"
                  className="btn waves-effect waves-light pl-0 pt-0 pb-0"
                >
                  Manage
                </Button>
              </Link>
            </div>
          </div>
        </div>
        )}

        <div className="form-group row">
          <p className="col-md-4 ml-1"> </p>
          <div>
            <Button
              disabled={props.cantEdit || props.disabled}
              color={
              // eslint-disable-next-line
              status === "error" ? "danger" :
              // eslint-disable-next-line
                !loading && status === "ok"
                  ? 'success'
                  : 'primary'
            }
              className="btn waves-effect waves-light ml-2 col-md-auto"
              style={{
                width: '109px',
                height: '36px',
                marginRight: '10px',
              }}
              onClick={onSave}
            >
              {status === 'ok' && (
              <>
                <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                Saved
              </>
              )}
              {loading === true && (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                Saving...
              </>
              )}

              {status === 'error' && (
              <>
                <i className="bx bx-block font-size-16 align-middle mr-2" />
                Error
              </>
              )}
              {status !== 'error'
              && loading !== true
              && status !== 'ok'
              && 'Save'}
            </Button>
            {(role === 1 && props.cantEdit)
            && (
            <Button
              disabled={reqStatus === true}
              color="primary"
              className="btn waves-effect waves-light ml-2 col-md-auto"
              style={{
                width: '135px',
                height: '36px',
                marginRight: '10px',
              }}
              onClick={() => {
                setReqStatus(true);
                props.requestAccess();
              }}
            >
              Request Access
            </Button>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

CardListDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
  listName: PropTypes.string.isRequired,
  sensivity: PropTypes.number.isRequired,
  listId: PropTypes.number.isRequired,
  options: PropTypes.shape({
    search_pep: PropTypes.bool.isRequired,
    search_media: PropTypes.bool.isRequired,
  }).isRequired,
  searchOptions: PropTypes.shape({
    aka: PropTypes.bool.isRequired,
    no_logging: PropTypes.bool.isRequired,
    phonetics: PropTypes.bool.isRequired,
  }).isRequired,
  score: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  requestAccess: PropTypes.func.isRequired,
};
