import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import styles from './index.module.scss';

function Loading({ size }) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spin indicator={(
        <LoadingOutlined
          className={classnames(styles.loading, styles?.[size])}
          style={{
            color: '#74788d',
          }}
          spin
        />
      )}
      />
    </div>
  );
}

Loading.defaultProps = {
  size: 'lg',
};

Loading.propTypes = {
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
  ]),
};

export default Loading;
