import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

function DeleteConfirmModal({
  isOpen,
  name,
  onCancel,
  onConfirm,
}) {
  return (
    <Modal
      style={{
        top: 'calc(20%)',
      }}
      fade={false}
      backdrop="static"
      isOpen={isOpen}
    >
      <div className="modal-body">
        <p>{`Do you really want to delete user "${name}"?`}</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-danger waves-effect waves-light"
          onClick={onConfirm}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
}

DeleteConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteConfirmModal;
