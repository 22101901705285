import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Progress,
} from 'reactstrap';
import { useAPI } from '../../services/api';
import CancelConfirmModal from '../../components/CancelConfirmModal';

const getFormattedTime = (date, dmy = false) => {
  try {
    const d = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(d);

    const hh = d.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = d.getMinutes();
    const minutes = m < 10 ? `0${m}` : m;
    if (dmy) {
      return `${day} ${month} ${year}`;
    }
    return `${day} ${month} ${year} at ${hours}.${minutes}`;
  } catch (error) {
    return '';
  }
};

function FileCard({
  file, progress, setInitialized, setCompleted, listId, clientId,
  getDocuments, setSelectedFiles, setProgress, setDocuments,
}) {
  const api = useAPI();

  const [progress2, setprogress2] = useState(-1);
  const [clickUpload, setClickUpload] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState('');
  const [docId, setDocId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const deleteFile = async () => {
    await api.deleteAutomatedClientDocument(listId, clientId, docId);
    setSelectedFiles((prevState) => prevState.filter((d) => d?.id !== docId));
    setDocuments((prevState) => prevState.filter((d) => d?.id !== docId));
    setConfirmToggle(false);
  };
  const uploadSingleFile = async () => {
    setClickUpload(true);
    setprogress2(20);
    setInitialized(true);
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log('percentCompleted', percentCompleted);
        if (percentCompleted === 100) {
          setTimeout(() => {
            setprogress2(percentCompleted);
          }, 1000);
          setTimeout(() => {
            setprogress2(-1);
          }, 1600);
        }
      },
    };
    try {
      const data = new FormData();
      data.append('documents', file?.file, file?.file?.name);
      const { data: { documents: docs } } = await api
        .postAutomatedClientDocuments(listId, clientId, data, config);
      setTimeout(() => {
        setCompleted(true);
        setInitialized(false);
      }, 1000);
      setTimeout(() => {
        setCompleted(false);
        setSelectedFiles((prevState) => {
          if (docs.length < prevState.length) {
            const errorFiles = prevState.filter((s) => (docs[0].filename !== s?.file?.name));
            const doc = { ...docs[0], isError: false, uploaded: true };
            return [...errorFiles, doc];
          }
          return docs.map((d) => ({ ...d, isError: false, uploaded: true }));
        });
        getDocuments();
        setClickUpload(false);
      }, 1600);
    } catch (e) {
      setTimeout(() => {
        setCompleted(true);
        setInitialized(false);
      }, 1000);
      setTimeout(() => {
        setProgress(-1);
        setprogress2(-1);
        setCompleted(false);
        setClickUpload(false);
      }, 1600);
    }
  };
  console.log(file);
  return (
    <div
      className={classnames({
        'd-flex': true,
        'align-items-center': true,
        'file-card-wrapper': true,
        'file-card-border-top': true,
      })}
    >
      <CancelConfirmModal
        isOpen={confirmToggle}
        onConfirm={deleteFile}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn
        selectedName
      >
        Are you sure you want to delete
        {' '}
        `
        <b>{selectedFileName}</b>
        `
        {' '}
        ? If you say yes, you cannot undo this step.
      </CancelConfirmModal>
      <div>
        <i className="bx bxs-file-blank doc-file-icon align-middle mr-2" />
      </div>
      <div className="w-100">
        <div className="px-3 d-flex">
          <div>
            <p className="m-0 doc-file-name">{file?.filename ?? file?.name ?? file?.file?.name}</p>
            <div className="d-flex align-items-center doc-file-info mt-2">
              {!file?.isError && file?.uploaded && (progress2 === -1 && progress === -1) && (
                <>

                  <p className="m-0">{getFormattedTime(file.creation_date)}</p>
                  <div className="d-flex align-items-center ml-3">
                    <div className="doc-file-size-circle mr-2" />
                    <p className="m-0">
                      {parseInt(file?.size, 10) ?? 0}
                      {' '}
                      Kb
                    </p>
                  </div>
                </>
              )}
              {file?.isError && !file?.uploaded && (progress2 === -1 && progress === -1) && (
              <>
                <p
                  className="m-0"
                  style={{
                    fontSize: '13px',
                    lineHeight: '15px',
                    color: '#F46A6A',
                  }}
                >
                  Upload failed
                </p>
              </>
              )}
            </div>
          </div>
          { !file?.isError && file?.uploaded && (
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-link waves-effect waves-light px-0"
                onClick={() => {
                  if (file?.url) {
                    window.open(file?.url, '_blank');
                  }
                }}
              >
                <i className="mdi mdi-file-eye doc-file-eye" />
              </button>
              <button
                type="button"
                className="btn btn-link py-0 px-0 ml-4"
                onClick={() => {
                  setSelectedFileName(file.filename);
                  setDocId(file.id);
                  setConfirmToggle(true);
                }}
              >
                <i className="mdi mdi-delete doc-file-delete" />
              </button>
            </div>
          )}

          {file?.isError && !file?.uploaded && (
          <button
            type="button"
            className="btn btn-secondary waves-effect waves-light ml-auto"
            onClick={uploadSingleFile}
            style={{
              width: '222.09px',
              height: '41px',
            }}
          >
            Try again
          </button>
          )}
        </div>
        {clickUpload ? (
          <>
            {file?.url === undefined && progress2 !== -1 && (
            <div id="bar" className="mt-2 px-3">
              <Progress color="success" striped animated value={progress2} />
              <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
            </div>
        )}
          </>
        ) : (
          <>
            {file?.url === undefined && progress !== -1 && (
            <div id="bar" className="mt-2 px-3">
              <Progress color="success" striped animated value={progress} />
              <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
            </div>
        )}
          </>
        ) }

      </div>
    </div>
  );
}
FileCard.propTypes = {
  listId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filename: PropTypes.string,
    creation_date: PropTypes.string,
    url: PropTypes.string,
    // eslint-disable-next-line
    file: PropTypes.any,
    uploaded: PropTypes.bool,
    isError: PropTypes.bool,
    size: PropTypes.number,
  }).isRequired,
  progress: PropTypes.number.isRequired,
  // status: PropTypes.string.isRequired,
  setInitialized: PropTypes.func.isRequired,
  setCompleted: PropTypes.func.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  // setStatus: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
};

export default FileCard;
