import ScreeningList from './List';
import ScreeningDetail from './Detail';
import ScreeningCreate from './Create';

const Screening = ScreeningList;
Screening.List = ScreeningList;
Screening.Detail = ScreeningDetail;
Screening.Create = ScreeningCreate;

export {
  ScreeningList,
  ScreeningDetail,
  ScreeningCreate,
};

export default Screening;
