import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/org-chart.css';
// const selectNode = () => {
//   // on select node
// };

/* <div>
    <div className="sh-name">
      {nodeData.name}
      <span className="tooltiptext">
        <div className="d-flex">
          <div className="font-weight-500">
            Type:
          </div>
          <div className="ml-1">
            {nodeData.type}
          </div>
        </div>
        <div className="d-flex">
          <div className="font-weight-500">
            Percentage:
          </div>
          <div className="ml-1">
            {nodeData.percent}
            %
          </div>
        </div>

      </span>
    </div>
  </div> */

const OrgChartNode = ({ nodeData }) => (
  <div className="org-chart">
    <div className="sh-name">
      <div className="d-flex align-items-center">
        <div className="mr-2">
          {nodeData.name}
        </div>
        {nodeData?.parentId && (
        <div className="ml-auto color-soft">
          {nodeData.percent}
          %
        </div>
)}
      </div>
      <span className="tooltiptext">
        <div className="ml-1 capitalize">
          {nodeData.type}
        </div>
      </span>
    </div>

  </div>
);
OrgChartNode.propTypes = {
  nodeData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired,
    parentId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
};

export default OrgChartNode;
