import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Card, CardBody, NavItem, NavLink, Row,
  TabContent, TabPane, Col, Progress, Alert,
} from 'reactstrap';
import './form.css';
import '../../assets/css/onboarding.css';
import '../../assets/css/form-documents.css';
import Welcome from './Welcome';
import Parties from './Parties';
import Items from './Items';
import FinalDestination from './FinalDestination';
import EndUse from './EndUse';
import Declaration from './Declaration';

function GeneralForm() {
  const [progressValue, setProgressValue] = useState(0);
  const [activeTabProgress, setActiveTabProgress] = useState(0);

  function toggleTabProgress(tab, e = null) {
    if (e !== null) e.preventDefault();
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 7) {
        setActiveTabProgress(tab);
        if (tab === 0) { setProgressValue(0); }
        if (tab === 1) { setProgressValue(20); }
        if (tab === 2) { setProgressValue(35); }
        if (tab === 3) { setProgressValue(45); }
        if (tab === 4) { setProgressValue(60); }
        if (tab === 5) { setProgressValue(75); }
        if (tab === 6) { setProgressValue(85); }
        if (tab === 7) { setProgressValue(100); }
      }
    }
  }

  return (
    <div id="onboarding" className="d-flex align-items-center justify-content-center">
      <Card style={{
        maxWidth: '1000px',
        minWidth: '800px',
        overflowY: 'inherit',
        backgroundColor: '#FDFDFD',
      }}
      >
        <CardBody
          className={classnames({
            'px-0': true,
            'pt-0': true,
          })}
        >
          <div id="progrss-wizard" className="twitter-bs-wizard">

            {activeTabProgress !== 0 && (
              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills mx-3">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabProgress > 0 })}
                    onClick={() => {
                      toggleTabProgress(1);
                    }}
                  >
                    <span className="step-number justify-self-center">A</span>
                    <span className="justify-self-center">Parties</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTabProgress > 1,
                      'd-grid': true,
                    })}
                    onClick={() => {
                      toggleTabProgress(2);
                    }}
                  >
                    <span className="step-number justify-self-center">B</span>
                    <span className="justify-self-center">Items</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabProgress > 2 })}
                    onClick={() => {
                      toggleTabProgress(3);
                    }}
                  >
                    <span className="step-number justify-self-center">C</span>
                    <span className="justify-self-center">Final destination</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabProgress > 3 })}
                    onClick={() => {
                      toggleTabProgress(4);
                    }}
                  >
                    <span className="step-number justify-self-center">D</span>
                    <span className="justify-self-center">
                      End-Use
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabProgress > 4 })}
                    onClick={() => {
                      toggleTabProgress(5);
                    }}
                  >
                    <span className="step-number justify-self-center">E</span>
                    <span className="justify-self-center">
                      Declaration
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabProgress > 5 })}
                  >
                    <span className="step-number justify-self-center">F</span>
                    <span className="justify-self-center">
                      Done!
                    </span>
                  </NavLink>
                </NavItem>

              </ul>
            )}
            <div id="bar" className="mt-4 px-3">
              <Progress color="success" striped animated value={progressValue} />
              <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
            </div>
            <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">

              <TabPane tabId={0}>
                <Welcome toggleTabProgress={toggleTabProgress} />
              </TabPane>
              <TabPane tabId={1}>
                <Parties toggleTabProgress={toggleTabProgress} />
              </TabPane>
              <TabPane tabId={2}>
                <Items toggleTabProgress={toggleTabProgress} />
              </TabPane>
              <TabPane tabId={3}>
                <FinalDestination toggleTabProgress={toggleTabProgress} />
              </TabPane>
              <TabPane tabId={4}>
                <EndUse toggleTabProgress={toggleTabProgress} />
              </TabPane>
              <TabPane tabId={5}>
                <Declaration toggleTabProgress={toggleTabProgress} />
              </TabPane>

              <TabPane tabId={6}>
                <div className="px-5">
                  <Row className="form-header">
                    <Col className="d-flex align-items-center">
                      Done!
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '29px' }}>
                    <Col md="6">
                      <Alert color="success" role="alert">
                        Your form was sent successfully.
                      </Alert>
                    </Col>
                  </Row>
                </div>
              </TabPane>

            </TabContent>

          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default GeneralForm;
