import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, resetForgotPassword } from '../store/reducers/auth';

import stBanner from '../assets/images/sanction-banner.png';

function ForgotPassword() {
  const { error, status } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    const {
      email: { value: email },
    } = e.target.elements;

    dispatch(forgotPassword(email));
  }
  useEffect(() => () => {
    dispatch(resetForgotPassword());
  }, []);

  return (
    <div className="pt-sm-5 account-pages main-login">
      <Container className="pt-5">
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col>
                    <img
                      src={stBanner}
                      alt="Sanction Trace Banner"
                      className="m-0 p-0"
                      width="100%"
                      height="85"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="text-primary p-4">
                      <p>
                        Please fill your information to reset your password.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-4">
                <div className="p-2">
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    {error && (
                      <Alert color="danger">Invalid email or password.</Alert>
                    )}
                    <div className="form-group">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        disabled={status === 'ok'}
                        required
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className={
                          status === 'ok'
                            ? 'btn btn-block waves-effect waves-light  btn-success'
                            : 'btn btn-block waves-effect waves-light btn-primary'
                        }
                        type="submit"
                        disabled={status === 'ok'}
                        style={{
                          transition: '0.3s',
                        }}
                      >
                        {status === 'ok'
                          ? 'We sent an email to reset your password'
                          : 'Reset'}
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-muted">
                        <i className="mdi mdi-lock mr-1" />
                        Sign in
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPassword;
