export const amlIndexColumns = [
  {
    name: 'Country Name',
    selector: 'name',
    sortable: true,
    minWidth: '260px',
  },
  {
    name: 'Region',
    selector: 'region',
    sortable: true,
    width: '195px',
  },
  {
    name: 'Score',
    selector: 'score',
    sortable: true,
    width: '58px',
  },
  {
    name: 'Rank',
    selector: 'rank',
    sortable: true,
    width: '58px',
  },
  {
    name: 'Risk Evaluation',
    selector: 'risk',
    sortable: true,
    width: '135px',
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
    },
  },
};
