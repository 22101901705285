import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  FormGroup,
  Label,
  Input,
  Col,
  Alert,
} from 'reactstrap';
import PermissionTable from './PermissionTable';
import UserItemHeader from './UserItemHeader';
import UserItemFooter from './UserItemFooter';

function UserItem({
  id,
  name = '',
  email,
  permissions,
  pending = false,
  isOpen = false,
  onClick,
  onSave,
  onDelete,
  emailDomain,
  setInv,
  setDeleteInvModal,
  setIsAddOpen,
}) {
  const [permissionsState, setPermissions] = useState(permissions);
  const [newUserEmail, setNewUserEmail] = useState('');
  const saving = useSelector((state) => state.users.saving);
  const error = useSelector((state) => state.users.error);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const handlePermissionGroupChange = useCallback((e) => {
    const { value } = e.currentTarget;
    setPermissions({ ...permissionsState, all: value === 'all' ? 'edit' : false });
  }, [permissionsState]);

  const handlePermissionTypeChange = useCallback((e) => {
    const { value } = e.currentTarget;
    setPermissions({ ...permissionsState, all: value });
  }, [permissionsState]);

  const handleItemCheckChange = useCallback((e) => {
    const { checked, value } = e.currentTarget;
    const nextLists = permissionsState.lists.map((list) => {
      if (list.id.toString() === value) {
        return {
          ...list,
          permission: checked ? 'edit' : null,
        };
      }
      return list;
    });

    setPermissions({ ...permissionsState, lists: nextLists });
  }, [permissionsState]);

  const handleListPermissionChange = useCallback((e) => {
    const { value } = e.currentTarget;
    const listId = e.currentTarget.getAttribute('data-id');
    const nextLists = permissionsState.lists.map((list) => {
      if (list.id.toString() === listId) {
        return {
          ...list,
          permission: value,
        };
      }
      return list;
    });

    setPermissions({ ...permissionsState, lists: nextLists });
  }, [permissionsState]);

  const handleSelectAllChange = useCallback((e) => {
    const { checked } = e.currentTarget;
    const nextLists = permissionsState.lists.map((list) => ({
      ...list,
      permission: checked ? (list.permission || 'edit') : null,
    }));

    setPermissions({ ...permissionsState, lists: nextLists });
  }, [permissionsState]);

  const isAllSelected = useMemo(
    () => permissionsState.lists.length > 0
  && !(permissionsState.lists.some((l) => !l.permission)),
    [permissionsState.lists],
  );

  const handleSaveClick = useCallback((e) => {
    const userId = e.currentTarget.getAttribute('data-id');
    if (newUserEmail.trim().length > 0) {
      onSave(userId, permissionsState, (newUserEmail + emailDomain));
      setNewUserEmail('');
    } else {
      onSave(userId, permissionsState);
    }
  }, [newUserEmail, onSave, permissionsState]);

  const handleDeleteClick = useCallback((e) => {
    const userId = e.currentTarget.getAttribute('data-id');
    onDelete(userId);
  }, [onDelete]);

  const checkNewUserEmail = (mail) => {
    if (mail.includes('@')) {
      const filtered = [...mail].filter((m) => m !== '@').join('');
      setNewUserEmail(filtered);
    } else setNewUserEmail(mail);
  };
  return (
    <Card className="mb-2">
      <CardHeader className="p-3 bg-transparent" id="headingOne">
        <Row>
          {
            id === 'new'
              ? (
                <Col md="12">
                  <div className="form-group row">
                    <label htmlFor="new-email" className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10 d-flex align-items-center">
                      <input
                        type="email"
                        className="form-control mr-2"
                        id="new-email"
                        disabled={saving}
                        value={newUserEmail}
                        onChange={(e) => { checkNewUserEmail(e.target.value); }}
                      />
                      <div className="text-muted">{emailDomain}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="alert" className="col-sm-2 col-form-label"> </label>
                    <div className="col-sm-10 d-flex align-items-center">
                      {error !== '' && error && (
                      <Alert
                        color="warning"
                        role="alert"
                        className="mt-2"
                      >
                        {error}
                      </Alert>
                      )}
                    </div>
                  </div>
                </Col>
              )
              : (
                <UserItemHeader
                  id={id}
                  name={name}
                  email={email}
                  pending={pending}
                  isAdmin={permissions.isAdmin}
                  onClick={onClick}
                  setDeleteInvModal={setDeleteInvModal}
                  setInv={setInv}
                />
              )
          }
        </Row>
      </CardHeader>
      {
        (isOpen) && (
          <CardBody>
            <FormGroup>
              <div className="custom-control custom-radio">
                <Input
                  id={`all-${id}`}
                  className="custom-control-input"
                  type="radio"
                  value="all"
                  disabled={saving}
                  checked={permissionsState.all}
                  onChange={handlePermissionGroupChange}
                />
                <Label className="custom-control-label" htmlFor={`all-${id}`}>Access all lists</Label>
              </div>
            </FormGroup>
            {
              permissionsState.all && (
                <div className="ml-4">
                  <FormGroup>
                    <div className="custom-control custom-radio">
                      <Input
                        id={`all-${id}-edit`}
                        className="custom-control-input"
                        type="radio"
                        value="edit"
                        disabled={saving}
                        checked={permissionsState.all === 'edit'}
                        onChange={handlePermissionTypeChange}
                      />
                      <Label className="custom-control-label" htmlFor={`all-${id}-edit`}>Edit</Label>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="custom-control custom-radio">
                      <Input
                        id={`all-${id}-view`}
                        className="custom-control-input"
                        type="radio"
                        value="view"
                        disabled={saving}
                        checked={permissionsState.all === 'view'}
                        onChange={handlePermissionTypeChange}
                      />
                      <Label className="custom-control-label" htmlFor={`all-${id}-view`}>View</Label>
                    </div>
                  </FormGroup>
                </div>
              )
            }
            <FormGroup>
              <div className="custom-control custom-radio">
                <Input
                  id={`lists-${id}`}
                  className="custom-control-input"
                  value="lists"
                  type="radio"
                  disabled={saving}
                  checked={!permissionsState.all}
                  onChange={handlePermissionGroupChange}
                />
                <Label className="custom-control-label" htmlFor={`lists-${id}`}>Select from lists</Label>
              </div>
            </FormGroup>
            {
              !permissionsState.all && (
                <PermissionTable
                  lists={permissionsState.lists}
                  isAllSelected={isAllSelected}
                  onSelectAllChange={handleSelectAllChange}
                  onItemCheckChange={handleItemCheckChange}
                  onListPermissionChange={handleListPermissionChange}
                />
              )
            }
            <UserItemFooter
              id={id}
              disabled={newUserEmail.trim().length === 0 && email.length === 0}
              onSaveClick={handleSaveClick}
              onDeleteClick={handleDeleteClick}
              setIsAddOpen={setIsAddOpen}
            />
          </CardBody>
        )
      }
    </Card>
  );
}

UserItem.defaultProps = {
  name: '',
  pending: false,
};

UserItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
    all: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setInv: PropTypes.func.isRequired,
  setDeleteInvModal: PropTypes.func.isRequired,
  emailDomain: PropTypes.string.isRequired,
  setIsAddOpen: PropTypes.func.isRequired,
};

export default UserItem;
