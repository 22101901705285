import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { useLanguage } from 'contexts/LanguageContext';

import {
  Card,
  Input,
  Button,
} from 'components/ui';

import {
  currentPasswordRules,
  passwordRules,
  confirmPasswordRules,
} from './constants';

function ResetPassword({ isDisabled, onUpdateUser }) {
  const { translate } = useLanguage();

  const [form] = Form.useForm();

  const [isFieldsTouched, setIsFieldsTouched] = useState(false);

  const formItem = useMemo(() => ({ ...translate('settings.resetPassword.form') }), [translate]);

  const onValuesChange = () => {
    setIsFieldsTouched(form.isFieldsTouched());
  };

  const onSubmit = (values) => {
    const { password } = values;
    onUpdateUser({ password });

    form.resetFields();
  };

  return (
    <Card className="_settings__reset-password">
      <h5 className="_settings__reset-password-header">
        {translate('settings.resetPassword.header')}
      </h5>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
      >
        <Form.Item
          label={formItem.currentPassword.label}
          name="oldPassword"
          rules={currentPasswordRules(formItem.currentPassword.label, translate)}
        >
          <Input.Password
            type="password"
            placeholder={formItem.currentPassword.placeholder}
          />
        </Form.Item>
        <Form.Item
          label={formItem.newPassword.label}
          name="password"
          rules={passwordRules(
            formItem.newPassword.label,
            formItem.currentPassword.label,
            translate,
          )}
        >
          <Input.Password placeholder={formItem.newPassword.placeholder} />
        </Form.Item>
        <Form.Item
          label={formItem.confirmPassword.label}
          name="confirmPassword"
          rules={confirmPasswordRules(
            formItem.confirmPassword.label,
            formItem.newPassword.label,
            translate,
          )}
        >
          <Input.Password placeholder={formItem.confirmPassword.placeholder} />
        </Form.Item>
        <Button
          disabled={!isFieldsTouched || isDisabled}
          type="submit"
          color="primary"
          className="_settings__reset-password-button"
        >
          {formItem.reset}
        </Button>
      </Form>
    </Card>
  );
}

ResetPassword.defaultProps = {
  isDisabled: false,
};

ResetPassword.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdateUser: PropTypes.func.isRequired,
};

export default ResetPassword;
