export async function request(promise) {
  try {
    const data = await promise;
    return [data, null];
  } catch (error) {
    return [null, error];
  }
}

export const getSortParams = (sortBy = '', sortOrder = '') => {
  if (sortBy && sortOrder) {
    const prefix = sortOrder === 'descend' ? '-' : '';
    return {
      ordering: prefix + sortBy,
    };
  }
  return {};
};

export default request;
