import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
class ImportListTable extends Component {
  renderTableHeader = (headers) => {
    if (headers !== undefined && headers.length > 0) {
      return (
        <tr>
          {[1, ...headers[0]].map((header, index) => {
            if (index === 0) {
              return (
                <th className={`cell${index} cell leftColumn`}>
                  <p style={{ float: 'left' }}>
                    #
                  </p>
                </th>
              );
            }
            return (

              <th
                className={`cell${index} cell`}
              >
                <div className="d-flex align-items-center justify-content-start">
                  <span className="mr-2">{header.toString().toUpperCase()}</span>
                  <input
                    disabled={this.props.disabled}
                    id={`cell${index}`}
                    type="radio"
                    name="radio"
                    className="ml-auto "
                    value={header}
                    style={{ marginLeft: '10px' }}
                    onChange={(e) => {
                      this.props.onChecked(e.target.value);
                    }}
                  />
                </div>
              </th>
            );
          })}
        </tr>
      );
    }
    return '';
  };

  renderTableData = (rows) => {
    let data = [];
    if (rows !== undefined && rows.length > 0) {
      // changing to show 10 row, add rows.slice(0, 11) instead of rows
      data = rows.map((row, index) => {
        if (index !== 0) {
          return (
            <tr>
              {[1, ...row].map((row1, i) => {
                if (i === 0) {
                  return (
                    <td
                      className={`cell${i} cell leftColumn`}
                    >
                      {index}
                    </td>
                  );
                }
                return (
                  <td
                    className={`cell${i} cell`}

                  >
                    {row1}
                  </td>
                );
              })}
            </tr>
          );
        }
        return data;
      });

      return data;
    }
    return data;
  };

  render() {
    return (
      <>
        <div
          style={{
            display: 'block',
            width: '100%',
            height: '46vh',
            overflowX: 'auto',
          }}
        >
          <table>
            <tbody>
              {this.renderTableHeader(this.props.data)}
              {this.renderTableData(this.props.data)}
            </tbody>
          </table>
        </div>
        <span style={{ display: 'block', marginTop: '10px', color: '#74788D' }}>
          {this.props.selectedRowsCount}
          {' '}
          item selected.
        </span>
      </>
    );
  }
}

ImportListTable.defaultProps = {
  onChecked: () => {},
  data: [],
  selectedRowsCount: null,
  disabled: false,
};

ImportListTable.propTypes = {
  disabled: PropTypes.bool,
  onChecked: PropTypes.string,
  data: PropTypes.func,
  selectedRowsCount: PropTypes.number,
};

export default ImportListTable;
