import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useLanguage } from 'contexts/LanguageContext';

import { Button, Icon, Modal } from 'components/ui';

function ProfileDelete({ name, onAction }) {
  const { translate } = useLanguage();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onAction();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        color="delete"
        className="mx-0 justify-self-right"
        onClick={showModal}
      >
        <Icon name="trash" />
      </Button>
      <Modal
        visible={visible}
        title={translate('client.list.modal.delete.title')}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {translate('client.list.modal.delete.content', [name])}
      </Modal>
    </>
  );
}

ProfileDelete.defaultProps = {
  name: '',
  onAction: () => {},
};

ProfileDelete.propTypes = {
  name: PropTypes.string,
  onAction: PropTypes.func,
};
export default ProfileDelete;
