import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import searchReducer from './reducers/search';
import usersReducer from './reducers/users';
import routeReducer from './reducers/route';
import searchHistoryReducer from './reducers/search-history';
import mapReducer from './reducers/sanction-map';
import onboardingReducer from './reducers/onboarding';
import thirdPartyManagementReducer from './reducers/third-party-management';
import {
  listReducer, createListReducer, createClientReducer, loadClientReducer,
} from './reducers/lists';
import {
  iListReducer, createIListReducer,
} from './reducers/integrated-lists';
import {
  automatedListReducer, createAutomatedListReducer, loadAutomatedClientReducer,
} from './reducers/automated-lists';

import dashboard from './dashboard';
import sources from './sources';
import media from './media';
import notifications from './notifications';
import reports from './reports';
import searchHistory from './searchHistory';
import users2 from './users';
import lists2 from './lists';
import clients from './clients';

export default combineReducers({
  auth: authReducer,
  search: searchReducer,
  lists: listReducer,
  createList: createListReducer,
  createClient: createClientReducer,
  client: loadClientReducer,
  users: usersReducer,
  history: searchHistoryReducer,
  routers: routeReducer,
  sanctionMap: mapReducer,
  onboarding: onboardingReducer,
  thirdPartyManagement: thirdPartyManagementReducer,
  // integrated list reducers
  integratedLists: iListReducer,
  createIList: createIListReducer,
  // automated list reducers
  automatedLists: automatedListReducer,
  createAutomatedList: createAutomatedListReducer,
  automatedClient: loadAutomatedClientReducer,
  // Pages
  dashboard,
  sources,
  media,
  notifications,
  reports,
  searchHistory,
  users2,
  lists2,
  clients,
});
