// * $t stands for translate function provided by context
export const nameRules = (label = '', $t = () => {}) => [
  {
    required: true,
    message: $t('form.validation.required', [label]),
  },
  {
    min: 3,
    message: $t('form.validation.min', [label, 3]),
  },
];

export default nameRules;
