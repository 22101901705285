import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../assets/css/navbar.css';
import { Button } from 'reactstrap';
// import { useAPI } from '../services/api';

const kocOrganization = 'koç';
// eslint-disable-next-line
function Sidebar({ expanded, setExpanded }) {
  // const api = useAPI();
  const isAdmin = useSelector((state) => state.auth.user.role === 3);
  const isKycFormFiller = useSelector((state) => state.auth.user.role === 4);
  const userOrganization = useSelector((state) => state.auth?.user?.organization?.name
  && state.auth?.user?.organization?.name.toLowerCase());

  const [dashboard, setDashboard] = useState(false);
  const [maps, setMaps] = useState(false);
  // eslint-disable-next-line
  const [notify, setNotify] = useState(false);

  const isBrisa = useMemo(() => userOrganization === 'brisa', [userOrganization]);

  // const fetchNotifications = async () => {
  //   const { data: { unread } } = await api.getNotifications();
  //   setNotify(unread > 0);
  // };

  // useEffect(() => {
  //   fetchNotifications();
  //   setInterval(async () => {
  //     fetchNotifications();
  //   }, 10000);
  // }, []);

  if (isKycFormFiller) {
    return (
      <div className="vertical-menu" aria-expanded={expanded}>
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="collapse-list-item">
                <Button
                  color="link"
                  className="btn waves-effect collapse-btn"
                  onClick={() => setExpanded(!expanded)}
                >
                  <i className={expanded ? 'mdi mdi-drag-horizontal-variant' : 'mdi mdi-drag-vertical-variant'} />
                </Button>
              </li>
            </ul>
            <ul
              className="metismenu list-unstyled"
              id="side-menu"
            >
              <li>
                <Link
                  to="/onboarding"
                  className="waves-effect"
                >
                  <i className="mdi mdi-account-multiple-outline" />
                  <span>Onboarding</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  if (userOrganization !== undefined && userOrganization.includes(kocOrganization)) {
    return (
      <div className="vertical-menu" aria-expanded={expanded}>
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="collapse-list-item">
                <Button
                  color="link"
                  className="btn waves-effect collapse-btn"
                  onClick={() => setExpanded(!expanded)}
                >
                  <i className={expanded ? 'mdi mdi-drag-horizontal-variant' : 'mdi mdi-drag-vertical-variant'} />
                </Button>
              </li>
              <li>
                <Link to="/dashboard" className="waves-effect" onClick={() => { setDashboard(!dashboard); }}>
                  <i className="mdi mdi-view-dashboard-outline" />
                  {isAdmin && (
                    <span className="float-right">
                      <i className="mdi mdi-chevron-down transform-chevron" aria-expanded={dashboard} />
                    </span>
                  )}
                  <span>Dashboard</span>
                </Link>
                {/* eslint-disable-next-line */}
                <ul className="sub-menu" aria-expanded={dashboard}>
                  {isAdmin && <li className="menu-list-item"><Link to="/dashboard">Default</Link></li>}
                  {isAdmin && <li className="menu-list-item"><Link to="/dashboard/sources">Sources</Link></li>}
                  {isAdmin && <li className="menu-list-item"><Link to="/dashboard/media">Media</Link></li>}
                </ul>
              </li>
              <li>
                <Link to="/automated-lists" className="waves-effect list-sidebar">
                  <i className="mdi mdi-view-list" />
                  <span>Automated Lists</span>
                </Link>
              </li>
              <li>
                <Link to="/users" className="waves-effect">
                  <i className="mdi mdi-account-multiple-outline" />
                  <span>Users</span>
                </Link>
              </li>

              <li>
                <Link to="/notifications" className="waves-effect">
                  {
                    notify ? <i className="mdi mdi-bell-alert" />
                      : <i className="mdi mdi-bell-outline" />
                  }
                  <span>Notifications</span>
                </Link>
              </li>
              <li>
                <Link to="/saved-reports" className="waves-effect">
                  <i className="bx bx-download" />
                  <span>Saved Reports</span>
                </Link>
              </li>
            </ul>
            <ul
              className="metismenu list-unstyled"
              id="side-menu"
            >
              <li>
                <Link
                  to="/onboarding"
                  className="waves-effect"
                >
                  <i className="mdi mdi-account-multiple-outline" />
                  <span>Onboarding</span>
                </Link>
              </li>
              {isAdmin && (
              <li>
                <Link to="/third-party-management" className="waves-effect">
                  <i className="mdi mdi-account-multiple-outline" />
                  <span>Third Party Management</span>
                </Link>
              </li>
              )}
              {isAdmin && (
              <li>
                <Link to="/transaction-monitoring" className="waves-effect">
                  <i className="mdi mdi-monitor-multiple" />
                  <span>Transaction Monitoring</span>
                </Link>
              </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="vertical-menu" aria-expanded={expanded}>
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="collapse-list-item">
              <Button
                color="link"
                className="btn waves-effect collapse-btn"
                onClick={() => setExpanded(!expanded)}
              >
                <i className={expanded ? 'mdi mdi-drag-horizontal-variant' : 'mdi mdi-drag-vertical-variant'} />
              </Button>
            </li>
            <li>
              <Link to="/dashboard" className="waves-effect" onClick={() => { setDashboard(!dashboard); }}>
                <i className="mdi mdi-view-dashboard-outline" />
                {(isAdmin || isBrisa) && (
                  <span className="float-right">
                    <i className="mdi mdi-chevron-down transform-chevron" aria-expanded={dashboard} />
                  </span>
                )}
                <span>Dashboard</span>
              </Link>
              {/* eslint-disable-next-line */}
              <ul className="sub-menu" aria-expanded={dashboard}>
                {(isAdmin || isBrisa) && <li className="menu-list-item"><Link to="/dashboard">Default</Link></li>}
                {(isAdmin || isBrisa) && <li className="menu-list-item"><Link to="/dashboard/sources">Sources</Link></li>}
                {isAdmin && <li className="menu-list-item"><Link to="/dashboard/media">Media</Link></li>}
              </ul>
            </li>
            <li>
              <Link to="/search" className="waves-effect">
                <i className="mdi mdi-magnify" />
                <span>Search</span>
              </Link>
            </li>
            <li>
              <Link to="/search-history" className="waves-effect">
                <i className="mdi mdi-history" />
                <span>Search History</span>
              </Link>
            </li>
            <li>
              <Link to="/corruption-map" className="waves-effect" onClick={() => { setMaps(!maps); }}>
                <i className="mdi mdi-map-outline" />
                <span className="float-right">
                  <i className="mdi mdi-chevron-down transform-chevron" aria-expanded={maps} />
                </span>
                <span>Maps</span>
              </Link>
              {/* eslint-disable-next-line */}
              <ul className="sub-menu" aria-expanded={maps}>
                {/* <li className="menu-list-item"><Link to="/map">Sanction Map</Link></li> */}
                <li className="menu-list-item"><Link to="/corruption-map">Corruption Index</Link></li>
                <li className="menu-list-item"><Link to="/aml-index">AML Index</Link></li>
                <li className="menu-list-item"><Link to="/world-factbook-map">World Factbook</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/lists" className="waves-effect list-sidebar">
                <i className="mdi mdi-view-list" />
                <span>Lists</span>
              </Link>
            </li>
            <li>
              <Link to="/integrated-lists" className="waves-effect list-sidebar">
                <i className="mdi mdi-view-list" />
                <span>Integrated Lists</span>
              </Link>
            </li>
            <li>
              <Link to="/users" className="waves-effect">
                <i className="mdi mdi-account-multiple-outline" />
                <span>Users</span>
              </Link>
            </li>
            <li>
              <Link to="/notifications" className="waves-effect">
                {
                  notify ? <i className="mdi mdi-bell-alert" />
                    : <i className="mdi mdi-bell-outline" />
                }
                <span>Notifications</span>
              </Link>
            </li>
            <li>
              <Link to="/saved-reports" className="waves-effect">
                <i className="bx bx-download" />
                <span>Saved Reports</span>
              </Link>
            </li>
          </ul>
          <ul
            className="metismenu list-unstyled"
            id="side-menu"
          >
            <li>
              <a
                href="https://onboarding.sanctiontrace.com/"
                className="d-flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mdi mdi-account-multiple-outline" />
                <span>Onboarding</span>
                <i
                  className="fas fa-external-link-alt ml-auto"
                  style={{
                    fontSize: '12px',
                  }}
                />
              </a>
            </li>
            { false && isAdmin && (
            <li>
              <Link to="/third-party-management" className="waves-effect">
                <i className="mdi mdi-account-multiple-outline" />
                <span>Third Party Management</span>
              </Link>
            </li>
            )}
            {isAdmin && (
            <li>
              <Link to="/transaction-monitoring" className="waves-effect">
                <i className="mdi mdi-monitor-multiple" />
                <span>Transaction Monitoring</span>
              </Link>
            </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default Sidebar;
