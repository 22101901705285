export const units = {
  month: 24 * 60 * 60 * 30,
  week: 24 * 60 * 60 * 7,
  day: 24 * 60 * 60,
  hour: 60 * 60,
  minute: 60,
};

const formatNumberToTwoDigit = (number = 0) => number.toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false,
});

export const isValidDate = (date = '') => {
  try {
    const d = new Date(date);
    if (Object.prototype.toString.call(d) === '[object Date]') {
      if (Number.isNaN(d.getTime())) {
        return false;
      }
      return true;
    }
    return false;
  } catch (_) {
    return false;
  }
};

export const getDateValue = (date) => {
  if (isValidDate(date)) {
    return new Date(date);
  }
  return '';
};

export const secondsToString = (seconds = 0) => {
  const unitName = Object.keys(units).find((unit) => (seconds >= units[unit]));
  const unitTime = Math.round(seconds / units[unitName]);

  if (unitTime >= 1) {
    return `${unitTime} ${unitTime === 1 ? unitName : `${unitName}s`}`;
  }
  return null;
};

export const elapsedTime = (startTime = new Date()) => {
  const endTime = new Date();
  const timeDiff = (endTime - new Date(startTime)) / 1000; // ms to seconds

  const timeString = secondsToString(timeDiff);
  if (timeString) {
    return `${timeString} ago`;
  }
  return null;
};

export const formatTime = (date = null) => {
  if (!date && !isValidDate(date)) {
    return {};
  }
  const d = new Date(date);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const [
    { value: month },,
    { value: day },,
    { value: year },
  ] = dateTimeFormat.formatToParts(d);

  const hours = formatNumberToTwoDigit(d.getHours()) ?? 0;
  const minutes = formatNumberToTwoDigit(d.getMinutes()) ?? 0;

  return {
    minutes,
    hours,
    day: day ?? 0,
    month: month ?? 0,
    year: year ?? 0,
  };
};

export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth()
    .toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  const day = today.getDate()
    .toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  const date = `${year}-${month}-${day}`;
  return date;
};

export const getMonthNumber = (date = new Date()) => {
  try {
    return new Date(date).getMonth();
  } catch (error) {
    return -1;
  }
};

export const formattedDate = (date = '') => {
  const {
    minutes,
    hours,
    day,
    month,
    year,
  } = formatTime(date);

  if (minutes && hours && day && month && year) {
    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }
  return '';
};
