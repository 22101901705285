import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button, Spinner,
} from 'reactstrap';
import { useAPI } from '../../services/api';

function EditableContent({
  id, fieldName, fieldContent, mId,
}) {
  const api = useAPI();

  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [editedContent, setEditedContent] = useState(fieldContent);
  const saveEditedContent = async (fieldId, fieldN, editedText, masterId = null) => {
    setLoading(true);
    setIsClicked(false);
    let stat = 'error';
    if (masterId === null) {
      const { data: { status } } = await api.editSanctionData(fieldId, { [fieldN]: editedText });
      stat = status;
    } else {
      const { data: { status } } = await api
        .editMeasuresData(masterId, fieldId, { details: editedText });
      stat = status;
    }
    console.log(stat);
    setLoading(false);
    setCompleted(true);
    setTimeout(() => {
      setCompleted(false);
    }, 1000);
  };
  return (
    <>
      <td data-id={id} id={fieldName}>
        <div className="d-flex">
          <p
            id={`${id}-${fieldName}`}
            contentEditable={isClicked}
            style={{
              border: isClicked ? '1px solid gray' : 'none',
              display: 'block',
            }}
            onInput={(e) => {
              setEditedContent(e.target.innerText);
            }}
            suppressContentEditableWarning
          >
            {fieldContent}
          </p>
          <div className="pl-2">
            <Button
              disabled={loading}
              type="button"
              color="link"
              className="btn btn-link pt-1 pb-0 ml-auto"
              style={{
                width: '24px',
                height: '24px',
              }}
              onClick={() => setIsClicked(!isClicked)}
            >
              <i
                className="bx bx-pencil"
                style={{
                  marginLeft: '-12px',
                  fontSize: '20px',
                  color: 'gray',
                }}
              />
            </Button>
            <Button
              disabled={loading}
              type="button"
              color="link"
              className="btn btn-link pt-2 pb-0 ml-auto"
              style={{
                width: '24px',
                height: '24px',
              }}
              onClick={() => {
                setIsClicked(!isClicked);
                saveEditedContent(id, fieldName, editedContent, mId);
              }}
            >
              {loading ? (
                <Spinner
                  color="secondary"
                  style={{
                    width: '16px',
                    height: '16px',
                    marginLeft: '-10px',
                    marginTop: '-18px',
                  }}
                />
              )
                : (
                  <i
                    className={classnames({
                      bx: true,
                      'bx-save': !loading && !completed,
                      'bx-check-circle': !loading && completed,
                    })}
                    style={{
                      marginLeft: '-12px',
                      marginTop: '-12px',
                      fontSize: '20px',
                      color: !loading && completed ? '#34c38f' : 'gray',
                    }}
                  />
                )}
            </Button>
          </div>
        </div>
      </td>
    </>
  );
}

EditableContent.defaultProps = {
  mId: null,
};

EditableContent.propTypes = {
  id: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldContent: PropTypes.string.isRequired,
  mId: PropTypes.number,
};

export default EditableContent;
