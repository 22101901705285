import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function NotificationItem({ type, date, content }) {
  return (
    <li className="_dashboard__latest_notifications-list-item">
      <i
        className={classnames(
          '_dashboard__latest_notifications-list-item-dot',
          'bx bxs-right-arrow-circle',
          type,
        )}
      />
      <div className="_dashboard__latest_notifications-list-item-text">
        {content}
      </div>
      <p className="_dashboard__latest_notifications-list-item-time">
        {date}
      </p>
    </li>
  );
}

NotificationItem.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
