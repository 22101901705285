import React from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

const Option = (props2) => (
  <div>
    {/* eslint-disable-next-line */}
    <components.Option {...props2} className="search-history-react-select">
      <input
        type="checkbox"
        // eslint-disable-next-line
        checked={props2.isSelected}
        onChange={() => null}
        style={{
          paddingTop: '4px',
        }}
      />
      {/* eslint-disable-next-line */}
      <label>{props2.label}</label>
    </components.Option>
  </div>
);

// eslint-disable-next-line
const MultiValue = (props1) => {
  // eslint-disable-next-line
  const labelToBeDisplayed = `${props1.data.label}, `;
  return (
    // eslint-disable-next-line
    <components.MultiValue {...props1}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

function ReactSelect(props) {
  // Select
  // eslint-disable-next-line
  const ValueContainer = ({ children, ...props3 }) => (
  // eslint-disable-next-line
  <components.ValueContainer {...props3} className="value-container">
    {/* eslint-disable-next-line */}
    {children[1]}
    <div className="sh-input-select">{props.header}</div>
  </components.ValueContainer>
  );

  const DropdownIndicator = (props4) => (
    // eslint-disable-next-line
    <components.DropdownIndicator {...props4} className="select-indicator">
      <i
        className="bx bxs-down-arrow"
        style={{
          fontSize: '10px',
        }}
      />
    </components.DropdownIndicator>
  );

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid #ced4da',
      borderRight: props.borderRight ?? 0,
      // This line disable the blue border
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #ced4da',
        borderRight: props.borderRight ?? 0,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#ced4da', // Custom colour
    }),
  };

  const optionsData = () => props.selectData;
  return (
    <Select
      isSearchable={false}
      options={optionsData()}
      isMulti
      closeMenuOnSelect
      hideSelectedOptions
      styles={customStyles}
      value={props.getSelected}
      components={{
        Option,
        MultiValue,
        ValueContainer,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      onChange={(e) => {
        props.setSelected(e);
      }}
    />
  );
}

ReactSelect.defaultProps = {
  borderRight: '0',
};

ReactSelect.propTypes = {
  selectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelected: PropTypes.func.isRequired,
  // eslint-disable-next-line
  getSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line
  header: PropTypes.string.isRequired,
  borderRight: PropTypes.string,
};

export default ReactSelect;
