import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Card, CardBody, CardTitle, Button, Input,
} from 'reactstrap';

import Lock from '../../assets/images/lock_24px_outlined.svg';
import { useAPI } from '../../services/api';

function ShareLink({ searchParams, setToggle }) {
  const api = useAPI();

  const baseLink = window.location.href;

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const [copyText, setCopyText] = useState('Copy Link');
  const [usersData, setUsersData] = useState([]);
  // eslint-disable-next-line
  const [filteredUsers, setFilteredUsers] = useState([]);
  // eslint-disable-next-line
  const [showUsers, setShowUsers] = useState(false);
  const [userInput, setUserInput] = useState('');

  const [tags, setTags] = useState([]);

  const getUsers = async () => {
    const { data: { users } } = await api.fetchUsers();

    const fixedUsers = users.map((usr) => ({
      id: usr.id,
      label: usr.name,
      email: usr.email,
      user: true,
    }));

    setUsersData(fixedUsers);
    setFilteredUsers(fixedUsers);
  };

  const copyToClipBoard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    setCopyText('Copied');
    setTimeout(() => {
      setCopyText('Copy Link');
    }, 1000);
    document.body.removeChild(el);
  };

  const createShareLink = () => {
    let temp = baseLink;
    if (temp.includes('?q=')) {
      return temp;
    }
    if (searchParams.query !== undefined && searchParams.query.trim() !== '') {
      temp += `?q=${searchParams.query}&s=${searchParams.minNameScore}`;
      if (searchParams.phonetics) {
        temp += `&phonetics=${searchParams.phonetics}`;
      }
      if (searchParams.aka) {
        temp += `&aka=${searchParams.aka}`;
      }
      return temp;
    }
    return 'No search provided';
  };

  const resetTags = () => {
    document.querySelectorAll('.tag').forEach((tag) => {
      tag.parentElement.removeChild(tag);
    });
  };

  const createTag = (label, email = '') => {
    const div = document.createElement('div');
    div.setAttribute('class', 'tag');
    div.setAttribute('data-item', email);
    const span = document.createElement('span');
    span.innerText = label;
    const closeBtn = document.createElement('i');
    closeBtn.setAttribute('class', 'bx bx-x font-size-16 align-middle');
    closeBtn.addEventListener('click', (e) => {
      closeBtn.parentElement.parentElement.removeChild(closeBtn.parentElement);

      setTags((oldArray) => {
        const temp = oldArray.filter((tag) => e
          .target.parentElement.getAttribute('data-item') !== tag.email);
        if (temp.length === 0) {
          resetTags();
        }
        return temp;
      });
    });
    div.appendChild(span);
    div.appendChild(closeBtn);
    return div;
  };

  const prependTag = (label, email = '') => {
    const tagContainer = document.querySelector('.tag-container');
    tagContainer.prepend(createTag(label, email));
  };

  // const addTags = (arr) => {
  //   resetTags();
  //   arr.slice(0).reverse().forEach((tag) => {
  //     prependTag(tag);
  //   });
  // };

  const userElement = (user) => (
    // eslint-disable-next-line
    <li
      key={user.email}
      id={user.email}
      className="list-group-item d-flex align-items-center py-2 lih"
      // eslint-disable-next-line
      onClick={() => {
        const isNotInclude = tags.some((usr) => usr.email === user.email);

        if (!isNotInclude) {
          setTags((oldArray) => ([...oldArray, user]));
          prependTag(user.label, user.email);
        }

        // setUserInput(user.label);
        setShowUsers(false);
        setUserInput('');
      }}
    >
      <span className="name-circle">
        {user.label[0]}
      </span>
      {user.label}
      {' / '}
      {user.email}
    </li>
  );

  useEffect(() => {
    getUsers();
    document.querySelector('.main-card').addEventListener('click', (e) => {
      if (e.target.id !== 'share-person' && !e.target.id.includes('@')) {
        setShowUsers(false);
      }
    });
  }, []);

  useEffect(() => {
    if (userInput.trim().length > 0) {
      setFilteredUsers(usersData.filter((usr) => usr
        .email.includes(userInput.toLowerCase()) || usr.label.includes(userInput.toLowerCase())));
    } else {
      setFilteredUsers(usersData);
    }
  }, [usersData, userInput]);

  // send url with email to users
  const onSendLink = async () => {
    setLoading(true);
    const userIds = tags.map((t) => ({ id: t.id }));
    const url = createShareLink();

    const { data } = await api.shareLink(userIds, url);
    setStatus(data.status);

    setTimeout(() => {
      setLoading(false);
      setStatus('');
    }, 500);
    setTimeout(() => {
      setToggle(false);
    }, 1500);
    console.log(url, userIds);
  };
  console.log(searchParams);
  return (
    <Card className="main-card">

      <CardBody className="px-5 mr-4 mt-0 pt-0">
        <CardTitle className="m-0 p-0">
          <p style={{
            fontSize: '20px',
            fontWeight: '500',
            paddingBottom: '20px',
            margin: '2px',
          }}
          >
            Share Link
          </p>
        </CardTitle>
        <div
          className="py-3"
          style={{
            borderTop: '1px solid #EFF2F7',
            height: '46px',
            display: 'block',
          }}
        >
          <h5>SHARE LINK OF YOUR SEARCH</h5>
          <div style={{
            background: 'rgba(191, 210, 238, 0.22)',
            marginTop: '36px',
            padding: '0px',
            height: '46px',
            display: 'flex',
            border: '1px solid rgba(110, 124, 174, 0.35)',
            borderRadius: '5px',
            alignContent: 'center',
            justifyContent: 'start',
          }}
          >
            <div style={{
              alignSelf: 'center',
              marginLeft: '9px',
              marginRight: '8px',
            }}
            >
              <img src={Lock} alt="lock" />
            </div>
            <Input
              disabled
              className="m-0 input-no-border"
              style={{
                fontSize: '12px',
                height: 'auto',
                alignSelf: 'center',
                background: 'rgba(191, 210, 238, 0.0)',
                border: 'none',
                borderColor: 'none',
                textDecoration: 'underline',
              }}
              id="copy-link"
              value={createShareLink()}
            />
            <Button
              value={createShareLink()}
              color={copyText === 'Copied' ? 'success' : 'link'}
              className="btn btn-link waves-effect"
              style={{
                marginBottom: '27px',
                marginLeft: 'auto',
                height: '46px',
                width: '120px',
                color: copyText === 'Copied' ? 'white' : '#649AED',
                borderLeft: '1px solid rgba(110, 124, 174, 0.34)',
                alignSelf: 'end',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              }}
              onClick={(e) => {
                copyToClipBoard(e.target.value);
              }}
            >
              {copyText === 'Copied' && <i className="bx bx-check-double font-size-16 align-middle mr-1" />}
              {copyText}
            </Button>
          </div>

        </div>
        <div style={{
          marginTop: '120px',
          borderTop: '1px solid #EFF2F7',
          paddingTop: '25px',
        }}
        >
          <h5>SHARE LINK WITH COLLEAGUES</h5>
          <div className="i-container">
            <div className="tag-container">
              <Input
                autoComplete="off"
                disabled={usersData.length === 0}
                className="tag-input position-relative"
                id="share-person"
                placeholder={usersData.length > 0 ? 'Type to filter users and click to add (This can be shared with accounts within your organisation.).' : 'No users found'}
                value={userInput}
                // onFocus={() => setShowUsers(true)}
                onChange={(e) => setUserInput(e.target.value)}
              />
              {(userInput.trim().length > 0 || showUsers) && (
              <div className="position-absolute share-user-bar">
                <ul className="list-group list-group-flush d-flex">
                  {filteredUsers.map((user) => userElement(user))}
                </ul>
              </div>
              )}
            </div>
          </div>

        </div>

        <Button
          disabled={usersData.length === 0 || tags.length === 0 || createShareLink() === 'No search provided'}
          value={createShareLink()}
          color={(status === 'ok'
            ? 'success'
            : 'primary')}
          className="btn btn-primary waves-effect"
          style={{
            marginTop: '30px',
            minWidth: '108px',
          }}
          onClick={onSendLink}
        >
          {status !== 'ok' && loading === true && (
          <>
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
            Sending...
          </>
          )}
          {status === 'ok' && (
          <>
            <i className="bx bx-check-double font-size-16 align-middle mr-2" />
            Sent
          </>
          )}
          {loading === false && 'Send Link'}
        </Button>
      </CardBody>
    </Card>
  );
}
// ShareLink.defaultProps={
//   searchParams:[]
// }

ShareLink.propTypes = {
  searchParams: PropTypes.shape({
    query: PropTypes.string,
    minNameScore: PropTypes.number,
    idNumber: PropTypes.string,
    birthPlace: PropTypes.string,
    type: PropTypes.string,
    birthDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    country: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    aka: PropTypes.bool,
    phonetics: PropTypes.bool,
    noLogging: PropTypes.bool,
  }).isRequired,
  setToggle: PropTypes.func.isRequired,
};

export default ShareLink;
