import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

function StatusLabel({
  label,
  value,
  color,
}) {
  return (
    <Col span="8">
      <div className="mt-4">
        <p className="mb-2 text-truncate">
          <i
            className="mdi mdi-circle mr-1"
            style={{ color }}
          />
          {label}
        </p>
        <h5>{value}</h5>
      </div>
    </Col>
  );
}

StatusLabel.defaultProps = {
  color: null,
};

StatusLabel.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default StatusLabel;
