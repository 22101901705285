import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, Input,
} from 'reactstrap';
import MaskedInput from 'react-maskedinput';

import { editClient, loadClient } from '../store/reducers/lists';

const getCurrentDate = (range) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  if (range) return `${yyyy}/${mm}/${dd}`;
  return `${yyyy}-${mm}-${dd}`;
};

function ClientProfile({
  client, listId, cantEdit, requestAccessToggle,
}) {
  const dispatch = useDispatch();
  const { loading, status } = useSelector((state) => state.lists);

  const [name, setName] = useState(client?.name ?? '');
  const [idNumber, setIdNumber] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');

  const onSaveChanges = (e) => {
    e.preventDefault();
    if (cantEdit) {
      requestAccessToggle(true);
    } else {
      let temp = '';
      if (birthDate) temp = birthDate.split('-');
      dispatch(
        editClient(listId, client?.id, {
          id_number: idNumber,
          birth_date: temp.length > 0 ? `${temp[2]}/${temp[1]}/${temp[0]}` : temp,
          name,
          email,
          phone,
          notes,
        }),
      );
      setTimeout(() => {
        dispatch(loadClient(listId, client.id));
      }, 1000);
    }
  };

  useEffect(() => {
    setName(client?.name ?? '');
    // eslint-disable-next-line
    setIdNumber(client?.id_number ?? '');
    setEmail(client?.email ?? '');
    // eslint-disable-next-line
    setBirthDate(client?.birth_date ?? '');
    setPhone(client?.phone ?? '');
    setNotes(client?.notes ?? '');
  }, [client, client.name]);

  // const onChangePhone = (e) => {
  //   if (e.target.value.trim() !== null) {
  //     setPhone([...e.target.value].map((letter, index) => {
  //       console.log(letter);
  //       switch (index) {
  //         case 0:
  //           return `${letter}-(`;
  //         default:
  //           return letter;
  //       }
  //     }));
  //   }
  // };

  return (
    <form onSubmit={onSaveChanges}>
      <div>
        {/* client name */}
        <div className="form-group row">
          <label htmlFor="client-name" className="col-md-3 col-form-label">
            Name
          </label>
          <div className="col-md-9">
            <input
              disabled={cantEdit}
              required
              id="client-name"
              name="client-name"
              className="form-control"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        {/* Id number */}
        <div className="form-group row">
          <label htmlFor="id-number" className="col-md-3 col-form-label">
            ID number
          </label>
          <div className="col-md-9">
            <input
              disabled={cantEdit}
              id="id-number"
              name="id-number"
              className="form-control"
              type="text"
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
            />
          </div>
        </div>

        {/* Email */}
        <div className="form-group row">
          <label htmlFor="email" className="col-md-3 col-form-label">
            Email
          </label>
          <div className="col-md-9">
            <input
              disabled={cantEdit}
              id="email"
              name="email"
              className="form-control"
              type="email"
              placeholder="example@sanctiontrace.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        {/* Date of birth */}
        <div className="form-group row">
          <label htmlFor="birth-date" className="col-md-3 col-form-label">
            Date of birth
          </label>
          <div className="col-md-9">
            <input
              disabled={cantEdit}
              id="birth-date"
              name="birth-date"
              className="form-control"
              min="1900-01-01"
              max={getCurrentDate()}
              type="date"
              value={birthDate}
              onChange={(e) => {
                setBirthDate(e.target.value);
              }}
              data-date-format="DD/MM/YYYY"
            />
          </div>
        </div>

        {/* Telephone */}
        <div className="form-group row">
          <label htmlFor="tel" className="col-md-3 col-form-label">
            Telephone
          </label>
          <div className="col-md-9">
            <MaskedInput
              disabled={cantEdit}
              className="form-control"
              mask="+111-(111)-111-1111"
              name="card"
              size="20"
              value={phone}
              onChange={(e) => {
                const withoutExtraLetters = e.target.value.split('').filter((p) => {
                  if (p === '-' || p === '_' || p === '(' || p === ')') return false;
                  return true;
                }).join('');
                setPhone(withoutExtraLetters);
              }}
            />
          </div>
        </div>

        {/* Notes */}
        <div className="form-group row">
          <label htmlFor="tel" className="col-md-3 col-form-label">
            Notes
          </label>
          <div className="col-md-9">
            <Input
              disabled={cantEdit}
              type="textarea"
              className="form-control textArea"
              rows="3"
              style={{
                whiteSpace: 'pre-wrap',
              }}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>

        {/* <div className="form-group row">
          <p className="col-md-3 col-form-label"> </p>
          <div className="col-md-9">
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
            >
              Send note
            </Button>
            <Button
              color="link"
              outlines
              className="waves-effect waves-light"
              style={{
                color: '#649AED',
              }}
            >
              See notes
            </Button>
          </div>
        </div> */}

        <div
          className="form-group row"
          style={{
            marginTop: '53px',
          }}
        >
          <p className="col-md-3 col-form-label"> </p>
          <div className="col-md-9">
            <Button
              type="submit"
              color={!loading && status === 'ok' ? 'success' : 'primary'}
              className="waves-effect waves-light"
            >
              {status === 'ok' && (
                <>
                  <i className="bx bx-check-double font-size-16 align-middle mr-2" />
                  Saved
                </>
              )}
              {loading === true && (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
                  Saving...
                </>
              )}
              {loading !== true && status !== 'ok' && 'Save changes'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
ClientProfile.defaultProps = {
  client: {
    id: -1,
    name: '',
    email: '',
    phone: '',
    namnotese: '',
  },
};

ClientProfile.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    notes: PropTypes.string,
  }),
  listId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};

export default ClientProfile;
