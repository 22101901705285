// * $t stands for translate function provided by context
export const nameRules = (label = '', $t = () => {}) => [
  {
    required: true,
    message: $t('form.validation.required', [label]),
  },
  {
    min: 3,
    message: $t('form.validation.min', [label, 3]),
  },
];

export const titleRules = (label = '', $t = () => {}) => [
  {
    min: 3,
    message: $t('form.validation.min', [label, 3]),
  },
];

export const departmentRules = (label = '', $t = () => {}) => [
  {
    min: 3,
    message: $t('form.validation.min', [label, 3]),
  },
];

export const phoneRules = (label = '', $t = () => {}) => [
  {
    pattern: /90\d{10}/,
    message: $t('form.validation.invalid', [label, 3]),
  },
];

export const phoneMask = '+{\\9\\0}(000) 000 0000';

export const currentPasswordRules = (label = '', $t = () => {}) => [
  {
    required: true,
    message: $t('form.validation.required', [label]),
  },
];

export const passwordRules = (
  newPasswordLabel = '',
  currentPasswordLabel = '',
  $t = () => {},
) => [
  {
    required: true,
    message: $t('form.validation.required', [newPasswordLabel]),
  },
  {
    min: 8,
    message: $t('form.validation.min', [newPasswordLabel, 8]),
  },
  {
    pattern: new RegExp(/[A-Z]/),
    message: $t('form.validation.include.capital', [newPasswordLabel]),
  },
  {
    pattern: new RegExp(/[0-9]/),
    message: $t('form.validation.include.number', [newPasswordLabel]),
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('oldPassword') !== value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error($t('form.validation.is', [
        newPasswordLabel,
        currentPasswordLabel,
      ])));
    },
  }),
];

export const confirmPasswordRules = (
  confirmPasswordLabel = '',
  newPasswordLabel = '',
  $t = () => {},
) => [
  {
    required: true,
    message: $t('form.validation.required', [confirmPasswordLabel]),
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error($t('form.validation.is_not', [
        confirmPasswordLabel,
        newPasswordLabel,
      ])));
    },
  }),
];
