import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import ProfileRow from './ProfileRow';
import '../assets/css/client.css';

const getFormattedTime = (date) => {
  try {
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(new Date(date));
    return `${day} ${month}, ${year}`;
  } catch (error) {
    return '';
  }
};

function ClientResults({
  results, setResults, getClickedSource, loading, clientResultColumns,
  cantEdit, clientId, listId, getSourceData,
  requestAccessToggle,
}) {
  const customTableStyles = {
    headCells: {
      style: {
        background: '#F8F9FA',
        color: 'black',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        height: '44px',
      },
      sortFocus: {
        color: '#FFFFFF',
      },
    },
    rows: {
      style: {
        maxHeight: '48px', // override the row height
        cursor: 'pointer',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#CDE1FF',
      },
    },
  };

  const onSortRows = (rows, field, direction) => {
    const ab = rows.sort((a, b) => {
      if (field === 'score') {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
      } else if (field === 'date') {
        if (a.pureDate === '') return -1;
        if (new Date(a.pureDate) < new Date(b.pureDate)) {
          return -1;
        }
        if (new Date(a.pureDate) > new Date(b.pureDate)) {
          return 1;
        }
      } else if (field === 'source') {
        if (a[field].props.id < b[field].props.id) {
          return -1;
        }
        if (a[field].props.id > b[field].props.id) {
          return 1;
        }
      } else if (field === 'action') {
        if (a.isTagged < b.isTagged) {
          return -1;
        }
        if (a.isTagged > b.isTagged) {
          return 1;
        }
      } else if (a[field] !== undefined && b[field] !== undefined) {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return -1;
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return 1;
        }
      }
      return 0;
    });
    if (direction === 'asc') return ab.slice(0);
    return ab.slice(0).reverse();
  };

  const fixedData = useMemo(() => results.map((r) => {
    const fixedSource = (
      <div className="client-row" id={r?.source?.name}>
        {r?.source?.name}
        {r?.source?.description && r?.source?.description.length > 0 && (<span className="tooltiptext">{r.source.description}</span>)}
      </div>
    );
    const fixedDate = (
      <div id={r?.content?.lastUpdated} className={getFormattedTime(r.content.lastUpdated)}>
        {getFormattedTime(r?.content?.lastUpdated)}
      </div>
    );
    return ({
      id: r.id,
      // eslint-disable-next-line
      name: r?.content?.preferred_name ?? r?.content?.name,
      score: parseInt(r?.content?.score, 10),
      source: fixedSource,
      date: fixedDate,
      pureDate: r?.content?.lastUpdated ?? '',
      isTagged: r?.is_tagged,
      action: <ProfileRow
        requestAccessToggle={requestAccessToggle}
        listId={listId}
        clientId={clientId}
        cantEdit={cantEdit}
        resultData={r}
        setResults={setResults}
      />,
    });
  }), [getClickedSource]);

  useEffect(() => {
    if (results.length === 0) getSourceData();
  }, []);
  return (
    <div className="table-responsive">
      <Card className="mb-0">
        <CardBody
          className="pb-0"
          style={{
            overflow: 'hidden',
          }}
        >
          <DataTable
            id=""
            progressPending={loading}
            allowOverflow
            overflowY
            progressComponent={<Spinner className="mr-2" color="secondary" />}
            sortFunction={onSortRows}
            defaultSortField="score"
            paginationPerPage={30}
            defaultSortAsc={false}
            onRowClicked={(e) => {
              getClickedSource(e.id);
            }}
            columns={clientResultColumns}
            customStyles={customTableStyles}
            data={fixedData}
            pagination
            highlightOnHover
            noHeader
          />
        </CardBody>
      </Card>
    </div>
  );
}

ClientResults.propTypes = {
  listId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  setResults: PropTypes.func.isRequired,
  getClickedSource: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cantEdit: PropTypes.bool.isRequired,
  clientResultColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    selector: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
  }).isRequired).isRequired,
  getSourceData: PropTypes.func.isRequired,
  requestAccessToggle: PropTypes.func.isRequired,
};

export default ClientResults;
