import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';
import {
  FETCH_LIST,
  FETCH_LISTS,
  FETCH_LIST_LOGS,
  UPDATE_LIST,
} from 'constants/actionTypes/lists';

export const fetchLists = createAsyncThunk(
  FETCH_LISTS,
  async (_, { rejectWithValue }) => {
    const [res, error] = await request(api.getLists2());

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { lists } } = res;
    return lists;
  },
);

export const fetchList = createAsyncThunk(
  FETCH_LIST,
  async (payload, { rejectWithValue }) => {
    const id = payload;
    const [res, error] = await request(api.getList(id));

    if (error) {
      return rejectWithValue(error);
    }
    const { data } = res;
    return data;
  },
);

export const fetchListLogs = createAsyncThunk(
  FETCH_LIST_LOGS,
  async (payload, { rejectWithValue }) => {
    const id = payload;
    const [res, error] = await request(api.getListLogs(id));

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { list_logs: logs } } = res;
    return logs;
  },
);

export const updateList = createAsyncThunk(
  UPDATE_LIST,
  async (payload, { rejectWithValue }) => {
    const { id, data } = payload;
    const [res, error] = await request(api.editList2(id, data));

    if (!res && error) {
      return rejectWithValue(error);
    }

    return payload;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: {
    lists: [],
    list: {
      logs: [],
    },
  },
};

const listsSlice = createSlice({
  name: 'lists2',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLists.pending]: (state) => {
      state.pending = true;
      state.data.lists = [];
      state.error = null;
    },
    [fetchLists.fulfilled]: (state, action) => {
      state.pending = false;
      state.data.lists = action.payload;
    },
    [fetchLists.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [fetchList.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [fetchList.fulfilled]: (state, action) => {
      state.pending = false;
      state.data.list = Object.assign(state.data.list, action.payload);
    },
    [fetchList.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [fetchListLogs.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [fetchListLogs.fulfilled]: (state, action) => {
      state.pending = false;
      state.data.list = Object.assign(state.data.list, { logs: action.payload });
    },
    [fetchListLogs.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [updateList.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [updateList.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.pending = false;
      state.data.list = Object.assign(state.data.list, data);
    },
    [updateList.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

// selectors
export const currentList = createSelector(
  (state) => state.lists2,
  (state) => state.clients,
  (lists2, clients) => ({
    pending: lists2.pending || clients.pending,
    error: lists2.error,
    list: {
      ...lists2.data.list,
      clients: clients.data,
    },
  }),
);

export default listsSlice.reducer;
