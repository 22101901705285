import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Spinner, Card, CardBody, CardSubtitle, CardTitle,
} from 'reactstrap';
import { elapsedTime } from 'helpers';

function ClientAdverseMedia({
  listId, clientId, results, loading, getSourceData,
}) {
  useEffect(() => {
    if (results.length === 0) getSourceData();
  }, [listId, clientId]);
  return (
    <>
      { loading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner className="mr-2" color="secondary" />
        </div>
      )}
      { results.length === 0 && !loading && <p>There is no news to display</p>}
      { results.length > 0 && !loading && (
        results.map(({ id: rId, content: r }) => (
          <Card key={rId} className="adverseMediaNewsCard">
            <CardBody className="d-flex pb-1 justify-content-center">
              <div className="mr-3">
                <CardSubtitle
                  tag="h6"
                  className="mb-2 d-flex align-items-center adverMediaNewsSource"
                >
                  <span>
                    {r?.source?.name}
                  </span>
                </CardSubtitle>
                <CardTitle>
                  <a
                    className="adverseMediaNewsTitle"
                    href={r?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {r?.title}
                  </a>
                </CardTitle>
                <span className="mr-2 text-muted adverseMediaNewsDetails">
                  {r?.description}
                </span>
                <p className="mt-2 mb-2 text-muted adverMediaNewsTimeDifference">
                  {elapsedTime(r?.date)}
                </p>
              </div>
              <div className="ml-auto">
                { r?.image.length > 0 && (
                <img
                  src={r?.image}
                  width="112"
                  height="112"
                  alt={r.title}
                  style={{
                    borderRadius: '8px',
                  }}
                />
)}
              </div>

            </CardBody>
          </Card>
        ))
      )}
    </>
  );
}

ClientAdverseMedia.propTypes = {
  listId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  getSourceData: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    source: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
};

export default ClientAdverseMedia;
