import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const getFormattedTime = (date, dmy = false) => {
  try {
    const d = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [
      { value: month },,
      { value: day },,
      { value: year },
    ] = dateTimeFormat.formatToParts(d);

    const hh = d.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = d.getMinutes();
    const minutes = m < 10 ? `0${m}` : m;
    if (dmy) {
      return `${day} ${month} ${year}`;
    }
    return `${day} ${month} ${year} at ${hours}.${minutes}`;
  } catch (error) {
    return '';
  }
};

function FileCard({ file, isFileSize }) {
  return (
    <div
      className={classnames({
        'verify-file-card': true,
        'd-flex': true,
        'align-items-center': true,
        'file-card-wrapper': true,
        'file-card-border-top': true,
      })}
    >
      <div>
        <i className="bx bxs-file-blank verify-file-icon align-middle mr-0" />
      </div>
      <div className="w-100">
        <div className="pl-2 pr-3 d-flex align-items-center">
          <div>
            <p className="m-0 doc-file-name preview-file-name">{file?.filename ?? ''}</p>
            <div className="d-flex align-items-center doc-file-info mt-2">

              <p className="m-0">{getFormattedTime(file.creation_date)}</p>
              {isFileSize && (
              <div className="d-flex align-items-center ml-3">
                <div className="doc-file-size-circle mr-2" />
                <p className="m-0">
                  {parseInt(file?.size, 10) ?? 0}
                  {' '}
                  Kb
                </p>
              </div>
              )}
            </div>
          </div>

          <div className="ml-auto">
            <button
              type="button"
              className="btn btn-link waves-effect waves-light px-0"
              onClick={() => {
                if (file?.url) {
                  window.open(file?.url, '_blank');
                }
              }}
            >
              <i className="mdi mdi-download doc-file-eye" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FileCard.defaultProps = {
  isFileSize: true,
};

FileCard.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filename: PropTypes.string,
    creation_date: PropTypes.string,
    url: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  isFileSize: PropTypes.bool,
};

export default FileCard;
