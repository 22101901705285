import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Button,
} from 'reactstrap';

function OnboardingWelcome({ name = '' }) {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col sm={3} md={3} xl={3} lg={3}>
            <Media>
              <div className="mr-3">
                {/* <img
                  src={avatar1}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail"
                /> */}
              </div>
              <div className="text-muted">
                <p className="mb-2">Welcome to ST Digital Onboarding Platform</p>
                <h5 className="mb-1">{name}</h5>
                {/* <p className="mb-0">UI / UX Designer</p> */}
              </div>
            </Media>
          </Col>
          <Col sm={7} md={7} xl={7} lg={7} className="align-self-center px-3 mb-4">
            <div className="text-lg-center mt-4 mt-lg-0">
              <Row>
                <Col xs="4">
                  <div>
                    <p className="text-muted text-truncate mb-2">Total Onboarding</p>
                    <h5 className="mb-0">48</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div>
                    <p className="text-muted text-truncate mb-2">Pending Onboarding</p>
                    <h5 className="mb-0">40</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div>
                    <p className="text-muted text-truncate mb-2">Comleted Onboarding</p>
                    <h5 className="mb-0">18</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={2} md={2} xl={2} lg={2}>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/onboarding" className="ml-auto">
                <Button
                  color="primary"
                  className="btn waves-effect waves-light"
                >
                  Onboarding
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

OnboardingWelcome.defaultProps = {
  name: '',
};

OnboardingWelcome.propTypes = {
  name: PropTypes.string,
};

export default OnboardingWelcome;
