import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';
import {
  REPORTS_FETCH,
  REPORTS_DELETE,
} from 'constants/actionTypes/reports';

export const fetchReports = createAsyncThunk(
  REPORTS_FETCH,
  async (userId, { rejectWithValue }) => {
    const [res, error] = await request(api.getGeneratedReports(userId));

    if (error) {
      return rejectWithValue(error);
    }
    const { data: { reports } } = res;
    return [...reports.reverse()];
  },
);

export const deleteReport = createAsyncThunk(
  REPORTS_DELETE,
  async (payload, { rejectWithValue }) => {
    const { userId, reportId } = payload;
    const [,, error] = await request(api.deleteGeneratedReport(userId, reportId));

    if (error) {
      return rejectWithValue(error);
    }

    return reportId;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchReports.pending]: (state) => {
      state.pending = true;
      state.data = [];
      state.error = null;
    },
    [fetchReports.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
    [fetchReports.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [deleteReport.fulfilled]: (state, action) => {
      state.data = state.data.filter((item) => item?.id !== action.payload);
    },
  },
});

export default reportsSlice.reducer;
