import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/auto-complete.css';
import { Input } from 'reactstrap';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: props.defaultValue,
    };
  }

  onChange = (e) => {
    const { suggestions, setInput, onBlur } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
    setInput(e.currentTarget.value);
    onBlur(e.currentTarget.value);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    const { setInput, onBlur } = this.props;
    setInput(e.currentTarget.innerText);
    onBlur(e.currentTarget.innerText);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { setInput } = this.props;
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
      setInput(filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <>
                  {/* eslint-disable-next-line */}
                  <li className={className} key={suggestion} onClick={onClick}>
                    {suggestion}
                  </li>
                </>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            {/* <em>No suggestions, you&apos;re on your own!</em> */}
          </div>
        );
      }
    }
    const {
      placeholder, id, disabled, defaultValue,
    } = this.props;

    if (id.length !== 0) {
      return (
        <>
          <Input
            type="text"
            className="form-control input-text"
            autoComplete="off"
            id={id}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            value={disabled ? defaultValue : userInput}
            disabled={disabled}
          />
          {suggestionsListComponent}
        </>
      );
    }
    return (
      <>
        <input
          disabled={disabled}
          autoComplete="off"
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          value={userInput}
        />
        {suggestionsListComponent}
      </>
    );
  }
}
Autocomplete.defaultProps = {
  disabled: false,
  suggestions: [],
  placeholder: '',
  id: '',
  defaultValue: '',
  setInput: () => {},
  onBlur: () => {},
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  setInput: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

export default Autocomplete;
