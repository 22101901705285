import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Modal, ModalBody, Row, Spinner,
} from 'reactstrap';
import { useAPI } from '../../services/api';
import { resultModalFields as fields } from './constants';

/* eslint-disable camelcase */
function CountryResultsModal({
  toggleModal, setToggleModal, selectedCountries, setSelectedCountries,
}) {
  const api = useAPI();
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedCountriesData, setSelectedCountriesData] = useState([]);

  // const titleCase = (str) => {
  //   if (str !== undefined) {
  //     return str
  //       .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  //   }
  //   return '';
  // };

  const getCountryData = async () => {
    const temp = [];
    setLoading(true);

    if (selectedCountries[0] !== undefined) {
      const { data: { data } } = await api.getFactbookCountryData(selectedCountries[0].code);

      const d = {
        country: selectedCountries[0].name,
        population: data?.peopleAndSociety?.population ?? '',
        nationality: `noun: ${data?.peopleAndSociety?.nationality?.noun ?? ''}\nadjective: ${data?.peopleAndSociety?.nationality?.adjective ?? ''}`,
        ethnicGroups: data?.peopleAndSociety?.ethnicGroups ?? '',
        languages: data?.peopleAndSociety?.languages ?? '',
        legalSystem: `${data?.government?.legalSystem ?? ''}`,
        political: data?.government?.politicalPartiesAndLeaders ?? '',
        organization: data?.government?.internationalOrganizationParticipation
          ? data?.government?.internationalOrganizationParticipation : '',
        creditRatings: '',
        realGDP: '',
        // realGDP: '$39.859 billion (2019 est.)\n
        // $38.986 billion (2018 est.)\n$37.461 billion (2017 est.)',
        easeOfDoingBusiness: '',
        // easeOfDoingBusiness: '53.5 (2020)',
        exports: '',
        // exports: '$900.7 million (2017 est.)\n$789.1 million (2016 est.)',
        exportsPartners: data?.economy?.exportsPartners ?? '',
        exportsCommodities: data?.economy?.exportsCommodities ?? '',
        imports: '',
        // imports: '$4.103 billion (2017 est.)\n$3.67 billion (2016 est.)',
        importsPartners: data?.economy?.importsPartners ?? '',
        importsCommodities: data?.economy?.importsCommodities ?? '',
        disputesInternational: data?.transnationalIssues?.disputesInternational ?? '',
        trafficking: data?.transnationalIssues?.traffickingInPersons ?? '',
      };
      temp.push(d);

      setLoading(false);
      setSelectedCountriesData(temp);
      let body = temp.map((s) => fields
        .filter((field) => !!s[field.key]).map((field) => (
          <tr key={field.key}>
            <td className="label-header" data-id={s.id} id={field.key}>
              {field.label}
            </td>
            {
            field.render && field.render(s[field.key], s.id, field.key)
          }
            {
            !field.render && !Array.isArray(s[field.key])
            && <td><p>{s[field.key]}</p></td>

          }
          </tr>
        )));

      body = body.map((b) => (
        <div style={{
          borderBottom: '2px solid #74788D',
        }}
        >
          {b}
        </div>
      ));

      setContent(body);
    }
  };

  const getClickableResources = () => {
    if (selectedCountriesData !== undefined) {
      const anchorElements = selectedCountriesData.map((c) => {
        const elements = selectedCountriesData.map((co) => fields
          .filter((field) => !!co[field.key]).map((s) => (
            <li className="list-group-item pl-0">
              <a href={`#${s.key}`}>
                {s.label}
              </a>
            </li>
          )));
        return (
          <div
            className="mb-4"
            style={{
              borderBottom: '1px solid rgb(238 238 238)',
            }}
          >
            <p className="country-name">
              {selectedCountries[0]?.name ?? c.country}
            </p>
            <ul className="list-group list-group-flush anchor scrollable-left-nav">
              {elements}
            </ul>
          </div>
        );
      });
      return (
        <div
          className="fixed-country-container"
          style={{
            overflowY: selectedCountriesData.length > 1 ? 'scroll' : 'none',
          }}
        >
          {anchorElements}
        </div>
      );
    }
    return '';
  };

  useEffect(() => {
    getCountryData();
  }, [selectedCountries]);
  return (
    <Modal
      isOpen={toggleModal}
      toggle={() => {
        setToggleModal(false);
        setSelectedCountries([]);
      }}
      centered
      size="xl"
    >
      <ModalBody
        className="p-0 ml-0"
        style={{
          background: '#F8F8FB',
          borderRadius: '5px',
          height: '90vh',
          overflowY: 'scroll',
        }}
      >
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: '100%',
            }}
          >
            <Spinner color="secondary" />
          </div>
        )}
        {!loading && (
          <Row
            className="p-0 m-0"
          >
            <Col md="3" className="pl-0">
              {getClickableResources()}
            </Col>
            <Col className="pt-2">
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setToggleModal(false);
                    setSelectedCountries([]);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ marginLeft: 'auto', fontSize: '24px' }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {' '}
              {content.map((c) => (
                <div className="mt-4">
                  <table
                    className="sanction-table"
                  >
                    <tbody>
                      {c}
                    </tbody>
                  </table>
                </div>
              ))}
            </Col>
          </Row>
        )}

      </ModalBody>
    </Modal>
  );
}

CountryResultsModal.propTypes = {
  toggleModal: PropTypes.bool.isRequired,
  setToggleModal: PropTypes.func.isRequired,
  setSelectedCountries: PropTypes.func.isRequired,
  selectedCountries: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CountryResultsModal;
