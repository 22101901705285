/* eslint-disable arrow-body-style */
import React, { createContext, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

class API {
  #http;

  constructor({ baseURL = process.env.REACT_APP_API_URL }) {
    if (!baseURL) {
      baseURL = 'https://api.sanctiontrace.com';
    }
    this.#http = axios.create({ baseURL });
  }

  setToken = (token) => {
    if (!token) {
      this.#http.defaults.headers.common.Authorization = '';
    } else {
      this.#http.defaults.headers.common.Authorization = `Token ${token}`;
    }
  };

  login = (email, password) => this.#http.post('/login', { email, password });

  register = (token, name, password, address = '', phone = '') => this.#http.post('/users/register', {
    invitation_token: token,
    name,
    password,
    address,
    phone,
  });

  deleteUser = (id) => this.#http.delete(`/users/${id}`);

  resetPassword = (password, token) => this.#http.post('users/password/reset', { password, token });

  forgotPassword = (email) => this.#http.post('/users/password/forgot ', { email });

  // TODO:: why we need a trailing slash
  search = (params) => this.#http.post('/search/sanction', params);

  searchPep = (name) => this.#http.post('/search/pep', { name });

  advancedSearch = (params) => this.#http.post('/search/sanction', params);

  getSources = () => axios.get('https://scout.sanctiontrace.com/sanctions/sources/');

  getSource = (id) => axios.get(`https://scout.sanctiontrace.com/sanctions/sources/${id}/`);

  getSourceLogs = (id) => axios.get(`https://scout.sanctiontrace.com/sanctions/sources/${id}/logs/`);

  getSourceData = (sourceId) => axios.get(`https://scout.sanctiontrace.com/sanctions/sources/${sourceId}`);

  getLists = () => this.#http.get('/lists/');

  getLists2 = (params = {}) => this.#http.get('/lists/', { params });

  getList = (id) => this.#http.get(`/lists/${id}`);

  getListLogs = (id) => this.#http.get(`/lists/${id}/logs`);

  getClients = (listId, page = 1, pageSize = 10, params = {}) => this.#http.get(`/v1/lists/${listId}/clients/?page=${page}&page_size=${pageSize}`, { params });

  getClients2 = (id, params = {}) => this.#http.get(`/v1/lists/${id}/clients/`, { params });

  getClient = (listId, clientId) => this.#http.get(`lists/${listId}/clients/${clientId}`);

  getClientDetails = (listId, id) => this.#http.get(`lists/${listId}/clients/${id}/search/results`);

  changeSourceStatus = (id, resultId, status) => this.#http.post(`/clients/${id}/search/results/status`, {
    result_id: resultId,
    status_id: status,
  });

  changeTail = (clientId, tail) => this.#http.post(`/clients/${clientId}/tail`, {
    tail,
  });

  editList = (id, name, sensivity, score, options, searchOptions) => this.#http.patch(`lists/${id}`, {
    name,
    sensivity,
    score,
    options,
    search_options: searchOptions,
  });

  editList2 = (id, payload) => this.#http.patch(`lists/${id}`, payload);

  editClient = (listId, clientId, data) => this.#http.patch(`/lists/${listId}/clients/${clientId}`, { ...data });

  createList = (data) => this.#http.post('/lists/', data);

  deleteList = (id) => this.#http.delete(`/lists/${id}`);

  deleteClient = (listId, clientId) => this.#http.delete(`/lists/${listId}/clients/${clientId}`);

  deleteSomeClients = (listId, ids) => this.#http.post(`/lists/${listId}/clients/delete`, ids);

  createClients = (id, data) => this.#http.post(`/lists/${id}/clients`, data);

  getNotifications = () => this.#http.get('/notifications/');

  getNotificationsByCategory = (id) => this.#http.get(`/notifications/${id === 0 ? '' : `?type_id=${id}`}`);

  pushSampleNotification = () => this.#http.get('/notifications/push');

  deleteNotification = (id) => this.#http.delete(`/notifications/${id}`);

  fetchUsers = () => this.#http.get('/users/');

  editPermissions = (id, request) => this.#http.post(`/users/${id}/permissions`, request);

  inviteUser = (email, list_permissions = [], user_permissions = []) => this.#http.post('/users/invites/send', { email, list_permissions, user_permissions });

  getSearchHistory = (userId, limits = '', token = '') => this.#http.get(`/users/${userId}/search/history${limits}${token}`);

  deleteSearchHistory = (userId, ids) => this.#http.post(`/users/${userId}/search/history/delete`, ids);

  shareLink = (users, url) => this.#http.post('/search/share', { users, url });

  getMapData = () => this.#http.get('/map/sanctions');

  getCountryData = (countryCode) => this.#http.get(`/map/countries/${countryCode}/sanctions`);

  editSanctionData = (id, params) => this.#http.patch(`/map/sanctions/${id}`, params);

  editMeasuresData = (id, mId, params) => this.#http.patch(`/map/sanctions/${id}/measures/${mId}`, params);

  getStates = (listId) => this.#http.get(`/lists/${listId}/states`);

  requestFile = (searchId, type) => this.#http.get(`/search/${searchId}/export/${type}`);

  requestHistoryFile = (userId, type = 'pdf') => this.#http.get(`/users/${userId}/search/history/export/${type}`);

  getPdf = (reportId) => this.#http.get(`/search/reports/${reportId}`);

  getGeneratedReports = (userId) => this.#http.get(`/users/${userId}/reports`);

  deleteGeneratedReport = (userId, reportId) => this.#http.delete(`/users/${userId}/reports/${reportId}`);

  fetchInvitation = (token) => this.#http.get(`/users/invites/${token}`);

  fetchInvites = () => this.#http.get('/users/invites');

  deleteInvitation = (userId, invId) => this.#http.delete(`/users/${userId}/invites/${invId}`);

  getSearchResults = (id, token = '') => this.#http.get(`/search/${id}${token}`);

  getAdverseMediaResults = (input, images) => this.#http.post('/search/media', { input, images });

  checkClients = (clients) => this.#http.post('/clients/check', { clients });

  changeClientStatus = (listId, clientId, status) => this.#http.patch(`/lists/${listId}/clients/${clientId}`, { status });

  getListPermissions = (listId) => this.#http.get(`/lists/${listId}/permissions`);

  getDashboardInfo = () => this.#http.get('/dashboard');

  changeReferencedStatus = (listId, clientId, resultId, isTagged) => this.#http.patch(`/lists/${listId}/clients/${clientId}/results/${resultId}`, {
    is_tagged: isTagged,
  });

  requestAccessToList = (listId) => this.#http.post(`/lists/${listId}/permissions/temporary`, {
    access_type: 2,
  });

  acceptRequestAccess = (listId, permId, token = '') => this.#http.get(`/lists/${listId}/permissions/temporary/${permId}/approve${token}`);

  getClientDocuments = (listId, clientId) => this.#http.get(`/lists/${listId}/clients/${clientId}/documents`);

  postClientDocuments = (listId, clientId, documents, config) => this.#http.post(`/lists/${listId}/clients/${clientId}/documents`, documents, config);

  deleteClientDocument = (listId, clientId, docId) => this.#http.delete(`/lists/${listId}/clients/${clientId}/documents/${docId}`);

  markAsRead = (notificationId) => this.#http.patch(`/notifications/${notificationId}`, { is_read: true });

  updateNotification = (id, params) => this.#http.patch(`/notifications/${id}`, params);

  // Onboarding

  getInvitations = () => this.#http.get('/kyc/invitations');

  createInvitation = (name, surname, email, orgTitle, language, permissions) => this.#http.post('/kyc/invitations', {
    name,
    surname,
    email,
    organization_title: orgTitle,
    language,
    permissions,
  });

  kycRegister = (data) => this.#http.post('/kyc/registration', data);

  deleteInvitations = (ids) => this.#http.delete('/kyc/invitations', {
    data: ids,
  });

  getForms = () => this.#http.get('/kyc/forms');

  getForm = (id) => this.#http.get(`/kyc/forms/${id}`);

  getFormHistory = (id) => this.#http.get(`/kyc/forms/${id}/history`);

  deleteForms = (ids) => this.#http.delete('/kyc/forms', {
    data: ids,
  });

  createForm = (data) => this.#http.post('/kyc/forms', {
    data,
  });

  updateForm = (formId, data) => this.#http.patch(`/kyc/forms/${formId}`, {
    data,
  });

  updateKycStatus = (formId, status, notes = '') => this.#http.patch(`/kyc/forms/${formId}`, {
    status,
    notes,
  });

  // updateFormPermissions = (formId, permissions) => this.#http.patch(`/kyc/forms/${formId}`, {
  //   permissions,
  // });

  updateFormPermissions = (formId, permissions) => this.#http.patch(`/kyc/forms/${formId}/permissions`, permissions);

  deleteFormPermissions = (formId, permissions) => this.#http.delete(`/kyc/forms/${formId}/permissions`, {
    data: permissions,
  });

  // form documents
  getFormDocuments = (formId) => this.#http.get(`/kyc/forms/${formId}/documents`);

  deleteFormDocument = (formId, docId) => this.#http.delete(`/kyc/forms/${formId}/documents/${docId}`);

  postFormDocuments = (formId, documents, config) => this.#http.post(`/kyc/forms/${formId}/documents`, documents, config);

  getFormDocuments2 = (formId) => this.#http.get(`/kyc/forms/${formId}/documents/v2`);

  postFormDocuments2 = (formId, documents, config) => this.#http.post(`/kyc/forms/${formId}/documents/v2`, documents, config);

  getAccountDetails = (userId) => this.#http.get(`/users/${userId}`);

  editAccountDetails = (userId, name, department, title, phone) => this.#http.patch(`/users/${userId}`, {
    name, department, title, phone,
  });

  // Users - start - new
  getUser = (userId) => this.#http.get(`/users/${userId}`);

  updateUser = (userId, payload) => this.#http.patch(`/users/${userId}`, payload);
  // Users - end - new

  // integrated lists
  getMockLists = () => this.#http.get('/lists/mocks');

  getMockList = (id) => this.#http.get(`/lists/mocks/${id}`);

  deleteMockList = (id) => this.#http.delete(`/lists/mocks/${id}`);

  editMockList = (id, name, sensivity, score, settings) => this.#http.patch(`/lists/mocks/${id}`, {
    name,
    sensivity,
    score,
    settings,
  });

  createMockList = (data) => this.#http.post('/lists/mocks', data);

  updateMockClients = (listId, fields) => this.#http.patch(`/lists/mocks/${listId}`, {
    fields,
  });
  // integrated lists

  // automated lists
  getOnboardingLists = () => this.#http.get('/lists/onboarding');

  getAutomatedLists = () => this.#http.get('/lists/auto');

  getAutomatedList = (id) => this.#http.get(`/lists/${id}`);

  createAutomatedList = (data) => this.#http.post('/lists/auto', data);

  pushAutomatedClients = (id) => this.#http.get(`/lists/auto/${id}/push`);

  deleteAutomatedList = (id) => this.#http.delete(`/lists/${id}`);

  getAutomatedListPermissions = (listId) => this.#http.get(`/lists/${listId}/permissions`);

  getAutomatedListLogs = (id) => this.#http.get(`/lists/${id}/logs`);

  getAutomatedStates = (listId) => this.#http.get(`/lists/${listId}/states`);

  editAutomatedList = (id, data) => this.#http.patch(`/lists/${id}`, data);

  getAutomatedClient = (listId, clientId) => this.#http.get(`lists/${listId}/clients/${clientId}`);

  getAutomatedClientDetails = (listId, id) => this.#http.get(`lists/${listId}/clients/${id}/search/results`);

  editAutomatedClient = (listId, clientId, data) => this.#http.patch(`/lists/${listId}/clients/${clientId}`, { ...data });

  changeAutomatedClientStatus = (listId, clientId, status) => this.#http.patch(`/lists/${listId}/clients/${clientId}`, { status });

  getAutomatedClientDocuments = (listId, clientId) => this.#http.get(`/lists/${listId}/clients/${clientId}/documents`);

  postAutomatedClientDocuments = (listId, clientId, documents, config) => this.#http.post(`/lists/${listId}/clients/${clientId}/documents`, documents, config);

  deleteAutomatedClientDocument = (listId, clientId, docId) => this.#http.delete(`/lists/${listId}/clients/${clientId}/documents/${docId}`);

  changeReferencedStatusAutomated = (listId, clientId, resultId, isTagged) => this.#http.patch(`/lists/${listId}/clients/${clientId}/results/${resultId}`, {
    is_tagged: isTagged,
  });

  requestAccessToAutomatedList = (listId) => this.#http.post(`/lists/${listId}/permissions/temporary`, {
    access_type: 2,
  });
  // automated lists

  // World Factbook
  getFactbookCountries = () => this.#http.get('/factbook');

  getFactbookCountryData = (countryCode) => this.#http.get(`/factbook/${countryCode}`);
  // World Factbook

  checkPdfReport = (uuid) => this.#http.get(`/search/reports/${uuid}`);

  searchExperimentalAdverseMedia = (params) => this.#http.post('/search/media/experimental', params);

  getLatestListSearches = (id, token = '') => this.#http.get(`/lists/${id}/searches/latest${token}`);
}

export const api = new API({});
export const APIContext = createContext();

export function ProvideAPI({ children }) {
  return (
    <APIContext.Provider value={api}>
      {children}
    </APIContext.Provider>
  );
}
ProvideAPI.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAPI() {
  return useContext(APIContext);
}
