import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
/* eslint-disable */
import { loadClient } from 'store/reducers/lists';
import { useAPI } from 'services/api';

import ClientProfile from 'components/ClientProfile';
import ClientResults from 'components/ClientResults';
import SourceInfo from 'components/SourceInfo';
import ClientDropdown from 'components/ClientDropdown';
import ClientDocuments from 'components/ClientDocuments';
import ClientAdverseMedia from 'components/ClientAdverseMedia';
import ClientPEPResults from 'components/ClientPEPResults';

function ClientDetails({ listId, clientId, toggle, baseId, clientName, cantEdit, requestAccessToggle }) {
  const api = useAPI();
  
  const expandedClientColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Score',
      selector: 'score',
      width: '60px',
      sortable: true,
    },
    {
      name: 'Source',
      selector: 'source',
      width: '120px',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      width: '125px',
      sortable: true,
    },
    {
      name: 'Action',
      selector: 'action',
      width: '150px',
      sortable: true,
    },
  ];

  const collapsedClientColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Action',
      selector: 'action',
      width: '150px',
      sortable: true,
    },
  ];

  const pepClientColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Place of Birth',
      selector: 'birthPlace',
      sortable: true,
    },
    {
      name: 'Office',
      selector: 'office',
      sortable: true,
    },
    {
      name: 'Action',
      selector: 'action',
      width: '150px',
      sortable: true,
    },
];

  const dispatch = useDispatch();
  const { options } = useSelector(({ lists: { data } }) => data
  .find((l) => l.id === parseInt(listId, 10)) ?? {});
  const clientData = useSelector((state) => state.client);
  const [initialized, setInitialized] = useState(false);
  const [activeTab, setActiveTab] = useState('2');
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [pepResults, setPepResults] = useState([]);
  const [adverseMediaResults, setAdverseMediaResults] = useState([]);
  const [source, setSource] = useState([]);
  // eslint-disable-next-line
  const [sourceType, setSourceType] = useState('sanction');

  const getSourceData = async () => {
    if (!isNaN(clientId) && clientId !== false) {
      setLoading(true);
      const { data } = await api.getClient(parseInt(listId, 10), parseInt(clientId, 10));
      // Sanction Results
      setResults(data.client?.results.filter((r)=>r.search.type_id===1));
      // PEP Results
      const fixedPepResults = data.client?.results.filter((r)=>r.search.type_id===2).map((r)=>{
        const pepObject = {};
        Object.keys(r.content).map((k)=>{
          Object.assign(pepObject,{ [k]: r.content[k].value });       
        });
        return {...r, content: pepObject};
      });
      setPepResults(fixedPepResults);
      // Adverse Media Results
      const sorted = data.client?.results
      .filter((r)=>r.search.type_id===3)
      .sort((a, b)=>{
        if(new Date(a?.content?.date)< new Date(b?.content?.date)){
          return 1;
        }
        if(new Date(a?.content?.date)> new Date(b.content?.date)){
          return -1
        }
        return 0;
      })
      setAdverseMediaResults(sorted);
      setToggleSwitch(data?.client?.is_tailing === true);
      if (activeTab !== "3" && activeTab !== "2") {
        if (data?.client?.results && data?.client?.results.length > 0) {
          setActiveTab("2");
        } else {
          setActiveTab("1");
        }
      }
      setLoading(false);
    }

  };

  useEffect(() => {
    if (
      (!clientData?.client?.id || clientData.client.id !== clientId) &&
      clientId !== false
    ) {
      dispatch(loadClient(listId, clientId));
      setInitialized(true);
      getSourceData();
      if (clientData.client.tail !== undefined) {
        setToggleSwitch(clientData.client.tail);
      }
    }
  }, [
    clientId,
    baseId,
  ]);

  const getClickedSource = (id) => {
    let selectedSourceType = [];
    if(results.find((r)=>r.id===id) !== undefined){
      setSourceType('sanction');
      selectedSourceType = results;
    }
    if(pepResults.find((r)=>r.id===id) !== undefined){
      setSourceType('pep');
      selectedSourceType = pepResults;
    }
    const selectedSource = selectedSourceType.find((r)=>r.id===id);
    setSource(selectedSource.content);
  }
  // eslint-disable-next-line
  const changeTail = async (tail) => {
    const { data } = await api.changeTail(baseId, tail);
    setToggleSwitch(data.tail);
    dispatch(loadClient(listId, clientId));
  }
  
  return (
    <Card>
      <CardBody className="pb-0">
        <CardTitle>
          <div style={{ display: "flex", padding: "0px" }}>
            <div className="d-flex nameTail mt-4">
              <h5 className="modal-title mt-0 ml-3 align-self-center font-weight-bold capitalize">
                {clientName}
              </h5>
              {false && (
                <div className="ml-3">
                  <Button
                    type="button"
                    color="link"
                    className="btn btn-link"
                    style={{
                      width: '24px',
                      placeItems: 'center',
                    }}
                    onClick={() => {
                      if (cantEdit) {
                        requestAccessToggle(true);
                      } else {
                        changeTail(!toggleSwitch);
                      }
                    }}
                  >

                    <div className="surveil-eye">
                      <i
                        className={classnames({
                          'mdi': true,
                          'mdi-eye-check': toggleSwitch,
                          'mdi-eye': !toggleSwitch,

                        })}
                        style={{
                          marginLeft: '-12px',
                          fontSize: '20px',
                          color: toggleSwitch ? '#556EE6' : 'gray',
                        }}
                      />
                      <span className="tooltiptext">Surveillance</span>
                    </div>
                  </Button>
                </div>
              )}
              <div className="ml-3">
                <ClientDropdown requestAccessToggle={requestAccessToggle} cantEdit={cantEdit} listId={listId} clientData={clientData.client} />
              </div>
            </div>
            <button
              type="button"
              className="close closeWithData  ml-auto"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                fontSize: "24px",
                padding: "0px",
                height: "24px",
              }}
              onClick={() => {
                setInitialized(false);
                toggle(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </CardTitle>
      </CardBody>
      <Row>
        <Col md={(source?.uuid === undefined && source?.name === undefined ) ? "12" : "7"} className="leftCol">
          <CardBody
            style={{
              height: "70vh",
              overflowY: "scroll",
            }}
          >
            <Nav tabs className="nav-tabs-custom">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    setActiveTab("1");
                  }}
                >
                  <span className="d-none d-sm-block">Profile</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    setActiveTab("2");
                  }}
                >
                  <span className="d-none d-sm-block">Results</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    setActiveTab("3");
                  }}
                >
                  <span className="d-none d-sm-block">Documents</span>
                </NavLink>
              </NavItem>
             {options?.search_pep && <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    setActiveTab("4");
                  }}
                >
                  <span className="d-none d-sm-block">PEP</span>
                </NavLink>
              </NavItem>}
              {options?.search_media && <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    setActiveTab("5");
                  }}
                >
                  <span className="d-none d-sm-block">Adverse Media</span>
                </NavLink>
              </NavItem>}
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="pt-3 pl-3 pr-3 pb-0">
                <Row>
                  <Col sm="12">
                    <ClientProfile
                      requestAccessToggle={requestAccessToggle}
                      cantEdit={cantEdit}
                      client={clientData.client}
                      listId={listId} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="pt-3 pl-3 pr-3 pb-0">
                <Row>
                  <Col sm="12">
                    <ClientResults
                      clientResultColumns={source?.uuid === undefined ? expandedClientColumns : collapsedClientColumns}
                      results={results}
                      setResults={setResults}
                      getClickedSource={getClickedSource}
                      getSourceData={getSourceData}
                      loading={loading}
                      listId={listId}
                      clientId={clientId}
                      cantEdit={cantEdit}
                      requestAccessToggle={requestAccessToggle}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3" className="pt-3 pl-3 pr-3 pb-0">
                <Row>
                  <Col sm="12">
                    <ClientDocuments
                      listId={listId}
                      clientId={clientId}
                      cantEdit={cantEdit}
                      requestAccessToggle={requestAccessToggle} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4" className="pt-3 pl-3 pr-3 pb-0">
                <Row>
                <Col sm="12">
                <ClientPEPResults
                      clientResultColumns={pepClientColumns}
                      results={pepResults}
                      setResults={setPepResults}
                      getClickedSource={getClickedSource}
                      getSourceData={getSourceData}
                      loading={loading}
                      listId={listId}
                      clientId={clientId}
                      cantEdit={cantEdit}
                      requestAccessToggle={requestAccessToggle}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5" className="pt-3 pl-3 pr-3 pb-0">
                <Row>
                  <Col sm="12">
                    <ClientAdverseMedia
                      loading={loading}
                      listId={listId}
                      clientId={clientId}
                      results={adverseMediaResults}
                      getSourceData={getSourceData}
                      />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Col>
        {(source?.uuid !== undefined || source?.name !== undefined)  && (
          <SourceInfo type={sourceType} info={source} setSource={setSource} />
        )}
      </Row>
    </Card>
  );
}

ClientDetails.propTypes = {
  listId: PropTypes.number.isRequired,
  // eslint-disable-next-line
  clientId: PropTypes.any.isRequired,
  clientName: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  baseId: PropTypes.number.isRequired,
  cantEdit: PropTypes.bool.isRequired,
};

export default ClientDetails;