import React from 'react';

export const mapColumns = [
  {
    name: 'Country or category',
    selector: 'name',
    sortable: true,
  },
];

export const customTableStyles = {
  headCells: {
    style: {
      background: '#F8F9FA',
      color: 'black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      height: '44px',
    },
    sortFocus: {
      color: '#FFFFFF',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#CDE1FF',
      cursor: 'pointer',
    },
  },
};

export const resultModalFields = [
  {
    key: 'country',
    label: ' Country or category',
    render(value) {
      return (<td><p className="capitalize">{value}</p></td>);
    },
  },
  {
    key: 'population',
    label: 'Population',
    render(value) {
      if (value?.text !== undefined) {
        return <td><p>{value?.text}</p></td>;
      }
      return (<td><p>{value}</p></td>);
    },
  },
  { key: 'nationality', label: 'Nationality' },
  {
    key: 'ethnicGroups',
    label: 'Ethnic Groups',
    render(value) {
      if (value?.text !== undefined) {
        return <td><p>{value?.text}</p></td>;
      }
      return (<td><p>{value}</p></td>);
    },
  },
  {
    key: 'languages',
    label: 'Languages',
    render(value) {
      if (value?.text !== undefined) {
        return <td><p>{value?.text}</p></td>;
      }
      return (<td><p>{value}</p></td>);
    },
  },
  { key: 'legalSystem', label: 'Legal System' },
  {
    key: 'political',
    label: 'Political parties and leaders',
    render(value) {
      if (value?.text !== undefined) {
        return (
          <td>
            <p dangerouslySetInnerHTML={{
              __html: value?.text,
            }}
            />
          </td>
        );
      }

      return (
        <td>
          <p dangerouslySetInnerHTML={{
            __html: value,
          }}
          />
        </td>
      );
    },
  },
  { key: 'organization', label: 'International organization participation' },
  { key: 'creditRatings', label: 'Credit ratings' },
  {
    key: 'realGDP',
    label: 'Real GDP',
    render(value) {
      if (value?.text !== undefined) {
        return <td><p>{value?.text}</p></td>;
      }
      return (<td><p>{value}</p></td>);
    },
  },
  { key: 'easeOfDoingBusiness', label: 'Ease of doing business index scores' },
  { key: 'exports', label: 'Exports' },
  { key: 'exportsPartners', label: 'Exports - partners' },
  { key: 'exportsCommodities', label: 'Exports - commodities' },
  { key: 'imports', label: 'Imports' },
  { key: 'importsPartners', label: 'Imports - partners' },
  { key: 'importsCommodities', label: 'Imports - commodities' },
  {
    key: 'disputesInternational',
    label: 'Disputes - international',
    render(value) {
      return (
        <td>
          <p dangerouslySetInnerHTML={{
            __html: value,
          }}
          />
        </td>
      );
    },
  },
  {
    key: 'trafficking',
    label: 'Trafficking in persons',
    render(value) {
      return (
        <td>
          {value?.currentSituation
        && (
        <p>
          <b>Current situation: </b>
          {value?.currentSituation }
        </p>
        )}
          <br />
          {value?.tierRating
        && (
        <p>
          <b>Tier rating: </b>
          {value?.tierRating }
        </p>
          )}
        </td>
      );
    },
  },
];
