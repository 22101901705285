import React from 'react';

import ReactApexChart from 'react-apexcharts';

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';

const chart = {
  series: [56, 38, 26],
  options: {
    labels: ['Need action', 'Clear', 'Positive'],
    colors: ['#f1b44c', '#34C38F', '#F46A6A'],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
  },
};

function DonutChart() {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{' '}</CardTitle>
        <div id="donut-chart" className="apex-charts">
          <ReactApexChart options={chart.options} series={chart.series} type="donut" height="300" />
        </div>
        <Row>
          {chart.options.labels.map((r, index) => (
            <Col xs="4" key={r} className="d-flex justify-content-center">
              <div className="mt-4">
                <p className="mb-2 text-truncate">
                  <i
                    className="mdi mdi-circle mr-1"
                    style={{
                      color: chart.options.colors[index],
                    }}
                  />
                  {r}
                </p>
                <h5 style={{
                  textAlign: 'center',
                }}
                >
                  {chart.series[index]}
                </h5>
              </div>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
}

export default DonutChart;
