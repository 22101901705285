import { getDateValue } from './date';
import { getNestedValue } from './object';

export const sortByDate = (data, key = 'date', direction = 'desc') => {
  const sorted = data.sort((a, b) => {
    if (getDateValue(a?.[key]) < getDateValue(b?.[key])) {
      return 1;
    }
    if (getDateValue(a?.[key]) > getDateValue(b?.[key])) {
      return -1;
    }
    return 0;
  });
  if (direction === 'desc') return sorted;
  return sorted.reverse();
};

export const sortString = (key, a, b) => {
  const aString = getNestedValue(key, a);
  const bString = getNestedValue(key, b);

  if (aString < bString) {
    return -1;
  }
  if (aString > bString) {
    return 1;
  }

  return 0;
};

export const sortDate = (key, a, b) => {
  const aDate = getDateValue(getNestedValue(key, a));
  const bDate = getDateValue(getNestedValue(key, b));

  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }

  return 0;
};
