import React from 'react';

const fixedDate = (date) => {
  try {
    const givenDate = new Date(date);
    const hh = givenDate.getHours();
    const hours = hh < 10 ? `0${hh}` : hh;
    const m = givenDate.getMinutes();
    const minute = m < 10 ? `0${m}` : m;
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat
      .formatToParts(givenDate);

    return `${day} ${month} ${year} at ${hours}:${minute}`;
  } catch (error) {
    return '';
  }
};

export const parameterFields = [
  {
    key: 'name',
    label: 'Search name: ',
  },
  {
    key: 'id_number',
    label: 'ID Number: ',
    render(value) {
      if (value.length === 0) return 'None';
      return value;
    },
  },
  {
    key: 'user',
    label: 'User: ',
    render(user) {
      return user.name;
    },
  },
  {
    key: 'entity_type',
    label: 'Type: ',
    render(value) {
      if (value.length === 0) return 'All Type';
      return value;
    },
  },
  {
    key: 'country',
    label: 'Country: ',
    render(values) {
      return [...values].join(', ');
    },
  },
  {
    key: 'birth_place',
    label: 'Place of birth: ',
    render(value) {
      if (value.length === 0) return 'None';
      return value;
    },
  },
  { key: 'birthDate', label: 'Date of birth: ' },
  {
    key: 'date',
    label: 'Search date: ',
    render(value) {
      return fixedDate(value);
    },
  },
  {
    key: 'score',
    label: 'Minimum name score: ',
    render(value) {
      return <span className="yes-no">{value}</span>;
    },
  },
  {
    key: 'settings',
    label: '',
    render(values) {
      return Object.keys(values).map((k) => {
        let label = k;
        switch (k) {
          case 'aka':
            label = 'Include AKA: ';
            break;
          case 'phonetics':
            label = 'Include phonetics: ';
            break;
          default:
            break;
        }
        if (k === 'no_logging') return null;
        return (
          <div key={k} className="parameter-row">
            <span>
              {label}
            </span>
            <span className="yes-no">{values[k] ? 'Yes' : 'No'}</span>
          </div>
        );
      });
    },
  },
];

export const pepFields = [
  {
    key: 'name',
    label: 'Name: ',
    render(name) {
      return (<span>{name.value}</span>);
    },
  },
  {
    key: 'gender',
    label: 'Gender: ',
    render(gender) {
      return (<span>{gender.value}</span>);
    },
  },
  {
    key: 'office',
    label: 'Office: ',
    render(office) {
      return (<span>{office.value}</span>);
    },
  },
  {
    key: 'title',
    label: 'Title: ',
    render(title) {
      if (title === null) return (<span>N/A</span>);
      return (<span>{title.value}</span>);
    },
  },
  {
    key: 'birthPlace',
    label: 'Place of Birth: ',
    render(place) {
      return (<span>{place.value}</span>);
    },
  },
  {
    key: 'abstract',
    label: 'Abstract: ',
    render(abstract) {
      return (<span>{abstract.value}</span>);
    },
  },

];

export const resultFields = [
  { key: 'source.name', label: 'Source: ' },
  { key: 'entity_type', label: 'Type: ' },
  { key: 'name', label: 'Full Name: ' },
  {
    key: 'score',
    label: 'Score: ',
    render(value) {
      return parseInt(value, 10);
    },
  },
  {
    key: 'identification',
    label: 'Identification: ',
    render(values) {
      if (values.length !== undefined) {
        if (typeof values === 'string') {
          return (
            <span dangerouslySetInnerHTML={{
              __html: values,
            }}
            />
          );
        }
        return (
          <span>
            {
              values.map((val) => Object.keys(val).map((key) => {
                const value = val[key];
                return (<p>{`${key}: ${value}`}</p>);
              }))
            }
          </span>
        );
      }
      return (
        <span>
          {
            Object.keys(values).map((key) => {
              const value = values[key];
              return (<p>{`${key}: ${value}`}</p>);
            })
          }
        </span>
      );
    },
  },

  {
    key: 'birth_date',
    label: 'Date of Birth: ',
    render(value) {
      let dateParts = value;
      if (typeof value === 'string') {
        [dateParts] = value.split('-');
      }
      return (<span>{`${dateParts}`}</span>);
      // return (<span>{`${dateParts[0]}`}</span>);
    },
  },
  { key: 'birth_place', label: 'Place of Birth: ' },
  { key: 'nationality', label: 'Nationality: ' },
  { key: 'citizenship', label: 'Citizenship: ' },
  { key: 'addresses', label: 'Address: ' },
  {
    key: 'last_update_date',
    label: 'Last Updated: ',
    render(value) {
      return (<span>{fixedDate(value)}</span>);
    },
  },

  {
    key: 'all_names',
    label: 'Aliases: ',
    render(values) {
      return (
        <div
          key="aliases"
          className="parameter-row"
          style={{
            gridColumn: values.length > 3 ? 'span 2' : 'span 1',
          }}
        >
          <span>
            {' '}
          </span>
          <span>
            {
          Object.keys(values).map((key, ind) => {
            const value = values[key].toLowerCase();
            return (
              <>
                {value}
                {Object.keys(values).length - 1 === ind ? '' : ', '}
              </>
            );
          })
        }
          </span>
        </div>
      );
    },
  },
  {
    key: 'comments',
    label: 'Comments: ',
    render(values) {
      let temp = values;
      if (typeof values === 'object') temp = values.join(' ');
      return (
        <div
          key="comments"
          className="parameter-row"
          style={{
            gridColumn: 'span 2',
          }}
        >
          <span>
            Comments:
            {' '}
          </span>
          <span>
            {temp.replace('<br><br>', '<br>')}
          </span>
        </div>
      );
    },
  },
  {
    key: 'clientSpecific',
    label: 'Additional Information: ',
    render(values) {
      // replaceAll puppeteer error
      const clientSpecific = values.replace('<br/><br/>', '<br/>');
      return (
        <div
          key="clientSpecific"
          className="parameter-row"
          style={{
            gridColumn: 'span 2',
          }}
        >
          <span style={{
            fontWeight: '500',
          }}
          >
            Additional Information:
            {' '}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: clientSpecific.replace('Additional Information: ', ''),
            }}
          />
        </div>
      );
    },
  },
];

export const historyFields = [
  {
    key: 'search_name',
    label: 'Search Name: ',
  },
  {
    key: 'search_date',
    label: 'Search Date: ',
    render(timeValue) {
      return (<span>{fixedDate(timeValue)}</span>);
    },
  },
  {
    key: 'user',
    label: 'User: ',
    render(user) {
      return (<span>{user.name}</span>);
    },
  },
  {
    key: 'search_score',
    label: 'Score: ',
  },
  {
    key: 'search_results_length',
    label: 'Search Result Count: ',
  },
  {
    key: 'search_entity_type',
    label: 'Entity Type: ',
    render(value) {
      if (value.length === 0) return 'All Type';
      return value;
    },
  },
  {
    key: 'search_country',
    label: 'Country: ',
    render(values) {
      if (values.length === 0) return 'N/A';
      return (
        <div key="search_country" className="parameter-row">
          <span>
            Country
          </span>
          <span>{values.join(', ')}</span>
        </div>
      );
    },
  },
  {
    key: 'search_birth_place',
    label: 'Place of Birth: ',
    render(value) {
      if (value.length === 0) return 'N/A';
      return value;
    },
  },
  {
    key: 'search_birth_date_start',
    label: 'Date of Birth Start: ',
    render(value) {
      if (value === null) return null;

      return (
        <div key="search_birth_date_start" className="parameter-row">
          <span>
            Date of Birth Start:
            {' '}
          </span>
          <span>{fixedDate(value)}</span>
        </div>
      );
    },
  },
  {
    key: 'search_birth_date_end',
    label: 'Date of Birth End ',
    render(value) {
      if (value === null) return null;

      return (
        <div key="search_birth_date_start" className="parameter-row">
          <span>
            Date of Birth Start:
            {' '}
          </span>
          <span>{fixedDate(value)}</span>
        </div>
      );
    },
  },
  {
    key: 'search_options',
    label: '',
    render(values) {
      return Object.keys(values).map((k) => {
        let label = k;
        switch (k) {
          case 'aka':
            label = 'Include AKA: ';
            break;
          case 'phonetics':
            label = 'Include phonetics: ';
            break;
          default:
            break;
        }
        if (k === 'no_logging') return null;
        return (
          <div key={k} className="parameter-row">
            <span>
              {label}
            </span>
            <span className="yes-no">{values[k] ? 'Yes' : 'No'}</span>
          </div>
        );
      });
    },
  },

];

export const listFields = [
  // search_birth_date_start: null,
  // search_birth_date_end: null,
  // search_country: [],
  { key: 'search_name', label: 'Search name: ' },
  { key: 'search_entity_type', label: 'Entity type: ' },
  { key: 'search_identity_number', label: 'ID number: ' },
  { key: 'search_birth_place', label: 'Place of birth: ' },
  { key: 'search_score', label: 'Search score: ' },
  { key: 'search_results_length', label: 'Results count: ' },
  {
    key: 'search_date',
    label: 'Search date: ',
    render(value) {
      return fixedDate(value);
    },
  },
  {
    key: 'search_options',
    label: 'Search options: ',
    render(values) {
      return Object.keys(values).map((k) => {
        let label = k;
        switch (k) {
          case 'aka':
            label = 'Include AKA: ';
            break;
          case 'phonetics':
            label = 'Include phonetics: ';
            break;
          default:
            break;
        }
        if (k === 'no_logging') return null;
        return (
          <div key={k} className="parameter-row">
            <span>
              {label}
            </span>
            <span className="yes-no">{values[k] ? 'Yes' : 'No'}</span>
          </div>
        );
      });
    },
  },

];
