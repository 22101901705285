import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { request } from 'helpers';

export const fetchSources = createAsyncThunk(
  'sources/fetch',
  async (_, { rejectWithValue }) => {
    const [res, error] = await request(api.getSources());

    if (error) {
      return rejectWithValue(error);
    }
    const { data } = res;
    return data;
  },
);

export const fetchSourceDetail = createAsyncThunk(
  'sources/detail/fetch',
  async (id, { rejectWithValue }) => {
    const [res, error] = await request(api.getSourceDetail(id));

    if (error) {
      return rejectWithValue(error);
    }
    const { data } = res;
    return data;
  },
);

const initialState = {
  pending: false,
  error: null,
  data: [],
  detail: {},
};

const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSources.pending]: (state) => {
      state.pending = true;
      state.data = [];
      state.error = null;
    },
    [fetchSources.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
    [fetchSources.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    [fetchSourceDetail.pending]: (state) => {
      state.pending = true;
      state.detail = {};
      state.error = null;
    },
    [fetchSourceDetail.fulfilled]: (state, action) => {
      state.pending = false;
      state.detail = action.payload;
    },
    [fetchSourceDetail.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

export default sourcesSlice.reducer;
