import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Container,
  Col,
  Button,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ListRow from './ListRow';
import CancelConfirmModal from './CancelConfirmModal';

import { deleteList } from '../store/reducers/lists';
import { changeRoute, resetRoute } from '../store/reducers/route';
import { useAPI } from '../services/api';

// const filter = (term) => (item) => {
//   if (!term) {
//     return true;
//   }
//   if (item.name.toLowerCase().includes(term.toLowerCase())) {
//     return true;
//   }
//   return item.clients.some(
//     (client) => client.name.toLowerCase().includes(term.toLowerCase()) >= 0,
//   );
// };

export default function List({ listData }) {
  const api = useAPI();

  const dispatch = useDispatch();

  const isSubUser = useSelector((state) => state.auth.user.role === 1);
  const loading = useSelector((state) => state.lists.loading);
  const [search, setSearch] = React.useState('');
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [dataId, setDataId] = useState(null);
  // const [sort, setSort] = useState({ by: 'creation_date', dir: 'desc' });
  const [direction, setDirection] = React.useState([2, 'desc']);
  // eslint-disable-next-line
  const [cantEdit, setCantEdit] = React.useState(true);

  // eslint-disable-next-line
  const ascDescIcon = (n) => (direction.includes(n) ? (	
    direction[1] === 'desc'
      ? <i className="bx bxs-down-arrow align-middle ml-1" />
      : <i className="bx bxs-up-arrow align-middle ml-1" />
  ) : '');

  const sortTable = (n) => {
    let rows;
    let switching;
    let i;
    let x;
    let y;
    let shouldSwitch;
    let dir;
    let switchcount = 0;
    const table = document.getElementById('lists-table');
    switching = true;
    dir = 'asc';
    setDirection([n, 'asc']);
    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i += 1) {
        shouldSwitch = false;

        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];
        if (dir === 'asc') {
          // for comparing date
          if (n === 2) {
            if (new Date(x.id) > new Date(y.id)) {
              shouldSwitch = true;
              break;
            }
          } else if (x.id.toLowerCase() > y.id.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          // for comparing date
          if (n === 2) {
            if (new Date(x.id) < new Date(y.id)) {
              shouldSwitch = true;
              break;
            }
          } else if (x.id.toLowerCase() < y.id.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount += 1;
      } else if (switchcount === 0 && dir === 'asc') {
        dir = 'desc';
        setDirection([n, 'desc']);
        switching = true;
      }
    }
  };

  // eslint-disable-next-line arrow-body-style
  const lists = useMemo(() => {
    if (search.trim()) {
      return listData.filter(({ name, clients }) => name
        .toLowerCase().indexOf(search.toLowerCase()) >= 0 || (clients
        .filter((client) => (client.name.toLowerCase()
          .indexOf(search.toLowerCase()) >= 0))).length > 0);
    }
    return listData;
  }, [search, listData]);

  const handleSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, [setSearch]);

  const handleListDeletion = useCallback((id) => {
    dispatch(deleteList(id));
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setConfirmToggle(false);
    }
  }, [handleListDeletion, loading]);

  // const handleSortChange = useCallback((e) => {
  //   const nextSortBy = e.currentTarget.getAttribute('data-sort-by');
  //   const currSortDir = e.currentTarget.getAttribute('data-sort-dir');
  //   const nextSortDir = currSortDir === 'desc' ? 'asc' : 'desc';
  //   setSort({ by: nextSortBy, dir: nextSortDir });
  // }, []);

  useEffect(() => {
    sortTable(2);
    dispatch(changeRoute('Lists'));
    return () => {
      dispatch(resetRoute());
    };
  }, []);

  const getPerms = async (lId) => {
    try {
      const {
        data: {
          list_permissions: listPermissions,
          user_permissions: userPermissions,
        },
      } = await api.getListPermissions(lId);

      if (isSubUser) {
        let cantEditStatus = true;
        // eslint-disable-next-line
        if(listPermissions?.permissions.can_read && listPermissions?.permissions.can_write) {
          cantEditStatus = false;
          // eslint-disable-next-line
        } else if (userPermissions?.read_all_lists && userPermissions?.write_all_lists) {
          cantEditStatus = false;
        }
        setCantEdit(cantEditStatus);
      } else setCantEdit(false);
    } catch (error) {
      setCantEdit(false);
    }
  };

  const requestAccess = async () => {
    try {
      await api.requestAccessToList(dataId);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onConfirmToggle = () => {
    if (cantEdit) {
      requestAccess();
    } else {
      handleListDeletion(dataId);
    }
    // setConfirmToggle(false);
  };

  return (
    <div>
      <CancelConfirmModal
        loading={loading}
        isOpen={confirmToggle}
        onConfirm={onConfirmToggle}
        onCancel={() => {
          setConfirmToggle(false);
        }}
        deleteUserBtn={!cantEdit}
        selectedName
        cantEdit={cantEdit}
      >
        {cantEdit ? 'You do not have required permission to delete' : 'Are you sure you want to delete' }
        {' '}
        `
        <b>{selectedName}</b>
        ` list.
        {' '}
        {cantEdit ? '' : 'If you proceed, you cannot undo this step and recover your data.'}
      </CancelConfirmModal>
      <Container fluid>
        <Col lg="12">
          <div className="mb-4" style={{ display: 'flex' }}>
            <input
              autoComplete="on"
              className="form-control"
              type="text"
              placeholder="Search..."
              style={{ width: '277px' }}
              value={search}
              onChange={handleSearch}
            />

            <Link to="lists/create" className="ml-auto">
              <Button
                className="btn waves-effect waves-light"
                color="primary"
                style={{ width: '187px' }}
              >
                Add new list
              </Button>
            </Link>
          </div>
          <div className="table-responsive">
            <Table
              id="lists-table"
              className="project-list-table table-nowrap table-centered table-borderless"
              style={{
                borderSpacing: '0 10px',
                borderCollapse: 'separate',
              }}
            >
              <thead id="listHeader">
                <tr style={{ backgroundColor: '#FFFFFF' }}>
                  <th
                    data-sort-by="name"
                    scope="col"
                    className="table-header"
                    onClick={() => sortTable(0)}
                  >
                    Name
                    {ascDescIcon(0)}
                  </th>
                  <th
                    data-sort-by="states"

                    scope="col"
                    className="table-header"
                    onClick={() => sortTable(1)}
                  >
                    Status
                    {ascDescIcon(1)}
                  </th>
                  <th
                    data-sort-by="creation_date"

                    scope="col"
                    className="table-header"
                    onClick={() => sortTable(2)}
                  >
                    Created at
                    {ascDescIcon(2)}
                  </th>
                  <th
                    data-sort-by="creator_name"

                    scope="col"
                    className="table-header"
                    onClick={() => sortTable(3)}
                  >
                    Created by
                    {ascDescIcon(3)}
                  </th>
                  <th scope="col"> </th>
                </tr>
              </thead>
              <tbody>
                {lists.map((list) => (
                  <ListRow
                    key={`list-${list.id}`}
                    rowData={list}
                    deleteList={setConfirmToggle}
                    getPerms={getPerms}
                    setId={setDataId}
                    setListName={setSelectedName}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Container>
    </div>
  );
}

List.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
