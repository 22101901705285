import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';

const initialState = {
  results: [],
  error: false,
  loading: false,
  status: '',
  pepResults: [],
  pepName: '',
  perror: false,
  ploading: false,
  pstatus: '',
  adverseMediaResults: [],
  adverseMediaName: '',
  aderror: false,
  adloading: false,
  adstatus: '',
  lastSearchId: null,
  lastReportId: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchStart(state) {
      state.error = false;
      state.loading = true;
      state.status = '';
    },
    searchSuccess(state, action) {
      state.results = action.payload.results;
      state.lastSearchId = action.payload.searchId;
      state.error = false;
      state.loading = false;
      state.status = 'ok';
    },
    searchFail(state) {
      state.error = true;
      state.loading = false;
    },
    searchReset(state) {
      state.results = [];
      state.error = false;
      state.loading = false;
      state.status = '';
      state.pepResults = [];
    },
    pepSearchStart(state) {
      state.perror = false;
      state.ploading = true;
      state.pstatus = '';
    },
    pepSearchSuccess(state, action) {
      state.pepResults = action.payload.results;
      state.pepName = action.payload.name;
      state.lastSearchId = action.payload.id;
      state.perror = false;
      state.ploading = false;
      state.pstatus = '';
    },
    adverseMediaSearchStart(state) {
      state.aderror = false;
      state.adloading = true;
      state.adstatus = '';
    },
    adverseMediaSearchSuccess(state, action) {
      state.adverseMediaResults = action.payload.results;
      state.adverseMediaName = action.payload.name;
      state.lastSearchId = action.payload.id;
      state.aderror = false;
      state.adloading = false;
      state.adstatus = '';
    },
    requestFileSuccess(state, action) {
      state.lastReportId = action.payload;
    },
  },
});

export const {
  searchStart,
  searchSuccess,
  searchFail,
  searchReset,
  pepSearchStart,
  pepSearchSuccess,
  adverseMediaSearchStart,
  adverseMediaSearchSuccess,
  requestFileSuccess,
} = searchSlice.actions;

export default searchSlice.reducer;

export const makeSearch = (
  searchInput,
  searchSimilarity,
  isPEP,
  isPhonetics,
  isAKA,
  isLogging,
) => async (dispatch) => {
  dispatch(searchStart());
  try {
    const { data } = await api.search({
      searchInput,
      searchSimilarity,
      isPEP,
      isPhonetics,
      isAKA,
      isLogging,
    });
    dispatch(searchSuccess(data));
  } catch (error) {
    dispatch(searchFail(error.message));
  }
};

export const makeAdvancedSearch = (params) => async (dispatch) => {
  dispatch(searchStart());
  try {
    const { data: { results, search } } = await api.advancedSearch(params);
    // const { data } = await api.searchPep(params.name);
    dispatch(searchSuccess({ results, searchId: search.id }));
    // dispatch(pepSearchSuccess(data.results));
  } catch (error) {
    dispatch(searchFail(error.message));
  }
};

export const makePepSearch = (name) => async (dispatch) => {
  dispatch(pepSearchStart());
  try {
    const { data } = await api.searchPep(name);
    dispatch(pepSearchSuccess({ results: data.results, name, id: data.search.id }));
  } catch (error) {
    console.log(error);
  }
};

export const makeAdverseMediaSearch = (name, isIncludeImages = false) => async (dispatch) => {
  dispatch(adverseMediaSearchStart());
  try {
    const { data: { results, search: { id } } } = await api
      .getAdverseMediaResults(name, isIncludeImages);
    dispatch(adverseMediaSearchSuccess({ results, name, id }));
  } catch (error) {
    console.log(error);
  }
};

export const resetSearchState = () => async (dispatch) => {
  dispatch(searchReset());
};

export const requestFile = (searchId, type = 'pdf') => async (dispatch) => {
  try {
    if (searchId !== null) {
      const { data: { report_id: reportId } } = await api.requestFile(searchId, type);
      dispatch(requestFileSuccess(reportId));
    }
  } catch (error) {
    console.log(error);
  }
};
